import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';

import useConditionalSearch from '../../hooks/useConditionalSearch/useConditionalSearch.js';
import useTab, { RouteTabs } from '../../hooks/useTab/useTab.js';
import useTablePagination from '../../hooks/useTablePagination/useTablePagination.jsx';
import useTableSorting from '../../hooks/useTableSorting/useTableSorting.jsx';
import type { WithPaginationResponse } from '../types.js';
import {
  createUser as createUserReq,
  deleteUser as deleteUserReq,
  editUser as editUserReq,
  fetchUsers,
  resendUserInvitation as resendUserInvitationReq,
  toggleUserActivityStatus as toggleUserActivityStatusReq,
  type UserType,
} from '../users/index.js';
import { checkEmail, checkEmailNew } from '../users/users.js';
import { queryClient, QueryKey } from './main.js';

type Params = {
  getFullList?: boolean;
  // in case of modal use fro useUsers
  disableUsersFetch?: boolean;
};

export const useUsers = (params?: Params) => {
  const searchParams = useConditionalSearch();

  const { limit, offset } = useTablePagination();

  const { getFullList, disableUsersFetch } = params ?? {};

  const { orderingBE } = useTableSorting();

  const searchString = !getFullList ? searchParams?.search : '';

  const tab = useTab();

  const users = useQuery({
    queryKey: [
      QueryKey.UsersData,
      searchString,
      limit,
      offset,
      orderingBE,
      getFullList,
    ],
    queryFn: () =>
      fetchUsers({
        search: searchString,
        limit: getFullList ? 0 : limit,
        offset: offset,
        ordering: orderingBE,
      }),

    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    enabled:
      !disableUsersFetch && (tab === RouteTabs.User || Boolean(getFullList)),
  });

  const usersData =
    getFullList || limit === 0
      ? (users.data as UserType[])
      : ((users.data as unknown as WithPaginationResponse<UserType>)?.results ??
        null);

  const createUser = useMutation({
    mutationFn: createUserReq,
  });

  const editUser = useMutation({
    mutationFn: editUserReq,
  });

  const toggleUserActivityStatus = useMutation({
    mutationFn: toggleUserActivityStatusReq,
  });

  const deleteUser = useMutation({
    mutationFn: deleteUserReq,
  });

  const resendUserInvitation = useMutation({
    mutationFn: resendUserInvitationReq,
  });

  const refetchUsers = () => {
    return queryClient.invalidateQueries({
      queryKey: [QueryKey.UsersData],
    });
  };

  const checkUserByEmail = useMutation({ mutationFn: checkEmail });
  const checkUserByEmailNew = useMutation({ mutationFn: checkEmailNew });

  return {
    users,
    usersData,
    createUser,
    editUser,
    deleteUser,
    resendUserInvitation,
    toggleUserActivityStatus,
    refetchUsers,
    checkUserByEmail,
    checkUserByEmailNew,
  };
};
