import { css, Global } from '@emotion/react';

import { useTheme } from '../index.js';

export const GlobalStyles = () => {
  const theme = useTheme();
  return (
    <Global
      styles={css`
        #page-wrapper:has(.grey-background) {
          background-color: ${theme.palette.background.default};
        }
        #page-wrapper:has(#import-hub-layout) {
          scrollbar-gutter: stable;
        }

        /* This is a fallback for browsers (firefox) that still doesn't support :has https://caniuse.com/css-has */
        @supports not selector(#page-wrapper:has(.grey-background)) {
          .grey-background {
            margin: ${theme.spacing(-3)};
            padding: ${theme.spacing(3)};
            background-color: ${theme.palette.background.default};
          }
        }
      `}
    />
  );
};
