import { useMutation, useQuery } from '@tanstack/react-query';

import { ClientType, useIsAgencyCompany } from '@mint-lib/profile-helpers';

import {
  assignClients as assignClientsRequest,
  fetchAgencyClients,
} from '../clients/clients.js';
import { QueryKey } from './main.js';

export const useAgencyClients = () => {
  const isAgencyCompany = useIsAgencyCompany();

  const agencyClients = useQuery<ClientType[]>({
    queryKey: [QueryKey.AgencyClients],
    queryFn: fetchAgencyClients,

    refetchOnWindowFocus: false,
    enabled: isAgencyCompany,
  });

  const data = agencyClients.data ? agencyClients.data : [];

  const assignClients = useMutation({
    mutationFn: assignClientsRequest,
  });
  return { agencyClients: data, assignClients };
};
