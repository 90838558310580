import React from 'react';

import LoadingButtonM, {
  LoadingButtonProps as LoadingButtonPropsM,
} from '@mui/lab/LoadingButton';

import type { IconsList as Icons } from '../../assets/asyncIcons.jsx';
import Icon, { IconProps } from '../Icon/Icon.jsx';

export interface LoadingButtonProps extends LoadingButtonPropsM {
  /**
   * The content of the component.
   * @uxpinpropname Text
   */
  children?: React.ReactNode;

  /**
   * Override or extend the styles applied to the component.
   * See CSS API https://mui.com/api/loading-button/#css for more details.
   */
  /** @uxpinignoreprop */
  classes?: LoadingButtonPropsM['classes'];

  /**
   * The HREF of the button.
   */
  href?: string;

  /**
   * If 'true', the component is disabled.
   */
  disabled?: boolean;

  /**
   * If 'true', the loading indicator is shown.
   */
  loading?: boolean;

  /**
   * Element placed before the children if the button is in loading state.
   * @uxpinignoreprop
   */
  loadingIndicator?: React.ReactNode;
  /**
   * The color of the button.
   */
  color?: LoadingButtonPropsM['color'];

  /**
   * The size of the button.
   */
  size?: LoadingButtonPropsM['size'];

  /**
   * The type of button.
   */
  variant?: LoadingButtonPropsM['variant'];

  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   * See the `sx` page for more details. https://mui.com/system/the-sx-prop/
   */
  sx?: LoadingButtonPropsM['sx'];

  /**
   * On click event to use with UXPin interactions.
   */
  onClick?: LoadingButtonPropsM['onClick'];
  uxpinRef?: React.Ref<HTMLButtonElement>;
  /**
   * The icon element.
   */
  icon?: Icons;
  /**
   * Icon props
   */
  IconProps?: Partial<IconProps>;
}

function LoadingButton({
  uxpinRef,
  children,
  loadingPosition = 'start',
  ...props
}: LoadingButtonProps) {
  return (
    <LoadingButtonM
      {...props}
      ref={uxpinRef}
      loadingPosition={loadingPosition}
      {...(children ? { className: 'has-children' } : {})}
    >
      {props.icon && !props.loading ? (
        <Icon component={props.icon} {...props.IconProps} />
      ) : null}
      {children}
    </LoadingButtonM>
  );
}

export default LoadingButton;
