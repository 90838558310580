import { useEffect, useState } from 'react';

import { useTranslation } from '@mint-lib/i18n';
import { enqueueSnackbar } from '@mint-lib/notifications';
import { SlotPortal } from '@mint-lib/slots';

import { useUserRole } from '../../api/queries/userRoles.js';
import {
  CreateRoleType,
  fetchRolesMeta,
  RolesMetaType,
  RoleType,
} from '../../api/roles/index.js';
import type { WithPaginationResponse } from '../../api/types.js';
import { useModal } from '../../context/ModalContext/useModal.js';
import Filters from '../../features/Filters/Filters.jsx';
import RoleTable from '../../features/RoleTable/index.js';
import useIsReadOnlyPermission from '../../hooks/useIsReadOnlyPermission/useIsReadOnlyPermission.js';
import useTablePagination from '../../hooks/useTablePagination/useTablePagination.jsx';
import RoleModal from '../../organisms/RoleModal/index.jsx';
import { DEFAULT_PAGINATION_STATE } from '../../store/atoms/TablePagination/constants.js';

const RolePage = () => {
  const { t } = useTranslation('@myn/permissions');

  const isReadonlyPermission = useIsReadOnlyPermission();

  const { open: isNewModalOpened, setOpen: setIsNewModalOpened } = useModal();

  const [rolesMeta, setRolesMeta] = useState<RolesMetaType | null>(null);

  const { userRoleData, userRole, createRole, editRole, refetchRoles } =
    useUserRole();

  // init pagination
  useTablePagination({
    ...DEFAULT_PAGINATION_STATE,
    totalItems:
      (userRole?.data as WithPaginationResponse<RoleType>)?.count ||
      (userRole?.data as RoleType[])?.length ||
      0,
  });

  useEffect(() => {
    fetchRolesMeta().then(setRolesMeta);
  }, []);

  const handleCreate = (options: CreateRoleType) =>
    createRole.mutateAsync(options, {
      onSuccess: (data) => {
        setIsNewModalOpened(false);

        enqueueSnackbar({
          removable: true,
          variant: 'success',
          title: t('Success'),
          subTitle: t('Role {{dataName}} was created', { dataName: data.name }),
        });

        refetchRoles();
      },
      onError: () => {
        enqueueSnackbar({
          removable: true,
          variant: 'error',
          title: t('Error'),
          subTitle: t(
            'An error occurred while creating the role. Please try again',
          ),
        });
      },
    });

  const handleUpdate = (options: Partial<RoleType>) =>
    editRole.mutateAsync(options, {
      onSuccess: (data) => {
        setIsNewModalOpened(false);

        enqueueSnackbar({
          removable: true,
          variant: 'success',
          title: t('Success'),
          subTitle: t('Role {{dataName}} was updated', {
            dataName: data.name,
          }),
        });

        refetchRoles();
      },
      onError: () => {
        enqueueSnackbar({
          removable: true,
          variant: 'error',
          title: t('Error'),
          subTitle: t(
            'An error occurred while creating the role. Please try again',
          ),
        });
      },
    });

  const noResultsStatus = !userRole.isLoading && userRoleData?.length === 0;

  const notNullRolesData = (userRoleData ?? []) as RoleType[];

  return (
    <>
      <RoleTable
        rolesMeta={rolesMeta}
        data={notNullRolesData}
        dataIsLoading={userRole.isPlaceholderData || userRole.isLoading}
        isReadonlyPermission={isReadonlyPermission}
        noResultsStatus={noResultsStatus}
      />
      <SlotPortal name="filters">
        <Filters key={'role'} />
      </SlotPortal>

      {isNewModalOpened && rolesMeta && (
        <RoleModal
          onClose={() => setIsNewModalOpened(false)}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          rolesMeta={rolesMeta}
          isActive={isNewModalOpened}
          isReadonlyPermission={isReadonlyPermission}
        />
      )}
    </>
  );
};

export default RolePage;
