import { createFileRoute, notFound } from '@tanstack/react-router';

import { mixChannelLoader } from '@myn/campaign-hub/loaders';

export const Route = createFileRoute('/_secured/campaign/$campaignId/hub')({
  async loader({ context, abortController, params }) {
    await context.serviceLocator.getInstance('i18n', '@myn/campaign-hub');
    return {
      mixChannel: await mixChannelLoader(context, {
        signal: abortController.signal,
        campaignId: params.campaignId,
      }),
      isRetail: await context.profileHelpers.isRetailLicensee(),
    };
  },
});
