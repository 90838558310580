import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_secured/insights/dashboards/$boardId/')(
  {
    beforeLoad: async ({ context }) => {
      const dashboardsAccess = await context.isFlagEnabled(
        '@myn/secured',
        'dashboardsAccess',
      );

      if (!dashboardsAccess) {
        throw redirect({ to: '/campaigns' });
      }
    },
    async loader({ context }) {
      await context.serviceLocator.getInstance(
        'i18n',
        '@myn/dashboards-builder',
      );
    },
  },
);
