import { Palette } from '@mui/material/styles';

const TableRowOverrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          '& .MuiTableCell-root.MuiTableCell-body': {
            backgroundColor: `${palette.background.tertiary} `,
          },
          '&:hover': {
            '& .MuiTableCell-root.MuiTableCell-body': {
              backgroundColor: `${palette.background.tertiary} `,
            },
          },
        },
        '&.Mui-selected, &:hover': {
          '& .MuiTableCell-root.MuiTableCell-body.error': {
            backgroundColor: palette.background.error,
          },
          '& .MuiTableCell-root.MuiTableCell-body.warning': {
            backgroundColor: palette.background.warning,
          },
          '& .MuiTableCell-root.MuiTableCell-body.success': {
            backgroundColor: palette.background.success,
          },
          '& .MuiTableCell-root.MuiTableCell-body.editable': {
            backgroundColor: palette.ui.hoverCell,
          },
        },
        '&:hover': {
          '& .MuiTableCell-root.MuiTableCell-body': {
            backgroundColor: `${palette.ui.hoverRow} `,
          },
          '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.icon-draggable': {
            visibility: 'visible',
          },
        },
        '& .MuiTableCell-root.MuiTableCell-body.hover-editable': {
          '&:hover': {
            backgroundColor: palette.ui.hoverCell,
          },
        },
      },
    },
  };
};

export default TableRowOverrides;
