import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_auth')({
  async beforeLoad({ context }) {
    return context;
  },
  async loader({ context }) {
    await context.serviceLocator.getInstance('i18n', '@myn/guest');
    await context.serviceLocator.getInstance('i18n', '@myn/mui');
  },
});
