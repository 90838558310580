import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import { useTranslation } from '@mint-lib/i18n';

import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyCellProps,
  TableCellExpander,
  TableHead,
  TableHeaderCell,
  TableRow,
} from '@myn/mui';

import type {
  TableCellDataType,
  TableDataType,
} from './permissionsTable.type.js';

export const AccessLevelsMap = {
  NoAccess: 'no_access_level',
  ReadOnly: 'read_only_level',
  ReadAndWrite: 'read_and_write_level',
};

export const SecondLevelParents = [
  'campaignStrategyFeature',
  'campaignPlanningFeature',
  'integrationsFeature',
  'campaignReportsInsightsFeature',
];

const isPermissionChecked = (
  level: string,
  data: TableCellDataType,
  rowLevel?: number,
): boolean => {
  if (data.children && rowLevel !== 1) {
    return data.children.every((childData) =>
      isPermissionChecked(level, childData),
    );
  }

  if (data.children && rowLevel === 1) {
    return data.accessLevel === level;
  }

  return data.accessLevel === level;
};

const changeTabPermission = (
  accessLevel: string,
  activeName: string,
  data: TableCellDataType,
  parents: any,
): TableCellDataType => {
  const parentsState = parents;

  if (SecondLevelParents.includes(data.name)) {
    parentsState[data.name] = {
      accessLevel: data.name === activeName ? accessLevel : data.accessLevel,
      children: data.children?.map((el) => el.name),
    };
  }

  for (const key in parentsState) {
    if (parentsState[key]?.children?.includes(activeName)) {
      if (
        parentsState[key]?.accessLevel === AccessLevelsMap.NoAccess &&
        (accessLevel === AccessLevelsMap.ReadAndWrite ||
          accessLevel === AccessLevelsMap.ReadOnly)
      ) {
        return {
          ...data,
          accessLevel:
            data.name === activeName
              ? AccessLevelsMap.NoAccess
              : data.accessLevel,
        };
      }
      if (
        parentsState[key]?.accessLevel === AccessLevelsMap.ReadOnly &&
        accessLevel === AccessLevelsMap.ReadAndWrite
      ) {
        return {
          ...data,
          accessLevel:
            data.name === activeName
              ? AccessLevelsMap.ReadOnly
              : data.accessLevel,
        };
      }
    }
  }

  if (data.children) {
    // * need to check all children if parent has been checked
    // * for that child.name is passed to the next level in order
    // * to check all nested levels
    const isLevelNameMatchName = data.name === activeName;

    return {
      ...data,
      accessLevel: data.name === activeName ? accessLevel : data.accessLevel,
      children: data.children.map((child) =>
        changeTabPermission(
          accessLevel,
          isLevelNameMatchName ? child.name : activeName,
          child,
          parentsState,
        ),
      ),
    };
  }

  if (
    activeName === 'companyInformationFeature' &&
    accessLevel === AccessLevelsMap.NoAccess
  ) {
    return {
      ...data,
      accessLevel: AccessLevelsMap.ReadOnly,
    };
  }

  return {
    ...data,
    accessLevel: data.name === activeName ? accessLevel : data.accessLevel,
  };
};

type PermissionsTableProps = {
  data: TableDataType;
  setData: (state: TableDataType) => void;
  nameLabels?: {
    [key: string]: string;
  };
  isDisabled?: boolean;
};

const PermissionsTable = ({
  data,
  setData,
  isDisabled,
}: PermissionsTableProps) => {
  const { t } = useTranslation('@myn/permissions');

  const [expanded, setExpanded] = useState<ExpandedState>(true);

  const bottomItems = data?.main?.children?.map((el) => {
    const lastItem = el?.children?.[el?.children?.length - 1];

    if (lastItem) {
      const lastItemChildren =
        lastItem?.children?.[lastItem?.children?.length - 1];

      return lastItemChildren?.name || lastItem?.name;
    }

    return el?.children?.[el?.children?.length - 1]?.name;
  });

  const handleCellChange = (activeLevel: string, cell: TableCellDataType) => {
    const tabState = changeTabPermission(activeLevel, cell.name, data.main, {});

    setData({
      ...data,
      main: tabState,
    });
  };

  const columns: ColumnDef<TableCellDataType>[] = useMemo(
    () => [
      {
        id: 'permission',
        cell: (info) => {
          return (
            <>
              <TableCellExpander
                expanded={info.row.getIsExpanded()}
                onExpand={() => {
                  info.row.toggleExpanded();
                }}
                sx={{
                  border: 'none',
                }}
                display={info.row.depth === 0 && !!info.row.original.children}
              />
              <TableBodyCell
                level={info.row.depth as TableBodyCellProps['level']}
                levelIsLast={bottomItems?.includes(info.row.original.name)}
                sx={{
                  borderTop: 'none',
                  ...(info.row.depth === 0 ? { paddingLeft: '0' } : {}),
                }}
              >
                {info.row.original.label}
              </TableBodyCell>
            </>
          );
        },
        header: () => t('Permission'),
      },
      {
        id: 'access',
        cell: (info) => (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            {info.row.original?.name === 'companyInformationFeature' ? null : (
              <Checkbox
                checked={isPermissionChecked(
                  AccessLevelsMap.NoAccess,
                  info.row.original as TableCellDataType,
                  info.row.depth,
                )}
                onChange={() =>
                  handleCellChange(AccessLevelsMap.NoAccess, info.row.original)
                }
                disabled={isDisabled}
              />
            )}
          </Box>
        ),
        header: () => t('No access'),
      },
      {
        id: 'readonly',
        cell: (info) => (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <Checkbox
              checked={isPermissionChecked(
                AccessLevelsMap.ReadOnly,
                info.row.original as TableCellDataType,
                info.row.depth,
              )}
              onChange={() =>
                handleCellChange(AccessLevelsMap.ReadOnly, info.row.original)
              }
              disabled={isDisabled}
            />
          </Box>
        ),
        header: () => t('Read only'),
      },
      {
        id: 'readwrite',
        cell: (info) => (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <Checkbox
              checked={isPermissionChecked(
                AccessLevelsMap.ReadAndWrite,
                info.row.original as TableCellDataType,
                info.row.depth,
              )}
              onChange={() =>
                handleCellChange(
                  AccessLevelsMap.ReadAndWrite,
                  info.row.original,
                )
              }
              disabled={isDisabled}
            />
          </Box>
        ),
        header: () => t('Read & Write'),
      },
    ],
    [t, data],
  );

  const { getHeaderGroups, getRowModel } = useReactTable({
    columns: columns,
    data: data.main.children ? data.main.children : [],
    state: {
      expanded,
    },
    onExpandedChange: (e) => {
      if (setExpanded) {
        setExpanded(e);
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => (row.children ? row.children : []),
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box sx={{ maxHeight: '410px', overflow: 'auto' }}>
        <Table>
          <TableHead>
            {getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHeaderCell key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </TableHeaderCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody sx={{ height: 'calc(100% - 40px)', overflowY: 'scroll' }}>
            {getRowModel().rows.map((row) => (
              <TableRow>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableBodyCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableBodyCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default PermissionsTable;
