import { createFileRoute } from '@tanstack/react-router';

import { z } from 'zod';

export const Route = createFileRoute(
  '/_secured/company-profile/integrations/$integration/$tabName/$type/',
)({
  validateSearch: z.object({
    state: z.string().optional(),
    id: z.coerce.string().optional(),
    code: z.string().optional(),
    scope: z.string().optional(),
  }),
});
