import React from 'react';

import LinkM, { LinkProps as LinkPropsM } from '@mui/material/Link';

import type { IconsList as Icons } from '../../assets/asyncIcons.jsx';
import SVGIcon from '../Icon/Icon.jsx';

export interface LinkProps extends LinkPropsM {
  /**
   * If true component is disabled
   */
  disabled?: boolean;
  /**
   * Icon element
   */
  icon?: Icons;
  component?: React.ElementType<any>;
}

const Link = <C extends React.ElementType>({
  icon,
  underline = 'hover',
  variant = 'bodyShort01',
  disabled = false,
  children,
  ...props
}: LinkPropsM<C, { component?: C }>) => {
  return (
    <LinkM
      variant={variant}
      underline={underline}
      disabled={disabled}
      sx={{
        color: ({ palette }) =>
          disabled ? palette.text.disabled : palette.text.link,
        cursor: 'pointer',
      }}
      {...props}
    >
      {icon && <SVGIcon component={icon} />}
      {children}
    </LinkM>
  );
};

export default Link;
