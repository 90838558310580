import { OptionType } from '@myn/mui';

import { UserType } from '../api/users/index.js';

export const mapUsersToSelect = (list: UserType[]): OptionType[] => {
  return list.map((option: UserType) => ({
    value: option.id as number | string,
    label: `${option.firstName} ${option.lastName}`.trim() || option?.email,
  }));
};
