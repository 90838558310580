const PROJECT_ID = '61f41084e474711edf57eb6f';
const SDK_SRC = 'https://static.axept.io/sdk-slim.js';
const DEFAULT_WIDGET_VERSION = 'v1';
const IS_LOGIN_ROUTE_KEY = 'isLoginRoute';
const COOKIES_CHOICES_KEY = 'cookiesChoices';

export {
  PROJECT_ID,
  SDK_SRC,
  DEFAULT_WIDGET_VERSION,
  IS_LOGIN_ROUTE_KEY,
  COOKIES_CHOICES_KEY,
};
