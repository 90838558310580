import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient();

export enum QueryKey {
  'UserProfile' = 'UserProfile',
  'UserRole' = 'UserRole',
  'UsersData' = 'UsersData',
  'AgencyClients' = 'AgencyClients',
}
