import { Palette } from '@mui/material/styles';

import typography from './typography.js';

const TablePaginationOverrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        ...typography.bodyShort01,
        color: palette.text.primary,
      },
      toolbar: {
        minHeight: '3rem !important',
        height: '3rem !important',
        backgroundColor: 'transparent',
        paddingRight: '8px !important',
      },
      selectIcon: {
        top: '8px',
        width: '1rem',
        height: '1rem',
      },
      input: {
        marginRight: '1.5rem',
        marginLeft: '2px',
        height: '2rem',
        minHeight: '2rem',
      },
      '.MuiBox-root': {
        marginLeft: '0px',
      },
    },
  };
};
export default TablePaginationOverrides;
