import React, { forwardRef } from 'react';

import ButtonM, { ButtonProps as ButtonPropsM } from '@mui/material/Button';

import type { IconsList as Icons } from '../../assets/asyncIcons.jsx';
import Icon from '../Icon/Icon.jsx';

export interface ButtonProps extends ButtonPropsM {
  /**
   * If set, icon will display to the left.
   * @default false
   */
  startIcon?: Icons;

  /**
   * If set, icon will display to the right.
   * @default false
   */
  endIcon?: Icons;
  /**
   * The variant to use.
   * @default 'contained'
   */
  variant?: ButtonPropsM['variant'] | 'plain';
}

const Button = forwardRef(
  (
    { variant = 'contained', ...props }: ButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <ButtonM
        ref={ref}
        variant={variant}
        {...props}
        endIcon={props.endIcon && <Icon component={props.endIcon} />}
        startIcon={props.startIcon && <Icon component={props.startIcon} />}
      >
        {props.children}
      </ButtonM>
    );
  },
);

export default Button;
