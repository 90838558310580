import { createFileRoute } from '@tanstack/react-router';

import { z } from 'zod';

// import { checkIfUserHasCampaignsAndViewLoader } from '@myn/pivot/loaders';
import { queryClientPivot } from '@myn/pivot/routes';

export const Route = createFileRoute('/_secured/insights/data-exploration/')({
  validateSearch: z.object({
    view: z.string().optional(),
  }),
  loader: async ({ context }) => {
    await context.serviceLocator.getInstance('i18n', '@myn/pivot');
    // TODO: dependencies problem
    // await checkIfUserHasCampaignsAndViewLoader(queryClientPivot);
  },
});
