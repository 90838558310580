import styled from '@mint-lib/styled';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Box } from '@mui/material';

import CircularProgress from '../CircularProgress/CircularProgress.jsx';
import Icon from '../Icon/Icon.jsx';
import IconButton from '../IconButton/IconButton.jsx';
import Truncation from '../Truncation/Truncation.jsx';
import type { FileUploaderV2Props } from './FileUploader.jsx';
import {
  getFileItemColor,
  getIconButtonColor,
  isDefined,
  revokePreviewUrl,
} from './utils.js';

type ImplicitProps = Parameters<
  NonNullable<FileUploaderV2Props['renderItem']>
>[0];

type ExplicitProps = {
  className?: string;
  children?: React.ReactNode;
  variant?: FileUploaderV2Props['itemVariant'];
};

export type FileUploaderV2ItemProps = ImplicitProps & ExplicitProps;

export const FileUploaderV2Item = ({
  variant = 'medium',
  ...rest
}: FileUploaderV2ItemProps) => {
  const componentsVariantsMap = {
    small: SmallFileItem,
    medium: MediumFileItem,
  };

  const VariantComponent = componentsVariantsMap[variant];

  return <VariantComponent {...rest} />;
};

type FileItemVariantProps = Omit<FileUploaderV2ItemProps, 'variant'>;

const SmallFileItem = ({
  className,
  baseFile,
  onRemove,
  onRetry,
  children,
}: FileItemVariantProps) => {
  const { name, validationError, uploadError, isUploaded } = baseFile;

  const errorMessage = validationError?.message || uploadError?.message;

  const tooltipTitle = getTooltipTitle(name, errorMessage);

  return (
    <StyledSmallFileItem
      data-testid={name}
      className={className}
      warning={Boolean(validationError)}
      error={Boolean(uploadError)}
      isUploaded={isUploaded}
    >
      <Icon component="Document" fontSize="small" />

      <Truncation
        //@ts-ignore
        tooltipTitle={tooltipTitle}
        tooltipPlacement="bottom"
        variant="bodyShort01"
      >
        {name}
      </Truncation>
      <Actions {...{ children, baseFile, onRemove, onRetry }} />
    </StyledSmallFileItem>
  );
};

const MediumFileItem = ({
  className,
  baseFile,
  onRemove,
  onRetry,
  children,
}: FileItemVariantProps) => {
  const { name, validationError, uploadError, isUploaded } = baseFile;

  const errorMessage = validationError?.message || uploadError?.message;

  const tooltipTitle = getTooltipTitle(name, errorMessage);

  return (
    <StyledMediumFileItem
      data-testid={name}
      className={className}
      warning={Boolean(validationError)}
      error={Boolean(uploadError)}
      isUploaded={isUploaded}
    >
      <ImagePreview previewUrl={baseFile.previewUrl} />
      <Box display="flex" flexDirection="column" gap={0.5} minWidth={0}>
        <Truncation variant="bodyShort01">{name}</Truncation>
        {Boolean(errorMessage) && (
          <Truncation
            role="alert"
            // @ts-ignore
            tooltipTitle={tooltipTitle}
            tooltipPlacement="bottom"
            variant="caption02"
          >
            {errorMessage}
          </Truncation>
        )}
      </Box>
      <Actions {...{ children, baseFile, onRemove, onRetry }} />
    </StyledMediumFileItem>
  );
};

const getTooltipTitle = (name: string, errorMessage?: string) => (
  <div>
    <div>{name}</div>
    {errorMessage && (
      <>
        <br />
        <div>{errorMessage}</div>
      </>
    )}
  </div>
);

const ImagePreview = ({ previewUrl }: { previewUrl?: string }) => {
  const placeholderProps = {
    bgcolor: 'ui.hover',
    children: <Icon component="Document" color="inherit" fontSize="medium" />,
  } as const;

  const previewImageProps = {
    component: 'img',
    src: previewUrl,
    alt: '',
    onLoad: () => {
      revokePreviewUrl(previewUrl);
    },
    sx: {
      objectFit: 'cover',
    },
  } as const;

  const previewProps = previewUrl ? previewImageProps : placeholderProps;

  return (
    <Box
      {...previewProps}
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={48}
      height={48}
      flex="0 0 48px"
      borderRadius="4px"
    />
  );
};

type ActionsProps = Pick<
  FileItemVariantProps,
  'baseFile' | 'onRemove' | 'onRetry' | 'children'
>;
const Actions = ({ children, baseFile, onRemove, onRetry }: ActionsProps) => {
  if (baseFile.isUploading) {
    return <CircularProgress size={16} />;
  }

  const defaultActions = (
    <>
      {baseFile.isLocalErrorFile() && (
        <IconButton
          aria-label="Retry"
          icon="Renew"
          size="small"
          variant="icon"
          color="inherit"
          onClick={onRetry}
        />
      )}
      <IconButton
        aria-label="Remove"
        icon="Delete"
        size="small"
        variant="icon"
        color="inherit"
        onClick={onRemove}
      />
    </>
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      ml="auto"
      gap="4px"
      sx={{ color: (theme) => getIconButtonColor(baseFile, theme) }}
    >
      {isDefined(children) ? children : defaultActions}
    </Box>
  );
};

const StyledBaseFileItem = styled('li', {
  shouldForwardProp: (prop) =>
    prop !== 'warning' && prop !== 'error' && prop !== 'isUploaded',
})<{ warning?: boolean; error?: boolean; isUploaded?: boolean }>`
  display: flex;
  align-items: center;
  color: ${getFileItemColor};
`;

const StyledSmallFileItem = styled(StyledBaseFileItem)`
  gap: 8px;
`;

const StyledMediumFileItem = styled(StyledBaseFileItem)`
  padding-right: 8px;
  gap: 12px;
  background-color: ${({ theme, warning, error }) =>
    warning
      ? theme.palette.background.warning
      : error
        ? theme.palette.background.error
        : theme.palette.ui.secondary};
  border-radius: 4px;
`;
