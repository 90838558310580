import { DropzoneRootProps } from 'react-dropzone';

import { useTranslation } from '@mint-lib/i18n';
import styled, { css, Theme } from '@mint-lib/styled';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Box } from '@mui/material';

import Button from '../Button/Button.jsx';
import Divider from '../Divider/Divider.jsx';
import Icon from '../Icon/Icon.jsx';
import Typography from '../Typography/Typography.jsx';
import { ChildrenFnArgs } from './types.js';

export const FileUploaderContent = ({
  getRootProps,
  getInputProps,
  isDragActive,
  open,
  disabled,
  variant = 'default',
}: ChildrenFnArgs) => {
  const componentVariantsMap = {
    default: DropAreaWithButton,
    area: DropArea,
    button: FileUploaderButton,
  };
  const VariantComponent = componentVariantsMap[variant];

  return (
    <VariantComponent
      {...{
        getRootProps,
        getInputProps,
        isDragActive,
        open,
        disabled,
        variant,
      }}
    />
  );
};

const DropAreaWithButton = ({
  getRootProps,
  getInputProps,
  isDragActive,
  open,
  disabled,
}: ChildrenFnArgs) => {
  const { t } = useTranslation('@myn/mui');

  return (
    <StyledDropAreaWithButton
      {...getRootProps({
        isDragActive,
        disabled,
        onClick: (event) => {
          event.stopPropagation();
          event.nativeEvent.stopImmediatePropagation();
        },
      })}
    >
      <input {...getInputProps()} />
      <div className="inner-container">
        <Typography variant="bodyShort02" textAlign="center">
          {t('Drag & drop here to start uploading')}
        </Typography>
        <Divider variant="middle" orientation="horizontal">
          <Typography variant="bodyShort01">OR</Typography>
        </Divider>
        <Button
          onClick={open}
          disabled={disabled}
          variant="outlined"
          color="primary"
        >
          {t('Browse files')}
        </Button>
      </div>
    </StyledDropAreaWithButton>
  );
};

const DropArea = ({
  getRootProps,
  getInputProps,
  isDragActive,
  disabled,
}: ChildrenFnArgs) => {
  return (
    <StyledDropArea
      {...getRootProps({
        isDragActive,
        disabled,
      })}
    >
      <input {...getInputProps()} />
      <StyledImportIconContainer>
        <Icon component="Import" />
      </StyledImportIconContainer>
    </StyledDropArea>
  );
};

const FileUploaderButton = ({
  getInputProps,
  open,
  disabled,
}: ChildrenFnArgs) => {
  const { t } = useTranslation('@myn/mui');

  return (
    <>
      <input {...getInputProps()} />
      <Button
        onClick={open}
        disabled={disabled}
        color="primary"
        variant="outlined"
        size="large"
        endIcon="Upload"
      >
        {t('Upload file')}
      </Button>
    </>
  );
};

const sharedStyles = (theme: Theme, disabled: boolean) => css`
  color: ${disabled ? theme.palette.text.disabled : 'inherit'};
  border: 1px dashed;
  border-color: ${theme.palette.border.secondary};
  border-radius: 8px;
  &:not([disabled]):hover,
  .isDragActive & {
    border-color: ${theme.palette.border.disabled};
    background-color: ${theme.palette.background.tertiary};
  }
`;

const StyledDropAreaWithButton = styled('div')<{
  disabled: boolean;
  isDragActive: boolean;
}>`
  container-type: inline-size;
  .inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    min-height: 80px;
    gap: 12px;
  }

  .MuiDivider-root {
    margin: 0;
    line-height: 1rem;
    width: 80px;
  }

  @container (min-width: 466px) {
    .inner-container {
      flex-direction: row;
      gap: 16px;
    }

    .MuiDivider-root {
      flex-direction: column;
      align-self: stretch;
      height: auto;
      width: auto;

      .MuiDivider-wrapper {
        padding: 0.5rem 0rem;
      }
      &:before,
      &:after {
        width: auto;
        height: 100%;
        border-top: none;
        border-left: thin solid rgba(0, 0, 0, 0.12);
      }
    }
  }
  ${({ theme, disabled }) => sharedStyles(theme, disabled)};
`;

export const StyledDropArea = styled(Box)<DropzoneRootProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  cursor: pointer;
  outline: none;
  ${({ theme, disabled }) => sharedStyles(theme, disabled)};
`;

const StyledImportIconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.palette.background.violet};
  border: 0.5px dashed;
  border-color: ${({ theme }) => theme.palette.violet.main};
  border-radius: 3px;
  color: ${({ theme }) => theme.palette.violet.main};
`;
