import React from 'react';

import ListSubheaderM, {
  ListSubheaderProps,
} from '@mui/material/ListSubheader';

export interface Props extends ListSubheaderProps {
  /**
   * The content of the component. Text or nested components.
   * @uxpinpropname Text
   */
  children?: React.ReactNode;
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api) below for more details.
   * @uxpinignoreprop
   */
  classes?: ListSubheaderProps['classes'];
  /**
   * The color of the Subheader.
   */
  color?: ListSubheaderProps['color'];
  /**
   * The component used for the root node. Either a string to use a HTML element or a component.
   */
  component?: React.ElementType;
  /**
   * If true, the left and right padding is removed.
   */
  disableGutters?: boolean;
  /**
   * If true, the List Subheader will not stick to the top during scroll.
   */
  disableSticky?: boolean;
  /**
   * If true, the List Subheader is indented.
   */
  inset?: boolean;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   * @uxpinignoreprop
   */
  sx?: ListSubheaderProps['sx'];
}

function ListSubheader(props: Props) {
  return <ListSubheaderM {...props}>{props.children}</ListSubheaderM>;
}

export default ListSubheader;
