import { useState } from 'react';

import { useTranslation } from '@mint-lib/i18n';
import { enqueueSnackbar } from '@mint-lib/notifications';

import { Box, Dialog, IconButton, Tooltip } from '@myn/mui';

import { useUserRole } from '../../../api/queries/userRoles.js';
import { RoleType } from '../../../api/roles/index.js';

export type Props = RoleType & {
  onEditClick: (currentRole: RoleType) => void;
  isReadonlyPermission: boolean;
};

const ActionsCell = ({
  onEditClick,
  isReadonlyPermission,
  ...value
}: Props) => {
  const { t } = useTranslation('@myn/permissions');

  const { deleteRole, refetchRoles } = useUserRole();
  const [isDeleteOpened, setIsDeleteOpened] = useState(false);

  const handleDelete = () => {
    setIsDeleteOpened(false);

    deleteRole.mutateAsync(value.id, {
      onSuccess: () => {
        enqueueSnackbar({
          removable: true,
          variant: 'success',
          title: t('Success'),
          subTitle: t('The role has been deleted successfully'),
        });

        refetchRoles();
      },
      onError: () => {
        enqueueSnackbar({
          removable: true,
          variant: 'error',
          title: t('Error'),
          subTitle: t(
            'An error occurred while deleting the role. Please try again',
          ),
        });
      },
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      {isReadonlyPermission ? (
        <Tooltip title={t(`View`)}>
          <IconButton
            variant="icon"
            onClick={() => {
              onEditClick(value);
            }}
            color="secondary"
            icon={'View'}
            aria-label="Edit-action"
          />
        </Tooltip>
      ) : (
        <>
          <Tooltip title={t(`Edit`)}>
            <IconButton
              variant="icon"
              onClick={() => {
                onEditClick(value);
              }}
              color="secondary"
              icon={'Edit'}
              aria-label="Edit-action"
            />
          </Tooltip>

          <Tooltip title={t(`Remove`)}>
            <IconButton
              variant="icon"
              onClick={() => {
                setIsDeleteOpened(true);
              }}
              disabled={isReadonlyPermission || !value.isEditable}
              color="secondary"
              icon={'Delete'}
              aria-label="Delete-action"
            />
          </Tooltip>
        </>
      )}

      {isDeleteOpened && (
        <Dialog
          open={isDeleteOpened}
          onClose={() => setIsDeleteOpened(false)}
          title={t('Are you sure you want to remove "{{value}}"?', {
            value: value.name,
          })}
          cancelButtonProps={{
            label: t('Cancel'),
            color: 'secondary',
            onClick: () => setIsDeleteOpened(false),
          }}
          submitButtonProps={{
            label: t('Remove'),
            color: 'error',
            onClick: handleDelete,
          }}
        >
          {t("This action can't be undone.")}
        </Dialog>
      )}
    </Box>
  );
};

export default ActionsCell;
