import { Theme } from '@mint-lib/styled';

import { AvatarSize } from './Avatar.constants.js';

export const getUserInitials = (
  firstName: string | undefined | null,
  lastName: string | undefined | null,
) => {
  const initials = `${firstName?.slice(0, 1) ?? ''}${
    lastName?.slice(0, 1) ?? ''
  }`;
  return initials.length > 0 ? initials : '';
};

export const getTypography = (avatarSize: AvatarSize, theme: Theme) =>
  ({
    1: theme.typography.caption03,
    1.5: theme.typography.caption02,
    2: theme.typography.bodyShort01,
    2.5: theme.typography.bodyShort01,
    5: theme.typography.h1,
  })[avatarSize] || theme.typography.bodyShort01;
