import { Skeleton, SkeletonProps } from '@myn/mui';

interface Props extends SkeletonProps {
  isLoading: boolean;
  children: React.ReactNode;
}

const ConditionalSkeleton = ({
  isLoading,
  children,
  ...skeletonProps
}: Props) => {
  return isLoading ? (
    <Skeleton variant="rectangular" {...skeletonProps} />
  ) : (
    <>{children}</>
  );
};

export default ConditionalSkeleton;
