import { Box, BoxProps } from '@myn/mui';

const FormBlock = ({ children, ...props }: BoxProps) => {
  return (
    <Box {...props} sx={{ display: 'flex', gap: (theme) => theme.spacing(1) }}>
      {children}
    </Box>
  );
};

export default FormBlock;
