import React from 'react';

import InputLabelM, { InputLabelProps } from '@mui/material/InputLabel';

export interface Props extends InputLabelProps {
  /**
   * @uxpinignoreprop
   * The contents of the `InputLabel`.
   */
  children?: React.ReactNode;
  /**
   * @uxpinignoreprop
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api) below for more details.
   */
  classes?: InputLabelProps['classes'];
  /**
   * If `true`, the transition animation is disabled.
   */
  disableAnimation?: boolean;
  /**
   * If `true`, apply disabled class.
   */
  disabled?: boolean;
  /**
   * If `true`, the label will be displayed in an error state.
   */
  error?: boolean;
  /**
   * If `true`, the input of this label is focused.
   */
  focused?: boolean;
  /**
   * If `dense`, will adjust vertical spacing. This is normally obtained via context from
   * FormControl.
   */
  margin?: 'dense';
  /**
   * if `true`, the label will indicate that the input is required.
   */
  required?: boolean;
  /**
   * If `true`, the label is shrunk.
   */
  shrink?: boolean;
  /**
   * The variant to use.
   */
  variant?: InputLabelProps['variant'];
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   * See the `sx` page for more details. https://mui.com/system/the-sx-prop/
   */
  sx?: InputLabelProps['sx'];
}

function InputLabel(props: Props) {
  return <InputLabelM {...props}>{props.children}</InputLabelM>;
}

export default InputLabel;
