import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_secured/campaign/$campaignId/planning/',
)({
  beforeLoad: ({ params, search }) => {
    throw redirect({
      to: '/campaign/$campaignId/planning/buying-plan',
      params: {
        campaignId: params.campaignId,
      },
      search,
      replace: true,
    });
  },
});
