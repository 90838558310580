const typography = {
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'sans-serif',
  ].join(','),
  h1: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
    fontWeight: 500,
  },
  h2: {
    fontSize: '1.5625rem',
    lineHeight: '2rem',
    fontWeight: 600,
  },
  h3: {
    fontSize: '1.3125rem',
    lineHeight: '1.5rem',
    fontWeight: 600,
  },
  h4: {
    fontSize: '1.0625rem',
    lineHeight: '1.25rem',
    fontWeight: 600,
  },
  h5: {
    fontSize: '0.9375rem',
    lineHeight: '1.25rem',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  h6: {
    fontSize: '0.875rem',
    lineHeight: '1rem',
    fontWeight: 600,
  },
  bodyShort01: {
    fontSize: '0.875rem',
    lineHeight: '1rem',
    fontWeight: 400,
  },
  bodyShort02: {
    fontSize: '0.875rem',
    lineHeight: '1rem',
    fontWeight: 500,
  },
  bodyLong01: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
  bodyLong02: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontWeight: 500,
  },
  caption01: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 500,
  },
  caption02: {
    fontSize: '0.6875rem',
    lineHeight: '0.75rem',
  },
  caption03: {
    fontSize: '0.5rem',
    lineHeight: '0.75rem',
    fontWeight: 400,
  },
  overline01: {
    fontSize: '0.6875rem',
    lineHeight: '0.75rem',
    textTransform: 'uppercase',
    fontWeight: 400,
  },
  label01: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
  },
  helperText01: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
  },
};

export default typography;
