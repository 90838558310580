import { Palette } from '@mui/material/styles';

import typography from './typography.js';

const AlertTitleOverrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        ...typography.h6,
        marginBottom: 0,
        marginTop: 0,
      },
    },
  };
};

export default AlertTitleOverrides;
