import { ReactNode, useCallback, useId, useRef, useState } from 'react';

import styled from '@mint-lib/styled';
import Tooltip, { TooltipProps as TooltipPropsM } from '@mui/material/Tooltip';

import Typography, { TypographyProps } from '../Typography/Typography.jsx';

export interface TruncationProps extends TypographyProps {
  /**
   * Tooltip Title
   */
  tooltipTitle?: TooltipPropsM['title'];
  /**
   * Tooltip Placement
   */
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  /**
   * Children of the component
   */
  children?: ReactNode;
  /**
   * variant of Typography
   */
  variant?: TypographyProps['variant'];
  TooltipProps?: Partial<TooltipPropsM>;

  customMouseEnterHandler?: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => boolean;
}
const Truncation = ({
  tooltipTitle,
  TooltipProps,
  tooltipPlacement = 'top',
  children,
  variant = 'bodyShort01',
  customMouseEnterHandler,
  ...props
}: TruncationProps) => {
  const [isOverflowed, setIsOverflowed] = useState(false);
  const textElementRef = useRef<HTMLElement>(null);

  const tid = useId();

  const mouseEnterHandler = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const element = e.target as HTMLElement;

      if (customMouseEnterHandler && !isOverflowed) {
        const hasOverflow = customMouseEnterHandler(e);
        setIsOverflowed(hasOverflow);
        return;
      }

      if (element.offsetWidth !== element.scrollWidth && !isOverflowed) {
        setIsOverflowed(true);
      } else if (element.offsetWidth === element.scrollWidth && isOverflowed) {
        setIsOverflowed(false);
      }
    },
    [isOverflowed, setIsOverflowed],
  );
  return isOverflowed ? (
    <Tooltip
      title={tooltipTitle || ''}
      arrow
      placement={tooltipPlacement}
      disableHoverListener={!isOverflowed}
      id={tid}
      {...TooltipProps}
    >
      <TruncationM
        ref={textElementRef}
        onMouseEnter={mouseEnterHandler}
        variant={variant}
        {...props}
      >
        {children}
      </TruncationM>
    </Tooltip>
  ) : (
    <TruncationM variant={variant} onMouseEnter={mouseEnterHandler} {...props}>
      {children}
    </TruncationM>
  );
};

export default Truncation;

const TruncationM = styled(Typography)({
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflowWrap: 'break-word',
  display: 'block',
});
