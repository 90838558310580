import { useTranslation } from '@mint-lib/i18n';
import { enqueueSnackbar } from '@mint-lib/notifications';
import { useProfile } from '@mint-lib/profile-helpers';

import { Switch } from '@myn/mui';

import { useUsers } from '../../../api/queries/usersData.js';
import { UserType } from '../../../api/users/index.js';

type Props = UserType & {
  isReadonlyPermission: boolean;
};

const StatusCell = ({ isReadonlyPermission, ...value }: Props) => {
  const { t } = useTranslation('@myn/permissions');

  const { profile } = useProfile();

  const { toggleUserActivityStatus, refetchUsers } = useUsers();

  const handleActivityChange = () => {
    toggleUserActivityStatus.mutateAsync(value.id as string | number, {
      onSuccess: (data) => {
        enqueueSnackbar({
          removable: true,
          variant: 'success',
          title: t('Success'),
          subTitle: t(`The user has been  {{status}} successfully`, {
            status: data?.isActive ? t('activated') : t('deactivated'),
          }),
        });

        refetchUsers();
      },
      // TODO: create type for error
      // @ts-ignore
      onError: ({ response }) => {
        enqueueSnackbar({
          removable: true,
          variant: 'error',
          title: t('Error'),
          subTitle: t(
            `An error occurred while  {{status}} the user. Please try again`,
            {
              status: response?.data?.isActive
                ? t('activating')
                : t('deactivating'),
            },
          ),
        });
      },
    });
  };

  return profile?.id === value?.id ? null : (
    <Switch
      withText
      size="small"
      checkedText={t('On')}
      unCheckedText={t('Off')}
      checked={Boolean(value?.isActive)}
      disabled={profile?.id === value?.id || isReadonlyPermission}
      onChange={handleActivityChange}
    />
  );
};

export default StatusCell;
