import { createFileRoute, redirect } from '@tanstack/react-router';

import { z } from 'zod';

export const Route = createFileRoute(
  '/_secured/campaign/$campaignId/settings/ghost/',
)({
  beforeLoad: () => {
    throw redirect({
      to: '/campaigns',
    });
  },
  validateSearch: z.object({
    mediaPlan: z.string().optional(),
  }),
});
