import { forwardRef } from 'react';

import { AlertColor, SnackbarContent } from '@myn/mui';

import { SnackbarContentProps } from './types.js';

export const Snackbar = forwardRef<HTMLDivElement, SnackbarContentProps>(
  ({ variant, ...props }, ref) => {
    return (
      <SnackbarContent
        {...props}
        ref={ref}
        severity={variant as AlertColor}
        fullHeight
      />
    );
  },
);
