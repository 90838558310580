import { Palette } from '@mui/material/styles';

const TableContainerOverrides = (palette: Palette) => ({
  styleOverrides: {
    root: {
      borderRadius: '8px',
      boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 0px 5px 0px rgba(23, 24, 24, 0.05)`,
      '&.isScrolled .MuiTable-stickyHeader .MuiTableCell-head': {
        boxShadow: '0px 4px 0px 0px rgba(47, 57, 65, 0.08)',
      },
      border: `1px solid ${palette.border.tertiary}`,
    },
  },
});

export default TableContainerOverrides;
