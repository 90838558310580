import {
  createApi,
  HttpStatus,
  requestDataToSnakeCase,
  responseDataToCamelCase,
  responseErrorDataToCamelCase,
  type AxiosError,
  type AxiosInstance,
  type CreateAxiosDefaults,
  type InternalAxiosRequestConfig,
} from '@mint-lib/api';
import { AuthService } from '@mint-lib/auth';

import type { MintServiceLocatorAbstractFactoryContext } from '../types.js';

/**
 * Builds an axios instances with the following types:
 * - guestApi: no authentication
 * - accessApi: uses access token for authentication (used only for requesting workspace access tokens)
 * - defaultApi: uses access token for a workspace
 */
export async function httpFactory(
  ctx: MintServiceLocatorAbstractFactoryContext,
  type: 'guestApi' | 'defaultApi' | 'accessApi' | (string & {}),
  config?: CreateAxiosDefaults,
): Promise<AxiosInstance> {
  const client = createApi({
    baseURL: '/',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
  client.interceptors.request.use(requestDataToSnakeCase, Promise.reject);
  if (type === 'guestApi') {
    client.interceptors.response.use(
      responseDataToCamelCase,
      responseErrorDataToCamelCase,
    );
  }
  if (['accessApi', 'defaultApi'].includes(type)) {
    const auth = await ctx.getInstance('auth');
    client.interceptors.response.use(
      responseDataToCamelCase,
      buildErrorInterceptor(auth),
    );
    switch (type) {
      case 'defaultApi':
        // We need to use pre-configured accessApi for defaultApi
        await ctx.getInstance('http', 'accessApi');
        client.interceptors.request.use(
          buildAccessTokenMiddleware(auth),
          Promise.reject,
        );
        break;
      case 'accessApi':
        client.interceptors.request.use(
          buildAccessTokenMiddleware(auth, true),
          Promise.reject,
        );
        auth.setAccessApi(client);
        break;
    }
    ctx.on('auth', 'auth', ctx.invalidate);
    ctx.on('auth', 'logout', () => ctx.invalidate());
  }
  return client;
}

function buildErrorInterceptor(auth: AuthService) {
  return function (err: AxiosError) {
    // eslint-disable-next-line no-console

    if (err.response?.status === HttpStatus.Unauthorized) {
      auth?.logout(err);
    }
    return responseErrorDataToCamelCase(err);
  };
}

function buildAccessTokenMiddleware(auth: AuthService, isNotWorkspace = false) {
  return async (config: InternalAxiosRequestConfig) => {
    if (import.meta.env.VITEST_WORKER_ID || auth === null) {
      return config;
    }

    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: await auth.provideBearerToken(!isNotWorkspace),
      },
    } as InternalAxiosRequestConfig;
  };
}
