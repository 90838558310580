import { Palette } from '@mui/material/styles';

import typography from './typography.js';

const formHelperTextOverrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        ...typography.helperText01,
        color: palette.text.secondary,
        marginLeft: 0,
        marginTop: 4,
      },
    },
  };
};

export default formHelperTextOverrides;
