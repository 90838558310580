import React from 'react';

export type Props = {
  alt?: string;
  height?: string;
  width?: string;
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  src?: string;
  style?: Record<string, string>;
};

function Image(props: Props) {
  return (
    <img
      alt={props.alt}
      src={props.src}
      height={props.height}
      width={props.width}
      style={{
        objectFit: props.objectFit,
        height: props.height,
        width: props.width,
        ...props.style,
      }}
    />
  );
}

Image.defaultProps = {
  src: 'http://uxpin.com/images/homepage/about-us/location-gdynia.jpg',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
};

export default Image;
