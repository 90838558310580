import i18n from '@mint-lib/i18n';

import type { MintServiceLocatorAbstractFactoryContext } from '../types.js';

export async function i18nFactory(
  ctx: MintServiceLocatorAbstractFactoryContext,
  ns: string,
): Promise<typeof i18n> {
  if (
    typeof import.meta.env.VITEST_WORKER_ID != 'undefined' ||
    import.meta.env.VITE_IGNORE_I18N
  ) {
    return i18n;
  }
  await i18n.loadNamespaces(ns);
  i18n.emit('i18n:localeChanged');
  i18n.on('languageChanged', load);
  ctx.addEffect(() => i18n.off('languageChanged', load));
  return i18n;

  function load() {
    i18n.loadNamespaces(ns).then(() => {
      i18n.emit('i18n:localeChanged');
      ctx.eventBus.emit('i18n', 'changeLanguage', i18n.language);
    });
  }
}
