import { createFileRoute, notFound } from '@tanstack/react-router';

import { buildGuards, hasPermission } from '@mint-lib/routing-guards';
import { z } from 'zod';

import { Layout } from '@myn/permissions/routes';

const guard = buildGuards(hasPermission('usersPermissionsFeature:view'));

export const Route = createFileRoute('/_secured/agency-hub/users-management')({
  async beforeLoad({ context }) {
    const guardsContext = await context.getGuardContext();
    if (!guard(guardsContext)) {
      throw notFound();
    }
    return context;
  },
  async loader({ context }) {
    await context.serviceLocator.getInstance('i18n', '@myn/permissions');
  },
  validateSearch: z.object({
    limit: z.number().optional(),
    offset: z.number().optional(),
    ordering: z.string().optional(),
    search: z.string().optional(),
  }),
  component: Layout,
});
