import { curry } from 'ramda';

import { isDecimal } from './formatComparator.js';
import {
  DEFAULT_LOCALE,
  formattedPrefixes,
  FormattedPrefixesType,
  formatTypes,
  LocaleSeparatorsType,
  localeSeparatorsTypes,
} from './formatterType.js';

export const formatPrefix = (
  numberStr: string,
  prefixes: FormattedPrefixesType,
) => {
  const sign = numberStr[numberStr.length - 1];

  // @ts-ignore
  return prefixes[sign] ? numberStr.replace(sign, prefixes[sign]) : numberStr;
};

type TypeResolver = (number: number, params: any) => string;

interface FormatNumberOptions {
  localeSeparators: LocaleSeparatorsType;
  defaultLocale: string;
  typeResolver: string | TypeResolver;
}

export const formatNumber =
  (options: FormatNumberOptions) =>
  (
    number: number,
    params: { [arg: string]: any } = {},
    userLocale: string = navigator.language /* || navigator.userLanguage */,
  ) => {
    const { localeSeparators, defaultLocale, typeResolver } = options;

    const localeSeparator =
      // @ts-ignore
      localeSeparators[userLocale.slice(0, 2) || defaultLocale] ||
      localeSeparators.it;

    const formatType =
      typeof typeResolver === 'function'
        ? typeResolver(number, params)
        : typeResolver;

    const formattedNumber = localeSeparator.format(formatType)(number);
    return formatPrefix(formattedNumber, formattedPrefixes);
  };

export const formatGoals = formatNumber({
  localeSeparators: localeSeparatorsTypes,
  defaultLocale: DEFAULT_LOCALE,
  typeResolver: (number) => {
    if (number >= 1000) {
      return formatTypes.COMPACT_1_AFTER_COMA;
    }

    return isDecimal(number)
      ? formatTypes.DECIMAL_SHORT
      : formatTypes.INTEGER_SHORT;
  },
});

export const formatWithPrefix = curry(
  (
    formatter: (val: string | number) => string | number,
    prefix: string,
    number: number,
  ): string => `${prefix}${formatter(number)}`,
);

export const formatWithPostfix = curry(
  (
    formatter: (val: number) => string | number,
    postfix: string,
    number: number,
  ): string => `${formatter(number)}${postfix}`,
);
