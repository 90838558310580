import { useFlags } from '@mint-lib/routing-context';

import { Box, Truncation, Typography } from '@myn/mui';

export type UserDataBlockProps = {
  label: string;
  data?: string | number;
  maxWidth?: number;
};

const UserDataBlock = ({ label, data, maxWidth = 170 }: UserDataBlockProps) => {
  const ffArm = useFlags('@mint/arm', ['userManagemetFixes']);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: (theme) => theme.spacing(0.5),
      }}
    >
      <Typography
        variant="label01"
        color={(theme) => theme.palette.text.secondary}
      >
        {label}
      </Typography>

      {ffArm.userManagemetFixes.enabled ? (
        <Truncation
          maxWidth={maxWidth}
          tooltipTitle={data}
          variant="bodyShort01"
        >
          {data || '-'}
        </Truncation>
      ) : (
        <Truncation maxWidth={200} title={String(data)}>
          <Typography variant="bodyShort01">{data || '-'}</Typography>
        </Truncation>
      )}
    </Box>
  );
};

export default UserDataBlock;
