import styled from '@mint-lib/styled';
import AlertM from '@mui/material/Alert';

import Box from '../Box/Box.jsx';
import { controlTextColor } from './utils.jsx';

export const ActionContainer = styled(Box)({
  gap: '4px',
  display: 'flex',
  alignItems: 'center',
});

export const StyledAlert = styled(AlertM, {
  shouldForwardProp: (prop) =>
    prop !== 'textLayout' &&
    prop !== 'removable' &&
    prop !== 'fullHeight' &&
    prop !== 'subTitle' &&
    prop !== 'dismissible',
})<{
  textLayout?: 'inline' | 'multiline';
  severity: string;
  removable: boolean;
  actions?: React.ReactNode;
  fullHeight?: boolean;
}>(({ textLayout, theme, severity, removable, actions, fullHeight }) => ({
  alignItems: textLayout === 'inline' ? 'center' : 'flex-start',
  padding: '0px',
  minHeight: removable || actions ? '40px' : '32px',
  borderRadius: '8px',
  width: '100%',
  whiteSpace: 'pre-line',
  height: fullHeight ? '100%' : 'initial',
  '& .MuiAlert-message': {
    color: controlTextColor(severity, theme),
    display: 'flex',
    height: fullHeight ? '100%' : 'initial',
    alignItems: textLayout === 'inline' ? 'center' : 'flex-start',
    width: '100%',
    gap: '8px',
    overflow: 'hidden',
    padding:
      textLayout === 'inline' ? '0px 8px 0px 12px' : '12px 8px 12px 12px',
  },
  '& .MuiAlert-action': {
    display: removable ? 'inline-flex' : 'none',
    padding: textLayout === 'inline' ? '0px 8px 0px 0px' : '8px 8px 0px 0px',
  },
  '&.MuiAlert-standardSuccess': {
    border: `1px solid ${theme.palette.success.main}`,
  },
  '&.MuiAlert-standardError': {
    border: `1px solid ${theme.palette.error.main}`,
  },
  '&.MuiAlert-standardWarning': {
    border: `1px solid ${theme.palette.warning.main}`,
  },
  '&.MuiAlert-standardInfo': {
    border: `1px solid ${theme.palette.info.main}`,
  },
}));
