import { createFileRoute, notFound } from '@tanstack/react-router';

import { buildGuards, hasPermission } from '@mint-lib/routing-guards';

const guard = buildGuards(hasPermission('ioGroupFeature:view'));

export const Route = createFileRoute('/_secured/company-profile/advertisers/')({
  async beforeLoad({ context }) {
    const guardsContext = await context.getGuardContext();
    if (!guard(guardsContext)) {
      throw notFound();
    }
    return context;
  },
  loader: async ({ context }) => {
    await context.serviceLocator.getInstance('i18n', '@myn/advertisers');
  },
});
