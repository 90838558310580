import { useLocation, useRouter } from '@tanstack/react-router';

import { NotFoundErrorPage } from '@mint-lib/error-boundary';

const redirects = new Map<RegExp, string>([
  // [/set-password/, '/auth/set-password/'],
]);

export function NotFound() {
  const router = useRouter();
  /* mint-disable-next-line no-use-location */
  const path = useLocation().pathname;
  for (const [pattern, redirect] of redirects) {
    if (pattern.test(path)) {
      router.navigate({
        ...router.state.location,
        to: redirect,
        replace: true,
      });
      return null;
    }
  }
  return <NotFoundErrorPage />;
}
