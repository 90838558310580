import typography from './typography.js';

const dialogContentOverrides = {
  styleOverrides: {
    root: {
      ...typography.bodyShort01,
      padding: '16px',
    },
  },
};

export default dialogContentOverrides;
