import { PropsWithChildren, useContext, useEffect } from 'react';

import { FlagsmithContext } from '@mint-lib/flags';
import { FlagsmithProviders } from '@mint-lib/flags-envs';

import { useInstance } from '../hooks/useInstance.js';

export interface FlagsmithProviderProps {
  name: keyof typeof FlagsmithProviders;
}

export function FlagsmithProvider({
  name,
  children,
}: PropsWithChildren<FlagsmithProviderProps>) {
  const parentFlagsmith = useContext(FlagsmithContext);
  const [newFlagsmith] = useInstance('flagsmith', name);

  // Set parent flagsmith if it exists so hasFeature calls can be made on the parent
  useEffect(() => {
    if (parentFlagsmith) {
      newFlagsmith?.setParent(parentFlagsmith);
    }
  }, [parentFlagsmith, newFlagsmith]);

  if (!newFlagsmith) {
    return null;
  }

  return (
    <FlagsmithContext.Provider value={newFlagsmith}>
      {children}
    </FlagsmithContext.Provider>
  );
}
