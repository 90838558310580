import { Box, Checkbox } from '@myn/mui';

import { useUsers } from '../../../api/queries/usersData.js';
import { UserType } from '../../../api/users/users.types.js';

type Props = UserType & {
  isReadonlyPermission: boolean;
};

const CarbonOffsetCell = ({ isReadonlyPermission, ...value }: Props) => {
  const { editUser, refetchUsers } = useUsers();

  const toggleCheck = () => {
    const { id, firstName, lastName, email, mobileNumber, officeNumber } =
      value;

    return editUser.mutateAsync(
      {
        id,
        firstName,
        lastName,
        email,
        mobileNumber,
        officeNumber,
        emailSubscriptions: JSON.stringify({
          co2_email: !value.emailSubscriptions?.co2Email,
        }),
        avatar: null,
      },
      {
        onSuccess: () => {
          refetchUsers();
        },
      },
    );
  };
  return (
    <Box>
      <Checkbox
        checked={value.emailSubscriptions?.co2Email}
        disabled={isReadonlyPermission}
        onChange={toggleCheck}
      />
    </Box>
  );
};

export default CarbonOffsetCell;
