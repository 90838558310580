import { FunctionComponent, SVGAttributes } from 'react';

import styled from '@mint-lib/styled';
import SvgIconM, { SvgIconProps } from '@mui/material/SvgIcon';

import { type IconsList } from '../../assets/asyncIcons.jsx';
import {
  IconLoadingState,
  useLazyLoadedIcon,
} from '../../hooks/useLazyLoadedIcon.js';

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    purple: true;
    pink: true;
    teal: true;
    text: true;
    tertiary: true;
    yellow: true;
    dark: true;
  }
}

// @ts-ignore
export interface IconProps extends SvgIconProps {
  /**
   * Hang over the name of icon to implement icon component
   */

  component: IconsList;

  /**
   * Override or extend the styles applied to the component.
   */

  classes?: SvgIconProps['classes'];

  /**
   * The color of the component.
   * It supports those theme colors that make sense for this component.
   * You can use the htmlColor prop to apply a color attribute to the SVG element.
   */

  color?: SvgIconProps['color'];

  /**
   * The fontSize applied to the icon. Defaults to 16px,
   * but can be configure to inherit font size.
   * @default medium
   */

  fontSize?: SvgIconProps['fontSize'];

  /**
   * Applies a color attribute to the SVG element.
   */

  htmlColor?: SvgIconProps['htmlColor'];
}

const Icon = ({ component, ...props }: IconProps) => {
  const { SvgIcon, state } = useLazyLoadedIcon(component);
  return (
    <CustomIcon
      role="img"
      alt={state === IconLoadingState.Loaded ? component : ''}
      aria-hidden={state !== IconLoadingState.Loaded}
      {...props}
      aria-label={
        state === IconLoadingState.Loaded ? props['aria-label'] : undefined
      }
      inheritViewBox
      // @ts-ignore
      component={SvgIcon}
    />
  );
};

type StyledIconProps = {
  component: FunctionComponent<SVGAttributes<SVGElement>>;
};

const CustomIcon = styled(SvgIconM)<StyledIconProps>(({ theme, fontSize }) => ({
  width: fontSize === 'large' ? '1.25rem' : '1rem',
  height: fontSize === 'large' ? '1.25rem' : '1rem',
  fontSize: fontSize === 'large' ? '1.25rem' : '1rem',
}));

export default Icon;
