import ChevronDown from '../../assets/svg/ChevronDown.svg?react';
import React, { useMemo } from 'react';

import styled from '@mint-lib/styled';
import TablePaginationM, {
  TablePaginationProps as TablePaginationPropsM,
} from '@mui/material/TablePagination';

import type { IconsList as Icons } from '../../assets/asyncIcons.jsx';
import getTablePaginationVariants from './getTablePaginationVariants.jsx';

export interface IconButtonInterface {
  disabled: boolean | undefined;
  ariaLabel: string;
  icon: Icons;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}
export type TablePaginationProps = TablePaginationPropsM & {
  type?: 'default' | 'withButtons' | 'withSelect' | 'withSelectAndButtons';
  /**
   * The component used for displaying the actions.
   * Either a string to use a HTML element or a component.
   * @default TablePaginationActions
   */
  ActionsComponent?: React.ElementType;
  /**
   * The total number of rows.
   *
   */
  count: number;
  /**
   * Accepts a function which returns a string value that provides a user-friendly name for the current page.
   * This is important for screen reader users.
   *
   * For localization purposes, you can use the provided [translations](/material-ui/guides/localization/).
   * @param {string} type The link or button type to format ('first' | 'last' | 'next' | 'previous').
   * @returns {string}
   * @default function defaultGetAriaLabel(type) {
   *   return `Go to ${type} page`;
   * }
   */
  getItemAriaLabel?: (type: 'first' | 'last' | 'next' | 'previous') => string;
  /**
   * Customize the rows per page label.
   *
   * For localization purposes, you can use the provided [translations](/material-ui/guides/localization/).
   * @default 'Items per page:'
   */
  labelRowsPerPage?: React.ReactNode;
  /**
   * Callback fired when the page is changed.
   *
   * @param {React.MouseEvent<HTMLButtonElement> | null} event The event source of the callback.
   * @param {number} page The page selected.
   */
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void;
  /**
   * Callback fired when the number of rows per page is changed.
   *
   * @param {React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>} event The event source of the callback.
   */
  onRowsPerPageChange?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  /**
   * The zero-based index of the current page.
   */
  page: number;
  /**
   * The number of rows per page.
   *
   * Set -1 to display all the rows.
   */
  rowsPerPage: number;
  /**
   * Customizes the options of the rows per page select field. If less than two options are
   * available, no select field will be displayed.
   * Use -1 for the value with a custom label to show all the rows.
   */
  rowsPerPageOptions?: Array<number | { value: number; label: string }>;
  buttonsDisabled?: boolean;
};
const TablePagination: React.FC<TablePaginationProps> = ({
  count = 2000,
  rowsPerPageOptions = [10, 20, 50, 100, 200, 2000],
  onPageChange,
  page,
  rowsPerPage,
  onRowsPerPageChange,
  type = 'default',
  buttonsDisabled = false,
  labelRowsPerPage = 'Items per page:',
  ...props
}) => {
  const calculatedProps = useMemo(
    () =>
      getTablePaginationVariants({
        rowsPerPageOptions,
        labelRowsPerPage,
        buttonsDisabled,
        type,
      }),
    [rowsPerPageOptions, labelRowsPerPage, type, buttonsDisabled],
  );
  const numberLength = useMemo(() => {
    const lastElement = rowsPerPageOptions[rowsPerPageOptions.length - 1];
    if (typeof lastElement === 'object') {
      return `${lastElement.value}`.length;
    }
    return `${lastElement}`.length;
  }, [rowsPerPageOptions]);
  const countLength = useMemo(() => `${count}`.length, [count]);
  return (
    //@ts-ignore
    <CustomizedTablePagination
      component="div"
      SelectProps={{
        IconComponent: ChevronDown,
        MenuProps: {
          PaperProps: {
            sx: { boxShadow: '0px 4px 8px 0px #00000040' },
          },
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        },
      }}
      count={count}
      page={page}
      onPageChange={onPageChange}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={onRowsPerPageChange}
      $numberLength={numberLength}
      $countLength={countLength}
      {...calculatedProps}
      {...props}
    />
  );
};
export default TablePagination;
const CustomizedTablePagination = styled(TablePaginationM, {
  shouldForwardProp: (prop) =>
    prop !== '$numberLength' && prop !== '$countLength',
})<{
  $numberLength: number;
  $countLength: number;
}>(({ $numberLength, $countLength, count, theme }) => ({
  '.MuiTablePagination-displayedRows': {
    flexBasis: count !== -1 ? 8 * $countLength * 3 + 36 + 'px' : '175px',
  },
  '.MuiTablePagination-select': {
    display: 'flex',
    justifyContent: 'space-between',
    width: 8 * $numberLength + 20 + 'px',
    minHeight: '1rem !important',
  },
  '.MuiTablePagination-selectIcon': {
    fill: theme.palette.icon.secondary,
  },
}));
