// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import axios, { AxiosInstance, CreateAxiosDefaults } from 'axios';

import {
  applyAccessToken,
  applyLocalAccessToken,
} from './middlewares/applyAccessToken.js';
import { requestDataToSnakeCase } from './middlewares/requestDataToSnakeCase.js';
import { responseDataToCamelCase } from './middlewares/responseDataToCamelCase.js';
import { responseErrorInterceptor } from './middlewares/responseErrorInterceptor.js';

const api = axios.create({
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

// This API client is used when should ignore addition token (workspace) in request
export const profileApi = axios.create({
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

// This API client is used by the `@mint-lib/auth` package.
export const guestApi = axios.create({
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

export function createApi(config?: CreateAxiosDefaults): AxiosInstance {
  return axios.create(config);
}

export default api;

api.interceptors.request.use(requestDataToSnakeCase, (error) =>
  Promise.reject(error),
);
api.interceptors.request.use(applyAccessToken, (error) =>
  Promise.reject(error),
);

api.interceptors.response.use(
  responseDataToCamelCase,
  responseErrorInterceptor,
);

guestApi.interceptors.request.use(requestDataToSnakeCase, (error) =>
  Promise.reject(error),
);
guestApi.interceptors.response.use(
  responseDataToCamelCase,
  responseErrorInterceptor,
);

profileApi.interceptors.request.use(requestDataToSnakeCase, (error) =>
  Promise.reject(error),
);
profileApi.interceptors.request.use(applyLocalAccessToken, (error) =>
  Promise.reject(error),
);

profileApi.interceptors.response.use(
  responseDataToCamelCase,
  responseErrorInterceptor,
);
