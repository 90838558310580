import { formControlLabelClasses } from '@mui/material/FormControlLabel';
import { Palette } from '@mui/material/styles';

import typography from './typography.js';

const overrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        gap: '0.5rem',
        marginLeft: 0,
        marginRight: 0,
        color: palette.text.primary,
        alignItems: 'flex-start',
        [`&.${formControlLabelClasses.disabled}`]: {
          color: palette.text.disabled,
        },
      },
      label: {
        ...typography.bodyLong01,
      },
    },
  };
};

export default overrides;
