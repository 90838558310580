import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { FlagsmithClient } from './FlagsmithClient.js';
import {
  IFlagsmith,
  IFlagsmithFeature,
  IFlagsmithTrait,
  IState,
} from './origins/types.js';

export const FlagsmithContext = createContext<FlagsmithClient<string> | null>(
  null,
);
export type FlagsmithContextType = {
  environmentID: string; // The environment ID
  name: string; // The name of the environment
  options?: Omit<Parameters<IFlagsmith['init']>[0], 'environmentID'>; // Initialisation options, if you do not provide this you will have to call init manually
  serverState?: IState;
};

/**
 * @deprecated
 */
export const FlagsmithProvider: FC<PropsWithChildren<FlagsmithContextType>> = ({
  environmentID,
  name,
  options = {},
  serverState,
  children,
}) => {
  const firstRenderRef = useRef(true);
  const parentFlagsmith = useContext(FlagsmithContext);
  const instance = useRef<FlagsmithClient<string>>(
    new FlagsmithClient(
      {
        environmentID,
        name,
      },
      parentFlagsmith,
    ),
  );
  const flagsmith = instance.current;

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.warn(
      'FlagsmithProvider from @mint-lib/flags has been deprecated, please use the new FlagsmithProvider from @mint-lib/routing-context instead.',
    );
  }, [flagsmith]);

  if (serverState && !flagsmith.initialised) {
    flagsmith.setState(serverState);
  }
  if (firstRenderRef.current && import.meta.env.VITE_ENV !== 'demo') {
    firstRenderRef.current = false;
    flagsmith
      .init({
        cacheFlags: true,
        ...options,
        state: options.state || serverState,
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  }
  return (
    <FlagsmithContext.Provider value={flagsmith}>
      {children}
    </FlagsmithContext.Provider>
  );
};

const useConstant = function <T>(value: T): T {
  const ref = useRef(value);
  if (!ref.current) {
    ref.current = value;
  }
  return ref.current;
};

const flagsAsArray = (_flags: string | readonly string[]): string[] => {
  if (typeof _flags === 'string') {
    return [_flags];
  } else if (typeof _flags === 'object') {
    // eslint-disable-next-line no-prototype-builtins
    if (_flags.hasOwnProperty('length')) {
      return _flags as string[];
    }
  }
  throw new Error(
    'Flagsmith: please supply an array of strings or a single string of flag keys to useFlags',
  );
};

const getRenderKey = (
  flagsmith: FlagsmithClient,
  flags: string[],
  traits: string[] = [],
  env = 'default',
) => {
  return flags
    .map((k) => {
      return `${flagsmith.getValue(env, k)}${flagsmith.hasFeature(env, k)}`;
    })
    .concat(traits.map((t) => `${flagsmith.getTrait(env, t)}`))
    .join(',');
};

export function useFeature<F extends string = string>(
  _flags: readonly F[],
  env = 'default',
): {
  [K in F]: IFlagsmithFeature;
} {
  const flags = useConstant<string[]>(flagsAsArray(_flags));
  const flagsmith = useContext(FlagsmithContext);
  if (!flagsmith) {
    throw new Error(
      'Flagsmith: please wrap your app in a FlagsmithProvider to use useFeature',
    );
  }
  const [renderKey, setRenderKey] = useState<string>(
    getRenderKey(flagsmith, flags, [], env),
  );
  const renderRef = useRef<string>(renderKey);
  const eventListener = useCallback(() => {
    flagsmith?.log('React - Event listener triggered');
    const newRenderKey = getRenderKey(flagsmith, flags, [], env);
    if (newRenderKey !== renderRef.current) {
      renderRef.current = newRenderKey;
      setRenderKey(newRenderKey);
    }
  }, []);

  useEffect(() => {
    return flagsmith?.subscribe(eventListener);
  }, [flagsmith]);

  return useMemo(() => {
    flagsmith?.log('React - Render key has changed');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res: any = {};
    flags.forEach((k) => {
      res[k] = {
        enabled: flagsmith!.hasFeature(env, k),
        value: flagsmith!.getValue(env, k),
      };
    });
    return res;
  }, [renderKey]);
}

export function useTrait<T extends string = string>(
  _traits: readonly T[],
  env = 'default',
): {
  [K in T]: IFlagsmithTrait;
} {
  const traits = useConstant<string[]>(flagsAsArray(_traits));
  const flagsmith = useContext(FlagsmithContext);
  if (!flagsmith) {
    throw new Error(
      'Flagsmith: please wrap your app in a FlagsmithProvider to use useTrait',
    );
  }
  const [renderKey, setRenderKey] = useState<string>(
    getRenderKey(flagsmith, [], traits, env),
  );
  const renderRef = useRef<string>(renderKey);
  const eventListener = useCallback(() => {
    flagsmith?.log('React - Event listener triggered');
    const newRenderKey = getRenderKey(flagsmith, [], traits, env);
    if (newRenderKey !== renderRef.current) {
      renderRef.current = newRenderKey;
      setRenderKey(newRenderKey);
    }
  }, []);

  useEffect(() => {
    return flagsmith?.subscribe(eventListener);
  }, [flagsmith]);

  return useMemo(() => {
    flagsmith?.log('React - Render key has changed');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res: any = {};
    traits.forEach((k) => {
      res[k] = flagsmith!.getTrait(env, k);
    });
    return res;
  }, [renderKey]);
}

export function useFlags<F extends string = string, T extends string = string>(
  _flags: readonly F[],
  _traits: readonly T[] = [],
  env = 'default',
): {
  [K in F]: IFlagsmithFeature;
} & {
  [K in T]: IFlagsmithTrait;
} {
  const flags = useConstant<string[]>(flagsAsArray(_flags));
  const traits = useConstant<string[]>(flagsAsArray(_traits));
  const flagsmith = useContext(FlagsmithContext);
  if (!flagsmith) {
    throw new Error(
      'Flagsmith: please wrap your app in a FlagsmithProvider to use useFlags',
    );
  }
  const [renderKey, setRenderKey] = useState<string>(
    getRenderKey(flagsmith, flags, traits, env),
  );
  const renderRef = useRef<string>(renderKey);
  const eventListener = useCallback(() => {
    flagsmith?.log('React - Event listener triggered');
    const newRenderKey = getRenderKey(flagsmith, flags, traits, env);
    if (newRenderKey !== renderRef.current) {
      renderRef.current = newRenderKey;
      setRenderKey(newRenderKey);
    }
  }, []);
  if (import.meta.env.DEV) {
    // @ts-ignore TS2339 we will use this in devtools
    eventListener.flags = flags;
    // @ts-ignore TS2339 we will use this in devtools
    eventListener.traits = traits;
    // @ts-ignore TS2339 we will use this in devtools
    eventListener.env = env;
  }
  useEffect(() => {
    return flagsmith?.subscribe(eventListener);
  }, [flagsmith]);
  return useMemo(() => {
    flagsmith?.log('React - Render key has changed');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res: any = {};
    flags.forEach((k) => {
      res[k] = {
        enabled: flagsmith!.hasFeature(env, k),
        value: flagsmith!.getValue(env, k),
      };
    });
    traits?.forEach((v) => {
      res[v] = flagsmith!.getTrait(env, v);
    });
    return res;
  }, [renderKey]);
}

export function useFlagsmith<F extends string = string>() {
  const context = useContext(FlagsmithContext);

  if (!context) {
    throw new Error('useFlagsmith must be used with in a FlagsmithProvider');
  }

  return context as FlagsmithClient<F>;
}
