import { useEffect, useState } from 'react';

import styled, { keyframes } from '@mint-lib/styled';

import { MintLogoArmEnterprise } from '../../assets/index.jsx';

const forward = keyframes`
0% {
  background-position: -200px 0;

}
100% {
  background-position: 200px 0;
}
`;

const back = keyframes`
0% {
  background-position: 200px 0;
}
100% {
  background-position: -200px 0;
}
`;

const MintLoader = () => {
  const [isAnimationForward, setIsAnimationForward] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimationForward((prev) => !prev);
    }, 1500);
    return () => clearInterval(interval);
  }, []);

  return <Loader className={isAnimationForward ? 'forward' : 'back'} />;
};
export default MintLoader;

const Loader = styled('div')`
  width: 200px;
  height: 50px;
  mask-size: 200px;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-image: url(${MintLogoArmEnterprise});
  background-size: contain;
  background-position: center;
  &.forward {
    background: linear-gradient(270deg, #75f461, rgba(117, 244, 97, 0));
    background-repeat: no-repeat;
    animation: 1.5s linear 0s infinite ${forward};
  }
  &.back {
    background: linear-gradient(90deg, #75f461, rgba(117, 244, 97, 0));
    background-repeat: no-repeat;
    animation: 1.5s linear 0s infinite ${back};
  }
`;
