import styled from '@mint-lib/styled';
import TabsM, { TabsProps as TabsPropsM } from '@mui/material/Tabs';

export interface TabsProps extends TabsPropsM {
  /**
   * Indicator can be located below or above
   * @default top
   */
  indicatorPosition?: 'top' | 'bottom';
}

function Tabs({
  indicatorPosition = 'bottom',
  orientation = 'horizontal',
  ...props
}: TabsProps) {
  return (
    <StyledTabs
      indicatorPosition={indicatorPosition}
      orientation={orientation}
      {...props}
    >
      {props.children}
    </StyledTabs>
  );
}

export default Tabs;

const StyledTabs = styled(TabsM, {
  shouldForwardProp: (prop) => prop !== 'indicatorPosition',
})<{
  indicatorPosition: 'top' | 'bottom';
  orientation?: 'horizontal' | 'vertical';
}>(({ theme, indicatorPosition, orientation }) => ({
  '& .MuiTabs-indicator': {
    [`${indicatorPosition}`]: 0,
  },
  boxShadow:
    orientation === 'horizontal'
      ? `inset 0 -1px 0 ${theme.palette.ui.tertiary}`
      : `inset -2px 0 0px -1px ${theme.palette.ui.tertiary}`,
  '.MuiTabs-flexContainer': {
    alignItems: 'center',
    gap: orientation === 'horizontal' ? '0px' : '4px',
    '.large': {
      height: orientation === 'horizontal' ? '2rem' : '2.5rem',
    },
    '.medium': {
      height: orientation === 'horizontal' ? '1.5rem' : '2rem',
    },
    '.MuiTab-root': {
      margin: orientation === 'horizontal' ? '4px 0px' : '0px 4px',
    },
  },
}));
