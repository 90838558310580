import { PermissionsRequestType } from '../getUserProfile/getUserProfile.types.js';
import { useProfile } from '../useProfile/index.jsx';

export const usePermissions = () => {
  const { profileService } = useProfile();
  const permissions = profileService.getPermissions();

  function getPermissionField<T extends keyof typeof permissions>(key: T) {
    return permissions[key];
  }

  return { permissions, getPermissionField };
};

export const useIsAllowedPermission = (
  requestedAccess: PermissionsRequestType,
) => {
  const { profileService } = useProfile();
  return profileService.hasPermission(requestedAccess);
};
