import { isBefore, subMonths } from 'date-fns';

import { formatDateToState } from './formatDateToState.js';
import toDate from './toDate.js';

export const initialDates = {
  startDate: formatDateToState(subMonths(new Date(), 6)) || null,
  endDate: formatDateToState(new Date()) || null,
};

export const getInitialDates = (
  startDate?: string | null,
  endDate?: string | null,
) => {
  const validatedStartDate = toDate(startDate);
  const validatedEndDate = toDate(endDate);

  if (!validatedStartDate) {
    return initialDates;
  }

  // If only start date is provided, set end date to start date
  if (validatedStartDate && !validatedEndDate) {
    return {
      startDate: formatDateToState(validatedStartDate),
      endDate: formatDateToState(validatedStartDate),
    };
  }

  if (
    validatedStartDate &&
    validatedEndDate &&
    isBefore(validatedEndDate, validatedStartDate)
  ) {
    return initialDates;
  }

  return initialDates;
};
