import { Box, useDateFormatter, useTimeFormatter } from '@myn/mui';

export type Props = { editedAt: string };

const DateTimeCell = ({ editedAt }: Props) => {
  const dateFormatter = useDateFormatter();
  const timeFormatter = useTimeFormatter();

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      {`${dateFormatter.format(editedAt)} - ${timeFormatter.format(editedAt)}`}
    </Box>
  );
};

export default DateTimeCell;
