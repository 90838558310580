import { alpha, Palette } from '@mui/material/styles';

import typography from './typography.js';

const buttonOverride = (palette: Palette) => {
  return {
    variants: [
      {
        props: { variant: 'plain' as const },
        style: {
          minWidth: '0px',
          gap: '4px',
          height: '1rem',
          padding: '0px',
          backgroundColor: palette.transparent.main,
          color: palette.tertiary.main,

          '&:hover': {
            backgroundColor: palette.transparent.main,
            color: palette.tertiary.hover,
          },
          '&:focus': {
            boxShadow: `0 0 0 2px ${palette.action.focusBorder}`,
          },
          '&:active': {
            color: palette.tertiary.dark,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.transparent.main,
            color: palette.text.disabled,
            border: 'none',
          },
        },
      },
    ],
    styleOverrides: {
      // Name of the slot
      root: {
        ...typography.bodyShort02,
        textTransform: 'none',
        letterSpacing: 0.16,
        borderRadius: '4px',
        boxShadow: 'none',
        whiteSpace: 'nowrap' as const,
        transition:
          'background 70ms cubic-bezier(0,0,.38,.9),box-shadow 70ms cubic-bezier(0,0,.38,.9),border-color 70ms cubic-bezier(0,0,.38,.9),outline 70ms cubic-bezier(0,0,.38,.9)',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
        '& .MuiSvgIcon-root': {
          color: 'currentColor',
        },
      },
      sizeLarge: {
        height: '2.5rem',
        padding: '12px 16px',
        gap: '8px',
      },
      sizeMedium: {
        height: '2rem',
        padding: '8px 12px',
        gap: '8px',
      },
      sizeSmall: {
        height: '1.5rem',
        padding: '4px 8px',
        gap: '4px',
      },

      containedPrimary: {
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        '&:hover': {
          backgroundColor: palette.primary.hover,
        },
        '&:active': {
          backgroundColor: palette.primary.active,
        },
        '&:focus': {
          boxShadow: `0 0 0 2px ${palette.primary.light}`,
        },
      },
      containedSecondary: {
        backgroundColor: palette.secondary.main,
        color: palette.secondary.contrastText,
        '&:hover': {
          backgroundColor: palette.secondary.hover,
        },
        '&:active': {
          backgroundColor: palette.secondary.active,
        },
        '&:focus': {
          boxShadow: `0 0 0 2px ${palette.action.focusBorder}`,
        },
      },
      containedError: {
        '&:active': {
          backgroundColor: palette.error.active,
        },
        '&:hover': {
          backgroundColor: palette.error.hover,
        },
        '&:focus': {
          boxShadow: `0 0 0 2px ${palette.error.light}`,
        },
      },
      containedWarning: {
        '&:active': {
          backgroundColor: palette.warning.active,
        },
        '&:hover': {
          backgroundColor: palette.warning.hover,
        },
        '&:focus': {
          boxShadow: `0 0 0 2px ${palette.warning.light}`,
        },
      },
      outlinedPrimary: {
        backgroundColor: palette.transparent.main,
        border: `1px solid ${palette.secondary.light}`,
        color: palette.secondary.main,
        '&:hover': {
          backgroundColor: palette.ui.hover,
          border: `1px solid ${palette.secondary.light}`,
        },
        '&:focus': {
          boxShadow: `0 0 0 2px ${palette.action.focusBorder}`,
        },
        '&:active': {
          backgroundColor: palette.ui.active,
        },
      },
      outlinedSecondary: {
        backgroundColor: palette.transparent.main,
        border: `1px solid ${palette.secondary.light}`,
        color: palette.secondary.main,
        '&:hover': {
          backgroundColor: palette.ui.hover,
          border: `1px solid ${palette.secondary.light}`,
        },
        '&:focus': {
          boxShadow: `0 0 0 2px ${palette.action.focusBorder}`,
        },
        '&:active': {
          backgroundColor: palette.ui.active,
        },
      },
      outlinedError: {
        backgroundColor: palette.transparent.main,
        border: `1px solid ${palette.secondary.light}`,
        color: palette.secondary.main,
        '&:hover, &:active': {
          color: palette.error.dark,
        },
        '&:hover': {
          backgroundColor: alpha(palette.error.main, 0.08),
          border: `1px solid ${palette.error.main}`,
        },
        '&:active': {
          backgroundColor: alpha(palette.error.main, 0.16),
          border: `1px solid ${palette.error.main}`,
        },
        '&:focus': {
          boxShadow: `0 0 0 2px ${palette.error.light}`,
        },
      },
      outlinedWarning: {
        backgroundColor: palette.transparent.main,
        border: `1px solid ${palette.secondary.light}`,
        color: palette.secondary.main,

        '&:hover, &:active': {
          color: palette.warning.dark,
        },
        '&:hover': {
          backgroundColor: alpha(palette.warning.main, 0.08),
          border: `1px solid ${palette.warning.main}`,
        },
        '&:active': {
          backgroundColor: alpha(palette.warning.main, 0.16),
          border: `1px solid ${palette.warning.main}`,
        },
        '&:focus': {
          boxShadow: `0 0 0 2px ${palette.warning.light}`,
        },
      },
      textPrimary: {
        backgroundColor: palette.transparent.main,
        color: palette.tertiary.main,

        '&:hover': {
          backgroundColor: alpha(palette.tertiary.light, 0.08),
        },
        '&:active': {
          backgroundColor: alpha(palette.tertiary.light, 0.16),
        },
        '&:focus': {
          boxShadow: `0 0 0 2px ${palette.action.focusBorder}`,
        },
      },
      textSecondary: {
        backgroundColor: palette.transparent.main,
        color: palette.tertiary.main,

        '&:hover': {
          backgroundColor: alpha(palette.tertiary.light, 0.08),
        },
        '&:active': {
          backgroundColor: alpha(palette.tertiary.light, 0.16),
        },
        '&:focus': {
          boxShadow: `0 0 0 2px ${palette.action.focusBorder}`,
        },
      },
      textError: {
        backgroundColor: palette.transparent.main,
        color: palette.error.dark,

        '&:hover': {
          backgroundColor: alpha(palette.error.main, 0.08),
        },
        '&:active': {
          backgroundColor: alpha(palette.error.main, 0.16),
        },
        '&:focus': {
          boxShadow: `0 0 0 2px ${palette.error.light}`,
        },
      },
      textWarning: {
        backgroundColor: palette.transparent.main,
        color: palette.warning.dark,
        '&:hover': {
          backgroundColor: alpha(palette.warning.main, 0.08),
        },
        '&:active': {
          backgroundColor: alpha(palette.warning.main, 0.16),
        },
        '&:focus': {
          boxShadow: `0 0 0 2px ${palette.warning.light}`,
        },
      },
      contained: {
        '&.Mui-disabled': {
          backgroundColor: palette.action.disabledBackground,
          color: palette.text.disabled,
          '&:active': {
            backgroundColor: palette.action.disabledBackground,
          },
          '&:focus': {
            backgroundColor: palette.action.disabledBackground,
          },
        },
      },
      outlined: {
        '&.Mui-disabled': {
          backgroundColor: palette.transparent.main,
          border: `1px solid ${palette.ui.tertiary}`,
          color: palette.text.disabled,
        },
      },
      text: {
        '&.Mui-disabled': {
          color: palette.text.disabled,
          border: 'none',
        },
      },
      endIcon: {
        marginRight: 0,
        marginLeft: 0,
        '& > *:nth-of-type(1)': {
          fontSize: 16,
          marginTop: 0,
        },
      },
      startIcon: {
        marginLeft: 0,
        marginRight: 0,

        '& > *:nth-of-type(1)': {
          fontSize: 16,
          marginTop: 0,
        },
      },
    },
  };
};
export default buttonOverride;
