import * as React from 'react';

import { useTranslation } from '@mint-lib/i18n';
import styled, { isPropValid } from '@mint-lib/styled';
import { PopoverProps } from '@mui/material/Popover';
import SelectM, { SelectProps as MUISelectProps } from '@mui/material/Select';
import { TooltipProps as TooltipPropsM } from '@mui/material/Tooltip';

import type { IconsList as Icons } from '../../assets/asyncIcons.jsx';
import HelperText from '../../common/Input/HelperText.jsx';
import Label from '../../common/Input/Label.jsx';
import OptionWithCheckbox from '../../common/Select/OptionWithCheckbox.jsx';
import SelectAllCheckbox from '../../common/Select/SelectAllCheckbox.jsx';
import SelectInputWrapper from '../../common/Select/SelectInputWrapper.jsx';
import { GroupType, OptionType, ValueTypes } from '../../types/SelectTypes.js';
import {
  checkForEmptyArray,
  findLabelInArrayIntersection,
} from '../../utils/selectUtils.js';
import Box from '../Box/Box.jsx';
import FormControl from '../FormControl/FormControl.jsx';
import Icon, { IconProps } from '../Icon/Icon.jsx';
import ListSubheader from '../ListSubheader/ListSubheader.jsx';
import Typography from '../Typography/Typography.jsx';

export interface SelectProps<VT extends ValueTypes> {
  /**
   * Two possibilities of options one for default one {value, label}
   * Second one for group {header, options: {label, value}}
   */
  options: OptionType[] | GroupType[];
  /**
   * If true, value must be an array and the menu will support multiple selections.
   * @default false
   */
  multiple?: boolean;

  name?: string;
  /**
   * The input value.
   * Providing an empty string will select no options. Set to an empty string '' if you don't want any of the available options to be selected.
   * If the value is an object it must have reference equality with the option in order to be selected.
   * If the value is not an object, the string representation must match with the string representation of the option in order to be selected.
   * @uxpinbind onChange 0
   */
  value: VT;
  /**
   * Callback fired when a menu item is selected.
   */
  onChange: (value: VT) => void;
  /**
   * Select Props applies for select component
   */
  selectProps?: Omit<
    MUISelectProps,
    | 'onChange'
    | 'value'
    | 'multiple'
    | 'onOpen'
    | 'open'
    | 'renderValue'
    | 'displayEmpty'
    | 'defaultValue'
  >;
  /**
   * Popover Props applies for Popover component
   */
  popoverProps?: Omit<
    PopoverProps,
    'open' | 'anchorOrigin' | 'anchorEl' | 'onClose'
  >;
  /**
   * Label for the select
   */
  label?: string;
  /**
   * Text under select
   */
  helperText?: string;
  /**
   * Placeholder for the select
   */
  placeholder?: string;
  /**
   * If true component state is error
   * @default false
   */
  error?: boolean;
  /**
   * If true component state is disabled
   * @default false
   */
  disabled?: boolean;
  /**
   * The size can be small or medium
   * @default medium
   */
  size?: 'small' | 'medium';
  /**
   * The color of the component
   * @default primary
   */
  color?: 'primary' | 'secondary';
  /**
   * Ability to stretch the entire width of the container
   * @default false
   */
  fullWidth?: boolean;
  /**
   * Icon placed at the start
   */
  startIcon?: Icons;
  /**
   *  Text to render tooltip for information
   */
  infoText?: string;
  /**
   * Tooltip props
   */
  TooltipProps?: Partial<TooltipPropsM>;
  HelperTextTooltipProps?: Partial<TooltipPropsM>;
  tooltipPlacement?: 'top' | 'bottom' | 'right';
  StartIconProps?: Partial<IconProps>;
  readOnly?: boolean;
  largeDropdown?: boolean;
  getOpenState?: (isOpen: boolean) => void;
  warning?: boolean;
  truncatedOptions?: boolean;
  width?: string;
  OptionsTooltipProps?: Partial<TooltipPropsM>;
  /**
   * Limit of options to be selected
   * If limit is set, select all(clear all) button will be hidden
   */
  limit?: number;
  /**
   * TOOLTIP PROPS OF OPTIONS
   * A text for tooltip
   */
  optionTooltipTitle?: string;
  /**
   * A placement for tooltip
   *@default right
   */
  optionTooltipPlacement?: TooltipPropsM['placement'];
}

const Select = <VT extends ValueTypes>({
  options,
  value,
  onChange,
  multiple,
  selectProps,
  popoverProps,
  label,
  helperText,
  placeholder,
  error,
  TooltipProps,
  HelperTextTooltipProps,
  disabled,
  size,
  color,
  fullWidth,
  startIcon,
  tooltipPlacement = 'right',
  infoText,
  StartIconProps,
  readOnly,
  largeDropdown,
  getOpenState,
  warning,
  truncatedOptions = false,
  width,
  OptionsTooltipProps,
  limit,
  optionTooltipTitle,
  optionTooltipPlacement = 'right',
  ...restProps
}: SelectProps<VT>) => {
  const { t } = useTranslation('@myn/mui');
  const selectRef = React.useRef<HTMLElement | null>();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const isGroup = React.useMemo(
    () => options[0] && 'header' in options[0],
    [options],
  );
  const allOptions = React.useMemo(
    () =>
      options
        ?.map((group) => ('options' in group ? group.options : group))
        .flat(),
    [options],
  );
  const handleToggle = () => {
    setIsOpen(!isOpen);
    getOpenState?.(!isOpen);
  };
  const handleSelectAll = () => {
    onChange(allOptions.map((option) => option.value) as VT);
  };
  const handleClearAll = () => {
    onChange([] as unknown as VT);
  };
  const handleChange = (v: VT) => {
    if (Array.isArray(v)) {
      const val = v.filter((el) => el);
      onChange(val as VT);
    } else if (multiple && typeof v === 'string') {
      // On autofill we get a stringified value.
      onChange(v.split(',') as VT);
    } else {
      if (v) {
        onChange(v as VT);
        setIsOpen(false);
        getOpenState?.(!isOpen);
      }
    }
  };

  return (
    <Box>
      {label ? (
        <Label
          disabled={disabled}
          label={label}
          infoText={infoText}
          tooltipPlacement={tooltipPlacement}
          TooltipProps={TooltipProps}
        />
      ) : null}
      <FormControl
        error={error}
        disabled={disabled}
        color={color}
        focused={isOpen}
        size={size}
        fullWidth={fullWidth}
      >
        <Wrapper fullWidth={fullWidth}>
          <Container>
            <SelectM
              {...restProps}
              sx={{
                '.MuiSelect-select': {
                  display: 'flex',
                  padding: size === 'small' ? '8px 12px' : '12px',
                  cursor: readOnly ? 'default' : 'pointer',
                },
              }}
              autoComplete="off"
              {...selectProps}
              className={`${warning ? 'warning-input' : ''}`}
              multiple={multiple}
              ref={selectRef}
              fullWidth={fullWidth}
              readOnly={readOnly}
              onChange={(e) => handleChange(e.target.value as VT)}
              onOpen={handleToggle}
              IconComponent={(props) =>
                readOnly ? null : <Icon {...props} component={'ChevronDown'} />
              }
              // @ts-ignore
              value={value || (multiple ? [] : '')}
              open={isOpen}
              displayEmpty
              renderValue={(selected: VT) => (
                <SelectInputWrapper
                  fullWidth={fullWidth}
                  width={width}
                  startIcon={startIcon}
                  StartIconProps={StartIconProps}
                >
                  {checkForEmptyArray(selected) ? (
                    findLabelInArrayIntersection(allOptions, selected)
                  ) : (
                    <Placeholder>{placeholder}</Placeholder>
                  )}
                </SelectInputWrapper>
              )}
              MenuProps={{
                ...popoverProps,
                variant: 'menu',
                onClose: handleToggle,
                PaperProps: {
                  sx: {
                    boxShadow:
                      '0px 2px 4px rgba(9, 30, 66, 0.16), 0px 0px 1px rgba(9, 30, 66, 0.24)',
                    width: truncatedOptions ? '120px' : 'inherit',
                    marginTop: '4px',
                    backgroundImage: 'none',
                    borderRadius: '4px',
                    padding: '4px 0px',
                  },
                },
                MenuListProps: {
                  sx: {
                    maxHeight: largeDropdown ? '20rem' : '13rem',
                    overflow: 'auto',
                  },
                },
              }}
              {...restProps}
            >
              {!!options.length &&
              multiple &&
              !limit &&
              Array.isArray(value) ? (
                <div>
                  <SelectAllCheckbox
                    allOptions={allOptions}
                    value={value}
                    text={
                      value.length !== allOptions.length
                        ? t('Select all')
                        : t('Clear all')
                    }
                    onClick={
                      value.length !== allOptions.length
                        ? handleSelectAll
                        : handleClearAll
                    }
                    isGroup={isGroup}
                  />
                </div>
              ) : null}
              {options.length ? (
                options?.map((option) => {
                  if ('options' in option && 'header' in option) {
                    return [
                      <StyledListSubheader>
                        {option.header}
                      </StyledListSubheader>,
                      ...option.options.map((opt) => (
                        <OptionWithCheckbox
                          key={opt.value}
                          value={opt.value}
                          selectValue={value}
                          option={opt}
                          isMultiple={multiple}
                          truncatedOptions={truncatedOptions}
                          OptionsTooltipProps={OptionsTooltipProps}
                          tooltipText={optionTooltipTitle}
                          tooltipPlacement={optionTooltipPlacement}
                          limit={limit}
                        />
                      )),
                    ];
                  }
                  return (
                    <OptionWithCheckbox
                      key={option.value}
                      selectValue={value}
                      value={option.value}
                      option={option}
                      isMultiple={multiple}
                      truncatedOptions={truncatedOptions}
                      OptionsTooltipProps={OptionsTooltipProps}
                      limit={limit}
                      tooltipText={optionTooltipTitle}
                      tooltipPlacement={optionTooltipPlacement}
                    />
                  );
                })
              ) : (
                <Typography
                  variant="bodyShort01"
                  padding="11px"
                  align="center"
                  display="block"
                  sx={(theme) => ({ color: theme.palette.text.secondary })}
                >
                  No options
                </Typography>
              )}
            </SelectM>
          </Container>
          {helperText ? (
            <HelperText
              warning={warning}
              HelperTextTooltipProps={HelperTextTooltipProps}
              error={error}
              fullWidth={fullWidth}
              helperText={helperText}
              helperTextWidth="16.875rem"
            />
          ) : null}
        </Wrapper>
      </FormControl>
    </Box>
  );
};
export default Select;
const Placeholder = styled('span')(({ theme }) => ({
  color: theme.palette.text.placeholder,
}));
const Container = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})<{
  fullWidth?: boolean;
}>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'min-content')};
`;
const StyledListSubheader = styled(ListSubheader, {
  shouldForwardProp: isPropValid,
})(({ theme }) => ({
  ...theme.typography.overline01,
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.ui.primary,
  padding: '12px 12px 4px 12px',
  backgroundImage: 'unset',
}));
