import { createFileRoute, notFound } from '@tanstack/react-router';

import { buildGuards, hasPermission } from '@mint-lib/routing-guards';

const guard = buildGuards(hasPermission('campaignStrategyFeature:view'));

export const Route = createFileRoute('/_secured/campaign/$campaignId/strategy')(
  {
    loader: async ({ context }) => {
      await context.serviceLocator.getInstance('i18n', '@myn/strategy_v2');
    },
    beforeLoad: async ({ context }) => {
      if (!guard(await context.getGuardContext())) {
        throw notFound();
      }
      return context;
    },
  },
);
