import { Palette } from '@mui/material/styles';

const AvatarGroupOverrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: ({ ownerState: { max } }: { ownerState: { max: number } }) => ({
        ...[...Array(max)].reduce(
          (result, curr, index) => ({
            ...result,
            [`& > .MuiAvatar-root:nth-child(${index + 1})`]: {
              zIndex: max - index,
            },
          }),
          {},
        ),
        '& .MuiAvatar-root': {
          display: 'flex',
          border: `1px solid ${palette.ui.tertiary}`,
          color: palette.text.secondary,
          justifyContent: 'center',
          alignItems: 'center',
          '&.MuiAvatar-square': {
            borderRadius: '4px',
          },
        },
      }),
    },
  };
};

export default AvatarGroupOverrides;
