import { useMemo, useState, type ChangeEvent } from 'react';

import type { IFlagsmithFeature } from '@mint-lib/flags';
import type { ClientType } from '@mint-lib/profile-helpers';

const COUNT_PER_PAGE = 13;

const useTablePagination = (clients: ClientType[], ff: IFlagsmithFeature) => {
  const [page, setPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const filteredClients = useMemo(
    () =>
      clients.filter((client) =>
        searchQuery ? client?.name.includes(searchQuery) : true,
      ),
    [searchQuery],
  );

  const paginatedClients = useMemo(
    () =>
      filteredClients.slice((page - 1) * COUNT_PER_PAGE, page * COUNT_PER_PAGE),
    [clients, page],
  );

  const pageCount = useMemo(() => {
    return Math.round(filteredClients.length / COUNT_PER_PAGE);
  }, [filteredClients]);

  const handleChangePage = (_: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const ffBasedClients = ff.enabled ? paginatedClients : clients;

  return {
    search: {
      value: searchQuery,
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        setPage(1);
        setSearchQuery(e.target.value);
      },
      onClear: () => {
        setPage(1);
        setSearchQuery('');
      },
    },
    pagination: {
      page,
      count: pageCount,
      onChange: handleChangePage,
    },
    data: ffBasedClients,
  };
};

export default useTablePagination;
