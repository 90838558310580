import type { PropsWithChildren } from 'react';

import { AuthContext } from '@mint-lib/auth';

import { useInstance } from '../hooks/useInstance.js';

export function AuthProvider({ children }: PropsWithChildren) {
  const [auth] = useInstance('auth');

  if (!auth) {
    return null;
  }

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}
