import { Palette } from '@mui/material/styles';

import typography from './typography.js';

const override = (palette: Palette) => {
  return {
    styleOverrides: {
      tooltip: {
        ...typography.bodyShort01,
        backgroundColor: palette.secondary.main,
        color: palette.text.inverse,
        borderRadius: '4px',
        fontWeight: 400,
        filter: 'drop-shadow(0px 0px 8px rgba(34, 37, 43, 0.24))',
        letterSpacing: '0.16px',
      },
      arrow: {
        color: palette.secondary.main,
      },
      popper: {
        '& .MuiTooltip-tooltip': {
          padding: '8px 8px',
        },
      },
    },
  };
};

export default override;
