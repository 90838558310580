import { forwardRef } from 'react';

import styled from '@mint-lib/styled';
import ChipM, { ChipProps as ChipPropsM } from '@mui/material/Chip';

import Icon from '../Icon/Icon.jsx';

export interface ChipProps extends ChipPropsM {
  /**
   * The color of the component.
   * @default default
   */
  color?: ChipPropsM['color'];

  /**
   * The size of the component.
   * @default medium
   */

  size?: ChipPropsM['size'];
}

const Chip = forwardRef(
  (
    {
      icon,
      deleteIcon,
      color = 'default',
      variant = 'filled',
      ...props
    }: ChipProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <StyledChip
        variant={variant}
        ref={ref}
        color={color}
        icon={icon}
        deleteIcon={<Icon component="Close" />}
        {...props}
      />
    );
  },
);

export default Chip;
const StyledChip = styled(ChipM)({
  '&.Mui-disabled': {
    opacity: '0.32',
  },
});
