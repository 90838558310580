import React, { forwardRef, ReactNode } from 'react';

import TableRowM, {
  TableRowProps as TableRowPropsM,
} from '@mui/material/TableRow';

type Child = ReactNode | ReactNode[];

export type TableRowProps = Omit<TableRowPropsM, 'children'> & {
  /**
   * Should be valid <tr> children such as `TableCell`.
     
     Normally Table with only 1 column doesn't make much sense
     but we have lots of them so we have to support this case in types (React.ReactElement)
   */
  children: Child | Child[];
  /**
   * Override or extend the styles applied to the component. See CSS API below for more details.
   * @uxpinignoreprop
   */
  classes?: TableRowPropsM['classes'];
  /**
   * If true, the table row will shade on hover.
   */
  hover?: boolean;
  /**
   * If true, the table row will have the selected shading.
   */
  selected?: boolean;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   * See the `sx` page for more details.
   */
  sx?: TableRowPropsM['sx'];
};

const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(
  ({ children, ...props }, ref) => {
    return (
      <TableRowM ref={ref} {...props}>
        {children as TableRowPropsM['children']}
      </TableRowM>
    );
  },
);

export default TableRow;
