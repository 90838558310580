import styled from '@mint-lib/styled';
import ListItemButton from '@mui/material/ListItemButton';

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== '$level',
})<{ $level?: 0 | 1 | 2 | 3 | 4 }>(({ $level }) => ({
  marginLeft: $level ? `${$level}rem` : 0,
  height: '20px',
}));

export const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== '$textLayout' && prop !== 'readOnly',
})<{
  $textLayout?: 'inline' | 'multiline';
  readOnly?: boolean;
}>(({ theme, $textLayout, readOnly }) => ({
  boxSizing: 'border-box',
  overflowWrap: $textLayout === 'multiline' ? 'anywhere' : 'initial',
  border: `1px solid ${theme.palette.border.secondary}`,
  backgroundColor: theme.palette.ui.primary,
  color: theme.palette.text.primary,
  borderRadius: '8px',
  gap: '8px',
  minHeight: '2rem',
  padding: '0px 12px',
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  '&:hover': {
    backgroundColor: readOnly
      ? theme.palette.ui.primary
      : theme.palette.ui.secondary,
  },
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: theme.palette.background.tertiary,
    borderColor: theme.palette.tertiary.light,
    color: theme.palette.tertiary.main,
  },
  '&.Mui-focusVisible': {
    backgroundColor: theme.palette.ui.primary,
    outline: `1px solid ${theme.palette.border.focus}`,
  },
  '&.Mui-focusVisible.Mui-selected': {
    backgroundColor: theme.palette.background.tertiary,
    borderColor: theme.palette.tertiary.light,
    color: theme.palette.tertiary.main,
  },

  '&.Mui-disabled': {
    boxShadow: 'none',
    borderColor: theme.palette.ui.tertiary,
    color: theme.palette.text.disabled,
    backgroundColor: theme.palette.transparent.main,
    opacity: 1,
  },
}));
