// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { AxiosResponse } from 'axios';

import {
  objectKeysToCamelCase,
  objectKeysToCamelCase2,
} from '../utils/objectKeysTo.js';

export function responseDataToCamelCase(
  response: AxiosResponse,
): AxiosResponse {
  return {
    ...response,
    data: objectKeysToCamelCase(response.data),
  };
}

export function responseDataToCamelCase2(
  response: AxiosResponse,
): AxiosResponse {
  return {
    ...response,
    data: objectKeysToCamelCase2(response.data),
  };
}
