import { useMemo } from 'react';

import camelcase from 'camelcase';
import { z } from 'zod';

import { useRequest } from './useRequest.js';

const IntegrationType = z.enum(['two_way', 'one_way', 'virtual'] as const);

const IntegrationCategory = z.enum([
  'integrated',
  'publisher',
  'dsp',
  'ad_network',
  'ad_server',
  'dmp',
  'brand_safety',
  'web_analytics',
] as const);

const GetPlatformsResponse = z.array(
  z.object({
    id: z.number(),
    platform: z.string(),
    label: z.string(),
    channels: z.array(z.string()),
    platformType: z.string(),
    description: z.string(),
    category: IntegrationCategory,
    logo: z.string().or(z.null()),
    type: IntegrationType,
    isDeletable: z.boolean().or(z.null()),
    isDeleted: z.boolean(),
    isIntegrated: z.boolean().or(z.null()),
    integrationsCount: z.number().or(z.null()),
    ghostCampaignIntegrationId: z.number().or(z.null()),
    isOmni: z.union([z.boolean(), z.undefined()]),
  }),
);

type IconByLabel = {
  [platformLabel: Platform['label']]: Platform;
};

export type PlatformsResponse = z.infer<typeof GetPlatformsResponse>;
export type Platform = PlatformsResponse[number];

export const usePlatforms = () => {
  const [platforms] = useRequest(
    'bff/v1/platforms/',
    undefined,
    GetPlatformsResponse,
  );

  const platformByLabel = useMemo(
    () =>
      (platforms ?? []).reduce(
        (acc: IconByLabel, item) => ({
          ...acc,
          [camelcase(item.label)]: item,
          // need for logs logos
          [item.platform]: item,
        }),
        {},
      ),
    [platforms],
  );

  return { platforms, platformByLabel };
};
