import { createFileRoute, notFound } from '@tanstack/react-router';

import { z } from 'zod';

export const Route = createFileRoute(
  '/_secured/campaign/$campaignId/trafficking',
)({
  validateSearch: z.object({
    key: z.string().optional(),
    search: z.string().optional(),
  }),
  async beforeLoad({ context }) {
    if (!context.hasPermission('campaignTraffickingFeature:view')) {
      throw notFound();
    }
    return context;
  },
  async loader({ context }) {
    await context.serviceLocator.getInstance('i18n', '@myn/trafficking');
  },
});
