import styled from '@mint-lib/styled';

const CalendarPopper = styled('div')`
  & .react-datepicker-popper {
    top: -6px !important;
    z-index: 100;
  }

  & .react-datepicker {
    border: ${({ theme }) =>
      `1px solid ${theme.palette.border.tertiary}`} !important;
    background-color: ${({ theme }) => theme.palette.ui.primary};
  }

  & .react-datepicker__triangle {
    display: none;
  }
`;

export default CalendarPopper;
