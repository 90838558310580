const overrides = {
  styleOverrides: {
    root: {
      marginTop: '0.5rem',
      gap: '0.5rem',
    },
  },
};

export default overrides;
