import { TFunction } from '@mint-lib/i18n';
import { z } from 'zod';

export const StringWithMaxLength = (length: number, t: TFunction) => {
  return z
    .string()
    .max(length, t('Max length is {{length}} characters.', { length }));
};

export const RequiredString = (t: TFunction) => {
  return z.string().nonempty(t('Required'));
};

export const UserModalValidationSchema = (t: TFunction) => {
  return z.object({
    firstName: RequiredString(t).pipe(StringWithMaxLength(255, t)),
    lastName: RequiredString(t).pipe(StringWithMaxLength(255, t)),
    mobileNumber: StringWithMaxLength(255, t).optional(),
    officeNumber: StringWithMaxLength(255, t).optional(),
    email: RequiredString(t).email(t(`This email isn't correct`)),
    role: z.union([z.string().min(1, 'Role is required'), z.number()]),
    assignedClients: z.array(z.number()).optional(),

    id: z.union([z.string(), z.number()]).optional(),
    avatar: z.string().nullable(),
    isActive: z.boolean().optional(),
  });
};

export type UserModalType = z.infer<
  ReturnType<typeof UserModalValidationSchema>
>;
