import { createFileRoute } from '@tanstack/react-router';

import { z } from 'zod';

const searchSchema = z.object({
  search: z.string().optional(),
  pageNumber: z.number().optional(),
  itemsPerPage: z.number().optional(),
});

export const Route = createFileRoute(
  '/_secured/company-profile/import-hub/custom-metrics',
)({
  validateSearch: searchSchema,
});
