import { useContext, useEffect, type ReactNode } from 'react';

import { Slots } from '../contexts/Slots.jsx';

export interface SlotPortalProps {
  name: 'topBarTitle' | 'sidebar' | 'filters' | 'topBarCampaignTitle';
  /**
   * The priority of the slot. Higher priority slots will be rendered.
   *
   * Set priority if you have a child that also tries to write into the same slot.
   */
  priority?: number;
  children?: ReactNode;
}

/**
 * A component that renders its children in a slot that was provided elsewhere.
 */
export function SlotPortal({ name, children, priority }: SlotPortalProps) {
  const manager = useContext(Slots);
  useEffect(() => {
    return manager.registerSlot(name, children, priority);
  }, [name, children, manager]);

  return null;
}
