import React, { forwardRef } from 'react';

import TableCellM, { TableCellProps } from '@mui/material/TableCell';

export interface Props extends TableCellProps {
  /**
   * The content of the TableCell, normally TableCellHead and TableCellBody.
   * @uxpinpropname Cell Data
   * */
  children?: React.ReactNode;
  /**
   * Set the text-align on the table cell content. M
   * onetary or generally number fields should be right aligned
   * as that allows you to add them up quickly in your head without having to worry about decimals.
   */
  align?: TableCellProps['align'];
  /**
   * Set aria-sort direction.
   */
  sortDirection?: TableCellProps['sortDirection'];
  /**
   * Specify the cell type.
   * The prop defaults to the value inherited from the parent TableHead, TableBody, or TableFooter components.
   */
  variant?: TableCellProps['variant'];
  /**
   * Sets the padding applied to the cell.
   * The prop defaults to the value ('default') inherited from the parent Table component.
   */
  padding?: TableCellProps['padding'];
  /**
   * Specify the size of the cell.
   * The prop defaults to the value ('medium') inherited from the parent Table component.
   */
  size?: TableCellProps['size'];
  /**
   * Override or extend the styles applied to the component. See CSS API below for more details.
   * @uxpinignoreprop
   */
  classes?: TableCellProps['classes'];
  /**
   * The component used for the root node. Either a string to use a HTML element or a component.
   */
  component?: TableCellProps['component'];
  /**
   * Set scope attribute.
   */
  scope?: string;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   * See the `sx` page for more details.
   */
  sx?: TableCellProps['sx'];
}

const TableCell = forwardRef((props: Props, ref) => {
  return (
    <TableCellM {...props} ref={ref}>
      {props.children}
    </TableCellM>
  );
});

export default TableCell;
