import { useTranslation } from '@mint-lib/i18n';

import { Box } from '@myn/mui';

import { UsersAndItemId } from './UserCascadeCell.types.js';

const UserCascadeCell = (value: UsersAndItemId) => {
  const { t } = useTranslation('@myn/permissions');
  const isEmpty = value.users.length === 0;

  return <Box>{isEmpty ? t('No user assigned') : value.users.length}</Box>;
};

export default UserCascadeCell;
