import { datadogRum } from '@mint-lib/datadog';
import type { Flagsmith } from '@mint-lib/flags';
import { FlagsmithProviders } from '@mint-lib/flags-envs';
import { PermissionsRequestType } from '@mint-lib/profile-helpers';
import { ServiceLocator } from '@mint-lib/service-locator';

import { authFactory } from './factories/auth.js';
import { flagsmithAbstractFactory } from './factories/flagsmith.js';
import { httpFactory } from './factories/http.js';
import { i18nFactory } from './factories/i18n.js';
import { profileFactory } from './factories/profile.js';
import { requestFactory } from './factories/request.js';
import type { MintServices, RouterContext } from './types.js';

export function makeRoutingContext(): RouterContext {
  const serviceLocator = new ServiceLocator<MintServices>();
  serviceLocator.registerAbstractFactory('auth', authFactory);
  serviceLocator.registerInstance('datadog', datadogRum);
  serviceLocator.registerAbstractFactory('profile', profileFactory);
  serviceLocator.registerAbstractFactory('flagsmith', flagsmithAbstractFactory);
  serviceLocator.registerAbstractFactory('i18n', i18nFactory);
  serviceLocator.registerAbstractFactory('http', httpFactory);
  serviceLocator.registerAbstractFactory('request', requestFactory);

  async function isFlagEnabled(
    env: keyof typeof FlagsmithProviders,
    flagName: string,
  ) {
    return (await serviceLocator.getInstance('flagsmith', env)).hasFeature(
      env as string,
      flagName,
    );
  }

  async function isAuthenticated() {
    return (await serviceLocator.getInstance('auth')).isLoggedIn();
  }

  async function getAuth0Credentials() {
    return (await serviceLocator.getInstance('auth')).auth0Credentials;
  }

  async function hasPermission(permission: PermissionsRequestType) {
    return (await serviceLocator.getInstance('profile')).hasPermission(
      permission,
    );
  }

  async function isControllerCompany() {
    return (await serviceLocator.getInstance('profile')).isControllerCompany();
  }

  async function isAgencyCompany() {
    return (await serviceLocator.getInstance('profile')).isAgencyCompany();
  }

  async function isRetailLicensee() {
    return (await serviceLocator.getInstance('profile')).isRetailLicensee();
  }

  async function isControllerLoggedInAsClient() {
    return (
      await serviceLocator.getInstance('profile')
    ).isControllerLoggedInAsClient();
  }

  async function getGuardContext(flagsService?: Flagsmith) {
    return {
      profileService: await serviceLocator.getInstance('profile'),
      authService: await serviceLocator.getInstance('auth'),
      flagsService:
        flagsService ||
        (await serviceLocator.getInstance('flagsmith', '@mint/app')),
    };
  }

  async function load<T>(
    url: string,
    params?: Record<string, string | number>,
    type: 'guestApi' | 'defaultApi' = 'defaultApi',
  ): Promise<T> {
    return serviceLocator.getInstance('request', url, params, type) as T;
  }

  return {
    isFlagEnabled,
    isAuthenticated,
    hasPermission,
    getGuardContext,
    getAuth0Credentials,
    load,
    serviceLocator,
    profileHelpers: {
      isControllerCompany,
      isAgencyCompany,
      isRetailLicensee,
      isControllerLoggedInAsClient,
    },
  };
}
