import { useMemo, useState } from 'react';

import { useFlags } from '@mint-lib/flags';
import { useTranslation } from '@mint-lib/i18n';

import {
  Box,
  Chip,
  Dialog,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@myn/mui';

import { Label, UserType } from '../../api/users/index.js';

type Props = {
  className?: string;
  onClose: () => void;
  userProfile: Partial<UserType>;
  isActive: boolean;
};

const LabelModal: React.FC<Props> = ({ onClose, isActive, userProfile }) => {
  const { t } = useTranslation('@myn/permissions');

  const ff = useFlags([`noLabelAssignedLabel`]);

  const [categoryType, setCategoryType] = useState<'brands' | 'geographies'>(
    'brands',
  );

  const filterLabels = useMemo(
    () =>
      userProfile.tags
        ?.filter((tag: Label) => tag.categoryType === categoryType)
        .sort((a: Label, b: Label) => +a.level - +b.level),
    [userProfile.tags, categoryType],
  );
  const subCategories = useMemo(
    () =>
      [...new Set(filterLabels?.map((tag: Label) => tag.category))].map(
        (subCategory: string) => {
          return {
            name: subCategory,
            labels: filterLabels?.filter(
              (label: Label) => label.category === subCategory,
            ),
          };
        },
      ),
    [filterLabels],
  );

  return (
    <Dialog
      open={isActive}
      onClose={onClose}
      title={
        `${userProfile.firstName} ${userProfile.lastName} ` +
        t('Assigned Labels')
      }
      size="m"
      submitButtonProps={{
        label: t('Ok'),
        onClick: onClose,
        color: 'primary',
      }}
      cancelButtonProps={{
        label: t('Cancel'),
        onClick: onClose,
        variant: 'text',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: (theme) => theme.spacing(2),
        }}
      >
        <ToggleButtonGroup value={categoryType} size="large">
          <ToggleButton
            size="large"
            value="brands"
            label={t('Brands & products')}
            onClick={() => {
              setCategoryType('brands');
            }}
          />

          <ToggleButton
            size="large"
            value="geographies"
            label={t('Geographies')}
            onClick={() => {
              setCategoryType('geographies');
            }}
          />
        </ToggleButtonGroup>

        {ff.noLabelAssignedLabel.enabled && subCategories.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              py: (theme) => theme.spacing(2),
            }}
          >
            <Typography variant="h4">
              {t(`There are no labels assigned yet`)}
            </Typography>
          </Box>
        ) : (
          subCategories.map((subCategory) => {
            return (
              <Box
                key={subCategory.name}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: (theme) => theme.spacing(1),
                }}
              >
                <Typography variant="h6">{subCategory.name}</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: (theme) => theme.spacing(1),
                  }}
                >
                  {subCategory.labels?.map((label) => {
                    return (
                      <Chip
                        key={label.name}
                        label={label.name}
                        variant="outlined"
                        color="secondary"
                      />
                    );
                  })}
                </Box>
              </Box>
            );
          })
        )}
      </Box>
    </Dialog>
  );
};

export default LabelModal;
