import React, {
  ComponentProps,
  ElementType,
  forwardRef,
  isValidElement,
  ReactNode,
  useLayoutEffect,
} from 'react';

import TableHeadM, {
  TableHeadProps as TableHeadPropsM,
} from '@mui/material/TableHead';

import { useTableContext } from '../Table/Table.jsx';
import TableHeaderCell from '../TableHeaderCell/TableHeaderCell.jsx';
import TableRow, { TableRowProps } from '../TableRow/TableRow.jsx';

export interface TableHeadProps extends TableHeadPropsM {
  /**
   * The content of the component, normally TableRow.
   * @uxpinignoreprop
   * */
  children?: ReactNode | ReactNode[];
  /**
   * Override or extend the styles applied to the component.
   * See CSS API below for more details.
   * @uxpinignoreprop
   */
  classes?: TableHeadPropsM['classes'];
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component?: ElementType;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   * See the `sx` page for more details.
   */
  sx?: TableHeadPropsM['sx'];
}

const TableHead = forwardRef<HTMLTableSectionElement, TableHeadProps>(
  ({ sx, ...props }, ref) => {
    const { isLoading, variants, setVariants } = useTableContext();

    const tableHeadRows = props.children;

    const tableHeadRowsChildren = ([] as TableHeadProps['children'][])
      .concat(tableHeadRows)
      .filter(isValidElement<ComponentProps<typeof TableRow>>)
      .flatMap((tableRow) => tableRow.props.children);

    const tableHeadCells = ([] as TableRowProps['children'][])
      .concat(tableHeadRowsChildren)
      .flatMap((child) => child)
      .filter(isValidElement<ComponentProps<typeof TableHeaderCell>>);

    const skeletonVariants = tableHeadCells.map(
      (child) => child.props.skeletonVariant,
    );

    useLayoutEffect(() => {
      setVariants(skeletonVariants);
    }, []);

    const isVariantsLoaded = Boolean(variants.length);
    const isReady = !isLoading || (isLoading && isVariantsLoaded);

    return (
      <TableHeadM
        ref={ref}
        sx={{ visibility: isReady ? 'visible' : 'hidden', ...sx }}
        {...props}
      />
    );
  },
);
export default TableHead;
