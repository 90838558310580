/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ServiceLocatorAbstractFactoryContext } from './ServiceLocatorAbstractFactoryContext.js';
import { ServiceLocatorEventBus } from './ServiceLocatorEventBus.js';
import type { ServiceLocatorInstanceHolder } from './ServiceLocatorInstanceHolder.js';
import type {
  ServiceArgs,
  ServiceEventsArgs,
  ServiceEventsNames,
  ServiceFromInstanceName,
  ServiceInstance,
  ServiceInstanceName,
  ServicesConfig,
  ServicesInstancesNames,
  ServicesNames,
} from './types.js';

export class ServiceLocator<Services extends ServicesConfig = {}> {
  private factories: Map<keyof Services, (...args: any) => Promise<any>> =
    new Map();
  private abstractFactories: Map<
    keyof Services,
    (
      ctx: ServiceLocatorAbstractFactoryContext<Services>,
      ...args: any
    ) => Promise<any>
  > = new Map();
  protected instancesHolders: Map<
    ServicesInstancesNames<Services>,
    ServiceLocatorInstanceHolder<Services>
  > = new Map();
  protected createInstancePromises: Map<
    ServicesInstancesNames<Services>,
    Promise<any>
  > = new Map();
  private eventBus: ServiceLocatorEventBus<Services>;

  constructor(private readonly logger: Console | null = null) {
    this.eventBus = new ServiceLocatorEventBus(logger);
  }

  getEventBus() {
    return this.eventBus;
  }

  public registerFactory<
    Key extends ServicesNames<Services>,
    Args extends ServiceArgs<Services, Key>,
    Instance extends ServiceInstance<Services, Key, Args>,
  >(name: Key, factory: (...args: Args) => Promise<Instance>) {
    this.logger?.log(
      `[ServiceLocator]#registerFactory(): Registering factory for ${name}`,
    );
    this.factories.set(name, factory);
  }

  public registerAbstractFactory<
    Key extends ServicesNames<Services>,
    Args extends ServiceArgs<Services, Key>,
    Instance extends ServiceInstance<Services, Key, Args>,
  >(
    name: Key,
    factory: (
      ctx: ServiceLocatorAbstractFactoryContext<Services>,
      ...args: Args
    ) => Promise<Instance>,
  ) {
    this.logger?.log(
      `[ServiceLocator]#registerAbstractFactory(): Registering abstract factory for ${name}`,
    );
    this.abstractFactories.set(name, factory);
  }

  public registerInstance<
    Key extends ServicesNames<Services>,
    Args extends ServiceArgs<Services, Key>,
    Instance extends ServiceInstance<Services, Key, Args>,
  >(name: ServiceInstanceName<Services, Key>, instance: Instance) {
    this.logger?.log(
      `[ServiceLocator]#registerInstance(): Registering instance for ${name}`,
    );
    const instanceHoler: ServiceLocatorInstanceHolder<Services, Key, Args> = {
      name,
      instance,
      kind: 'instance',
      effects: [],
      deps: [],
      destroyListeners: [],
      createdAt: Date.now(),
      ttl: Infinity,
    };
    this.instancesHolders.set(name, instanceHoler as any);
  }

  public async getInstance<
    Key extends ServicesNames<Services>,
    Args extends ServiceArgs<Services, Key>,
    Instance extends ServiceInstance<Services, Key, Args>,
  >(name: Key, ...args: Args): Promise<Instance> {
    const holder = this.getInstanceHolder<Key, Args>(name, ...args);
    if (holder) {
      //@ts-expect-error TS2322
      return holder.instance;
    }
    const instanceName = this.makeInstanceName(name, args);

    return this.createInstance(instanceName, name, args);
  }

  private getInstanceHolder<
    Key extends ServicesNames<Services>,
    Args extends ServiceArgs<Services, Key>,
  >(
    name: Key,
    ...args: Args
  ): ServiceLocatorInstanceHolder<Services, Key, Args> | undefined {
    const instanceName = this.makeInstanceName(name, args);
    this.logger?.log(
      `[ServiceLocator]#getInstanceHolder() Returning existing instance holder for ${instanceName}`,
    );
    let holder = this.instancesHolders.get(instanceName);

    // check ttl
    if (holder && holder.ttl !== Infinity) {
      const now = Date.now();
      if (now - holder.createdAt > holder.ttl) {
        this.logger?.log(
          `[ServiceLocator]#getInstanceHolder() TTL expired for ${holder.name}`,
        );
        this.invalidate(holder.name);
        holder = undefined;
      }
    }
    return holder as
      | ServiceLocatorInstanceHolder<Services, Key, Args>
      | undefined;
  }

  private notifyListeners(
    name: ServicesInstancesNames<Services>,
    event: 'create' | 'destroy' = 'create',
  ) {
    setTimeout(() => {
      this.logger?.log(
        `[ServiceLocator]#notifyListeners() Notifying listeners for ${name} with event ${event}`,
      );
      // @ts-expect-error This is correct type
      this.eventBus.emit(name, event);
    }, 10);
  }

  private async createInstance<
    Key extends ServicesNames<Services>,
    Args extends ServiceArgs<Services, Key>,
    Instance extends ServiceInstance<Services, Key, Args>,
  >(
    instanceName: ServiceInstanceName<Services, Key>,
    name: Key,
    args: Args,
  ): Promise<Instance> {
    if (this.createInstancePromises.has(instanceName)) {
      this.logger?.log(
        `[ServiceLocator]#createInstance() Returning existing promise for ${instanceName} creation`,
      );
      return this.createInstancePromises.get(instanceName) as Promise<Instance>;
    }
    this.logger?.log(
      `[ServiceLocator]#createInstance() Creating instance for ${instanceName} from ${name}`,
    );
    if (this.factories.has(name)) {
      return this.createInstancePromises
        .set(
          instanceName,
          this.createInstanceFromFactory(instanceName, name, args).then(
            (instance) => {
              this.createInstancePromises.delete(instanceName);
              return instance;
            },
          ),
        )
        .get(instanceName) as Promise<Instance>;
    } else if (this.abstractFactories.has(name)) {
      return this.createInstancePromises
        .set(
          instanceName,
          this.createInstanceFromAbstractFactory(instanceName, name, args).then(
            (instance) => {
              this.createInstancePromises.delete(instanceName);
              return instance;
            },
          ),
        )
        .get(instanceName) as Promise<Instance>;
    } else {
      throw new Error(`No factory found for ${name as string}`);
    }
  }

  private async createInstanceFromFactory<
    Key extends ServicesNames<Services>,
    Args extends ServiceArgs<Services, Key>,
    Instance extends ServiceInstance<Services, Key, Args>,
  >(
    instanceName: ServiceInstanceName<Services, Key>,
    name: Key,
    args: Args,
  ): Promise<Instance> {
    this.logger?.log(
      `[ServiceLocator]#createInstanceFromFactory(): Creating instance for ${instanceName} from factory`,
    );
    const holder: ServiceLocatorInstanceHolder<Services, Key> = {
      name: instanceName,
      instance: await this.factories.get(name)?.(...args),
      kind: 'factory',
      effects: [],
      deps: [],
      destroyListeners: [],
      createdAt: Date.now(),
      ttl: Infinity,
    };
    this.instancesHolders.set(instanceName, holder);
    this.notifyListeners(name);
    this.notifyListeners(instanceName);

    return holder.instance as Instance;
  }

  private async createInstanceFromAbstractFactory<
    Key extends ServicesNames<Services>,
    Args extends ServiceArgs<Services, Key>,
    Instance extends ServiceInstance<Services, Key, Args>,
  >(
    instanceName: ServiceInstanceName<Services, Key>,
    name: Key,
    args: Args,
  ): Promise<Instance> {
    this.logger?.log(
      `[ServiceLocator]#createInstanceFromAbstractFactory(): Creating instance for ${instanceName} from abstract factory`,
    );
    const ctx = this.createContextForAbstractFactory(name, instanceName);
    const instance = await this.abstractFactories.get(name)?.(ctx, ...args);
    const destroyListeners = ctx.getDestroyListeners();
    const holder: ServiceLocatorInstanceHolder<Services, Key> = {
      name: instanceName,
      instance,
      kind: 'abstractFactory',
      effects: [],
      deps: ctx.getDependencies(),
      destroyListeners,
      createdAt: Date.now(),
      ttl: ctx.getTtl(),
    };
    this.instancesHolders.set(instanceName, holder);
    const dependencies = ctx.getDependencies();
    if (dependencies.length > 0) {
      this.logger?.log(
        `[ServiceLocator]#createInstanceFromAbstractFactory(): Adding subscriptions for ${instanceName} dependencies for their invalidations: ${dependencies.join(
          ', ',
        )}`,
      );
      dependencies.forEach((dependency) => {
        destroyListeners.push(
          // @ts-expect-error This is correct type
          this.eventBus.on(dependency, 'destroy', () =>
            this.invalidate(instanceName),
          ),
        );
      });
    }
    this.notifyListeners(name as unknown as ServicesInstancesNames<Services>);
    this.notifyListeners(instanceName);

    return holder.instance as Instance;
  }

  private createContextForAbstractFactory<Key extends ServicesNames<Services>>(
    holder: Key,
    instanceName: ServicesInstancesNames<Services>,
  ): ServiceLocatorAbstractFactoryContext<Services> {
    const dependencies = new Set<ServicesInstancesNames<Services>>();
    const destroyListeners = new Set<() => void>();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    async function getInstance<
      Instance extends ServiceInstance<Services, Key, Args>,
      Args extends ServiceArgs<Services, Key>,
      Key extends Exclude<keyof Services, symbol | number>,
    >(name: Key, ...args: Args): Promise<Instance> {
      dependencies.add(self.makeInstanceName(name, args));
      self.logger?.log(
        `[ServiceLocator]#createContextForAbstractFactory('${holder}'): Getting instance for ${name}`,
      );
      return self.getInstance<Key, Args, Instance>(name, ...args);
    }
    function addDependency(name: ServicesInstancesNames<Services>) {
      dependencies.add(name);
    }
    function invalidate(name = instanceName) {
      return self.invalidate(name);
    }
    function addEffect(listener: () => void) {
      destroyListeners.add(listener);
    }
    let ttl = Infinity;
    function setTtl(value: number) {
      ttl = value;
    }
    function getTtl() {
      return ttl;
    }

    function on<
      K extends ServicesInstancesNames<Services>,
      Service extends ServicesNames<Services> = ServiceFromInstanceName<
        Services,
        K
      >,
      E extends ServiceEventsNames<
        Services,
        Service,
        ServiceArgs<Services, Service>
      > = ServiceEventsNames<Services, Service, ServiceArgs<Services, Service>>,
      Args extends ServiceEventsArgs<
        Services,
        Service,
        ServiceArgs<Services, Service>,
        E
      > = ServiceEventsArgs<
        Services,
        Service,
        ServiceArgs<Services, Service>,
        E
      >,
    >(key: K, event: E, listener: (event: E, ...args: Args) => void) {
      // @ts-expect-error This is correct type
      destroyListeners.add(self.eventBus.on(key, event, listener));
    }

    return {
      getInstance,
      invalidate,
      eventBus: self.eventBus,
      on: on as ServiceLocatorEventBus<Services>['on'],
      addDependency,
      getDependencies: () => Array.from(dependencies),
      addEffect,
      getDestroyListeners: () => Array.from(destroyListeners),
      setTtl,
      getTtl,
    };
  }

  public getSyncInstance<
    Key extends ServicesNames<Services>,
    Args extends ServiceArgs<Services, Key>,
    Instance extends ServiceInstance<Services, Key, Args>,
  >(name: Key, ...args: Args): Instance | null {
    // @ts-expect-error This is correct type
    return this.getInstanceHolder<Key, Args>(name, ...args)?.instance || null;
  }

  invalidate(service: ServicesInstancesNames<Services>): Promise<any> {
    this.logger?.trace(
      `[ServiceLocator]#invalidate(): Invalidating ${service}`,
    );
    return Promise.all(
      [...this.instancesHolders.entries()].map(([key, value]) => {
        if (
          typeof key === 'string' &&
          (key.startsWith(`${service}:`) || key === service)
        ) {
          this.instancesHolders.delete(key);
          this.logger?.log(
            `[ServiceLocator]#invalidate(): Invalidating ${key} and notifying listeners`,
          );
          value.destroyListeners.forEach((listener) => listener());
          // @ts-expect-error This is correct type
          return this.eventBus.emit(service, 'destroy');
        }
      }),
    );
  }

  makeInstanceName(
    name: ServicesNames<Services>,
    args: any,
  ): ServicesInstancesNames<Services> {
    if (args.length === 0) {
      return name as unknown as ServicesInstancesNames<Services>;
    }
    return `${name as Exclude<keyof Services, symbol | number>}:${JSON.stringify(
      args,
    )
      .replace('[', '')
      .replace(']', '')
      .replace(/"/g, '')}` as ServicesInstancesNames<Services>;
  }
}
