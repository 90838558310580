import { useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

// @ts-ignore
import { yupResolver } from '@hookform/resolvers/yup';
import { useFlags } from '@mint-lib/flags';
import { useTranslation } from '@mint-lib/i18n';

import {
  Box,
  Button,
  Chip,
  Dialog,
  SelectWithSearch,
  Typography,
} from '@myn/mui';

import { useUsers } from '../../api/queries/usersData.js';
import {
  CreateRoleType,
  RolesMetaType,
  RoleType,
} from '../../api/roles/index.js';
import type { UserType } from '../../api/users/users.types.js';
import FormBlock from '../../atoms/FormBlock/FormBlock.jsx';
import InputField from '../../atoms/InputField/InputField.jsx';
import { getFlatErrorText } from '../../utils/getFlatErrorText.js';
import { mapUsersToSelect } from '../../utils/mapUsersToSelect.js';
import PermissionsTable from './PermissionsTable.jsx';
import type { TableDataType } from './permissionsTable.type.js';
import {
  mapInitialValues,
  mapToPermissions,
  mapToTableState,
} from './RoleModal.utils.js';
import { validationSchema } from './validation.js';

export const filterAgencyUsers = (
  list: UserType[],
  ff: boolean,
  roleType?: string,
): UserType[] => {
  return ff
    ? roleType === 'agency_client_role_type'
      ? list.filter((user) => user.agencyUser)
      : list
    : list;
};

type Props = {
  className?: string;
  onClose: () => void;
  onCreate: (options: CreateRoleType) => Promise<RoleType>;
  onUpdate: (options: Partial<RoleType>) => Promise<RoleType>;
  initialValues?: Partial<RoleType>;
  rolesMeta: RolesMetaType;
  isActive: boolean;
  isReadonlyPermission: boolean;
};

const RoleModal: React.FC<Props> = ({
  onClose,
  onCreate,
  onUpdate,
  initialValues = {},
  rolesMeta,
  isActive,
  isReadonlyPermission,
}) => {
  const { t } = useTranslation('@myn/permissions');
  const ff = useFlags(['agencyRoleOnlyForAgencyUsers', 'approvalFlowFeature']);
  const [serverError, setServerError] = useState<string | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [isUsersExpanded, setIsUsersExpanded] = useState(false);

  const { usersData } = useUsers({ getFullList: true });

  const userOptions = useMemo(
    () =>
      mapUsersToSelect(
        filterAgencyUsers(
          usersData || [],
          ff.agencyRoleOnlyForAgencyUsers.enabled,
          initialValues.roleType,
        ),
      ),
    [usersData],
  );

  const chipsToDisplay = useMemo(() => {
    const selectedUsersToMap = isUsersExpanded
      ? selectedUsers
      : selectedUsers.slice(0, 2);

    return selectedUsersToMap.flatMap((user) => {
      return userOptions.find((option) => option.value === user) || [];
    });
  }, [isUsersExpanded, selectedUsers, userOptions]);

  const [permissions, setPermissions] = useState<TableDataType>(
    mapToTableState(
      rolesMeta.nameLabels,
      initialValues?.outputFeaturePermissions === undefined
        ? {}
        : mapInitialValues(initialValues.outputFeaturePermissions),
      {
        // @ts-ignore
        main: rolesMeta?.features
          ? Object.keys(rolesMeta?.features)?.map(
              (key) => rolesMeta?.features[key],
            )
          : [],
      },
      ff,
    ),
  );

  const hasInitialValues = Object.keys(initialValues ?? {}).length > 0;
  const restValues = hasInitialValues
    ? initialValues
    : ({} as Partial<RoleType>);

  const handleSubmitForm: SubmitHandler<Partial<RoleType>> = (values) => {
    setServerError(null);

    const { name = '', description = '' } = values;

    const submitPromise =
      restValues?.isDefault && !restValues?.isEditable
        ? onUpdate({
            id: restValues?.id,
            users: selectedUsers,
            description,
          })
        : restValues?.isDefault && restValues?.isEditable
          ? onUpdate({
              id: restValues?.id,
              name,
              users: selectedUsers,
              description,
              inputFeaturePermissions: mapToPermissions(permissions),
            })
          : onCreate({
              ...restValues,
              name,
              users: selectedUsers,
              description,
              inputFeaturePermissions: mapToPermissions(permissions),
            });

    submitPromise.catch((e) => {
      const flatError = getFlatErrorText(e?.response?.data);
      setServerError(flatError);
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, ...restFormState },
    setValue,
    trigger,
    getValues,
    control,
    ...methods
  } = useForm({
    defaultValues: initialValues,
    mode: 'onChange',
    resolver: yupResolver(validationSchema(t)),
  });

  const { name = '' } = getValues();

  const isAllRequiredFieldsFilled = !!name;

  useEffect(() => {
    if (!hasInitialValues) {
      return;
    }

    const initialSelectedUses = initialValues.usersDetails
      ? initialValues.usersDetails.flatMap((user) => Number(user.id) || [])
      : [];

    setSelectedUsers(initialSelectedUses);
  }, [usersData]);

  return (
    <FormProvider
      {...{
        ...methods,
        register,
        handleSubmit,
        formState: { errors, ...restFormState },
        setValue,
        trigger,
        getValues,
        control,
      }}
    >
      <Dialog
        open={isActive}
        onClose={onClose}
        title={hasInitialValues ? t('Edit role') : t('Add new role')}
        size="m"
        submitButtonProps={{
          label: t('Save'),
          onClick: handleSubmit(handleSubmitForm),
          color: 'primary',
          disabled: !isAllRequiredFieldsFilled || isReadonlyPermission,
        }}
        cancelButtonProps={{
          label: t('Cancel'),
          onClick: onClose,
          variant: 'text',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: (theme) => theme.spacing(2),
          }}
        >
          <FormBlock>
            <Box sx={{ flexGrow: '1' }}>
              <InputField
                label={t('Role name*')}
                placeholder={t('Enter a name')}
                disabled={
                  restValues?.isEditable === false || isReadonlyPermission
                }
                name="name"
              />
            </Box>
            <Box
              sx={{
                width: '326px',
                '& span': {
                  width: '17rem',
                },
              }}
            >
              <SelectWithSearch
                multiple
                label={t('Search and add user')}
                options={userOptions}
                value={selectedUsers}
                placeholder={t('Select from the list')}
                disabled={isReadonlyPermission}
                onChange={(e) => {
                  setSelectedUsers(e.filter((userId) => userId));
                }}
                truncatedOptions
              />
            </Box>
          </FormBlock>
          {!!chipsToDisplay.length && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  overflow: 'hidden',
                  gap: (theme) => theme.spacing(1),
                  padding: '7px 0 0 0',
                }}
              >
                {chipsToDisplay.map((user) => {
                  return <Chip label={user?.label} />;
                })}
                {!isUsersExpanded && selectedUsers.length > 2 && (
                  <Chip size="medium" label={`+${selectedUsers.length - 2}`} />
                )}
              </Box>
              {selectedUsers.length > 2 && (
                <Box>
                  <Button
                    variant="text"
                    endIcon={isUsersExpanded ? 'ChevronUp' : 'ChevronDown'}
                    onClick={() => setIsUsersExpanded((prev) => !prev)}
                  >
                    {isUsersExpanded ? t('Hide Users') : t('See All Users')}
                  </Button>
                </Box>
              )}
            </Box>
          )}

          <FormBlock>
            <Typography variant="h5">{t('Assign permissions')}</Typography>
          </FormBlock>
          <FormBlock>
            <InputField
              label={t('Role description')}
              placeholder={t('Enter description')}
              disabled={
                restValues?.isEditable === false || isReadonlyPermission
              }
              name="description"
              fullWidth
            />
          </FormBlock>
          <FormBlock>
            <PermissionsTable
              data={permissions}
              setData={setPermissions}
              nameLabels={rolesMeta.nameLabels}
              isDisabled={
                (restValues?.isDefault && !restValues?.isEditable) ||
                isReadonlyPermission
              }
            />
          </FormBlock>
        </Box>
        {!!serverError && (
          <Typography color={'error.main'} align="center">
            {serverError}
          </Typography>
        )}
      </Dialog>
    </FormProvider>
  );
};

export default RoleModal;
