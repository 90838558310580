import { formatLocale, FormatLocaleDefinition } from 'd3-format';

export const REGEX_NUMBERS = /(\d*\.\d?\d?\d?)|\d+/;
export const DEFAULT_LOCALE = 'it';

export const formattedPrefixes = {
  k: 'K',
  M: 'M',
  G: 'B',
  T: 'T',
};
export type FormattedPrefixesType = typeof formattedPrefixes;

export const IT_SETTINGS: FormatLocaleDefinition = {
  decimal: ',',
  thousands: '.',
  grouping: [3],
  currency: ['', ''],
};

export const US_SETTINGS: FormatLocaleDefinition = {
  decimal: '.',
  thousands: ',',
  grouping: [3],
  currency: ['', ''],
};

export const localeSeparatorsSettings = {
  it: IT_SETTINGS,
  en: US_SETTINGS,
};

export const separatorsTypes = {
  EUROPEAN: formatLocale(IT_SETTINGS),
  ENGLISH: formatLocale(US_SETTINGS),
};
export type SeparatorsType = typeof separatorsTypes;

export const localeSeparatorsTypes = {
  it: separatorsTypes.EUROPEAN,
  en: separatorsTypes.ENGLISH,
};
export type LocaleSeparatorsType = typeof localeSeparatorsTypes;

export const formatTypes = {
  COMPACT_SHORT: ',.4~s',
  COMPACT_1_AFTER_COMA: ',.2~s',
  COMPACT_SHORT_AXIS: ',.2~r',
  NUMERIC: ',.2f',
  NUMERIC_INT: ',',
  INTEGER: ',.1r',
  // New types
  DECIMAL_SHORT: ',.2f',
  DECIMAL_3: ',.3f',

  INTEGER_SHORT: ',.0f',
};

const formatToNumber = (value: number): string => {
  const results = String(value).match(REGEX_NUMBERS);
  return results?.[0] || '';
};

export const valueFormatter = (value: number): string => formatToNumber(value);
