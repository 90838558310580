import { useMemo } from 'react';

import { useTranslation } from '@mint-lib/i18n';
import { useProfile } from '@mint-lib/profile-helpers';
import { format } from 'date-fns';
import { enGB, enUS, it, ptBR, uk } from 'date-fns/locale';

const localeMap = {
  'en-US': enUS,
  'en-GB': enGB,
  'it-IT': it,
  'uk-UA': uk,
  'pt-BR': ptBR,
};

type Params = {
  options?: {
    format?: 'P' | 'PP' | 'PPP' | 'PPPP';
  };
};

/**
 * Returns a date formatter function that formats a given date according to the specified format and locale.
 *
 * @param options - An optional object containing the following options:
 *   - format: A string representing the format of the date. Can be one of the following:
 *     - 'P': Long localized date (e.g. 05/29/1453)
 *     - 'PP': Long localized date with month name (e.g. May 29, 1453)
 *     - 'PPP': Long localized date with ordinal (e.g. May 29th, 1453)
 *     - 'PPPP': Long localized date with weekday, month name, and ordinal (e.g. Sunday, May 29th, 1453)
 *
 * @returns A date formatter function that takes a date as input and returns a formatted string.
 */
export const useDateFormatter = (params?: Params) => {
  const options = params?.options ?? {};

  const { i18n } = useTranslation();

  const { getProfileField } = useProfile();

  const locale = (
    getProfileField('formattingConfig')?.datesFormat
      ? getProfileField('formattingConfig')?.datesFormat
      : i18n.language
  ) as keyof typeof localeMap;

  // only one reason to overengineering the return type is the will to follow the same interface as for the rest of the formatter
  const formatter = useMemo(
    () => ({
      format: (date: number | string | Date) =>
        format(new Date(date), options?.format ?? 'P', {
          locale: localeMap[locale],
        }),
    }),
    [options?.format, locale],
  );

  return formatter;
};

/**
 * A component that displays a static date string formatted according to the provided options.
 * Uses the `useDateFormatter` hook to format the date string.
 *
 * @param date - The date to format. Can be a number, string, or Date object.
 * @param options - An optional object containing formatting options.
 * @param options.format - The format to use for the date. Can be one of the following:
 *   - `P`: 05/29/1453
 *   - `PP`: May 29, 1453
 *   - `PPP`: May 29th, 1453
 *   - `PPPP`: Sunday, May 29th, 1453
 * @returns A JSX element containing the formatted date string.
 */
const StaticDateSpan = ({
  date,
  options,
}: {
  date: number | string | Date;
  options?: {
    /**
     * The format to use for the date.
     *
     * - `P`: 05/29/1453
     * - `PP`: May 29, 1453
     * - `PPP`: May 29th, 1453
     * - `PPPP`: Sunday, May 29th, 1453
     */
    format?: 'P' | 'PP' | 'PPP' | 'PPPP';
  };
}): JSX.Element => {
  const formatter = useDateFormatter({ options });

  const res = formatter.format(date);

  return <span>{res}</span>;
};

export default StaticDateSpan;
