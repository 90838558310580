import {
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
  type ReactNode,
} from 'react';

import { Slots } from '../contexts/Slots.jsx';

export interface SlotProps {
  name: string;
  onRender?: (slotValue: ReactNode) => void;
}

/**
 * We register a slot with a name that will be used to render the children of the SlotPortal component.
 */
export default memo(function Slot({ name, onRender }: SlotProps) {
  const manager = useContext(Slots);
  const [child, setSlotValue] = useState<ReactNode>(null);
  if (import.meta.env.DEV) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const prevName = useRef(name);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const prevCallback = useRef(onRender);
    if (prevName.current !== name) {
      throw new Error('Slot name cannot change.');
    }
    if (prevCallback.current !== onRender) {
      throw new Error(
        'Slot onRender callback cannot change, please use useEvent or useCallback before sending the data.',
      );
    }
  }
  useEffect(() => {
    return manager.subscribe(name, (_, slotValue) => {
      onRender?.(slotValue);
      setSlotValue(slotValue);
    });
  }, [name, manager]);
  return child;
});
