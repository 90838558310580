import { useEffect, useMemo, useRef, useState } from 'react';

import { Flagsmith, IFlagsmithFeature, IFlagsmithTrait } from '@mint-lib/flags';
import { FlagsmithProviders } from '@mint-lib/flags-envs';
import { useEvent } from '@mint-lib/hooks';

import { useInstance } from './useInstance.js';

export function useFlags<F extends string = string, T extends string = string>(
  env: keyof typeof FlagsmithProviders,
  _flags: readonly F[],
  _traits: readonly T[] = [],
): {
  [K in F]: IFlagsmithFeature;
} & {
  [K in T]: IFlagsmithTrait;
} {
  const flags = useConstant<string[]>(flagsAsArray(_flags));
  const traits = useConstant<string[]>(flagsAsArray(_traits));
  const [flagsmith, version] = useInstance('flagsmith', env);
  const [renderKey, setRenderKey] = useState<string>(
    getRenderKey(flagsmith, flags, traits, env),
  );
  const renderRef = useRef<string>(renderKey);
  const eventListener = useEvent(() => {
    flagsmith?.log('React - Event listener triggered');
    const newRenderKey = getRenderKey(flagsmith, flags, traits, env);
    if (newRenderKey !== renderRef.current) {
      renderRef.current = newRenderKey;
      setRenderKey(newRenderKey);
    }
  });
  if (import.meta.env.DEV) {
    // @ts-ignore TS2339 we will use this in devtools
    eventListener.flags = flags;
    // @ts-ignore TS2339 we will use this in devtools
    eventListener.traits = traits;
    // @ts-ignore TS2339 we will use this in devtools
    eventListener.env = env;
  }
  useEffect(() => {
    eventListener();
    return flagsmith?.subscribe(eventListener);
  }, [version]);
  return useMemo(() => {
    flagsmith?.log('React - Render key has changed');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res: any = {};
    flags.forEach((k) => {
      res[k] = {
        enabled: flagsmith?.hasFeature(env, k),
        value: flagsmith?.getValue(env, k),
      };
    });
    traits?.forEach((v) => {
      res[v] = flagsmith?.getTrait(env, v);
    });
    return res;
  }, [renderKey]);
}

const getRenderKey = (
  flagsmith: Flagsmith | null,
  flags: string[],
  traits: string[] = [],
  env = 'default',
) => {
  return flags
    .map((k) => {
      return `${flagsmith?.getValue(env, k)}${flagsmith?.hasFeature(env, k)}`;
    })
    .concat(traits.map((t) => `${flagsmith?.getTrait(env, t)}`))
    .join(',');
};

const flagsAsArray = (_flags: string | readonly string[]): string[] => {
  if (typeof _flags === 'string') {
    return [_flags];
  } else if (typeof _flags === 'object') {
    // eslint-disable-next-line no-prototype-builtins
    if (_flags.hasOwnProperty('length')) {
      return _flags as string[];
    }
  }
  throw new Error(
    'Flagsmith: please supply an array of strings or a single string of flag keys to useFlags',
  );
};

const useConstant = function <T>(value: T): T {
  const ref = useRef(value);
  if (!ref.current) {
    ref.current = value;
  }
  return ref.current;
};
