/* eslint-disable no-underscore-dangle */
import { useEffect, useRef } from 'react';

import { vendorsCookiesHelper, type CustomWindow } from '@mint-lib/axeptIo-sdk';
import { useTranslation } from '@mint-lib/i18n';
import { useFlags } from '@mint-lib/routing-context';

import {
  DEFAULT_WIDGET_VERSION,
  IS_LOGIN_ROUTE_KEY,
  PROJECT_ID,
  SDK_SRC,
} from '../constants.js';

// https://developers.axeptio.eu/cookies/axeptio-javascript-sdk#using-a-callback-function-to-get-a-reference-to-the-sdk
(window as never as CustomWindow)._axcb =
  (window as never as CustomWindow)._axcb || [];

export const useAxeptIo = () => {
  const ff = useFlags('@mint/app', ['axeptIoCookiesDurationSixMonths']);

  const isLoginRoute = localStorage.getItem(IS_LOGIN_ROUTE_KEY) === 'true';

  const inserted = useRef(false);

  const { i18n } = useTranslation();

  // use first two characters from locale to prevent wrong version use
  // see admin panel for all widgets
  // en - en
  // en-US - en
  // en-GB - en
  // it-It - it
  const currentLocale = i18n.language.slice(0, 2) || 'en';

  const getCookiesVersion = () => {
    // show specific version for login pages
    if (isLoginRoute) {
      return DEFAULT_WIDGET_VERSION + `_login`;
    }

    if (currentLocale && currentLocale !== 'en' && currentLocale !== 'fake') {
      return DEFAULT_WIDGET_VERSION + `_${currentLocale}`;
    }

    return DEFAULT_WIDGET_VERSION;
  };

  useEffect(() => {
    if (inserted.current) {
      return;
    }

    vendorsCookiesHelper();

    inserted.current = true;

    (window as never as CustomWindow).axeptioSettings = {
      clientId: PROJECT_ID,
      cookiesVersion: getCookiesVersion(),
      // 6 months
      ...(ff.axeptIoCookiesDurationSixMonths.enabled
        ? { userCookiesDuration: 182 }
        : {}),
    };

    (function (d) {
      const t = d.getElementsByTagName('script')[0];
      const el = d.createElement('script');

      el.setAttribute('async', 'true');
      el.setAttribute('src', SDK_SRC);

      if (t.parentNode) {
        t.parentNode.insertBefore(el, t);
      }
    })(document);
  }, [inserted.current]);

  useEffect(() => {
    if ((window as never as CustomWindow)?.axeptioSDK) {
      (window as never as CustomWindow)?._axcb?.push(() => {
        (window as never as CustomWindow)?.axeptioSDK?.setCookiesVersion(
          getCookiesVersion(),
        );
      });
    }
  }, [currentLocale, isLoginRoute]);

  return null;
};
