import React from 'react';

import { useTheme } from '@mint-lib/styled';
import { CheckboxProps as CheckboxPropsM } from '@mui/material/Checkbox';
import { ListItemButtonProps as ListItemButtonPropsM } from '@mui/material/ListItemButton';
import { RadioProps as RadioPropsM } from '@mui/material/Radio';

import Box from '../Box/Box.jsx';
import Checkbox from '../Checkbox/Checkbox.jsx';
import Radio from '../Radio/Radio.jsx';
import Truncation from '../Truncation/Truncation.jsx';
import Typography, { TypographyProps } from '../Typography/Typography.jsx';
import {
  Container,
  StyledListItemButton,
} from './SelectionListItem.styles.jsx';

export interface SelectionListItemProps extends ListItemButtonPropsM {
  /**
   * Text inside menu item
   */
  text: string;
  /**
   * level defines spacing
   */
  level?: 0 | 1 | 2 | 3 | 4;
  /**
   * Variant of the text layout
   * @default multiline
   */
  textLayout?: 'inline' | 'multiline';
  /**
   * cb for click on menu item
   */
  onItemClick?: (...args: any) => void;
  /**
   * if true  - highlights the text with green
   */
  IsHighlighted?: boolean;
  variant?: 'checkbox' | 'radio' | 'default';
  CheckboxProps?: CheckboxPropsM;
  RadioProps?: RadioPropsM;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  readOnly?: boolean;
}

const SelectionListItem: React.FC<SelectionListItemProps> = ({
  text,
  onItemClick,
  CheckboxProps,
  RadioProps,
  level,
  variant = 'default',
  endAdornment,
  textLayout = 'inline',
  startAdornment,
  IsHighlighted = false,
  ...props
}) => {
  const theme = useTheme();

  const inputVariants = {
    radio: () => (
      <Radio
        {...RadioProps}
        disabled={props.disabled}
        checked={props.selected}
      />
    ),
    checkbox: () => (
      <Checkbox
        {...CheckboxProps}
        disabled={props.disabled}
        checked={props.selected}
      />
    ),
    default: () => null,
  };

  const textCommonProps: TypographyProps = {
    sx: {
      backgroundColor: IsHighlighted
        ? theme.palette.background.primary
        : theme.palette.transparent.main,
      width: 'fit-content',
    },
    variant: props.selected ? 'bodyShort02' : 'bodyLong01',
  };

  return (
    <StyledListItemButton
      onClick={(e) => onItemClick && onItemClick(e)}
      $textLayout={textLayout}
      {...props}
    >
      {level || variant !== 'default' ? (
        <Container $level={level}>{inputVariants[variant]()}</Container>
      ) : null}
      {startAdornment}
      {textLayout === 'inline' ? (
        <Truncation tooltipTitle={text} {...textCommonProps}>
          {text}
        </Truncation>
      ) : (
        <Typography {...textCommonProps}>{text}</Typography>
      )}
      <Box sx={{ marginLeft: 'auto', display: 'inline-flex' }}>
        {endAdornment}
      </Box>
    </StyledListItemButton>
  );
};

export default SelectionListItem;
