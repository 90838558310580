import { Palette } from '@mui/material/styles';

import typography from './typography.js';

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    transparent: true;
    tertiary: true;
    yellow: true;
    teal: true;
    pink: true;
    purple: true;
    silver: true;

    ice: true;
    rose: true;
    peach: true;
    violet: true;
    fuchsia: true;
    green: true;
    royal: true;
    orange: true;
    emerald: true;
    lime: true;
    mint: true;
    blue: true;
    azure: true;
    jungle: true;
    skin: true;
    kingdom: true;
    blaze: true;
    desert: true;
    amber: true;
  }
}

const chipOverrides = (palette: Palette) => {
  return {
    variants: [
      {
        props: { color: 'silver' as const, variant: 'filled' as const },
        style: {
          '&:hover': {
            backgroundColor: palette.silver.main,
          },
          '& .MuiChip-label': {
            color: palette.silver.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.silver.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.silver.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.silver.contrastText,
              opacity: '1',
            },
          },
        },
      },
      {
        props: { color: 'transparent' as const, variant: 'filled' as const },
        style: {
          '&:hover': {
            backgroundColor: palette.transparent.main,
          },
          '& .MuiChip-label': {
            color: palette.text.primary,
          },
          '& .MuiChip-icon': {
            color: palette.text.primary,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.text.primary,
            opacity: '0.7',
            '&:hover': {
              color: palette.text.primary,
              opacity: '1',
            },
          },
        },
      },
      {
        props: { color: 'teal' as const, variant: 'filled' as const },

        style: {
          backgroundColor: palette.background.teal,
          '&:hover': {
            backgroundColor: palette.background.teal,
          },
        },
      },
      {
        props: { color: 'purple' as const, variant: 'filled' as const },

        style: {
          backgroundColor: palette.background.purple,
          '&:hover': {
            backgroundColor: palette.background.purple,
          },
        },
      },
      {
        props: { color: 'pink' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.background.pink,
          '&:hover': {
            backgroundColor: palette.background.pink,
          },
        },
      },
      {
        props: { color: 'yellow' as const, variant: 'filled' as const },

        style: {
          backgroundColor: palette.background.yellow,
          '&:hover': {
            backgroundColor: palette.background.yellow,
          },
        },
      },
      {
        props: { color: 'error' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.error.light,
          '&:hover': {
            backgroundColor: palette.error.light,
          },
        },
      },
      {
        props: { color: 'ice' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.background.ice,
          '&:hover': {
            backgroundColor: palette.background.ice,
          },
        },
      },

      {
        props: { color: 'rose' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.background.rose,
          '&:hover': {
            backgroundColor: palette.background.rose,
          },
        },
      },
      {
        props: { color: 'peach' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.background.peach,
          '&:hover': {
            backgroundColor: palette.background.peach,
          },
        },
      },
      {
        props: { color: 'violet' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.background.violet,
          '&:hover': {
            backgroundColor: palette.background.violet,
          },
        },
      },

      {
        props: { color: 'fuchsia' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.background.fuchsia,
          '&:hover': {
            backgroundColor: palette.background.fuchsia,
          },
        },
      },
      {
        props: { color: 'green' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.background.green,
          '&:hover': {
            backgroundColor: palette.background.green,
          },
        },
      },
      {
        props: { color: 'royal' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.background.royal,
          '&:hover': {
            backgroundColor: palette.background.royal,
          },
        },
      },

      {
        props: { color: 'orange' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.background.orange,
          '&:hover': {
            backgroundColor: palette.background.orange,
          },
        },
      },
      {
        props: { color: 'emerald' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.background.emerald,
          '&:hover': {
            backgroundColor: palette.background.emerald,
          },
        },
      },
      {
        props: { color: 'lime' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.background.lime,
          '&:hover': {
            backgroundColor: palette.background.lime,
          },
        },
      },

      {
        props: { color: 'mint' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.background.mint,
          '&:hover': {
            backgroundColor: palette.background.mint,
          },
        },
      },
      {
        props: { color: 'blue' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.background.blue,
          '&:hover': {
            backgroundColor: palette.background.blue,
          },
        },
      },
      {
        props: { color: 'azure' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.background.azure,
          '&:hover': {
            backgroundColor: palette.background.azure,
          },
        },
      },
      {
        props: { color: 'jungle' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.background.jungle,
          '&:hover': {
            backgroundColor: palette.background.jungle,
          },
        },
      },
      {
        props: { color: 'skin' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.background.skin,
          '&:hover': {
            backgroundColor: palette.background.skin,
          },
        },
      },
      {
        props: { color: 'kingdom' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.background.kingdom,
          '&:hover': {
            backgroundColor: palette.background.kingdom,
          },
        },
      },
      {
        props: { color: 'blaze' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.background.blaze,
          '&:hover': {
            backgroundColor: palette.background.blaze,
          },
        },
      },
      {
        props: { color: 'desert' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.background.desert,
          '&:hover': {
            backgroundColor: palette.background.desert,
          },
        },
      },
      {
        props: { color: 'amber' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.background.amber,
          '&:hover': {
            backgroundColor: palette.background.amber,
          },
        },
      },
      {
        props: { color: 'primary' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.primary.light,
          '&:hover': {
            backgroundColor: palette.primary.main,
          },
        },
      },
      {
        props: { color: 'secondary' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.secondary.main,
          '&:hover': {
            backgroundColor: palette.secondary.dark,
          },
        },
      },
      {
        props: { color: 'tertiary' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.tertiary.light,
          '&:hover': {
            backgroundColor: palette.ui.highlight,
          },

          '& .MuiChip-label': {
            color: palette.tertiary.dark,
          },
          '& .MuiChip-icon': {
            color: palette.tertiary.dark,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.tertiary.dark,
            opacity: '0.7',
            '&:hover': {
              color: palette.tertiary.dark,
              opacity: '1',
            },
          },
        },
      },
      {
        props: { color: 'success' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.success.light,
          '&:hover': {
            backgroundColor: palette.success.light,
          },
        },
      },
      {
        props: { color: 'warning' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.warning.light,
          '&:hover': {
            backgroundColor: palette.warning.light,
          },
        },
      },
      {
        props: { color: 'info' as const, variant: 'filled' as const },
        style: {
          backgroundColor: palette.info.light,
          '&:hover': {
            backgroundColor: palette.info.light,
          },
        },
      },

      {
        props: { color: 'pink' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.pink.main}`,
          '& .MuiChip-label': {
            color: palette.pink.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.pink.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.pink.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.pink.contrastText,
              opacity: '1',
            },
          },
        },
      },

      {
        props: { color: 'yellow' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.yellow.main}`,
          '& .MuiChip-label': {
            color: palette.yellow.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.yellow.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.yellow.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.yellow.contrastText,
              opacity: '1',
            },
          },
        },
      },

      {
        props: { color: 'teal' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.teal.main}`,
          '& .MuiChip-label': {
            color: palette.teal.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.teal.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.teal.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.teal.contrastText,
              opacity: '1',
            },
          },
        },
      },

      {
        props: { color: 'rose' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.rose.main}`,
          '& .MuiChip-label': {
            color: palette.rose.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.rose.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.rose.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.rose.contrastText,
              opacity: '1',
            },
          },
        },
      },

      {
        props: { color: 'purple' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.purple.main}`,
          '& .MuiChip-label': {
            color: palette.purple.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.purple.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.purple.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.purple.contrastText,
              opacity: '1',
            },
          },
        },
      },

      {
        props: { color: 'ice' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.ice.main}`,
          '& .MuiChip-label': {
            color: palette.ice.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.ice.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.ice.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.ice.contrastText,
              opacity: '1',
            },
          },
        },
      },

      {
        props: { color: 'peach' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.peach.main}`,
          '& .MuiChip-label': {
            color: palette.peach.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.peach.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.peach.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.peach.contrastText,
              opacity: '1',
            },
          },
        },
      },

      {
        props: { color: 'violet' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.violet.main}`,
          '& .MuiChip-label': {
            color: palette.violet.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.violet.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.violet.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.violet.contrastText,
              opacity: '1',
            },
          },
        },
      },

      {
        props: { color: 'fuchsia' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.fuchsia.main}`,
          '& .MuiChip-label': {
            color: palette.fuchsia.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.fuchsia.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.fuchsia.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.fuchsia.contrastText,
              opacity: '1',
            },
          },
        },
      },

      {
        props: { color: 'green' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.green.main}`,
          '& .MuiChip-label': {
            color: palette.green.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.green.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.green.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.green.contrastText,
              opacity: '1',
            },
          },
        },
      },

      {
        props: { color: 'orange' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.orange.main}`,
          '& .MuiChip-label': {
            color: palette.orange.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.orange.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.orange.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.orange.contrastText,
              opacity: '1',
            },
          },
        },
      },

      {
        props: { color: 'royal' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.royal.main}`,
          '& .MuiChip-label': {
            color: palette.royal.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.royal.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.royal.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.royal.contrastText,
              opacity: '1',
            },
          },
        },
      },

      {
        props: { color: 'emerald' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.emerald.main}`,
          '& .MuiChip-label': {
            color: palette.emerald.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.emerald.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.emerald.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.emerald.contrastText,
              opacity: '1',
            },
          },
        },
      },

      {
        props: { color: 'lime' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.lime.main}`,
          '& .MuiChip-label': {
            color: palette.lime.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.lime.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.lime.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.lime.contrastText,
              opacity: '1',
            },
          },
        },
      },
      {
        props: { color: 'blue' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.blue.main}`,
          '& .MuiChip-label': {
            color: palette.blue.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.blue.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.blue.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.blue.contrastText,
              opacity: '1',
            },
          },
        },
      },

      {
        props: { color: 'mint' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.mint.main}`,
          '& .MuiChip-label': {
            color: palette.mint.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.mint.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.mint.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.mint.contrastText,
              opacity: '1',
            },
          },
        },
      },

      {
        props: { color: 'azure' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.azure.main}`,
          '& .MuiChip-label': {
            color: palette.azure.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.azure.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.azure.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.azure.contrastText,
              opacity: '1',
            },
          },
        },
      },

      {
        props: { color: 'jungle' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.jungle.main}`,
          '& .MuiChip-label': {
            color: palette.jungle.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.jungle.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.jungle.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.jungle.contrastText,
              opacity: '1',
            },
          },
        },
      },

      {
        props: { color: 'skin' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.skin.main}`,
          '& .MuiChip-label': {
            color: palette.skin.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.skin.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.skin.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.skin.contrastText,
              opacity: '1',
            },
          },
        },
      },

      {
        props: { color: 'kingdom' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.kingdom.main}`,
          '& .MuiChip-label': {
            color: palette.kingdom.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.kingdom.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.kingdom.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.kingdom.contrastText,
              opacity: '1',
            },
          },
        },
      },

      {
        props: { color: 'blaze' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.blaze.main}`,
          '& .MuiChip-label': {
            color: palette.blaze.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.blaze.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.blaze.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.blaze.contrastText,
              opacity: '1',
            },
          },
        },
      },
      {
        props: { color: 'amber' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.amber.main}`,
          '& .MuiChip-label': {
            color: palette.amber.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.amber.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.amber.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.amber.contrastText,
              opacity: '1',
            },
          },
        },
      },
      {
        props: { color: 'desert' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.desert.main}`,
          '& .MuiChip-label': {
            color: palette.desert.contrastText,
          },
          '& .MuiChip-icon': {
            color: palette.desert.contrastText,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.desert.contrastText,
            opacity: '0.7',
            '&:hover': {
              color: palette.desert.contrastText,
              opacity: '1',
            },
          },
        },
      },
      {
        props: { color: 'transparent' as const, variant: 'outlined' as const },
        style: {
          border: 'none !important',
          '& .MuiChip-label': {
            color: palette.text.primary,
          },
          '& .MuiChip-icon': {
            color: palette.text.primary,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.text.primary,
            opacity: '0.7',
            '&:hover': {
              color: palette.text.primary,
              opacity: '1',
            },
          },
        },
      },
      {
        props: { color: 'primary' as const, variant: 'outlined' as const },
        style: {
          '&:hover': {
            backgroundColor: `${palette.background.primary} !important`,
          },
          border: `1px solid ${palette.primary.light}`,
          '& .MuiChip-label': {
            color: palette.primary.dark,
          },
          '& .MuiChip-icon': {
            color: palette.primary.dark,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.primary.dark,
            opacity: '0.7',
            '&:hover': {
              color: palette.primary.dark,
              opacity: '1',
            },
          },
        },
      },
      {
        props: { color: 'secondary' as const, variant: 'outlined' as const },
        style: {
          '&:hover': {
            backgroundColor: `${palette.ui.hover} !important`,
          },
          border: `1px solid ${palette.secondary.light}`,
        },
      },
      {
        props: { color: 'tertiary' as const, variant: 'outlined' as const },
        style: {
          '&:hover': {
            backgroundColor: `${palette.background.tertiary} !important`,
          },
          border: `1px solid ${palette.tertiary.light}`,
          '& .MuiChip-label': {
            color: palette.tertiary.main,
          },
          '& .MuiChip-icon': {
            color: palette.tertiary.main,
          },
          '& .MuiChip-deleteIcon': {
            color: palette.tertiary.main,
            opacity: '0.7',
            '&:hover': {
              color: palette.tertiary.main,
              opacity: '1',
            },
          },
        },
      },
      {
        props: { color: 'error' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.error.light}`,
          color: palette.error.dark,
          '& .MuiChip-deleteIcon': {
            color: palette.error.dark,
            opacity: '0.7',
            '&:hover': {
              color: palette.error.dark,
              opacity: '1',
            },
          },
        },
      },
      {
        props: { color: 'success' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.success.light}`,
          color: palette.success.dark,
          '& .MuiChip-deleteIcon': {
            color: palette.success.dark,
            opacity: '0.7',
            '&:hover': {
              color: palette.success.dark,
              opacity: '1',
            },
          },
        },
      },
      {
        props: { color: 'warning' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.warning.light}`,
          color: palette.warning.dark,
          '& .MuiChip-deleteIcon': {
            color: palette.warning.dark,
            opacity: '0.7',
            '&:hover': {
              color: palette.warning.dark,
              opacity: '1',
            },
          },
        },
      },
      {
        props: { color: 'info' as const, variant: 'outlined' as const },
        style: {
          border: `1px solid ${palette.info.light}`,
          color: palette.info.dark,
          '& .MuiChip-deleteIcon': {
            color: palette.info.dark,
            opacity: '0.7',
            '&:hover': {
              color: palette.info.dark,
              opacity: '1',
            },
          },
        },
      },
      {
        props: { color: 'silver' as const, variant: 'outlined' as const },
        style: {
          color: palette.text.primary,
          border: `1px solid ${palette.silver.main}`,
          '& .MuiChip-deleteIcon': {
            color: palette.text.primary,
            opacity: '0.7',
            '&:hover': {
              color: palette.text.primary,
              opacity: '1',
            },
          },
        },
      },
    ],

    styleOverrides: {
      root: {
        ...typography.caption01,
        transition: 'none',
        paddingLeft: 8,
        paddingRight: 8,
        borderRadius: 12,
        '&:active': {
          boxShadow: 'none',
        },
      },
      outlined: {
        backgroundColor: `${palette.transparent.main} !important`,
        '&:hover': {
          backgroundColor: `${palette.transparent.main} !important`,
        },
      },
      label: {
        paddingRight: 0,
        paddingLeft: 0,
      },
      icon: {
        marginLeft: 0,
        marginRight: 0,
      },
      avatar: {
        margin: '0px',
      },
      deleteIcon: {
        margin: '0px 0px 0px -2px',
      },
      sizeSmall: {
        height: '1.25rem',
        padding: '2px 4px',
        gap: '2px',
      },
      sizeMedium: {
        height: '1.5rem',
        padding: '4px 8px',
        gap: '4px',
      },
    },
  };
};

export default chipOverrides;
