import { useEffect } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import {
  datadogRum,
  type RumActionEventDomainContext,
  type RumEvent,
} from '@datadog/browser-rum';
import { vendorsCookiesHelper } from '@mint-lib/axeptIo-sdk';
import type { UserProfile } from '@mint-lib/profile-helpers';

import { errorsToSuppress } from './errorsToSuppress.js';

const TEST_ENVS = {
  test: 'test',
  test2: 'test2',
} as const;

const PROD_LIKE_ENVS = {
  prod: 'prod',
  demo: 'demo',
  uat: 'uat',
  uat1: 'uat1',
  uat2: 'uat2',
  uat3: 'uat3',
  uat4: 'uat4',
  uat5: 'uat5',
  uat6: 'uat6',
} as const;

const ALLOWED_ENVS = {
  ...PROD_LIKE_ENVS,
  ...TEST_ENVS,
};

/**
 * Map over envs and assign tokens to them
 * @param keys a lookup table of key of envs on infra side
 * @param token a datadog dashboard token
 * @returns a lookup table of env-token relation
 */
const matchTokenToKeys = <T extends { [key: string]: string }>(
  keys: T,
  token: string,
) =>
  Object.keys(keys).reduce(
    (acc, item: keyof T) => {
      acc[item] = token;
      return acc;
    },
    {} as { -readonly [P in keyof T]: string },
  );

const logKeys = {
  ...matchTokenToKeys(TEST_ENVS, 'pub6c7ab1a05f24327933dca2d5130cc6da'),
  ...matchTokenToKeys(PROD_LIKE_ENVS, 'pube0041e6e027ecce244d3ab05dece3518'),
};

const rumAppIds = {
  ...matchTokenToKeys(TEST_ENVS, '8ba4ec5f-b01d-4206-8522-2e93c8d002dc'),
  ...matchTokenToKeys(PROD_LIKE_ENVS, '3b0127f2-fca6-411c-84f7-e099c3a0a18c'),
};

const rumClientTokens = {
  ...matchTokenToKeys(TEST_ENVS, 'pubc842680b76a3d4b076a088acd43a0c60'),
  ...matchTokenToKeys(PROD_LIKE_ENVS, 'pub40441a3155ffe75dc3b508d43d5ffdc5'),
};

type TargetSimplified = {
  nodeName: string;
  parentNode?: TargetSimplified;
  className: string;
};

const importantUrlParts = [
  'builder',
  'campaigns',
  'trafficking',
  '/settings',
  'trafficking-analytics',
  'actual-vs-goals',
  'ai-impact',
  'insights',
  'insights/data-exploration',
  'notifications',
  'company-settings',
  'currencies',
  'import-hub',
  'strategy',
  'integrations',
  'brands',
] as const;

/**
 * Obfuscates the given action name.
 * @param event - The event to obfuscate.
 * @param ctx - A context of the event.
 * @param reason - The reason for obfuscating the action name.
 * @returns The obfuscated action name.
 */
const actionNameObfuscator = (
  event: RumEvent,
  ctx: RumActionEventDomainContext,
  reason: 'dead click' | 'too long' | 'contains numbers',
) => {
  const target = (ctx.events?.[0].target ?? {}) as TargetSimplified;
  const targetNodeName = target.nodeName;
  const parentNodeName = target.parentNode?.nodeName;
  const parentOfParentNodeName = target.parentNode?.parentNode?.nodeName;

  const moduleByUrl = importantUrlParts.find((part) =>
    event.view.url.includes(part),
  );

  return [
    '[obfuscated]',
    {
      message: `In ${moduleByUrl} data with reference ${targetNodeName} -> ${parentNodeName} -> ${parentOfParentNodeName} auto-obfuscated due to: ${reason}`,
      reason,
      module: moduleByUrl,
      htmlGroup: `${targetNodeName} -> ${parentNodeName} -> ${parentOfParentNodeName}`,
      targetClassName: target.className,
    },
  ] as const;
};

/**
 * Initializes Datadog logging and Real User Monitoring (RUM) for the application.
 *
 * @param profileData - The user's profile data, which includes the environment configuration.
 *
 * This function checks if the current environment is allowed for logging and not localhost.
 * If these conditions are met, it initializes the Datadog logs with the appropriate client token,
 * and sets up the service with the environment from the user's profile data.
 *
 * The client tokens for logging and RUM are determined based on the environment from the user's profile data.
 */
const useDatadog = (profileData: UserProfile | null) => {
  const isLocalhost = window.location.href.includes('localhost:');

  const isAllowedEnv = Object.keys(ALLOWED_ENVS).includes(
    profileData?.profile.config.environment || '--no-env-data',
  );

  const logClientToken =
    logKeys[profileData?.profile.config.environment as keyof typeof logKeys];

  const rumAppId =
    rumAppIds[
      profileData?.profile.config.environment as keyof typeof rumAppIds
    ];

  const rumClientToken =
    rumClientTokens[
      profileData?.profile.config.environment as keyof typeof rumClientTokens
    ];

  const cookies = vendorsCookiesHelper();

  useEffect(() => {
    // init if user accepted cookies
    if (!cookies?.Datadog) {
      return;
    }

    if (isAllowedEnv && !isLocalhost && logClientToken) {
      datadogLogs.init({
        clientToken: logClientToken,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: 'all',
        forwardReports: 'all',
        sessionSampleRate: 100,
        service: 'browser',
        version: import.meta.env.VITE_VERSION,
        env: profileData?.profile.config.environment,
        beforeSend: (log) => {
          if (
            errorsToSuppress.filter((toSuppress) =>
              log.message.includes(toSuppress),
            ).length > 0
          ) {
            return false;
          }
          return true;
        },
      });
    }

    if (
      isAllowedEnv &&
      !isLocalhost &&
      rumAppId &&
      rumClientToken &&
      profileData
    ) {
      datadogRum.init({
        applicationId: rumAppId,
        clientToken: rumClientToken,
        site: 'datadoghq.com',
        service: 'browser',
        version: import.meta.env.VITE_VERSION,
        env: profileData?.profile.config.environment,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        allowedTracingUrls: [/https:\/\/.*\.mint\.ai/],
        // we use aria-label from
        // (ARIA standard)[https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label]
        // to populate datadog clicks
        actionNameAttribute: 'aria-label',
        // TODO: remove when feature will be stable on datadog's side
        enableExperimentalFeatures: ['feature_flags'],
        beforeSend: (event, ctx) => {
          // Check if the event type is 'error' and if the error message includes any of the errors to suppress.
          // If so, return false to prevent sending the error to Datadog.
          if (
            event.type === 'error' &&
            errorsToSuppress.filter((toSuppress) =>
              event.error.message.includes(toSuppress),
            ).length > 0
          ) {
            return false;
          }

          const isAction =
            event.type === 'action' &&
            event.action.target != null &&
            'events' in ctx;

          // obfuscate dead clicks
          if (
            isAction &&
            event.action.frustration?.type.includes('dead_click') &&
            event.context
          ) {
            const [newName, obfuscationCtx] = actionNameObfuscator(
              event,
              ctx,
              'dead click',
            );
            event.action.target.name = newName;
            event.context.obfuscation = obfuscationCtx;

            return true;
          }

          // obfuscate long action names
          if (
            isAction &&
            event.action.target.name.length > 75 &&
            event.context
          ) {
            const [newName, obfuscationCtx] = actionNameObfuscator(
              event,
              ctx,
              'too long',
            );
            event.action.target.name = newName;
            event.context.obfuscation = obfuscationCtx;
            return true;
          }

          // obfuscate if action names is containing a number
          if (
            isAction &&
            /\d/.test(event.action.target.name) &&
            event.context
          ) {
            const [newName, obfuscationCtx] = actionNameObfuscator(
              event,
              ctx,
              'contains numbers',
            );
            event.action.target.name = newName;
            event.context.obfuscation = obfuscationCtx;
            return true;
          }

          return true;
        },
      });

      datadogRum.setUser({
        email: profileData.email,
        isMinter: profileData.email.endsWith('@mint.ai'),
        companyName: profileData.profile.company.name,
        isAdmin: profileData.isAdminUser,
        isOwner: profileData.isOwner,
        isCentralUser: profileData.isCentralUser,
        platform: profileData.profile.company.platform,
        license: profileData.profile.company.license,
        originCountry: profileData.profile.company.country,
        currency: profileData.profile.company.currency,
      });
    }
  }, [profileData?.profile.config.environment, JSON.stringify(cookies)]);
};

export default useDatadog;
