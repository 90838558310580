import styled from '@mint-lib/styled';

const EventMark = styled('div')`
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.palette.tertiary.light};
  position: absolute;
  right: 0;
  bottom: 0;
`;

export default EventMark;
