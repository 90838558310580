import React from 'react';
import {
  DropzoneOptions,
  DropzoneState,
  FileError,
  useDropzone,
} from 'react-dropzone';

import { useControllableState } from '@mint-lib/hooks';
import { useTranslation } from '@mint-lib/i18n';
import styled from '@mint-lib/styled';
import accepts from 'attr-accept';

import Icon from '../Icon/Icon.jsx';
import Tooltip from '../Tooltip/Tooltip.jsx';
import Typography from '../Typography/Typography.jsx';
import { ERRORS, getGlobalErrors } from './constants.js';
import { FileUploaderContent, StyledDropArea } from './FileUploaderContent.jsx';
import { FileUploaderV2Item } from './FileUploaderItem.jsx';
import { BaseFile, LocalFile, ReadyForUploadFile } from './types.js';
import {
  asyncCompose,
  createBaseFile,
  createBaseFileFromFileBlob,
  formatBytes,
  isFileError,
  isLocalFile,
  isLocalInvalidFile,
  isLocalValidFile,
  isPersistentFile,
  isReadyForUploadFile,
} from './utils.js';

export type FileUploaderV2Ref = React.ForwardedRef<{
  uploadFiles: (
    validFiles: ReadyForUploadFile[],
  ) => Promise<PromiseSettledResult<any>[]>;
}>;

export type FileUploaderV2Props = Omit<
  DropzoneOptions,
  'multiple' | 'accept'
> & {
  initialFiles?: Pick<BaseFile, 'name' | 'size' | 'previewUrl'>[];
  onChange?: (files: BaseFile[]) => void;
  validate?: (fileBlob: File) => Promise<null | FileError>;
  onUpload: (fileBlob: File) => Promise<any>;
  onDelete?: (file: BaseFile) => void;
  label?: string;
  helperText?: string;
  accept?: string[];
  totalSize?: number;
  variant?: 'area' | 'button' | 'default';
  itemVariant?: 'small' | 'medium';
  className?: string;
  fullWidth?: boolean;
  children?:
    | React.ReactNode
    | ((props: {
        getRootProps: DropzoneState['getRootProps'];
        getInputProps: DropzoneState['getInputProps'];
        isDragActive: DropzoneState['isDragActive'];
        open: DropzoneState['open'];
        disabled: boolean;
        variant?: 'area' | 'button' | 'default';
      }) => React.ReactNode);
  renderItem?: (props: {
    key: string;
    baseFile: BaseFile;
    onPreview: () => void;
    onRemove: () => void;
    onRetry: () => void;
    variant?: 'small' | 'medium';
  }) => React.ReactNode;
};

export const FileUploaderV2 = React.forwardRef(
  (
    {
      onChange,
      initialFiles = [],
      validate,
      onUpload,
      onDelete,
      disabled,
      children = defaultRenderChildren,
      renderItem = defaultRenderItem,
      minSize,
      maxSize,
      totalSize,
      maxFiles,
      accept,
      className,
      label,
      helperText,
      variant,
      itemVariant,
      fullWidth = false,
      ...dropzoneOptions
    }: FileUploaderV2Props,
    ref: FileUploaderV2Ref,
  ) => {
    const { t } = useTranslation('@myn/mui');

    React.useImperativeHandle(ref, () => ({
      uploadFiles,
    }));

    const [files, setFiles] = useControllableState<BaseFile[]>({
      defaultProp: initialFiles.map((initialFile) =>
        createBaseFile({ ...initialFile, isUploaded: true }),
      ),
      onChange,
    });

    const updateFileById = (id: string, filePartial: Partial<BaseFile>) => {
      setFiles((previousFiles) => {
        const updatedFiles = [...previousFiles];
        const fileToUpdateIndex = updatedFiles.findIndex(
          (file) => file.cid === id,
        );
        updatedFiles[fileToUpdateIndex] = {
          ...updatedFiles[fileToUpdateIndex],
          ...filePartial,
        };
        return updatedFiles;
      });
    };

    const [rejectionError, setRejectionError] =
      React.useState<FileError | null>();

    const isMaxFilesReached = maxFiles && files.length >= maxFiles;
    const currentTotalSize = files.reduce((a, f) => a + (f.size || 0), 0);
    const isTotalSizeExceeded = Boolean(
      totalSize && currentTotalSize > totalSize,
    );
    const isUploading = files.some((file) => file.isUploading);
    const hasValidationError = files.some(({ validationError }) =>
      Boolean(validationError),
    );
    const hasUploadError = files.some(({ uploadError }) =>
      Boolean(uploadError),
    );

    const isDisabled =
      disabled || isMaxFilesReached || isTotalSizeExceeded || isUploading;

    const GLOBAL_ERRORS = getGlobalErrors({ maxFiles });

    const checkFilesForRejection = (
      filesToValidate: LocalFile[],
    ): FileError | null => {
      const isTooManyFiles =
        maxFiles && files.length + filesToValidate.length > maxFiles;

      if (isTooManyFiles) {
        return GLOBAL_ERRORS.tooManyFiles;
      }

      return null;
    };

    const resetValidation = async (
      filesToValidate: BaseFile[],
    ): Promise<BaseFile[]> => {
      filesToValidate.forEach((file) => {
        file.validationError = null;
      });

      return filesToValidate;
    };

    const fileTypeValidation = async (
      filesToValidate: BaseFile[],
    ): Promise<BaseFile[]> => {
      if (!accept?.length) {
        return filesToValidate;
      }

      const localValidFiles: LocalFile[] =
        filesToValidate.filter(isLocalValidFile);

      localValidFiles.forEach((file) => {
        const isAcceptable =
          file.fileBlob.type === 'application/x-moz-file' ||
          accepts(file.fileBlob, accept);

        if (!isAcceptable) {
          file.validationError = ERRORS.invalidType;
        }
      });

      return filesToValidate;
    };

    const fileSizeValidation = async (
      filesToValidate: BaseFile[],
    ): Promise<BaseFile[]> => {
      if (!minSize && !maxSize) {
        return filesToValidate;
      }

      const localValidFiles: LocalFile[] =
        filesToValidate.filter(isLocalValidFile);

      localValidFiles.forEach((file) => {
        if (file.size) {
          if (minSize && file.size < minSize) {
            file.validationError = ERRORS.minSize;
          } else if (maxSize && file.size > maxSize) {
            file.validationError = ERRORS.maxSize;
          }
        }
      });

      return filesToValidate;
    };

    const duplicationValidation = async (
      filesToValidate: BaseFile[],
    ): Promise<BaseFile[]> => {
      const localValidFiles: LocalFile[] =
        filesToValidate.filter(isLocalValidFile);

      localValidFiles.forEach((file) => {
        const firstIndex = filesToValidate.findIndex(
          (f) => f.name === file.name,
        );
        const lastIndex = filesToValidate.findLastIndex(
          (f) => f.name === file.name,
        );
        const isDuplicate = firstIndex !== lastIndex;
        if (isDuplicate) {
          file.validationError = ERRORS.fileDuplication;
        }
      });

      return filesToValidate;
    };

    const totalSizeValidation = async (
      filesToValidate: BaseFile[],
    ): Promise<BaseFile[]> => {
      if (!totalSize) {
        return filesToValidate;
      }

      const persistentFiles = filesToValidate.filter(isPersistentFile);

      const persistentFilesSize = persistentFiles.reduce(
        (acc, persistentFile) => acc + (persistentFile?.size || 0),
        0,
      );

      let totalSizeAccumulator = persistentFilesSize;

      const localFiles = filesToValidate.filter(isLocalFile);
      localFiles.forEach((file) => {
        totalSizeAccumulator += file.size || 0;
        if (totalSizeAccumulator > totalSize) {
          file.validationError = ERRORS.totalSize;
        }
      });

      return filesToValidate;
    };

    const customValidation = async (
      filesToValidate: BaseFile[],
    ): Promise<BaseFile[]> => {
      if (!validate) {
        return filesToValidate;
      }

      const localValidFiles: LocalFile[] =
        filesToValidate.filter(isLocalValidFile);
      const customValidationResults = await Promise.all(
        localValidFiles.map((file) => validate(file.fileBlob)),
      );

      localValidFiles.forEach((validFile, index) => {
        const validationResult = customValidationResults[index];
        if (isFileError(validationResult)) {
          validFile.validationError = validationResult;
        }
      });

      return filesToValidate;
    };

    const normalizeValidation = async (
      filesToValidate: BaseFile[],
    ): Promise<BaseFile[]> => {
      filesToValidate.forEach((file) => {
        if (file.validationError) {
          file.uploadError = null;
        }
      });
      return filesToValidate;
    };

    const validateFiles = async (filesToValidate: BaseFile[]) => {
      /*
        `fileTypeValidation` and `fileSizeValidation` are the copies
        of corresponding built-in validation functions in 'react-dropzone'
        We have to write them ourselves to keep the validation process simple
        otherwise we would've had "built-in" and "our" validators
        which we would've had to manage differently
      */

      const validators = [
        resetValidation,
        fileTypeValidation,
        fileSizeValidation,
        duplicationValidation,
        totalSizeValidation,
        customValidation,
        normalizeValidation,
      ];

      const validatedFiles = await asyncCompose(validators)(filesToValidate);

      const localInvalidFiles = validatedFiles.filter(isLocalInvalidFile);
      const localValidFiles = validatedFiles.filter(isLocalValidFile);
      const persistentFiles = validatedFiles.filter(isPersistentFile);

      setFiles([...localInvalidFiles, ...localValidFiles, ...persistentFiles]);

      return { localValidFiles };
    };

    const validateAndUploadFiles = async (filesToValidate: BaseFile[]) => {
      const { localValidFiles } = await validateFiles(filesToValidate);

      const readyForUploadFiles = localValidFiles.filter(isReadyForUploadFile);

      // if auto mode then start upload automagically
      if (!ref) {
        await uploadFiles(readyForUploadFiles);
      }
    };

    async function uploadFiles(filesToUpload: ReadyForUploadFile[]) {
      filesToUpload.forEach((fileToUpload) => {
        updateFileById(fileToUpload.cid, {
          isUploading: true,
        });
      });

      const fileUploadRequests = filesToUpload.map((fileToUpload) =>
        onUpload(fileToUpload.fileBlob),
      );

      fileUploadRequests.forEach((fileUploadRequest, index) =>
        fileUploadRequest
          .then(() => {
            updateFileById(filesToUpload[index].cid, {
              isUploaded: true,
              uploadError: null,
            });
          })
          .catch((error) => {
            updateFileById(filesToUpload[index].cid, {
              uploadError: isFileError(error) ? error : ERRORS.upload,
            });
          })
          .finally(() => {
            updateFileById(filesToUpload[index].cid, {
              isUploading: false,
            });
          }),
      );

      const response = await Promise.allSettled(fileUploadRequests);

      return response;
    }

    const handlePreviewFile = (previewUrl?: string) => () => {
      if (previewUrl) {
        window.open(previewUrl, '_blank');
      }
    };

    const handleRemoveFile = (fileToRemove: BaseFile) => () => {
      if (isPersistentFile(fileToRemove)) {
        onDelete?.(fileToRemove);
      }

      /*
        Some validations depend on other files (totalSize / duplications)
        if any file is removed, we need to revalidate all
      */
      const updatedFiles = files.filter(
        (file) => file.cid !== fileToRemove.cid,
      );
      validateFiles(updatedFiles);

      if (inputRef.current?.files) {
        const dt = new DataTransfer();
        const selectedFiles = Array.from(inputRef.current.files);
        for (const selectedFile of selectedFiles) {
          selectedFile.name !== fileToRemove.name && dt.items.add(selectedFile);
        }
        try {
          inputRef.current.files = dt.files;
        } catch {}
      }
    };

    const handleRetryFile = (fileToRetry: BaseFile) => async () => {
      if (isLocalFile(fileToRetry)) {
        try {
          updateFileById(fileToRetry.cid, {
            isUploading: true,
            uploadError: null,
          });
          await onUpload?.(fileToRetry.fileBlob);
          updateFileById(fileToRetry.cid, {
            isUploaded: true,
          });
        } catch (error) {
          updateFileById(fileToRetry.cid, {
            uploadError: isFileError(error) ? error : ERRORS.upload,
          });
        } finally {
          updateFileById(fileToRetry.cid, {
            isUploading: false,
          });
        }
      }
    };

    const { getRootProps, getInputProps, isDragActive, open, inputRef } =
      useDropzone({
        ...dropzoneOptions,
        disabled: isDisabled,
        accept,
        multiple: maxFiles !== 1,
        onDrop: async (acceptedFiles, fileRejections) => {
          const selectedFiles = [
            ...acceptedFiles,
            ...fileRejections.map(({ file }) => file),
          ].map((fileBlob) => createBaseFileFromFileBlob(fileBlob));

          const rejectionCheckResult = checkFilesForRejection(selectedFiles);
          setRejectionError(rejectionCheckResult);

          if (isFileError(rejectionCheckResult)) {
            return;
          }

          validateAndUploadFiles([...selectedFiles, ...files]);
        },
      });

    const fileItems = files.map((baseFile) => {
      const fileItemElement = renderItem({
        key: baseFile.cid,
        baseFile,
        variant: itemVariant,
        onPreview: handlePreviewFile(baseFile.previewUrl),
        onRemove: handleRemoveFile(baseFile),
        onRetry: handleRetryFile(baseFile),
      });

      return fileItemElement;
    });

    const getTooltipTitle = () => {
      if (isMaxFilesReached) {
        return 'Maximum amount of files has been uploaded';
      }
      if (isTotalSizeExceeded) {
        return 'Total allowed size exceeded';
      }
      if (isUploading) {
        return 'Please wait until ongoing file loading completes';
      }

      return null;
    };

    const tooltipTitle = getTooltipTitle();
    const rootClassName = [className, isDragActive && 'isDragActive']
      .filter(Boolean)
      .join(' ');

    const [totalSizeDisplayValue = 0, totalSizeDisplaySuffix] = totalSize
      ? formatBytes(totalSize)
      : [];
    const currentTotalSizeDisplayValue =
      totalSize &&
      ((currentTotalSize / totalSize) * totalSizeDisplayValue).toFixed(0);

    return (
      <StyledContainer
        className={rootClassName}
        fullWidth={fullWidth}
        isDisabled={isDisabled}
      >
        {!!label && <Typography variant="label01">{label}</Typography>}
        <Tooltip title={tooltipTitle} placement="right">
          <div>
            {typeof children === 'function' ? (
              children({
                getRootProps,
                getInputProps,
                isDragActive,
                disabled: isDisabled,
                open,
                variant,
              })
            ) : (
              <StyledDropArea
                {...getRootProps({
                  isDragActive,
                  disabled,
                })}
              >
                <input {...getInputProps()} />
                {children}
              </StyledDropArea>
            )}
          </div>
        </Tooltip>
        {!!helperText && (
          <Typography variant="helperText01">{helperText}</Typography>
        )}
        {Number(maxFiles) > 1 && (
          <Typography variant="helperText01">
            {t('Max. files:')} {maxFiles}
          </Typography>
        )}
        {!!accept?.length && (
          <Typography variant="helperText01">
            {t('Supported files:')} {accept.join(', ')}
          </Typography>
        )}
        {!!maxSize && (
          <Typography variant="helperText01">
            {t('Max. file size:')} {formatBytes(maxSize).join('')}
          </Typography>
        )}
        {!!totalSize && (
          <Typography variant="helperText01">
            Uploaded files size: {currentTotalSizeDisplayValue} /{' '}
            {`${totalSizeDisplayValue}${totalSizeDisplaySuffix}`}
          </Typography>
        )}

        {hasValidationError && (
          <StyledErrorMessage>
            <Icon component="WarningAltFilled" color="warning" />
            <Typography variant="helperText01" color="warning.dark">
              {GLOBAL_ERRORS.hasValidationError.message}
            </Typography>
          </StyledErrorMessage>
        )}
        {!!isTotalSizeExceeded && (
          <StyledErrorMessage>
            <Icon component="WarningAltFilled" color="warning" />
            <Typography variant="helperText01" color="warning.dark">
              {GLOBAL_ERRORS.totalSize.message}
            </Typography>
          </StyledErrorMessage>
        )}
        {rejectionError && (
          <StyledErrorMessage>
            <Icon component="WarningAltFilled" color="warning" />
            <Typography variant="helperText01" color="warning.dark">
              {rejectionError.message}
            </Typography>
          </StyledErrorMessage>
        )}
        {hasUploadError && (
          <StyledErrorMessage>
            <Icon component="WarningFilled" color="error" />
            <Typography variant="helperText01" color="error.dark">
              {GLOBAL_ERRORS.hasUploadError.message}
            </Typography>
          </StyledErrorMessage>
        )}
        {!!fileItems?.length && (
          <StyledFileItemsList>{fileItems}</StyledFileItemsList>
        )}
      </StyledContainer>
    );
  },
);

const defaultRenderChildren: NonNullable<FileUploaderV2Props['children']> = (
  props,
) => <FileUploaderContent {...props} />;

const defaultRenderItem: NonNullable<FileUploaderV2Props['renderItem']> = (
  props,
) => <FileUploaderV2Item {...props} />;

const StyledContainer = styled('div')<{
  isDisabled: boolean;
  fullWidth: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: ${(props) => (props.fullWidth ? 'none' : '470px')};
  color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.palette.text.disabled : theme.palette.text.secondary};
`;

const StyledErrorMessage = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledFileItemsList = styled('ul')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0;
  padding: 0;
`;
