import typography from './typography.js';

const overrides = {
  styleOverrides: {
    root: {
      legend: {
        ...typography.label01,
      },
    },
  },
};

export default overrides;
