import React, { forwardRef } from 'react';

import CheckboxM, { CheckboxProps } from '@mui/material/Checkbox';

import Icon from '../Icon/Icon.jsx';

const Checkbox = forwardRef(
  (
    props: CheckboxProps & { readOnly?: boolean },
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <CheckboxM
        checkedIcon={
          <Icon component="CheckboxCheckedYes" data-testid="CheckBoxIcon" />
        }
        icon={
          <Icon
            component="CheckboxCheckedNo"
            data-testid="CheckBoxOutlineBlankIcon"
          />
        }
        indeterminateIcon={
          <Icon
            component="CheckboxIntermediateYes"
            data-testid="IndeterminateCheckBoxIcon"
          />
        }
        {...props}
        disabled={props.readOnly || props.disabled}
        ref={ref}
        className={`${props.readOnly ? 'readonly-checkbox' : ''} ${props.className}`}
      />
    );
  },
);

export default Checkbox;
