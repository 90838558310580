import { loadingButtonClasses } from '@mui/lab/LoadingButton';
import { Palette } from '@mui/material/styles';

import typography from './typography.js';

const loadingButtonOverrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        minWidth: 'auto',
        '&.MuiButton-root.has-children': {
          ...typography.bodyShort02,
          '.MuiLoadingButton-loadingIndicatorEnd': {
            marginLeft: '8px',
          },
          '&.MuiButton-sizeSmall': {
            padding: '4px 8px',
          },
          '&.MuiButton-sizeMedium': {
            padding: '8px 12px',
          },
          '&.MuiButton-sizeLarge': {
            padding: '12px 16px',
          },
        },
        '.MuiLoadingButton-endIconLoadingEnd, .MuiLoadingButton-startIconLoadingEnd':
          {
            display: 'none',
          },
        [`& .${loadingButtonClasses.loadingIndicator}`]: {
          position: 'initial',
        },
        '&.MuiLoadingButton-loading': {
          color: palette.text.disabled,
        },
        '&.MuiButton-outlined': {
          border: `1px solid ${palette.ui.tertiary}`,
        },

        '&.MuiButton-sizeSmall': {
          padding: '4px',
        },

        '&.MuiButton-sizeMedium': {
          padding: '8px',
        },
        '&.MuiButton-sizeLarge': {
          padding: '12px',
        },
      },
    },
  };
};

export default loadingButtonOverrides;
