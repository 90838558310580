import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_secured/campaign/$campaignId')({
  async loader({ context }) {
    await context.serviceLocator.getInstance('i18n', '@myn/campaign');
    const isRetailLicensee = await context.profileHelpers.isRetailLicensee();

    return {
      isRetailLicensee,
    };
  },
});
