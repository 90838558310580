const TableOverrides = {
  styleOverrides: {
    root: {
      borderRadius: '8px',
      boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 0px 5px 0px rgba(23, 24, 24, 0.05)`,
    },
  },
};

export default TableOverrides;
