import { z } from 'zod';

export type MainCurrencyType = {
  id: string;
  code: CurrencyCodeType;
  addedInDruid: boolean;
  // used in journey or datintel
  usedInCampaign: boolean;
};

export type CurrencyType<T> = {
  symbol: string;
  name: string;
  code: T;
  id: string;
};

export type NormalizedMainCurrencyItem = {
  currency: CurrencyType<CurrencyCodeType>;
  addedInDruid: boolean;
  usedInCampaign: boolean;
};

export type CurrenciesListType = {
  [K in CurrencyCodeType]: CurrencyType<K>;
};

export const currenciesZodSchema = z.union([
  z.literal('AED'),
  z.literal('AFN'),
  z.literal('ALL'),
  z.literal('AMD'),
  z.literal('ANG'),
  z.literal('AOA'),
  z.literal('ARS'),
  z.literal('AUD'),
  z.literal('AWG'),
  z.literal('AZN'),
  z.literal('BAM'),
  z.literal('BBD'),
  z.literal('BDT'),
  z.literal('BGN'),
  z.literal('BHD'),
  z.literal('BIF'),
  z.literal('BMD'),
  z.literal('BND'),
  z.literal('BOB'),
  z.literal('BRL'),
  z.literal('BSD'),
  z.literal('BTN'),
  z.literal('BWP'),
  z.literal('BZD'),
  z.literal('CAD'),
  z.literal('CDF'),
  z.literal('CHF'),
  z.literal('CLP'),
  z.literal('CNY'),
  z.literal('COP'),
  z.literal('CRC'),
  z.literal('CUP'),
  z.literal('CZK'),
  z.literal('DJF'),
  z.literal('DKK'),
  z.literal('DOP'),
  z.literal('DZD'),
  z.literal('EGP'),
  z.literal('ERN'),
  z.literal('ETB'),
  z.literal('EUR'),
  z.literal('FJD'),
  z.literal('FKP'),
  z.literal('GBP'),
  z.literal('GEL'),
  z.literal('GGP'),
  z.literal('GHS'),
  z.literal('GIP'),
  z.literal('GMD'),
  z.literal('GNF'),
  z.literal('GTQ'),
  z.literal('GYD'),
  z.literal('HKD'),
  z.literal('HNL'),
  z.literal('HRK'),
  z.literal('HTG'),
  z.literal('HUF'),
  z.literal('IDR'),
  z.literal('ILS'),
  z.literal('IMP'),
  z.literal('INR'),
  z.literal('IQD'),
  z.literal('IRR'),
  z.literal('ISK'),
  z.literal('JEP'),
  z.literal('JMD'),
  z.literal('JOD'),
  z.literal('JPY'),
  z.literal('KES'),
  z.literal('KGS'),
  z.literal('KHR'),
  z.literal('KMF'),
  z.literal('KRW'),
  z.literal('KWD'),
  z.literal('KYD'),
  z.literal('KZT'),
  z.literal('LAK'),
  z.literal('LBP'),
  z.literal('LKR'),
  z.literal('LRD'),
  z.literal('LSL'),
  z.literal('LYD'),
  z.literal('MAD'),
  z.literal('MDL'),
  z.literal('MGA'),
  z.literal('MKD'),
  z.literal('MMK'),
  z.literal('MNT'),
  z.literal('MOP'),
  z.literal('MUR'),
  z.literal('MVR'),
  z.literal('MWK'),
  z.literal('MXN'),
  z.literal('MYR'),
  z.literal('MZN'),
  z.literal('NAD'),
  z.literal('NGN'),
  z.literal('NIO'),
  z.literal('NOK'),
  z.literal('NPR'),
  z.literal('NZD'),
  z.literal('OMR'),
  z.literal('PAB'),
  z.literal('PEN'),
  z.literal('PGK'),
  z.literal('PHP'),
  z.literal('PKR'),
  z.literal('PLN'),
  z.literal('PYG'),
  z.literal('QAR'),
  z.literal('RON'),
  z.literal('RSD'),
  z.literal('RUB'),
  z.literal('RWF'),
  z.literal('SAR'),
  z.literal('SBD'),
  z.literal('SCR'),
  z.literal('SDG'),
  z.literal('SEK'),
  z.literal('SGD'),
  z.literal('SHP'),
  z.literal('SLE'),
  z.literal('SOS'),
  z.literal('SRD'),
  z.literal('SSP'),
  z.literal('SYP'),
  z.literal('SZL'),
  z.literal('THB'),
  z.literal('TJS'),
  z.literal('TMT'),
  z.literal('TND'),
  z.literal('TOP'),
  z.literal('TRY'),
  z.literal('TTD'),
  z.literal('TWD'),
  z.literal('TZS'),
  z.literal('UAH'),
  z.literal('UGX'),
  z.literal('USD'),
  z.literal('UYU'),
  z.literal('UZS'),
  z.literal('VND'),
  z.literal('VUV'),
  z.literal('WST'),
  z.literal('XAF'),
  z.literal('XCD'),
  z.literal('XDR'),
  z.literal('XOF'),
  z.literal('XPF'),
  z.literal('YER'),
  z.literal('ZAR'),
  z.literal('ZWL'),
]);

export type CurrencyCodeType = z.infer<typeof currenciesZodSchema>;
