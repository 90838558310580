import styled from '@mint-lib/styled';
import TableCell, { TableCellProps } from '@mui/material/TableCell';

import Skeleton from '../Skeleton/Skeleton.jsx';
import { controlColorDescription, getSkeletonStyle } from './utils.jsx';

export interface TableBodyCellProps extends TableCellProps {
  /**
   * The level can be from 1 to 5
   * @default false
   */
  level?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /**
   * Extra styles are applied for the last level
   * @default false
   */
  levelIsLast?: boolean;
  /**
   * align-items: baseline
   */
  baseline?: boolean;
  /**
   * background color of the cell
   * editable - bg color for editable cell
   * hover-editable - bg color for editable cell changes only on hover
   */
  bgColor?:
    | 'warning'
    | 'error'
    | 'success'
    | 'default'
    | 'editable'
    | 'hover-editable';
  /**
   * default variant takes 100% of the container so "width" prop for table cell is required
   */
  skeletonVariant?: 'default' | 'checkbox' | 'avatar';
  /**
   * if "true" - shows skeleton
   */
  isLoading?: boolean;
}

const TableBodyCell = ({
  level,
  levelIsLast,
  baseline,
  bgColor = 'default',
  className,
  skeletonVariant = 'default',
  isLoading,
  ...props
}: TableBodyCellProps) => {
  return (
    <StyledTableBodyCell
      variant="body"
      levelIsLast={levelIsLast}
      level={level}
      baseline={baseline}
      bgColor={bgColor}
      className={`${bgColor} ${className}`}
      {...props}
    >
      <Container align={props.align} baseline={baseline}>
        {isLoading ? (
          <Skeleton
            sx={getSkeletonStyle(skeletonVariant)}
            variant={skeletonVariant === 'avatar' ? 'circular' : 'rounded'}
          />
        ) : (
          <>
            {level ? (
              <Level
                levelIsLast={levelIsLast}
                level={level}
                className="table-cell-level"
              />
            ) : null}
            {props.children}
          </>
        )}
      </Container>
    </StyledTableBodyCell>
  );
};
export default TableBodyCell;

const StyledTableBodyCell = styled(TableCell, {
  shouldForwardProp: (prop) =>
    prop !== 'levelIsLast' &&
    prop !== 'level' &&
    prop !== 'baseline' &&
    prop !== 'bgColor',
})<{
  levelIsLast?: boolean;
  baseline?: boolean;
  level?: TableBodyCellProps['level'];
  bgColor: NonNullable<TableBodyCellProps['bgColor']>;
}>(({ levelIsLast, level, baseline, bgColor, theme }) => ({
  ...theme.typography.bodyShort01,
  backgroundColor: controlColorDescription(bgColor, theme),
  verticalAlign: 'middle',
  borderTop: `1px solid ${theme.palette.border.secondary}`,
  minHeight: '2.5rem',
  padding: '0 1rem 0 1rem',
  boxSizing: 'border-box',
  position: 'relative',
  '&::before': {
    content: level ? "''" : null,
    width: 1,
    backgroundColor: theme.palette.ui.tertiary,
    position: 'absolute',
    height: levelIsLast ? (baseline ? '24px' : '50%') : '100%',
    left: 24,
    top: 0,
  },
}));

const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'align' && prop !== 'baseline',
})<{ align?: TableCellProps['align']; baseline?: boolean }>(
  ({ align, baseline }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    minHeight: '2.45rem',
    alignItems: baseline ? 'baseline' : 'center',
    paddingTop: baseline ? '11px' : 0,
    justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
    gap: '0.5rem',
  }),
);
const Level = styled('div', {
  shouldForwardProp: (prop) => prop !== 'levelIsLast' && prop !== 'level',
})<{
  level?: TableBodyCellProps['level'];
  levelIsLast?: boolean;
}>(({ level, theme }) => ({
  position: 'relative',
  width: `${level}rem`,
  height: 1,
  backgroundColor: theme.palette.ui.tertiary,
  marginLeft: '0.5rem',
  flexShrink: 0,
}));
