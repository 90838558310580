import { ReactNode } from 'react';

import { ToastState } from './sonner/state.js';
import { ExternalToast, ToastT, ToastTypes } from './sonner/types.js';

export const enqueueSnackbar = (
  options: ExternalToast & {
    variant?: ToastTypes;
    title?: ReactNode;
    subTitle?: ReactNode;
    removable?: boolean;
    actions?: ReactNode;
    textLayout?: 'inline' | 'multiline';
    onDismiss?: (t: ToastT) => void;
  },
) => {
  return ToastState.create(options);
};
