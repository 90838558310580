import { Palette } from '@mui/material/styles';

const override = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        display: 'inline-flex',
        letterSpacing: 0.16,
        whiteSpace: 'nowrap' as const,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      underlineHover: {
        color: palette.text.link,
        '&:active': {
          color: palette.text.link,
        },
        '&:visited': {
          color: palette.text.link,
        },
        '&:hover': {
          color: palette.text.hoverPrimary,
        },
      },
      underlineNone: {
        color: palette.text.link,
        padding: '0.6875rem 12px',
        '&:active': {
          color: palette.text.link,
          backgroundColor: `${palette.background.success} !important`,
          '&:hover': {
            backgroundColor: `${palette.ui.highlight} !important`,
          },
        },
        '&:visited': {
          color: palette.text.primary,
        },
        '&:hover': {
          backgroundColor: palette.ui.hover,
        },
      },
      underlineAlways: {
        color: palette.text.link,
        textDecorationColor: palette.text.link,
        '&:active': {
          color: palette.text.link,
          textDecorationColor: palette.text.link,
        },
      },
    },
  };
};

export default override;
