import * as R from 'ramda';

import JourneyProviders from './JourneyProviders.js';

export const CHANNEL_PLATFORM_MAPPING = {
  display: ['dv360'],
  video: ['youtube', 'theoutplay'],
  social: ['facebook', 'instagram'],
  search: ['theoutplay', 'adwords'],
};

export const ADSERVER_TYPES = {
  CAMPAIGN_MANAGER: 'campaign_manager',
  ADFORM: 'adform',
};

export const IntegrationStatusMap = {
  SETUP: 'Setup',
  IN_PROGRESS: 'In-progress',
  FUTURE: 'Future integration',
};

export const IntegrationSettingsMapByType = {
  media: {},
  adNetwork: {},
  adServer: {
    [JourneyProviders.CAMPAIGN_MANAGER]: {
      status: IntegrationStatusMap.SETUP,
      image: 'campaignManager',
    },
    [JourneyProviders.ADFORM]: {
      status: IntegrationStatusMap.SETUP,
      image: 'adform',
    },
  },
  display: {
    [JourneyProviders.DBM]: {
      status: IntegrationStatusMap.SETUP,
      image: 'dv360',
    },
    [JourneyProviders.DV360]: {
      status: IntegrationStatusMap.SETUP,
      image: 'dv360',
    },
    [JourneyProviders.ADFORM]: {
      status: IntegrationStatusMap.IN_PROGRESS,
      image: 'adform',
    },
    [JourneyProviders.GOOGLE_DISPLAY_NETWORK]: {
      status: IntegrationStatusMap.IN_PROGRESS,
      image: 'googleDisplayNetwork',
    },
    [JourneyProviders.APPNEXUS]: {
      status: IntegrationStatusMap.IN_PROGRESS,
      image: 'appnexus',
    },
    [JourneyProviders.THE_TRADE_DESK]: {
      status: IntegrationStatusMap.FUTURE,
      image: 'the_trade_desk',
    },
    [JourneyProviders.OATH]: {
      status: IntegrationStatusMap.FUTURE,
      image: 'oath',
    },
    [JourneyProviders.CRITEO]: {
      status: IntegrationStatusMap.FUTURE,
      image: 'criteo',
    },
    [JourneyProviders.AMAZON_ADVERTISING]: {
      status: IntegrationStatusMap.FUTURE,
      image: 'amazon_advertising',
    },
    [JourneyProviders.HYBRID_AI]: {
      status: IntegrationStatusMap.FUTURE,
      image: 'hybridai',
    },
    [JourneyProviders.INSTAL]: {
      status: IntegrationStatusMap.FUTURE,
      image: 'instal',
    },
  },
  search: {
    [JourneyProviders.ADWORDS]: {
      status: IntegrationStatusMap.SETUP,
      image: 'google_ads',
    },
    [JourneyProviders.BING]: {
      status: IntegrationStatusMap.FUTURE,
      image: 'bing',
    },
    [JourneyProviders.YANDEX]: {
      status: IntegrationStatusMap.FUTURE,
      image: 'yandex',
    },
    [JourneyProviders.YAHOO]: {
      status: IntegrationStatusMap.FUTURE,
      image: 'yahoo',
    },
    [JourneyProviders.BAIDU]: {
      status: IntegrationStatusMap.FUTURE,
      image: 'baidu',
    },
  },
  social: {
    [JourneyProviders.FB]: {
      status: IntegrationStatusMap.SETUP,
      image: 'facebook',
    },
    [JourneyProviders.INSTA]: {
      status: IntegrationStatusMap.SETUP,
      image: 'instagram',
    },
    [JourneyProviders.YOUTUBE]: {
      status: IntegrationStatusMap.IN_PROGRESS,
      image: 'youtube',
    },
    [JourneyProviders.TWITTER]: {
      status: IntegrationStatusMap.FUTURE,
      image: 'twitter',
    },
    [JourneyProviders.LINKEDIN]: {
      status: IntegrationStatusMap.FUTURE,
      image: 'linkedin',
    },
    [JourneyProviders.VK]: {
      status: IntegrationStatusMap.FUTURE,
      image: 'vk',
    },
    [JourneyProviders.XING]: {
      status: IntegrationStatusMap.FUTURE,
      image: 'xing',
    },
    [JourneyProviders.PINTEREST]: {
      status: IntegrationStatusMap.FUTURE,
      image: 'pinterest',
    },
    [JourneyProviders.WHATSAPP]: {
      status: IntegrationStatusMap.FUTURE,
      image: 'whatsapp_business',
    },
    [JourneyProviders.MAKEMEREACH]: {
      status: IntegrationStatusMap.FUTURE,
      image: 'makemereach',
    },
    [JourneyProviders.WECHAT]: {
      status: IntegrationStatusMap.FUTURE,
      image: 'wechat',
    },
    [JourneyProviders.WEIBO]: {
      status: IntegrationStatusMap.FUTURE,
      image: 'weibo',
    },
    [JourneyProviders.CONVERSION_PIXEL]: {
      status: IntegrationStatusMap.SETUP,
      image: '"conversionPixel"',
    },
  },
  video: {
    [JourneyProviders.THEOUTPLAY]: {
      status: IntegrationStatusMap.SETUP,
      image: 'my_video',
    },
    [JourneyProviders.YOUTUBE]: {
      status: IntegrationStatusMap.IN_PROGRESS,
      image: 'youtube',
    },
    [JourneyProviders.DV360_VIDEO]: {
      status: IntegrationStatusMap.IN_PROGRESS,
      image: 'dv360',
    },
    [JourneyProviders.APPNEXUS]: {
      status: IntegrationStatusMap.IN_PROGRESS,
      image: 'appnexus',
    },
    [JourneyProviders.ADFORM]: {
      status: IntegrationStatusMap.IN_PROGRESS,
      image: 'adform',
    },
  },
  native: {
    // [JourneyProviders.OUTBRAIN]: {
    //   status: IntegrationStatusMap.IN_PROGRESS,
    //   image: 'outbrain',
    // },
  },
  dmp: {
    // ! one of them should die
    // [JourneyProviders.ADOBE_AUDIENCE_MANAGER]: {
    //   status: IntegrationStatusMap.SETUP,
    //   image: 'adobeAudienceManager',
    // },
    [JourneyProviders.ADOBE]: {
      status: IntegrationStatusMap.SETUP,
      image: 'adobeAudienceManager',
    },
  },
};

export const PossibleIntegrations = R.mapObjIndexed(
  (campaigns, type) =>
    R.mapObjIndexed(
      (campaign, provider) => ({
        platformImageName: campaign.image,
        status: campaign.status,
        campaign: provider,
        type,
      }),
      campaigns,
    ),
  IntegrationSettingsMapByType,
);

// @ts-ignore
export const AvailableIntegrationsByType = R.map(
  (campaigns) =>
    R.filter(
      (campaign) => campaign.status === IntegrationStatusMap.SETUP,
      campaigns,
    ),
  PossibleIntegrations,
);

export const FlatAvailableIntegrationsByType = Object.values(
  AvailableIntegrationsByType,
).reduce(
  // @ts-ignore
  (acc, integrationsByType) => [...acc, ...Object.values(integrationsByType)],
  [],
);

const statusesOrder = [
  IntegrationStatusMap.SETUP,
  IntegrationStatusMap.IN_PROGRESS,
  IntegrationStatusMap.FUTURE,
].reverse(); // unknown will be last
// @ts-ignore
const getOrderRank = ({ status }) => statusesOrder.indexOf(status);

export const IntegrationsCatalogue = R.mapObjIndexed(
  R.pipe(
    // @ts-ignore
    R.values,
    R.sort((a, b) => getOrderRank(b) - getOrderRank(a)),
  ),
  PossibleIntegrations,
);
