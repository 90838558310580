import { createFileRoute, notFound } from '@tanstack/react-router';

import { buildGuards, hasPermission } from '@mint-lib/routing-guards';
import { z } from 'zod';

const guard = buildGuards(hasPermission('campaignPlanningFeature:view'));

export enum PanelTab {
  AddRow = 'AddRow',
  Recommendations = 'Recommendations',
  Co2 = 'Co2',
  Versions = 'Versions',
}

export const Route = createFileRoute(
  '/_secured/campaign/$campaignId/planning/buying-plan',
)({
  validateSearch: z.object({
    mediaplan: z.number().optional(),
    tab: z.nativeEnum(PanelTab).optional(),
    row: z.number().optional(),
  }),
  async beforeLoad({ context }) {
    const guardsContext = await context.getGuardContext(
      await context.serviceLocator.getInstance('flagsmith', '@myn/secured'),
    );
    if (!guard(guardsContext)) {
      throw notFound();
    }
    return context;
  },
  loader: async ({ context }) => {
    await context.serviceLocator.getInstance('i18n', '@myn/planning');
  },
});
