import { Truncation } from '@myn/mui';

type Props = {
  text: string;
};

const RegularTextCell = ({ text }: Props) => (
  <Truncation tooltipTitle={text} maxWidth={250}>
    {text}
  </Truncation>
);

export default RegularTextCell;
