import { ChangeEvent } from 'react';

import { useTranslation } from '@mint-lib/i18n';
import styled from '@mint-lib/styled';
import TextField from '@mui/material/TextField';
import { addYears, getMonth, getYear } from 'date-fns';

import IconButton from '../../components/IconButton/IconButton.jsx';
import { getLocaleObject } from './getLocaleObject.jsx';

interface Props {
  changeYear: any;
  date: Date;
  className?: string;
  monthDate: Date;
  decreaseMonth: any;
  increaseMonth: any;
  customHeaderCount: any;
  variant: 'single' | 'range';
}

const CalendarHeader = ({
  changeYear,
  decreaseMonth,
  increaseMonth,
  date,
  className,
  customHeaderCount,
  variant,
  monthDate,
}: Props) => {
  const { i18n } = useTranslation();

  const handleYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (+event.target.value > 0 && +event.target.value < 9999) {
      changeYear(+event.target.value);
    }
  };
  const handleYearIncrease = () => {
    changeYear(getYear(addYears(date, 1)));
  };
  const handleYearDecrease = () => {
    changeYear(getYear(addYears(date, -1)));
  };

  // remove after the full release;
  const langs = {
    en: 'en-GB',
    it: 'it-IT',
  };
  // @ts-ignore
  const locale = langs[i18n.language] ?? i18n.language;

  return (
    <StyledHeaderWrapper className={className}>
      <StyledArrowLeft
        aria-label="Previous Month"
        variant="icon"
        color="secondary"
        size="medium"
        sx={
          variant === 'range' && customHeaderCount === 1
            ? { visibility: 'hidden' }
            : {}
        }
        onClick={decreaseMonth}
        icon="ArrowLeft"
      />
      <CenteredContainer>
        <MothLabel>
          {getLocaleObject(locale).localize.month(getMonth(monthDate))}
        </MothLabel>
        <InputContainer>
          <StyledTextField
            variant="standard"
            type="number"
            value={getYear(monthDate)}
            onChange={handleYearChange}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              min: 0,
              max: 9999,
            }}
          />
          <CaretContainer>
            <IconButton
              onClick={handleYearIncrease}
              size="small"
              icon="CaretUp"
              variant="icon"
              color="primary"
            />
            <IconButton
              onClick={handleYearDecrease}
              size="small"
              icon="CaretDown"
              variant="icon"
              color="primary"
            />
          </CaretContainer>
        </InputContainer>
      </CenteredContainer>

      <IconButton
        aria-label="Next Month"
        size="medium"
        variant="icon"
        color="secondary"
        sx={
          variant === 'range' && customHeaderCount === 0
            ? { visibility: 'hidden' }
            : {}
        }
        onClick={increaseMonth}
        icon="ArrowRight"
      />
    </StyledHeaderWrapper>
  );
};

const StyledHeaderWrapper = styled('div')`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
`;

const InputContainer = styled('div')`
  display: flex;
  height: 2rem;
  box-shadow: none;
  & .MuiInput-root {
    position: relative;
    ::before,
    ::after {
      display: none;
    }

    & > input {
      font-size: 0.875rem;
      line-height: 1.125rem;
      padding: 0px 4px;
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      ::-webkit-inner-spin-button,
      ::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
    }
  }

  & .MuiInput-root:not(.Mui-focused) {
    border-bottom: 2px solid transparent;
  }
  & .MuiIconButton-root {
    padding: 0;
    border-radius: 0;
    height: 0.5rem;
    width: fit-content;
    &:focus::after {
      content: none;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
`;

const CaretContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  font-size: 0.5rem;
  cursor: pointer;
  justify-content: center;
`;

const MothLabel = styled('div')`
  font-size: 0.875rem;
  line-height: 1.125rem;
  justify-self: end;
  padding: 4px;
  color: ${({ theme }) => theme.palette.text.primary};
  text-transform: capitalize;
`;

const StyledTextField = styled(TextField)`
  & .MuiInput-root {
    width: fit-content;
    min-height: 2rem;
    box-shadow: none;
    background-color: ${({ theme }) => theme.palette.ui.primary};
  }
`;

const CenteredContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
`;
const StyledArrowLeft = styled(IconButton)`
  & .MuiSvgIcon-root {
    transform: scale(1.13);
  }
`;

export default CalendarHeader;
