import { CurrenciesListType } from '../api/getMainCurrencies/getMainCurrencies.types.js';

export interface OptionType {
  label: string;
  value: string;
}

export const CURRENCIES_META: CurrenciesListType = {
  AED: {
    id: '',
    symbol: 'AED',
    name: 'United Arab Emirates Dirham',
    code: 'AED',
  },
  AFN: {
    id: '',
    symbol: 'Af',
    name: 'Afghan Afghani',
    code: 'AFN',
  },
  ALL: {
    id: '',
    symbol: 'ALL',
    name: 'Albanian Lek',
    code: 'ALL',
  },
  AMD: {
    id: '',
    symbol: 'AMD',
    name: 'Armenian Dram',
    code: 'AMD',
  },
  ANG: {
    id: '',
    symbol: 'ƒ',
    name: 'Netherlands Antillean Guilder',
    code: 'ANG',
  },
  AOA: {
    id: '',
    symbol: 'Kz',
    name: 'Angolan Kwanza',
    code: 'AOA',
  },
  ARS: {
    id: '',
    symbol: 'AR$',
    name: 'Argentine Peso',
    code: 'ARS',
  },
  AUD: {
    id: '',
    symbol: 'AU$',
    name: 'Australian Dollar',
    code: 'AUD',
  },
  AWG: {
    id: '',
    symbol: 'ƒ',
    name: 'Aruban Florin',
    code: 'AWG',
  },
  AZN: {
    id: '',
    symbol: 'man.',
    name: 'Azerbaijani Manat',
    code: 'AZN',
  },
  BAM: {
    id: '',
    symbol: 'KM',
    name: 'Bosnia-Herzegovina Convertible Mark',
    code: 'BAM',
  },
  BBD: {
    id: '',
    symbol: '$',
    name: 'Barbadian Dollar',
    code: 'BBD',
  },
  BDT: {
    id: '',
    symbol: 'Tk',
    name: 'Bangladeshi Taka',
    code: 'BDT',
  },
  BGN: {
    id: '',
    symbol: 'BGN',
    name: 'Bulgarian Lev',
    code: 'BGN',
  },
  BHD: {
    id: '',
    symbol: 'BD',
    name: 'Bahraini Dinar',
    code: 'BHD',
  },
  BIF: {
    id: '',
    symbol: 'FBu',
    name: 'Burundian Franc',
    code: 'BIF',
  },
  BMD: {
    id: '',
    symbol: '$',
    name: 'Bermudian Dollar',
    code: 'BMD',
  },
  BND: {
    id: '',
    symbol: 'BN$',
    name: 'Brunei Dollar',
    code: 'BND',
  },
  BOB: {
    id: '',
    symbol: 'Bs',
    name: 'Bolivian Boliviano',
    code: 'BOB',
  },
  BRL: {
    id: '',
    symbol: 'R$',
    name: 'Brazilian Real',
    code: 'BRL',
  },
  BSD: {
    id: '',
    symbol: '$',
    name: 'Bahamian Dollar',
    code: 'BSD',
  },
  BTN: {
    id: '',
    symbol: 'Nu.',
    name: 'Bhutanese Ngultrum',
    code: 'BTN',
  },
  BWP: {
    id: '',
    symbol: 'BWP',
    name: 'Botswanan Pula',
    code: 'BWP',
  },
  BZD: {
    id: '',
    symbol: 'BZ$',
    name: 'Belize Dollar',
    code: 'BZD',
  },
  CAD: {
    id: '',
    symbol: 'CA$',
    name: 'Canadian Dollar',
    code: 'CAD',
  },
  CDF: {
    id: '',
    symbol: 'CDF',
    name: 'Congolese Franc',
    code: 'CDF',
  },
  CHF: {
    id: '',
    symbol: 'CHF',
    name: 'Swiss Franc',
    code: 'CHF',
  },
  CLP: {
    id: '',
    symbol: 'CL$',
    name: 'Chilean Peso',
    code: 'CLP',
  },
  CNY: {
    id: '',
    symbol: 'CN¥',
    name: 'Chinese Yuan',
    code: 'CNY',
  },
  COP: {
    id: '',
    symbol: 'CO$',
    name: 'Colombian Peso',
    code: 'COP',
  },
  CRC: {
    id: '',
    symbol: '₡',
    name: 'Costa Rican Colón',
    code: 'CRC',
  },
  CUP: {
    id: '',
    symbol: '$',
    name: 'Cuban Peso',
    code: 'CUP',
  },
  CZK: {
    id: '',
    symbol: 'Kč',
    name: 'Czech Republic Koruna',
    code: 'CZK',
  },
  DJF: {
    id: '',
    symbol: 'Fdj',
    name: 'Djiboutian Franc',
    code: 'DJF',
  },
  DKK: {
    id: '',
    symbol: 'Dkr',
    name: 'Danish Krone',
    code: 'DKK',
  },
  DOP: {
    id: '',
    symbol: 'RD$',
    name: 'Dominican Peso',
    code: 'DOP',
  },
  DZD: {
    id: '',
    symbol: 'DA',
    name: 'Algerian Dinar',
    code: 'DZD',
  },
  EGP: {
    id: '',
    symbol: 'EGP',
    name: 'Egyptian Pound',
    code: 'EGP',
  },
  ERN: {
    id: '',
    symbol: 'Nfk',
    name: 'Eritrean Nakfa',
    code: 'ERN',
  },
  ETB: {
    id: '',
    symbol: 'Br',
    name: 'Ethiopian Birr',
    code: 'ETB',
  },
  EUR: {
    id: '',
    symbol: '€',
    name: 'Euro',
    code: 'EUR',
  },
  FJD: {
    id: '',
    symbol: '$',
    name: 'Fijian Dollar',
    code: 'FJD',
  },
  FKP: {
    id: '',
    symbol: '£',
    name: 'Falkland Islands Pound',
    code: 'FKP',
  },
  GBP: {
    id: '',
    symbol: '£',
    name: 'British Pound Sterling',
    code: 'GBP',
  },
  GEL: {
    id: '',
    symbol: 'GEL',
    name: 'Georgian Lari',
    code: 'GEL',
  },
  GGP: {
    id: '',
    symbol: '£',
    name: 'Guernsey Pound',
    code: 'GGP',
  },
  GHS: {
    id: '',
    symbol: 'GH₵',
    name: 'Ghanaian Cedi',
    code: 'GHS',
  },
  GIP: {
    id: '',
    symbol: '£',
    name: 'Gibraltar Pound',
    code: 'GIP',
  },
  GMD: {
    id: '',
    symbol: 'D',
    name: 'Gambian Dalasi',
    code: 'GMD',
  },
  GNF: {
    id: '',
    symbol: 'FG',
    name: 'Guinean Franc',
    code: 'GNF',
  },
  GTQ: {
    id: '',
    symbol: 'GTQ',
    name: 'Guatemalan Quetzal',
    code: 'GTQ',
  },
  GYD: {
    id: '',
    symbol: '$',
    name: 'Guyanese Dollar',
    code: 'GYD',
  },
  HKD: {
    id: '',
    symbol: 'HK$',
    name: 'Hong Kong Dollar',
    code: 'HKD',
  },
  HNL: {
    id: '',
    symbol: 'HNL',
    name: 'Honduran Lempira',
    code: 'HNL',
  },
  HRK: {
    id: '',
    symbol: 'kn',
    name: 'Croatian Kuna',
    code: 'HRK',
  },
  HTG: {
    id: '',
    symbol: 'G',
    name: 'Haitian Gourde',
    code: 'HTG',
  },
  HUF: {
    id: '',
    symbol: 'Ft',
    name: 'Hungarian Forint',
    code: 'HUF',
  },
  IDR: {
    id: '',
    symbol: 'Rp',
    name: 'Indonesian Rupiah',
    code: 'IDR',
  },
  ILS: {
    id: '',
    symbol: '₪',
    name: 'Israeli New Sheqel',
    code: 'ILS',
  },
  IMP: {
    id: '',
    symbol: '£',
    name: 'Manx Pound',
    code: 'IMP',
  },
  INR: {
    id: '',
    symbol: 'Rs',
    name: 'Indian Rupee',
    code: 'INR',
  },
  IQD: {
    id: '',
    symbol: 'IQD',
    name: 'Iraqi Dinar',
    code: 'IQD',
  },
  IRR: {
    id: '',
    symbol: 'IRR',
    name: 'Iranian Rial',
    code: 'IRR',
  },
  ISK: {
    id: '',
    symbol: 'Ikr',
    name: 'Icelandic Króna',
    code: 'ISK',
  },
  JEP: {
    id: '',
    symbol: '£',
    name: 'Jersey Pound',
    code: 'JEP',
  },
  JMD: {
    id: '',
    symbol: 'J$',
    name: 'Jamaican Dollar',
    code: 'JMD',
  },
  JOD: {
    id: '',
    symbol: 'JD',
    name: 'Jordanian Dinar',
    code: 'JOD',
  },
  JPY: {
    id: '',
    symbol: '¥',
    name: 'Japanese Yen',
    code: 'JPY',
  },
  KES: {
    id: '',
    symbol: 'Ksh',
    name: 'Kenyan Shilling',
    code: 'KES',
  },
  KGS: {
    id: '',
    symbol: 'som',
    name: 'Kyrgyzstani Som',
    code: 'KGS',
  },
  KHR: {
    id: '',
    symbol: 'KHR',
    name: 'Cambodian Riel',
    code: 'KHR',
  },
  KMF: {
    id: '',
    symbol: 'CF',
    name: 'Comorian Franc',
    code: 'KMF',
  },
  KRW: {
    id: '',
    symbol: '₩',
    name: 'South Korean Won',
    code: 'KRW',
  },
  KWD: {
    id: '',
    symbol: 'KD',
    name: 'Kuwaiti Dinar',
    code: 'KWD',
  },
  KYD: {
    id: '',
    symbol: '$',
    name: 'Cayman Islands Dollar',
    code: 'KYD',
  },
  KZT: {
    id: '',
    symbol: 'KZT',
    name: 'Kazakhstani Tenge',
    code: 'KZT',
  },
  LAK: {
    id: '',
    symbol: '₭',
    name: 'Lao Kip',
    code: 'LAK',
  },
  LBP: {
    id: '',
    symbol: 'L.L.',
    name: 'Lebanese Pound',
    code: 'LBP',
  },
  LKR: {
    id: '',
    symbol: 'SLRs',
    name: 'Sri Lankan Rupee',
    code: 'LKR',
  },
  LRD: {
    id: '',
    symbol: '$',
    name: 'Liberian Dollar',
    code: 'LRD',
  },
  LSL: {
    id: '',
    symbol: 'L',
    name: 'Lesotho Loti',
    code: 'LSL',
  },
  LYD: {
    id: '',
    symbol: 'LD',
    name: 'Libyan Dinar',
    code: 'LYD',
  },
  MAD: {
    id: '',
    symbol: 'MAD',
    name: 'Moroccan Dirham',
    code: 'MAD',
  },
  MDL: {
    id: '',
    symbol: 'MDL',
    name: 'Moldovan Leu',
    code: 'MDL',
  },
  MGA: {
    id: '',
    symbol: 'MGA',
    name: 'Malagasy Ariary',
    code: 'MGA',
  },
  MKD: {
    id: '',
    symbol: 'MKD',
    name: 'Macedonian Denar',
    code: 'MKD',
  },
  MMK: {
    id: '',
    symbol: 'MMK',
    name: 'Myanma Kyat',
    code: 'MMK',
  },
  MNT: {
    id: '',
    symbol: '₮',
    name: 'Mongolian Tögrög',
    code: 'MNT',
  },
  MOP: {
    id: '',
    symbol: 'MOP$',
    name: 'Macanese Pataca',
    code: 'MOP',
  },
  MUR: {
    id: '',
    symbol: 'MURs',
    name: 'Mauritian Rupee',
    code: 'MUR',
  },
  MVR: {
    id: '',
    symbol: '.ރ',
    name: 'Maldivian Rufiyaa',
    code: 'MVR',
  },
  MWK: {
    id: '',
    symbol: 'MK',
    name: 'Malawian Kwacha',
    code: 'MWK',
  },
  MXN: {
    id: '',
    symbol: 'MX$',
    name: 'Mexican Peso',
    code: 'MXN',
  },
  MYR: {
    id: '',
    symbol: 'RM',
    name: 'Malaysian Ringgit',
    code: 'MYR',
  },
  MZN: {
    id: '',
    symbol: 'MTn',
    name: 'Mozambican Metical',
    code: 'MZN',
  },
  NAD: {
    id: '',
    symbol: 'N$',
    name: 'Namibian Dollar',
    code: 'NAD',
  },
  NGN: {
    id: '',
    symbol: '₦',
    name: 'Nigerian Naira',
    code: 'NGN',
  },
  NIO: {
    id: '',
    symbol: 'C$',
    name: 'Nicaraguan Córdoba',
    code: 'NIO',
  },
  NOK: {
    id: '',
    symbol: 'Nkr',
    name: 'Norwegian Krone',
    code: 'NOK',
  },
  NPR: {
    id: '',
    symbol: 'NPRs',
    name: 'Nepalese Rupee',
    code: 'NPR',
  },
  NZD: {
    id: '',
    symbol: 'NZ$',
    name: 'New Zealand Dollar',
    code: 'NZD',
  },
  OMR: {
    id: '',
    symbol: 'OMR',
    name: 'Omani Rial',
    code: 'OMR',
  },
  PAB: {
    id: '',
    symbol: 'B/.',
    name: 'Panamanian Balboa',
    code: 'PAB',
  },
  PEN: {
    id: '',
    symbol: 'S/.',
    name: 'Peruvian Nuevo Sol',
    code: 'PEN',
  },
  PGK: {
    id: '',
    symbol: 'K',
    name: 'Papua New Guinean Kina',
    code: 'PGK',
  },
  PHP: {
    id: '',
    symbol: '₱',
    name: 'Philippine Peso',
    code: 'PHP',
  },
  PKR: {
    id: '',
    symbol: 'PKRs',
    name: 'Pakistani Rupee',
    code: 'PKR',
  },
  PLN: {
    id: '',
    symbol: 'zł',
    name: 'Polish Zloty',
    code: 'PLN',
  },
  PYG: {
    id: '',
    symbol: '₲',
    name: 'Paraguayan Guarani',
    code: 'PYG',
  },
  QAR: {
    id: '',
    symbol: 'QR',
    name: 'Qatari Rial',
    code: 'QAR',
  },
  RON: {
    id: '',
    symbol: 'RON',
    name: 'Romanian Leu',
    code: 'RON',
  },
  RSD: {
    id: '',
    symbol: 'din.',
    name: 'Serbian Dinar',
    code: 'RSD',
  },
  RUB: {
    id: '',
    symbol: 'RUB',
    name: 'Russian Ruble',
    code: 'RUB',
  },
  RWF: {
    id: '',
    symbol: 'RWF',
    name: 'Rwandan Franc',
    code: 'RWF',
  },
  SAR: {
    id: '',
    symbol: 'SR',
    name: 'Saudi Riyal',
    code: 'SAR',
  },
  SBD: {
    id: '',
    symbol: '$',
    name: 'Solomon Islands Dollar',
    code: 'SBD',
  },
  SCR: {
    id: '',
    symbol: '₨',
    name: 'Seychellois Rupee',
    code: 'SCR',
  },
  SDG: {
    id: '',
    symbol: 'SDG',
    name: 'Sudanese Pound',
    code: 'SDG',
  },
  SEK: {
    id: '',
    symbol: 'Skr',
    name: 'Swedish Krona',
    code: 'SEK',
  },
  SGD: {
    id: '',
    symbol: 'S$',
    name: 'Singapore Dollar',
    code: 'SGD',
  },
  SHP: {
    id: '',
    symbol: '£',
    name: 'Saint Helena Pound',
    code: 'SHP',
  },
  SLE: {
    id: '',
    symbol: 'Le',
    name: 'Sierra Leonean Leone',
    code: 'SLE',
  },
  SOS: {
    id: '',
    symbol: 'Ssh',
    name: 'Somali Shilling',
    code: 'SOS',
  },
  SRD: {
    id: '',
    symbol: '$',
    name: 'Surinamese Dollar',
    code: 'SRD',
  },
  SSP: {
    id: '',
    symbol: '£',
    name: 'South Sudanese Pound',
    code: 'SSP',
  },
  SYP: {
    id: '',
    symbol: 'SY£',
    name: 'Syrian Pound',
    code: 'SYP',
  },
  SZL: {
    id: '',
    symbol: 'L',
    name: 'Swazi Lilangeni',
    code: 'SZL',
  },
  THB: {
    id: '',
    symbol: '฿',
    name: 'Thai Baht',
    code: 'THB',
  },
  TJS: {
    id: '',
    symbol: 'SM',
    name: 'Tajikistani Somoni',
    code: 'TJS',
  },
  TMT: {
    id: '',
    symbol: 'm',
    name: 'Turkmenistan Manat',
    code: 'TMT',
  },
  TND: {
    id: '',
    symbol: 'DT',
    name: 'Tunisian Dinar',
    code: 'TND',
  },
  TOP: {
    id: '',
    symbol: 'T$',
    name: 'Tongan Paʻanga',
    code: 'TOP',
  },
  TRY: {
    id: '',
    symbol: 'TL',
    name: 'Turkish Lira',
    code: 'TRY',
  },
  TTD: {
    id: '',
    symbol: 'TT$',
    name: 'Trinidad and Tobago Dollar',
    code: 'TTD',
  },
  TWD: {
    id: '',
    symbol: 'NT$',
    name: 'New Taiwan Dollar',
    code: 'TWD',
  },
  TZS: {
    id: '',
    symbol: 'TSh',
    name: 'Tanzanian Shilling',
    code: 'TZS',
  },
  UAH: {
    id: '',
    symbol: '₴',
    name: 'Ukrainian Hryvnia',
    code: 'UAH',
  },
  UGX: {
    id: '',
    symbol: 'USh',
    name: 'Ugandan Shilling',
    code: 'UGX',
  },
  USD: {
    id: '',
    symbol: '$',
    name: 'US Dollar',
    code: 'USD',
  },
  UYU: {
    id: '',
    symbol: '$U',
    name: 'Uruguayan Peso',
    code: 'UYU',
  },
  UZS: {
    id: '',
    symbol: 'UZS',
    name: 'Uzbekistan Som',
    code: 'UZS',
  },
  VND: {
    id: '',
    symbol: '₫',
    name: 'Vietnamese Dong',
    code: 'VND',
  },
  VUV: {
    id: '',
    symbol: 'Vt',
    name: 'Vanuatu Vatu',
    code: 'VUV',
  },
  WST: {
    id: '',
    symbol: 'T',
    name: 'Samoan Tālā',
    code: 'WST',
  },
  XAF: {
    id: '',
    symbol: 'FCFA',
    name: 'CFA Franc BEAC',
    code: 'XAF',
  },
  XCD: {
    id: '',
    symbol: '$',
    name: 'East Caribbean Dollar',
    code: 'XCD',
  },
  XDR: {
    id: '',
    symbol: 'XDR',
    name: 'Special Drawing Rights',
    code: 'XDR',
  },
  XOF: {
    id: '',
    symbol: 'CFA',
    name: 'CFA Franc BCEAO',
    code: 'XOF',
  },
  XPF: {
    id: '',
    symbol: 'F',
    name: 'Cfp Franc',
    code: 'XPF',
  },
  YER: {
    id: '',
    symbol: 'YR',
    name: 'Yemeni Rial',
    code: 'YER',
  },
  ZAR: {
    id: '',
    symbol: 'R',
    name: 'South African Rand',
    code: 'ZAR',
  },
  ZWL: {
    id: '',
    symbol: 'ZWL$',
    name: 'Zimbabwean Dollar',
    code: 'ZWL',
  },
};

export const CurrenciesSelectOptions: OptionType[] = (
  Object.keys(CURRENCIES_META) as Array<keyof typeof CURRENCIES_META>
).map((currencyCode) => {
  const { name, code } = CURRENCIES_META[currencyCode];
  return { label: name, value: code };
});

export const CurrenciesSelectOptionsLabelCode: OptionType[] = (
  Object.keys(CURRENCIES_META) as Array<keyof typeof CURRENCIES_META>
).map((currencyCode) => {
  const { code } = CURRENCIES_META[currencyCode];
  return { label: code, value: code };
});
