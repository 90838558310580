import { createFileRoute } from '@tanstack/react-router';

import { z } from 'zod';

export const Route = createFileRoute('/_secured/agency-hub/clients/')({
  validateSearch: z.object({
    addNewClient: z.boolean().optional(),
    pageSize: z.number().optional(),
    pageIndex: z.number().optional(),
    orderingColumn: z.string().optional(),
    orderingDirection: z.boolean().optional(),
    search: z.string().optional(),
  }),
  loader: async ({ context }) => {
    await context.serviceLocator.getInstance('i18n', '@myn/clients');
  },
});
