import type { ReactNode } from 'react';

export type SlotSubscriber<E extends ReactNode = ReactNode> = (
  slotName: string,
  slotValue: E,
) => void;

export class SlotsManager {
  listeners: Map<string, Set<SlotSubscriber>> = new Map();
  registeredSlots: Map<string, Map<number, ReactNode>> = new Map();

  registerSlot(slotName: string, slotValue: ReactNode, priority = 5) {
    if (!this.registeredSlots.has(slotName)) {
      this.registeredSlots.set(slotName, new Map());
    }
    if (import.meta.env.DEV) {
      if (this.registeredSlots.get(slotName)?.has(priority)) {
        // eslint-disable-next-line no-console
        console.warn(
          `Slot "${slotName}" already has a value with the same priority ${priority}`,
        );
      }
    }
    this.registeredSlots.get(slotName)?.set(priority, slotValue);
    this.notifyListeners(slotName, this.getSlotValue(slotName));
    return () => {
      this.removeSlot(slotName, priority);
    };
  }

  removeSlot(slotName: string, priority: number) {
    const slots = this.registeredSlots.get(slotName);
    if (!slots) {
      return;
    }
    slots.delete(priority);
    if (slots.size === 0) {
      this.registeredSlots.delete(slotName);
    }
    this.notifyListeners(slotName, this.getSlotValue(slotName));
  }

  subscribe(slotName: string, listener: SlotSubscriber) {
    if (!this.listeners.has(slotName)) {
      this.listeners.set(slotName, new Set());
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.listeners.get(slotName)!.add(listener);
    return () => {
      this.listeners.get(slotName)?.delete(listener);
    };
  }

  notifyListeners(slotName: string, slotValue: ReactNode) {
    this.listeners
      .get(slotName)
      ?.forEach((listener) => listener(slotName, slotValue));
  }

  private getSlotValue(slotName: string) {
    const slots = this.registeredSlots.get(slotName);
    if (!slots) {
      return null;
    }
    const sortedSlots = Array.from(slots.entries()).sort(
      ([priorityA], [priorityB]) => priorityA - priorityB,
    );
    return sortedSlots[sortedSlots.length - 1][1];
  }
}
