import { ColumnDef } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import { useFlags } from '@mint-lib/flags';
import { useTranslation } from '@mint-lib/i18n';
import { enqueueSnackbar } from '@mint-lib/notifications';
import { useIsAgencyCompany } from '@mint-lib/profile-helpers';

import { useUserRole } from '../../api/queries/userRoles.js';
import { RolesMetaType, RoleType } from '../../api/roles/index.js';
import RegularTextCell from '../../atoms/RegularTextCell/RegularTextCell.jsx';
import UserCascadeCell from '../../atoms/UserCascadeCell/UserCascadeCell.jsx';
import { UsersAndItemId } from '../../atoms/UserCascadeCell/UserCascadeCell.types.js';
import useTableSorting from '../../hooks/useTableSorting/useTableSorting.jsx';
import PermissionsTable from '../../organisms/PermissionsTable/index.js';
import RoleModal from '../../organisms/RoleModal/index.jsx';
import ActionsCell from './ActionsCell/index.js';
import DateTimeCell from './DateTimeCell/index.js';

type Props = {
  rolesMeta: RolesMetaType | null;
  data: RoleType[];
  isReadonlyPermission: boolean;
  dataIsLoading: boolean;
  noResultsStatus: boolean;
};

const RoleTable = ({
  data,
  rolesMeta,
  isReadonlyPermission,
  dataIsLoading,
  noResultsStatus,
}: Props) => {
  const { t } = useTranslation('@myn/permissions');

  const ff = useFlags(['agencyRedesignRoleTab']);

  const isAgencyCompany = useIsAgencyCompany();

  useTableSorting();

  const { editRole, refetchRoles } = useUserRole();
  const [activeRole, setActiveRole] = useState<RoleType | null>(null);

  const handleSubmitUserModalChanges = (newData: Partial<RoleType>) =>
    editRole.mutateAsync(newData, {
      onSuccess: () => {
        setActiveRole(null);

        enqueueSnackbar({
          removable: true,
          variant: 'success',
          title: t('Success'),
          subTitle: t('The role has been updated successfully'),
        });

        refetchRoles();
      },
      onError: () => {
        enqueueSnackbar({
          removable: true,
          variant: 'error',
          title: t('Error'),
          subTitle: t(
            'An error occurred while updating the role. Please try again',
          ),
        });
      },
    });

  const columns: ColumnDef<RoleType>[] = useMemo(
    () => [
      {
        id: 'name',
        meta: { field: 'name' },
        accessorFn: (row) => row.name,
        cell: (info) => {
          return <RegularTextCell text={info.getValue() as RoleType['name']} />;
        },
        header: () => t('Role'),
      },
      {
        id: 'description',
        meta: { field: 'description' },
        accessorFn: (row) => row.description,
        cell: (info) => {
          return (
            <RegularTextCell
              text={(info.getValue() as RoleType['description']) || ''}
            />
          );
        },
        header: () => t('Role description'),
      },
      {
        accessorFn: (row) => ({
          users: row.usersDetails,
          id: row.id,
        }),
        id: 'users',
        cell: (info) => {
          return <UserCascadeCell {...(info.getValue() as UsersAndItemId)} />;
        },
        header: () => t('Users'),
        enableSorting: false,
      },
      {
        accessorFn: (row) => row?.editedAt,
        meta: { field: 'updated_at' },
        id: 'editedAt',
        cell: (info) => (
          <DateTimeCell editedAt={info.getValue() as RoleType['editedAt']} />
        ),
        header: () => t('Edited'),
      },
      {
        accessorFn: (row) => row,
        id: 'actions',
        cell: (info) => (
          <ActionsCell
            {...(info.getValue() as RoleType)}
            onEditClick={setActiveRole}
            isReadonlyPermission={isReadonlyPermission}
          />
        ),
        header: () => null,
        enableSorting: false,
      },
    ],
    [],
  );

  const initialState = useMemo(() => {
    return {
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],

      columnVisibility: {
        actions: !isAgencyCompany,
        createdAt: !(ff.agencyRedesignRoleTab.enabled && isAgencyCompany),
        editedAt: !(ff.agencyRedesignRoleTab.enabled && isAgencyCompany),
        description: ff.agencyRedesignRoleTab.enabled && isAgencyCompany,
      },
    };
  }, [isAgencyCompany]);

  return (
    <>
      <PermissionsTable<RoleType>
        data={data}
        columns={columns}
        initialState={initialState}
        dataIsLoading={dataIsLoading}
        noResultsStatus={noResultsStatus}
      />

      {!!activeRole && rolesMeta && (
        <RoleModal
          onClose={() => setActiveRole(null)}
          onCreate={handleSubmitUserModalChanges}
          onUpdate={handleSubmitUserModalChanges}
          rolesMeta={rolesMeta}
          initialValues={activeRole}
          isActive={!!activeRole}
          isReadonlyPermission={isReadonlyPermission}
        />
      )}
    </>
  );
};

export default RoleTable;
