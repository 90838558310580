import { TFunction } from '@mint-lib/i18n';
import * as Yup from 'yup';

export const emailRegExp =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/;

export const StringWithMaxLength = (
  length: number,
  t: TFunction,
): Yup.StringSchema<string | null | undefined> =>
  Yup.string()
    .trim()
    .max(length, t('Max length is {{length}} characters.', { length }))
    .nullable();

export const RequiredString = (
  t: TFunction,
): Yup.StringSchema<string | null | undefined> =>
  Yup.string().test(
    t('Success'),
    t('Required') as string,
    (value) => !!value && !!String(value).trim(),
  );

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    firstName: StringWithMaxLength(255, t).concat(RequiredString(t)),
    lastName: StringWithMaxLength(255, t).concat(RequiredString(t)),
    email: RequiredString(t)
      .matches(emailRegExp, t(`This email isn't correct`))
      .nullable(),
    mobileNumber: StringWithMaxLength(255, t),
    officeNumber: StringWithMaxLength(255, t),
    role: Yup.number().required(t('Required')).nullable(),
    clients: Yup.array(Yup.number()).nullable(),
  });
