import { useEffect } from 'react';

import { useNavigate } from '@mint/routing';
import { useAtom } from 'jotai';

import { DEFAULT_LIMIT } from '../../store/atoms/TablePagination/constants.js';
import { tablePaginationStore } from '../../store/atoms/TablePagination/tablePaginationStore.js';
import { TablePaginationType } from '../../store/atoms/TablePagination/tablePaginationStore.types.js';
import useConditionalSearch from '../useConditionalSearch/useConditionalSearch.js';

const useTablePagination = (initialPagination?: TablePaginationType) => {
  const navigate = useNavigate();

  const searchParams = useConditionalSearch();

  const [pagination, setPagination] = useAtom(tablePaginationStore);

  const setCurrentOffset = (currentPage: number) => {
    const offset = currentPage * pagination.limit;

    navigate({
      search: (prev) => ({ ...prev, offset }),
    });
  };

  const setCurrentPage = (currentPage: number) => {
    setPagination((state) => {
      state.currentPage = currentPage;
    });

    setCurrentOffset(currentPage);
  };

  const setTotalItems = (totalItems: number) => {
    setPagination((state) => {
      state.totalItems = totalItems;
    });
  };

  const setLimit = (limit: number) => {
    navigate({
      search: (prev) => {
        return { ...prev, limit, offset: 0 };
      },
    }).then(() => {
      setCurrentPage(0);
    });
  };

  const resetPagination = (defaultPagination: TablePaginationType) => {
    setPagination(defaultPagination);
  };

  useEffect(() => {
    if (initialPagination) {
      // initial current page for table pagination component
      // if user provide offset in route search params
      const currentPage =
        searchParams.offset && searchParams.limit
          ? Math.floor(searchParams.offset / searchParams.limit)
          : initialPagination.currentPage;

      const limit =
        searchParams.limit && searchParams.limit > 0
          ? searchParams.limit
          : initialPagination.limit;

      const offset =
        searchParams.offset && searchParams.offset > 0
          ? searchParams.offset
          : initialPagination.offset;

      resetPagination({
        ...initialPagination,
        limit,
        offset,
        currentPage,
      });
    }
  }, [JSON.stringify(initialPagination)]);

  return {
    limit: searchParams.limit || DEFAULT_LIMIT,
    offset: searchParams.offset,
    setLimit,
    currentPage: pagination.currentPage,
    setCurrentPage,
    totalItems: pagination.totalItems,
    setTotalItems,
    setPagination,
  };
};

export default useTablePagination;
