import React, { useContext, useMemo } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import type { ComponentContext } from './rumComponentContext.js';
import { RumComponentContext } from './rumComponentContext.js';

function roundToNearestTen(num: number) {
  return Math.round(num / 10) * 10;
}

function getZoomPercentage() {
  const winRatioPercentage = (window.outerHeight / window.innerHeight) * 100;
  return roundToNearestTen(winRatioPercentage);
}

const staticContext = {
  zoomPercentage: getZoomPercentage(),
};

/**
 * Context Provider to add a new component to the action breadcrumbs. Useful for class Components.
 */
export const RumComponentContextProvider: React.FunctionComponent<{
  componentArea: string;
  customAttributes?: object;
  children: React.ReactNode;
}> = ({ componentArea, customAttributes, children }) => {
  const parentContext = useContext(RumComponentContext);

  const newContext = useMemo<ComponentContext>(
    () => ({
      componentArea,
      customAttributes: {
        ...parentContext.customAttributes,
        ...customAttributes,
      },
      componentBreadCrumbs: `${parentContext.componentBreadCrumbs}.${componentArea}`,
      ...staticContext,
    }),
    [
      componentArea,
      parentContext.componentBreadCrumbs,
      parentContext.customAttributes,
      customAttributes,
    ],
  );

  datadogRum.setGlobalContext(newContext);
  datadogLogs.setGlobalContext(newContext);

  return (
    <RumComponentContext.Provider value={newContext}>
      {children}
    </RumComponentContext.Provider>
  );
};
