// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { InternalAxiosRequestConfig } from 'axios';

import {
  objectKeysToSnakeCase,
  objectKeysToSnakeCase2,
} from '../utils/objectKeysTo.js';

export function requestDataToSnakeCase(request: InternalAxiosRequestConfig) {
  if (request.headers?.['Content-Type'] === 'application/json') {
    return {
      ...request,
      data: objectKeysToSnakeCase(request.data),
    };
  }
  return request;
}

export function requestDataToSnakeCase2(request: InternalAxiosRequestConfig) {
  if (request.headers?.['Content-Type'] === 'application/json') {
    return {
      ...request,
      data: objectKeysToSnakeCase2(request.data),
    };
  }
  return request;
}
