import styled from '@mint-lib/styled';
import { TooltipProps as TooltipPropsM } from '@mui/material/Tooltip';

import Box from '../../components/Box/Box.jsx';
import Checkbox from '../../components/Checkbox/Checkbox.jsx';
import MenuItem from '../../components/MenuItem/MenuItem.jsx';
import Tooltip, { TooltipProps } from '../../components/Tooltip/Tooltip.jsx';
import Truncation from '../../components/Truncation/Truncation.jsx';
import Typography from '../../components/Typography/Typography.jsx';
import { OptionType, ValueTypes } from '../../types/SelectTypes.js';

type Props<VT extends ValueTypes> = {
  isMultiple?: boolean;
  selectValue: VT;
  option: OptionType;
  value: string | number;
  truncatedOptions?: boolean;
  width?: string;
  OptionsTooltipProps?: Partial<TooltipPropsM>;
  limit?: number;
  tooltipText?: string;
  tooltipPlacement?: TooltipProps['placement'];
};

const OptionWithCheckbox = <VT extends ValueTypes>({
  selectValue,
  option,
  isMultiple,
  truncatedOptions = true,
  OptionsTooltipProps,
  limit,
  tooltipText,
  tooltipPlacement,
  ...rest
}: Props<VT>) => {
  const selected = Array.isArray(selectValue)
    ? selectValue.indexOf(option.value) > -1
    : option.value === selectValue;

  const getOptionDisabled = () => {
    if (
      limit !== undefined &&
      Array.isArray(selectValue) &&
      !selected &&
      selectValue.length >= limit
    ) {
      return true;
    }
    return false;
  };

  const renderOptionComponent = (
    optionLabel?: string | number,
    isTruncated?: boolean,
  ) => {
    if (isTruncated) {
      return (
        <Truncation
          variant={selected && isMultiple ? 'bodyShort02' : 'bodyShort01'}
          tooltipTitle={optionLabel}
          TooltipProps={OptionsTooltipProps}
          color={selected && isMultiple ? 'tertiary.dark' : 'inherit'}
        >
          {optionLabel}
        </Truncation>
      );
    }
    return (
      <Typography
        variant={selected && isMultiple ? 'bodyShort02' : 'bodyShort01'}
        color={selected && isMultiple ? 'tertiary.dark' : 'inherit'}
      >
        {optionLabel}
      </Typography>
    );
  };

  return getOptionDisabled() ? (
    <Tooltip
      title={tooltipText}
      className="selectTooltip"
      placement={tooltipPlacement}
    >
      <div>
        <StyledMenuItem
          key={option.value}
          selected={selected}
          $isMultiple={isMultiple}
          disabled={getOptionDisabled()}
          {...rest}
        >
          {option.startAdornment ? (
            <Box sx={{ mr: '8px', height: '16px' }}>
              {option.startAdornment}
            </Box>
          ) : null}
          {isMultiple && (
            <StyledCheckbox
              disabled={getOptionDisabled()}
              checked={
                Array.isArray(selectValue) &&
                selectValue.indexOf(option.value) > -1
              }
              sx={{ marginRight: '8px' }}
            />
          )}
          {renderOptionComponent(option.label, truncatedOptions)}
          <Box sx={{ ml: '4px' }}>{option.endAdornment}</Box>
        </StyledMenuItem>
      </div>
    </Tooltip>
  ) : (
    <StyledMenuItem
      key={option.value}
      selected={selected}
      $isMultiple={isMultiple}
      {...rest}
    >
      {option.startAdornment ? (
        <Box sx={{ mr: '8px', height: '16px' }}>{option.startAdornment}</Box>
      ) : null}
      {isMultiple && (
        <StyledCheckbox
          checked={
            Array.isArray(selectValue) && selectValue.indexOf(option.value) > -1
          }
          sx={{ marginRight: '8px' }}
        />
      )}
      {renderOptionComponent(option.label, truncatedOptions)}
      <Box sx={{ ml: '4px' }}>{option.endAdornment}</Box>
    </StyledMenuItem>
  );
};

export default OptionWithCheckbox;

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== '$isMultiple',
})<{
  $isMultiple?: boolean;
}>(({ $isMultiple, selected, theme }) => ({
  clear: 'both',
  display: 'flex',
  alignItems: 'center',
  padding: $isMultiple ? '10px 12px' : '12px',
  color: selected ? theme.palette.tertiary.dark : theme.palette.text.primary,
  '.MuiSvgIcon-root': {
    color: selected && theme.palette.tertiary.dark,
  },
  '&:focus': {
    backgroundColor: theme.palette.transparent.main,
  },
}));
const StyledCheckbox = styled(Checkbox)({
  '&:focus-within': {
    outline: 'none',
  },
});
