import React, { forwardRef } from 'react';

import TypographyM, {
  TypographyProps as TypographyPropsM,
} from '@mui/material/Typography';

import type { IconsList as Icons } from '../../assets/asyncIcons.jsx';
import SVGIcon from '../Icon/Icon.jsx';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bodyShort01: true;
    bodyShort02: true;
    bodyLong01: true;
    bodyLong02: true;
    caption01: true;
    caption02: true;
    overline01: true;
    label01: true;
    helperText01: true;
  }
}

export interface TypographyProps extends TypographyPropsM {
  /**
   * The typography style to apply.
   * @uxpinpropname  Text
   */
  children?: React.ReactNode;
  /**
   * The typography style to apply.
   */
  variant?: TypographyPropsM['variant'];
  /**
   * Alignment of text.
   */
  align?: TypographyPropsM['align'];
  /**
   * Color of text.
   */
  color?: TypographyPropsM['color'];
  /**
   * Color of the Background.
   */
  bgcolor?: TypographyPropsM['color'];
  /**
   *  Controls if typography is inline or block level.
   */
  display?: 'initial' | 'block' | 'inline';
  /**
   * If `true`, the text will have a bottom margin.
   */
  gutterBottom?: boolean;
  /**
   * If `true`, the text will have a bottom margin.
   */
  paragraph?: boolean;
  /**
   * If `true`, the text will not wrap, but instead will truncate with an ellipsis.
   */
  noWrap?: boolean;
  /**
   * Map typography to another html tag.
   * @uxpinignoreprop
   */
  component?: React.ElementType;
  /**
   * We are empirically mapping the variant property to a range of different DOM element types.
   * For instance, subtitle1 to `<h6>`.
   * If you wish to change that mapping, you can provide your own.
   * Alternatively, you can use the `component` property.
   * The default mapping is the following:
   * @uxpinignoreprop
   */
  headlineMapping?: Record<string, string>;
  /**
   * Override or extend the styles applied to the component.
   * @uxpinignoreprop
   */
  classes?: TypographyPropsM['classes'];
  /**
   * All Padding.
   * In pixels
   */
  padding?: string;
  /**
   * Top Padding.
   * In pixels
   */
  paddingTop?: string;
  /**
   * Right Padding.
   * In pixels
   */
  paddingRight?: string;
  /**
   * Bottom Padding.
   * In pixels
   */
  paddingBottom?: string;
  /**
   * Left Padding.
   * In pixels
   */
  paddingLeft?: string;
  /**
   * All Margin.
   * In pixels
   */
  margin?: string;
  /**
   * Top margin.
   * In pixels
   */
  marginTop?: string;
  /**
   * Right margin.
   * In pixels
   */
  marginRight?: string;
  /**
   * Bottom margin.
   * In pixels
   */
  marginBottom?: string;
  /**
   * Left margin.
   * In pixels
   */
  marginLeft?: string;
  /**
   * Border margin.
   * In pixels
   */
  border?: number;
  /**
   * Border Top.
   * In pixels
   */
  borderTop?: number;
  /**
   * Border margin.
   * In pixels
   */
  borderRight?: number;
  /**
   * Border margin.
   * In pixels
   */
  borderBottom?: number;
  /**
   * Border margin.
   * In pixels
   */
  borderLeft?: number;
  borderColor?: TypographyPropsM['color'];
  borderRadius?: string;

  /**
   * Icon element
   */
  icon?: Icons;
}
const Typography = forwardRef(
  (
    { icon, ...props }: TypographyProps,
    ref: React.ForwardedRef<HTMLSpanElement>,
  ) => {
    return (
      <TypographyM ref={ref} {...props}>
        {icon && <SVGIcon component={icon} />}
        {props.children}
      </TypographyM>
    );
  },
);
export default Typography;
