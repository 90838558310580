import { OptionType, ValueTypes } from '../types/SelectTypes.js';

export const filterOption = ({ label }: OptionType, search: string) => {
  if (label === '') {
    return true;
  }
  return label
    .toLocaleLowerCase()
    .includes(search.toString().trim().toLocaleLowerCase());
};

export const checkForEmptyArray = <VT extends ValueTypes>(value: VT) => {
  if (Array.isArray(value)) {
    return value.filter((item) => item).length;
  }
  return value !== '' && value !== undefined;
};

export const findLabelInArrayIntersection = <VT extends ValueTypes>(
  options: OptionType[],
  value: VT,
) => {
  if (Array.isArray(value)) {
    return options
      .filter((item) => value.includes(item.value))
      .map((item) => item.label)
      .join(', ');
  }
  return options.find((item) => item.value === value)?.label;
};
