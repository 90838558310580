import styled, { Theme } from '@mint-lib/styled';
import { SxProps } from '@mui/material/styles';

import IconButton from '../IconButton/IconButton.jsx';
import Skeleton from '../Skeleton/Skeleton.jsx';
import TableBodyCell, {
  TableBodyCellProps,
} from '../TableBodyCell/TableBodyCell.jsx';

export interface TableCellExpanderProps {
  expanded?: boolean;
  onExpand?: () => void;
  sx?: SxProps<Theme>;
  display?: boolean;
  bgColor?: TableBodyCellProps['bgColor'];
  /**
   * if "true" - shows skeleton
   */
  isLoading?: boolean;
}
const TableCellExpander = ({
  expanded,
  onExpand,
  sx,
  isLoading,
  bgColor = 'default',
  display = true,
}: TableCellExpanderProps) => {
  return (
    <StyledTableCell sx={sx} bgColor={bgColor}>
      {isLoading ? (
        <Skeleton
          sx={{
            width: '16px',
            height: '16px',
          }}
          variant="rounded"
        />
      ) : display ? (
        <IconButton
          aria-label="expand-button"
          color="secondary"
          variant="icon"
          size="medium"
          icon={expanded ? 'ChevronDown' : 'ChevronRight'}
          onClick={onExpand}
          sx={{
            margin: '0 auto',
          }}
        />
      ) : null}
    </StyledTableCell>
  );
};

export default TableCellExpander;

const StyledTableCell = styled(TableBodyCell)({
  width: '40px',
  height: '40px',
  justifyContent: 'center',
  padding: 0,
});
