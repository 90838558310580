import api, { Tokens } from '@mint-lib/api';

import { WORKSPACES } from '../endpointConstants.js';

export const selectWorkspace = async (
  setTokens: (tokens: Tokens) => void,
  companyId?: number | string,
) => {
  const tokens = await api.post<Tokens>(WORKSPACES, {
    company: companyId,
  });

  if (tokens) {
    setTokens(tokens.data as Tokens);
  }
};
