import { createFileRoute } from '@tanstack/react-router';

import { z } from 'zod';

export const Route = createFileRoute('/_secured/campaigns/')({
  validateSearch: z.object({
    from: z.string().optional(),
    to: z.string().optional(),
    search: z.string().optional(),
    page: z.coerce.number().optional(),
    size: z.coerce.number().optional(),
    clients: z.array(z.coerce.number()).optional(),
  }),
  beforeLoad({ context }) {
    return context;
  },
});
