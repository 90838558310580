import { createFileRoute } from '@tanstack/react-router';

import { z } from 'zod';

export const Route = createFileRoute(
  '/_secured/company-profile/import-hub/$ingestionType/imports/$dataFileId/validation',
)({
  validateSearch: z.object({
    onlyErrors: z.boolean().catch(false),
    itemsPerPage: z.number().catch(50),
  }),
});
