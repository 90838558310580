export interface FlagsmithProviderProps {
  envID: string;
  hasIdentify: boolean;
  hasCompanyTraits?: boolean;
  injectDatadogRum?: boolean;
  options?: Record<string, unknown>;
}

export const FlagsmithProviders = {
  '@mint/arm': {
    envID: import.meta.env.VITE_ARM_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
    options: {},
  },
  '@mint/app': {
    envID: import.meta.env.VITE_APP_FF,
    hasIdentify: false,
    injectDatadogRum: true,
    options: {},
  },
  '@myn/guest': {
    envID: import.meta.env.VITE_GUEST_FF,
    hasIdentify: false,
  },
  '@myn/secured': {
    envID: import.meta.env.VITE_SECURED_FF,
    hasIdentify: true,
  },
  '@myn/campaigns': {
    envID: import.meta.env.VITE_CAMPAIGNS_FF,
    hasIdentify: true,
  },
  '@myn/notifications': {
    envID: import.meta.env.VITE_NOTIFICATIONS_FF,
    hasIdentify: true,
  },
  '@myn/ai-hub': {
    envID: import.meta.env.VITE_AI_HUB_FF,
    hasIdentify: true,
  },
  '@myn/company': {
    envID: import.meta.env.VITE_COMPANY_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/strategy_v2': {
    envID: import.meta.env.VITE_STRATEGY_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/dv360': {
    envID: import.meta.env.VITE_DV360_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/facebook': {
    envID: import.meta.env.VITE_FACEBOOK_FF,
    hasIdentify: true,
  },
  '@myn/flowchart': {
    envID: import.meta.env.VITE_FLOWCHART_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/user-profile': {
    envID: import.meta.env.VITE_USER_PROFILE_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/planning': {
    envID: import.meta.env.VITE_PLANNING_FF,
    hasIdentify: true,
  },
  '@myn/integrations_v2': {
    envID: import.meta.env.VITE_INTEGRATIONS_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/trafficking': {
    envID: import.meta.env.VITE_TRAFFICKING_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/trafficking-ga4': {
    envID: import.meta.env.VITE_TRAFFICKING_GA4_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/pivot': {
    envID: import.meta.env.VITE_PIVOT_FF,
    hasIdentify: true,
  },
  '@myn/logs': {
    envID: import.meta.env.VITE_LOGS_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/brands': {
    envID: import.meta.env.VITE_BRANDS_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/import-hub': {
    envID: import.meta.env.VITE_IMPORT_HUB_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/clients': {
    envID: import.meta.env.VITE_CLIENTS_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/campaign-hub': {
    envID: import.meta.env.VITE_CAMPAIGN_HUB_FF,
    hasIdentify: true,
  },
  '@myn/naming-convention': {
    envID: import.meta.env.VITE_NAMING_CONVENTION_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/currencies': {
    envID: import.meta.env.VITE_CURRENCIES_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/permissions': {
    envID: import.meta.env.VITE_PERMISSIONS_FF,
    hasIdentify: true,
  },
  '@myn/advertisers': {
    envID: import.meta.env.VITE_ADVERTISERS_FF,
    hasIdentify: true,
  },
  '@mint-admin/auth-login': {
    envID: import.meta.env.VITE_AUTH_LOGIN_FF,
    hasIdentify: false,
  },
  '@myn/zemanta': {
    envID: import.meta.env.VITE_ZEMANTA_FF,
    hasIdentify: true,
  },
  '@myn/search': {
    envID: import.meta.env.VITE_GOOGLE_ADS_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/video-adwords': {
    envID: import.meta.env.VITE_GOOGLE_ADS_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/mobile-apps-adwords': {
    envID: import.meta.env.VITE_GOOGLE_ADS_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/display-adwords': {
    envID: import.meta.env.VITE_GOOGLE_ADS_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/ghost': {
    envID: import.meta.env.VITE_GHOST_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/search-microsoft': {
    envID: import.meta.env.VITE_DISPLAY_MS_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/myvideo': {
    envID: import.meta.env.VITE_MYVIDEO_FF,
    hasIdentify: true,
  },
  '@myn/display-microsoft': {
    envID: import.meta.env.VITE_DISPLAY_MS_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/native-microsoft': {
    envID: import.meta.env.VITE_DISPLAY_MS_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/omni-connector': {
    envID: import.meta.env.VITE_OMNI_CONNECTOR_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/approval': {
    envID: import.meta.env.VITE_APPROVAL_FF,
    hasIdentify: true,
    hasCompanyTraits: true,
  },
  '@myn/dashboards-builder': {
    envID: import.meta.env.VITE_DASHBOARDS_BUILDER_FF,
    hasIdentify: true,
  },
} as const;
