/* eslint-disable no-underscore-dangle */

import { COOKIES_CHOICES_KEY } from '../constants.js';
import type { CustomWindow } from '../types.js';
import sortObjectByKeys from './sortObjectByKeys.js';

type VendorsType = 'mixpanel' | 'Datadog';

const vendors = ['mixpanel', 'Datadog'];

export const vendorsCookiesHelper = (): Record<
  VendorsType,
  boolean | undefined
> => {
  const getCurrentCookies = () =>
    sortObjectByKeys(
      vendors.reduce((result, vendor) => {
        return {
          ...result,
          [vendor]:
            (window as never as CustomWindow)?.axeptioSDK?.hasAcceptedVendor?.(
              vendor,
            ) === true,
        };
      }, {}),
    );

  // to prevent reloading we should track accepted cookies
  // decided to use logs, because it can handel changes on guest page
  // its init on first load then we'll update storage inside consent:saved
  // additional check for already existing key will prevent cookiesChoices reset on reload
  if (!localStorage.getItem(COOKIES_CHOICES_KEY)) {
    localStorage.setItem(
      COOKIES_CHOICES_KEY,
      JSON.stringify(getCurrentCookies()),
    );
  }

  const cachedChoices: Record<string, boolean> = JSON.parse(
    localStorage.getItem(COOKIES_CHOICES_KEY) || '',
  );

  let choices: Record<string, boolean> = { ...getCurrentCookies() };

  // triggers _axcb callback on event
  // cookies:consent when the user updates consent on cookies
  void 0 === (window as never as CustomWindow)?._axcb &&
    ((window as never as CustomWindow)._axcb = []);

  (window as never as CustomWindow)._axcb.push((axeptio: any) => {
    axeptio.on(
      'cookies:consent',
      (userChoices: { choices: Record<string, boolean> }) => {
        choices = { ...sortObjectByKeys(userChoices.choices) };
      },
    );

    // see docs
    // https://developers.axeptio.eu/site-integration/special-cases-spa-or-react
    // we check for changes in accepted cookies and if something changed
    // we should refresh the page; it will help to reset libs access to user events
    axeptio.on('consent:saved', () => {
      if (
        Object.keys(cachedChoices).length &&
        JSON.stringify(cachedChoices) !== JSON.stringify(choices)
      ) {
        localStorage.setItem(
          COOKIES_CHOICES_KEY,
          JSON.stringify(getCurrentCookies()),
        );

        window.location.reload();
      }
    });
  });

  return choices;
};
