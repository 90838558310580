import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_secured/campaign/$campaignId/settings/dv360',
)({
  async beforeLoad({ context }) {
    if (!context.hasPermission('financialDataFeature:view')) {
      throw redirect({
        to: '/campaigns/',
      });
    }
    return context;
  },
});
