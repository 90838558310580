import { z } from 'zod';

const ONLINE_TYPE = 'online';
const OFFLINE_TYPE = 'offline';
const BOTH = 'both';

const CurrencySchema = z.object({
  value: z.string(),
  displayName: z.string(),
  sign: z.string(),
});

const GoalSchema = z.object({
  id: z.number(),
  metric: z.coerce.number(),
  metricLabel: z.string(),
  isPrimary: z.boolean(),
  value: z.number(),
  goalValue: z.coerce.number(),
  valueType: z.string(),
  justAdded: z.optional(z.boolean()),
  objective: z.optional(z.enum(['awareness', 'all', 'performance'])),
  isNew: z.optional(z.boolean()),
});

const TileSchema = z.object({
  id: z.string().or(z.number()),
});

const CampaignsSchema = z.object({
  displayCampaign: z.array(TileSchema).optional(),
  ghostCampaign: z.array(TileSchema).optional(),
  searchCampaign: z.array(TileSchema).optional(),
  socialCampaign: z.array(TileSchema).optional(),
  videoCampaign: z.array(TileSchema).optional(),
  emailCampaign: z.array(TileSchema).optional(),
});

const MediaSchema = z.object({
  id: z.number(),
  name: z.string(),
  file: z.string(),
  uploadedAt: z.string(),
  type: z.enum(['Presentation', 'Video', 'Banner']),
});

export const CampaignSchema = z.object({
  id: z.coerce.number(),
  name: z.string(),
  adServer: z.string(),
  conceptType: z.optional(z.enum([ONLINE_TYPE, OFFLINE_TYPE, BOTH])),
  published: z.boolean(),
  description: z.string(),
  currency: CurrencySchema,
  budget: z.string(),
  allocatedBudget: z.coerce.number(),
  dateFrom: z.string().or(z.null()),
  dateTo: z.string().or(z.null()),
  budgetSpent: z.coerce.number(),
  status: z.string(),
  goals: z.array(GoalSchema),
  campaigns: CampaignsSchema,
  media: z.array(MediaSchema).optional(),
});

export type CampaignType = z.infer<typeof CampaignSchema>;
