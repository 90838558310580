import { Palette } from '@mui/material/styles';

import typography from './typography.js';

const listSubHeaderOverrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        ...typography.h6,
        color: palette.text.primary,
        paddingTop: 11,
        paddingBottom: 11,
      },
    },
  };
};

export default listSubHeaderOverrides;
