import { inputLabelClasses } from '@mui/material/InputLabel';
import { Palette } from '@mui/material/styles';

import typography from './typography.js';

const inputLabelOverrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        color: palette.text.secondary,
        ...typography.label01,
        height: 'initial',
        border: 'none',
        [`&.${inputLabelClasses.error}`]: {
          color: palette.text.secondary,
        },
      },
    },
  };
};

export default inputLabelOverrides;
