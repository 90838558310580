import React, { forwardRef } from 'react';

import styled from '@mint-lib/styled';
import AvatarM, { AvatarProps as AvatarPropsM } from '@mui/material/Avatar';

import { AvatarSize } from './Avatar.constants.js';
import { getTypography, getUserInitials } from './Avatar.utils.js';

export interface AvatarProps extends AvatarPropsM {
  /**
   * Letters for initial icons
   */

  children?: React.ReactNode;
  color?: AvatarPropsM['color'];
  /**
   * The image URL source.
   */
  src?: string;
  /**
   * Used in combination with `src` or `srcSet` to
   * provide an alt attribute for the rendered `img` element.
   */

  alt?: string;
  /**
   * The `srcSet` attribute for the `img` element.
   */

  srcSet?: string;
  /**
   * Override or extend the styles applied to the component.
   */

  classes?: AvatarPropsM['classes'];
  /**
   * Attributes applied to the `img` element if the component
   * is used to display an image.
   */

  imgProps?: AvatarPropsM['imgProps'];
  /**
   * The `sizes` attribute for the `img` element.
   */

  sizes?: string;
  /**
   * The shape of the avatar.

   */
  variant?: AvatarPropsM['variant'];
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx?: AvatarPropsM['sx'];
  /**
   * background color of Avatar.
   */
  bgColor?: 'secondary' | 'primary';
  /**
   * size of Avatar.
   */
  avatarSize?: AvatarSize;
  /**
   * first Name
   */
  firstName?: string;
  /**
   * last Name
   */
  lastName?: string;
  /**
   * email
   */
  email?: string;
  /**
   * The hover state of the avatar is optional.
   */
  hover?: boolean;
  /**
   * The disabled state of the avatar is optional.
   */
  disabled?: boolean;
}

const Avatar = forwardRef(
  (
    {
      avatarSize = AvatarSize.large,
      bgColor = 'primary',
      variant = 'circular',
      firstName,
      lastName,
      src,
      email,
      children,
      ...props
    }: AvatarProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const userInitials = (
      getUserInitials(firstName, lastName) || email?.slice(0, 2)
    )?.toUpperCase();

    const content = typeof children === 'string' ? children : userInitials;

    return (
      <StyledAvatar
        avatarSize={avatarSize}
        bgColor={bgColor}
        src={src}
        alt={content}
        {...props}
        ref={ref}
        withUserInitials={userInitials ? true : false}
        data-dd-action-name="Avatar"
      >
        {content}
      </StyledAvatar>
    );
  },
);

export default Avatar;

const StyledAvatar = styled(AvatarM, {
  shouldForwardProp: (prop) =>
    prop !== 'avatarSize' && prop !== 'bgColor' && prop !== 'withUserInitials',
})<{
  avatarSize: AvatarSize;
  bgColor?: AvatarProps['bgColor'];
  src?: AvatarProps['src'];
  hover?: AvatarProps['hover'];
  disabled?: AvatarProps['disabled'];
  withUserInitials?: boolean;
}>(
  ({ theme, avatarSize, bgColor, src, hover, disabled, withUserInitials }) => ({
    width: `${avatarSize}rem`,
    height: `${avatarSize}rem`,
    ...(withUserInitials
      ? {
          color: disabled
            ? theme.palette.text.disabled
            : theme.palette.text.secondary,
        }
      : {
          color: disabled
            ? theme.palette.greyscale.grey20
            : theme.palette.ui.tertiary,
        }),
    border: disabled
      ? `1px solid ${theme.palette.border.disabled}`
      : `1px solid ${theme.palette.border.primary}`,
    backgroundColor:
      bgColor === 'primary'
        ? theme.palette.ui.primary
        : theme.palette.ui.secondary,
    ...getTypography(avatarSize, theme),
    position: 'relative',
    '&:hover': hover && {
      cursor: 'pointer',
      backgroundColor: theme.palette.ui.secondary,
      '&::after': {
        content: '""',
        display: 'block',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: src
          ? theme.palette.ui.hoverSelected
          : theme.palette.ui.hover,
        opacity: src ? 0 : 1,
        '&:hover': hover &&
          src && {
            opacity: 0.2,
          },
      },
    },
  }),
);
