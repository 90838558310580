import { sliderClasses } from '@mui/material/Slider';
import { Palette } from '@mui/material/styles';

const override = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        [`&.${sliderClasses.dragging}`]: {
          [`
            & .${sliderClasses.thumb}, 
            & .${sliderClasses.track}, 
            & .${sliderClasses.thumb}::before
        `]: {
            backgroundColor: palette.primary.main,
            boxShadow: 'none',
          },
        },
        [`&.${sliderClasses.disabled}`]: {
          [`
            & .${sliderClasses.thumb}, 
            & .${sliderClasses.track}, 
            & .${sliderClasses.thumb}::before
        `]: {
            backgroundColor: palette.action.disabled01,
          },
          [`
        & .${sliderClasses.mark},
        `]: {
            backgroundColor: palette.ui.secondary,
          },
        },
        [`& .${sliderClasses.thumb}`]: {
          height: '16px',
          transition: 'width 0.2s ease-in-out, height 0.2s ease-in-out',
          width: '16px',

          '&:hover, &:active, &.Mui-focusVisible': {
            boxShadow: 'none',
            height: '20px',
            outline: 'initial',
            width: '20px',
          },
        },
        [`& .${sliderClasses.thumb}, & .${sliderClasses.track}`]: {
          backgroundColor: palette.secondary.main,
          zIndex: 1,
        },
        [`
        &:hover:not(.${sliderClasses.dragging}) .${sliderClasses.thumb}, 
        &:hover:not(.${sliderClasses.dragging}) .${sliderClasses.track}
      `]: {
          backgroundColor: palette.secondary.hover,
          boxShadow: 'none',
        },
        [`& .${sliderClasses.valueLabel}`]: {
          backgroundColor: palette.secondary.main,
          color: palette.text.inverse,
          borderRadius: '4px',
          top: '-5px',
        },
        [`& .${sliderClasses.mark}`]: {
          backgroundColor: palette.ui.inverse01,
        },
        [`& .${sliderClasses.rail}`]: {
          backgroundColor: palette.ui.tertiary,
        },
      },
    },
  };
};

export default override;
