import { useRouterState } from '@mint/routing';

export enum RouteTabs {
  Role = 'role',
  User = 'user',
}

const useTab = () => {
  const tab = useRouterState({
    select: (state) =>
      state.matches
        .map((match) => {
          switch (match.routeId) {
            case '/_secured/company-profile/users-management/role/':
            case '/_secured/agency-hub/users-management/role/':
              return RouteTabs.Role;
            case '/_secured/company-profile/users-management/user/':
            case '/_secured/agency-hub/users-management/user/':
              return RouteTabs.User;
            default:
              return;
          }
        })
        .filter(Boolean)
        .at(0),
  });

  return tab;
};

export default useTab;
