import { TFunction } from '@mint-lib/i18n';
import * as Yup from 'yup';

import StringWithMaxLength from '../../utils/stringWithMaxLengthValidation.js';

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    name: StringWithMaxLength(t, 255).required(t('Required')),
    description: StringWithMaxLength(t, 255),
  });
