import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_secured/company-profile/user-activities/',
)({
  beforeLoad: ({ params }) => {
    throw redirect({
      to: '/company-profile/user-activities/user-view',
      replace: true,
    });
  },
});
