import { Row } from '@tanstack/react-table';

import { UserType } from '../../api/users/users.types.js';

export const userCellSort = (a: Row<UserType>, b: Row<UserType>) => {
  const firstFullName = `${a.original.firstName}${a.original.lastName}`;
  const secondFullName = `${b.original.firstName}${b.original.lastName}`;

  return firstFullName.toLocaleLowerCase() > secondFullName.toLocaleLowerCase()
    ? 1
    : -1;
};

export const getFullName = (user: Pick<UserType, 'firstName' | 'lastName'>) =>
  `${user.firstName} ${user.lastName}`.trim();
