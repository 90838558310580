import {
  ProfileService,
  type AmbiguousUserProfile,
} from '@mint-lib/profile-helpers';

import type { MintServiceLocatorAbstractFactoryContext } from '../types.js';

function toName(name: string, args: string[]) {
  return `${name}:${JSON.stringify(args)
    .replace('[', '')
    .replace(']', '')
    .replace(/"/g, '')}`;
}

export async function profileFactory(
  ctx: MintServiceLocatorAbstractFactoryContext,
) {
  const auth = await ctx.getInstance('auth');
  const profileService = new ProfileService(auth);
  async function refreshProfileData() {
    if (auth.isLoggedIn()) {
      await ctx.invalidate(
        // @ts-expect-error TS2345
        toName('request', ['authentication/v1/user/profile/']),
      );
      const profileData = await ctx.getInstance(
        'request',
        'authentication/v1/user/profile/',
      );
      profileService.setProfileData(profileData as AmbiguousUserProfile);
    } else {
      profileService.setProfileData(null);
    }
  }
  await refreshProfileData();
  ctx.on('auth', 'auth', refreshProfileData);
  ctx.on('auth', 'logout', () => {
    return Promise.all([
      // @ts-expect-error TS2345
      ctx.invalidate(toName('request', ['authentication/v1/user/profile/'])),
      // profileService.setProfileData(null);
      ctx.invalidate(),
    ]);
  });
  ctx.on('profile', 'refresh', refreshProfileData);
  ctx.addEffect(
    profileService
      .getEventEmitter()
      .addChannel('profile', 'update', ctx.eventBus),
  );
  return profileService;
}
