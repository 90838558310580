import { Palette } from '@mui/material/styles';
import { switchClasses } from '@mui/material/Switch';

const override = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        width: '3rem',
        height: '1.5rem',
        padding: 0,
        '&:focus-visible, &:focus-within': {
          outline: `2px solid ${palette.action.focusBorder}`,
          borderRadius: 12,
        },
      },
      switchBase: {
        padding: 3,
        color: palette.ui.tertiary,
        [`&.${switchClasses.checked}`]: {
          color: palette.icon.inverse,
          transform: 'translateX(1.5rem)',
        },
        [`&.${switchClasses.checked}+.${switchClasses.track}`]: {
          backgroundColor: palette.primary.main,
          opacity: 1,
        },
        [`&.${switchClasses.disabled}`]: {
          color: palette.action.disabled01,
        },
        [`&.${switchClasses.disabled}+.${switchClasses.track}`]: {
          backgroundColor: palette.action.disabledBackground,
          opacity: 1,
        },
        [`&.${switchClasses.checked}.${switchClasses.disabled}`]: {
          color: palette.action.disabled01,
        },
      },
      track: {
        backgroundColor: palette.ui.inverse01,
        opacity: 1,
        borderRadius: 12,
      },
      thumb: {
        width: '1.125rem',
        height: '1.125rem',
        boxShadow: 'none',
      },
      sizeSmall: {
        width: '2rem',
        height: '1rem',
        [`& .${switchClasses.thumb}`]: {
          width: '0.625rem',
          height: '0.625rem',
        },
        [`& .${switchClasses.switchBase}`]: {
          padding: 3,
          [`&.${switchClasses.checked}`]: {
            transform: 'translateX(1rem)',
          },
        },
      },
    },
  };
};

export default override;
