import { useContext } from 'react';

import ModalContext from './ModalContext.js';

export const useModal = () => {
  const { open, setOpen } = useContext(ModalContext);

  return {
    open,
    setOpen,
  };
};
