import { Controller, useFormContext } from 'react-hook-form';

import { Box, TextField, TextFieldProps } from '@myn/mui';

type InputFieldProps = {
  name: string;
} & TextFieldProps;

const InputField = ({ name, ...otherProps }: InputFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field }) => (
        <Box sx={{ width: '100%' }}>
          <TextField
            {...field}
            error={!!errors[name]}
            fullWidth
            helperText={
              errors[name] ? (errors[name]?.message as unknown as string) : ''
            }
            {...otherProps}
            onChange={(e) => {
              field.onChange(e);
              otherProps?.onChange?.(e);
            }}
          />
        </Box>
      )}
    />
  );
};

export default InputField;
