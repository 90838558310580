import {
  Avatar,
  AvatarSize,
  FileUploaderItem,
  IconButton,
  Truncation,
} from '@myn/mui';

export type FilePreview = {
  name: string;
  preview: string;
};

type Props = {
  file: FilePreview;
  onDeleteClick: () => void;
  disabled: boolean;
};

const FileUploaderPreview = ({ file, onDeleteClick, disabled }: Props) => {
  return (
    <FileUploaderItem key={file.name}>
      <Avatar src={file.preview} avatarSize={AvatarSize.xsmall} />
      <Truncation tooltipTitle={file.name} maxWidth={'150px'}>
        {file.name}
      </Truncation>
      <IconButton
        icon="View"
        size="small"
        variant="icon"
        color="primary"
        onClick={() => window.open(file.preview, '_blank')}
      />
      <IconButton
        icon="Delete"
        size="small"
        variant="icon"
        color="primary"
        onClick={onDeleteClick}
        disabled={disabled}
      />
    </FileUploaderItem>
  );
};

export default FileUploaderPreview;
