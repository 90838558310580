import { useMemo } from 'react';

import { usePermissions } from '@mint-lib/profile-helpers';

import { PERMISSIONS_MAP } from '../../constants.js';

const useIsReadOnlyPermission = () => {
  const { getPermissionField } = usePermissions();

  const isReadOnly = useMemo(
    () =>
      getPermissionField('usersPermissionsFeature') === PERMISSIONS_MAP.View,
    [getPermissionField('usersPermissionsFeature')],
  );

  return isReadOnly;
};

export default useIsReadOnlyPermission;
