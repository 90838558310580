/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  type AuthServiceInterface,
  type AxiosInstance,
  type Tokens,
} from '@mint-lib/api';
import { EventEmitter } from '@mint-lib/service-locator';

import { BaseAuthService, type BaseAuthEvents } from './BaseAuthService.js';
import type { WorkspacesAuthStorage } from './types.js';

export type AuthEvents = BaseAuthEvents & {
  workspaceAuth: [];
  workspaceLogout: [];
};

export type Auth0Credentials = {
  domain: string;
  applicationClientId: string;
};

const WORKSPACE_TOKEN_REFRESH = 'api/authentication/v1/workspaces/';
export class AuthService
  extends BaseAuthService<WorkspacesAuthStorage>
  implements AuthServiceInterface
{
  companyId: number | undefined;
  private accessApi: AxiosInstance | null = null;
  auth0Credentials: Auth0Credentials | null = null;

  constructor(
    guestApi: AxiosInstance,
    storage: WorkspacesAuthStorage,
    auth0Credentials?: Auth0Credentials,
  ) {
    super(guestApi, storage);
    this.auth0Credentials = auth0Credentials ?? null;
  }

  protected readonly eventEmitter: EventEmitter<AuthEvents> =
    new EventEmitter();

  override async provideBearerToken(useWorkspace?: boolean): Promise<string> {
    await this.ensureTokensValid();
    if (useWorkspace && this.companyId) {
      return `jwt ${this.storage.getWorkspaceAccessToken()}`;
    }
    return `jwt ${this.storage.getAccessToken()}`;
  }

  setAccessApi(accessApi: AxiosInstance) {
    this.accessApi = accessApi;
  }

  workspaceRefreshTokenExpired() {
    return !this.isTokenAlive(this.storage.getWorkspaceRefreshTokenExpiresIn());
  }

  workspaceAccessTokenExpired() {
    return !this.isTokenAlive(this.storage.getAccessTokenExpiresIn() - 60);
  }

  saveWorkspaceTokens({
    access,
    refresh,
    accessExpiresIn,
    refreshExpiresIn,
  }: Tokens) {
    this.storage.setWorkspaceAccessToken(access);
    this.storage.setWorkspaceRefreshToken(refresh);
    this.storage.setWorkspaceAccessTokenExpiresIn(accessExpiresIn);
    this.storage.setWorkspaceRefreshTokenExpiresIn(refreshExpiresIn);
    return this.eventEmitter.emit('workspaceAuth');
  }

  protected override async executeEnsureTokensValid() {
    await super.executeEnsureTokensValid();
    if (this.companyId && this.workspaceRefreshTokenExpired()) {
      this.clearWorkspaceSession();
      throw new Error('Workspace refresh token expired');
    }
    if (this.companyId && this.workspaceAccessTokenExpired()) {
      try {
        await this.updateWorkspaceTokens();
      } catch (error) {
        this.clearWorkspaceSession();
        throw new Error('Failed update workspace tokens');
      }
    }
  }

  async updateWorkspaceTokens(logout = false) {
    if (!this.accessApi) {
      throw new Error('accessApi was not set. Please check the factory');
    }
    if (!this.companyId) {
      this.clearWorkspaceSession();
      return;
    }

    const tokensPromise = this.accessApi.post<Tokens>(WORKSPACE_TOKEN_REFRESH, {
      company: this.companyId,
      logout,
    });
    const tokens = await tokensPromise;

    await this.saveWorkspaceTokens(tokens.data);
    return tokens.data;
  }

  clearWorkspaceSession() {
    this.storage.setWorkspaceAccessToken(null);
    this.storage.setWorkspaceRefreshToken(null);
    this.storage.setWorkspaceAccessTokenExpiresIn(null);
    this.storage.setWorkspaceRefreshTokenExpiresIn(null);
    return this.eventEmitter.emit('workspaceLogout');
  }

  isInterimLoggedIn() {
    return Boolean(
      this.storage.getWorkspaceAccessToken() &&
        this.storage.getWorkspaceRefreshToken() &&
        this.storage.getWorkspaceAccessTokenExpiresIn() &&
        !this.workspaceRefreshTokenExpired(),
    );
  }
}
