import { Palette } from '@mui/material/styles';

import typography from './typography.js';

const ToggleButtonOverrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        ...typography.bodyShort01,
        letterSpacing: 0.16,
        borderRadius: '4px',
        textTransform: 'none',
        '&.MuiToggleButton-standard': {
          backgroundColor: palette.ui.primary,
          color: palette.text.secondary,
          border: `1px solid ${palette.ui.tertiary}`,
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
            color: palette.action.disabled01,
            '&.MuiSvgIcon-root': {
              color: palette.action.disabled01,
            },
            border: 'none !important',
          },
        },
        '&.MuiToggleButton-sizeLarge': {
          height: '2.5rem',
        },
        '&.MuiToggleButton-sizeMedium': {
          height: '2rem',
        },
        '&.MuiToggleButton-sizeSmall': {
          height: '1.5rem',
        },
        '&:hover': {
          backgroundColor: palette.ui.hover,
          border: `1px solid ${palette.ui.tertiary}`,
        },
        '&.Mui-selected': {
          backgroundColor: palette.background.tertiary,
          color: palette.tertiary.main,
          border: `1px solid ${palette.tertiary.light}`,
          '&:hover': {
            backgroundColor: palette.ui.highlight,
          },
        },
      },
    },
  };
};
export default ToggleButtonOverrides;
