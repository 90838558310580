import { AuthServiceInterface } from '@mint-lib/api';
import { EventEmitter } from '@mint-lib/service-locator';

import {
  FeaturePermission,
  PERMISSIONS_HIERARCHY,
} from '../constants/index.js';
import { PermissionsRequestType } from '../getUserProfile/getUserProfile.types.js';
import { AmbiguousUserProfile } from '../ProfileContext/index.jsx';

export type ProfileEvents = {
  update: [profile: AmbiguousUserProfile];
};

export class ProfileService {
  private readonly eventEmitter: EventEmitter<ProfileEvents> =
    new EventEmitter();
  private profileData: AmbiguousUserProfile = null;
  private eventListeners: Set<() => void> = new Set();
  constructor(private auth: AuthServiceInterface) {}

  subscribe(cb: () => void) {
    this.eventListeners.add(cb);
    return () => {
      this.eventListeners.delete(cb);
    };
  }

  setProfileData(
    data:
      | AmbiguousUserProfile
      | ((user: AmbiguousUserProfile) => AmbiguousUserProfile),
  ) {
    if (typeof data === 'function') {
      this.profileData = data(this.profileData);
    } else {
      this.profileData = data;
    }
    this.eventListeners.forEach((cb) => cb());
    return this.eventEmitter.emit('update', this.profileData);
  }

  getEventEmitter() {
    return this.eventEmitter;
  }

  getProfileData() {
    return this.profileData;
  }

  getPermissions() {
    return this.profileData?.profile?.permissions ?? {};
  }

  hasPermission(requestedAccess: PermissionsRequestType) {
    if (!this.profileData) {
      return false;
    }
    const { permissions } = this.profileData.profile;
    const [item, requestedAction] = requestedAccess.split(':') as [
      keyof typeof permissions,
      FeaturePermission,
    ];
    const requestedAccessIndex = PERMISSIONS_HIERARCHY.findIndex(
      (perm) => perm === requestedAction,
    );
    const requiredActionIndex = PERMISSIONS_HIERARCHY.findIndex(
      (perm) => perm === permissions[item],
    );

    // Safecheck: if no permission found consider denial
    if (requestedAccessIndex === -1 || requiredActionIndex === -1) {
      return false;
    }

    return requiredActionIndex >= requestedAccessIndex;
  }

  isAgencyCompany() {
    return this.profileData?.profile?.company?.agencyCompany ?? false;
  }

  isControllerCompany() {
    return this.profileData?.profile?.company?.controllerCompany ?? false;
  }

  isControllerLoggedInAsClient() {
    return this.isControllerCompany() && this.auth.isInterimLoggedIn();
  }

  isRetailLicensee() {
    return this.profileData?.profile?.company?.license === 'RETAIL';
  }
}
