import {
  createTheme,
  ThemeProvider,
  useTheme,
  type Theme,
} from '@mui/material/styles';

import type {} from '@mui/lab/themeAugmentation';
import type {} from './extensions/palette.js';
import type {} from './extensions/typography.js';
import type {} from './extensions/chip.js';
import type {} from './extensions/button.js';

import boilerplateTheme from './styles/boilerplate-theme.js';

export type ThemeModeType = 'dark' | 'light';

export const mockTheme: Theme = createTheme(boilerplateTheme('light'));

export const getTheme = (mode: ThemeModeType = 'light') =>
  createTheme(boilerplateTheme(mode));

export type { Theme };
export { ThemeProvider, useTheme };
