import React from 'react';

import MenuItemM, { MenuItemProps } from '@mui/material/MenuItem';

export interface Props extends MenuItemProps {
  autoFocus?: boolean;
  children?: React.ReactNode;
  /**
   * @uxpinignoreprop
   * Override or extend the styles applied to the component.
   */
  classes?: MenuItemProps['classes'];
  /**
   * @uxpinignoreprop
   */
  component?: React.ElementType;
  dense?: boolean;
  disableGutters?: boolean;
  divider?: boolean;
  /**
   * @uxpinignoreprop
   */
  focusVisibleClassName?: string;
  /**
   * @uxpinignoreprop
   * Override or extend the styles applied to the component.
   */
  sx?: MenuItemProps['sx'];
  value?: string | number;
}

function MenuItem(props: Props) {
  return (
    <MenuItemM {...props} data-dd-action-name="Select option">
      {props.children}
    </MenuItemM>
  );
}

export default MenuItem;
