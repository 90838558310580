import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';

import useConditionalSearch from '../../hooks/useConditionalSearch/useConditionalSearch.js';
import useTab, { RouteTabs } from '../../hooks/useTab/useTab.js';
import useTablePagination from '../../hooks/useTablePagination/useTablePagination.jsx';
import useTableSorting from '../../hooks/useTableSorting/useTableSorting.jsx';
import {
  createRole as createRoleReq,
  deleteRole as deleteRoleReq,
  editRole as editRoleReq,
  fetchUserRoles,
  type RoleType,
} from '../roles/index.js';
import { WithPaginationResponse } from '../types.js';
import { queryClient, QueryKey } from './main.js';

type Params = { searchQuery?: string; getFullList?: boolean };

export const useUserRole = (params?: Params) => {
  const searchParams = useConditionalSearch();

  const { limit, offset } = useTablePagination();

  const { getFullList } = params ?? {};

  const { orderingBE } = useTableSorting();

  const searchString = !getFullList ? searchParams?.search : '';

  const tab = useTab();

  const userRole = useQuery({
    queryKey: [
      QueryKey.UserRole,
      searchString,
      limit,
      offset,
      orderingBE,
      getFullList,
    ],
    queryFn: () => {
      return fetchUserRoles({
        search: searchString,
        limit: getFullList ? 0 : limit,
        offset: offset,
        ordering: orderingBE,
      });
    },

    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    enabled: tab === RouteTabs.Role || getFullList,
  });

  const userRoleData =
    getFullList || limit === 0
      ? (userRole.data as RoleType[])
      : ((userRole.data as unknown as WithPaginationResponse<RoleType>)
          ?.results ?? null);

  const createRole = useMutation({
    mutationFn: createRoleReq,
  });

  const editRole = useMutation({
    mutationFn: editRoleReq,
  });

  const deleteRole = useMutation({
    mutationFn: deleteRoleReq,
  });

  const refetchRoles = () =>
    queryClient.invalidateQueries({
      queryKey: [QueryKey.UserRole],
    });

  return {
    userRole,
    userRoleData,
    createRole,
    editRole,
    deleteRole,
    refetchRoles,
  };
};
