// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { AxiosError } from 'axios';

import { objectKeysToCamelCase } from '../utils/objectKeysTo.js';

export function responseErrorDataToCamelCase(err: AxiosError) {
  if (err.response) {
    // eslint-disable-next-line no-throw-literal
    throw {
      ...err,
      response: {
        ...err.response,
        // @ts-ignore TS2345: Argument of type 'unknown' is not assignable to parameter of type 'AxiosResponse<any, any>'.
        data: objectKeysToCamelCase(err.response.data),
      },
    };
  }
  throw err;
}
