import api, { profileApi } from '@mint-lib/api';

import { UserProfile } from './getUserProfile.types.js';

const USER_PROFILE = '/api/authentication/v1/user/profile/';

export const fetchOriginUserProfile = (
  api = profileApi,
): Promise<UserProfile> => profileApi.get(USER_PROFILE).then((res) => res.data);

export const fetchUserProfile = (): Promise<UserProfile> =>
  api.get(USER_PROFILE).then((res) => res.data);
