import { useMemo } from 'react';

import { useTranslation } from '@mint-lib/i18n';
import { useProfile } from '@mint-lib/profile-helpers';
import { format } from 'date-fns';
import { enGB, enUS, it, ptBR, uk } from 'date-fns/locale';

const localeMap = {
  'en-US': enUS,
  'en-GB': enGB,
  'it-IT': it,
  'pt-BR': ptBR,
  'uk-UA': uk,
};

type Params = {
  options?: {
    format?: 'p' | 'pp' | 'ppp' | 'pppp';
  };
};

/**
 * Returns a date formatter function that formats a given date according to the specified format and locale.
 *
 * @param options - An optional object containing the following options:
 *   - format: A string representing the format of the date. Can be one of the following:
 * - `p`: 12:00 AM
 * - `pp`: 12:00:00 AM
 * - `ppp`: 12:00:00 AM GMT+2
 * - `pppp`: 12:00:00 AM GMT+02:00
 *
 * @returns A date formatter function that takes a date as input and returns a formatted string.
 */
export const useTimeFormatter = (params?: Params) => {
  const options = params?.options ?? {};

  const { i18n } = useTranslation();

  const { getProfileField } = useProfile();

  // if flag enabled check for config and return it or language
  // else return 'it-It'
  const locale = (
    getProfileField('formattingConfig')?.datesFormat
      ? getProfileField('formattingConfig')?.datesFormat
      : i18n.language
  ) as keyof typeof localeMap;

  const formatter = useMemo(
    () => ({
      format: (date: number | string | Date) =>
        format(new Date(date), options?.format ?? 'p', {
          locale: localeMap[locale],
        }),
    }),
    [options?.format, locale],
  );

  return formatter;
};

/**
 * A component that displays a static date string formatted according to the provided options.
 * Uses the `useTimeFormatter` hook to format the date string.
 * - `p`: 12:00 AM
 * - `pp`: 12:00:00 AM
 * - `ppp`: 12:00:00 AM GMT+2
 * - `pppp`: 12:00:00 AM GMT+02:00
 * @returns A JSX element containing the formatted date string.
 */
const StaticTimeSpan = ({
  date,
  options,
}: {
  date: number | string | Date;
  options?: {
    /**
     * The format to use for the time.
     *
     * - `P`: 12:00 AM
     * - `pp`: 12:00:00 AM
     * - `ppp`: 12:00:00 AM GMT+2
     * - `pppp`: 12:00:00 AM GMT+02:00
     */
    format?: 'p' | 'pp' | 'ppp' | 'pppp';
  };
}): JSX.Element => {
  const formatter = useTimeFormatter({ options });

  const res = formatter.format(date);

  return <span>{res}</span>;
};

export default StaticTimeSpan;
