import api from '@mint-lib/api';
import { ClientType } from '@mint-lib/profile-helpers';

import { AGENCY_CLIENTS } from '../endpointConstants.js';

export type AssignParamsType = {
  clients: number[];
  user: number | string;
};

export const fetchAgencyClients = (): Promise<ClientType[]> =>
  api.get(AGENCY_CLIENTS).then((data) => data.data);

export const assignClients = (
  params: AssignParamsType,
): Promise<ClientType[]> =>
  api.post(`${AGENCY_CLIENTS}assign/`, params).then((data) => data.data);
