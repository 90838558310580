import { createFileRoute, notFound, redirect } from '@tanstack/react-router';

import { buildGuards, hasPermission } from '@mint-lib/routing-guards';
import { z } from 'zod';

const guard = buildGuards(hasPermission('brandsGeographiesFeature:view'));

const tabValueZodSchema = z.union([z.literal('brands'), z.literal('geo')]);

export type BrandsTabValueType = z.infer<typeof tabValueZodSchema>;

export const Route = createFileRoute('/_secured/company-profile/brands/')({
  async beforeLoad({ context, search }) {
    const guardsContext = await context.getGuardContext();
    if (!guard(guardsContext)) {
      throw notFound();
    }

    return context;
  },
  validateSearch: z.object({
    search: z.string().optional(),
    tabsValue: tabValueZodSchema.default('brands'),
  }),
  loader: async ({ context }) => {
    await context.serviceLocator.getInstance('i18n', '@myn/brands');
  },
});
