const paletteDark = {
  primary: {
    main: '#75F461',
    light: '#AEF8A2',
    dark: '#3D9730',
    hover: '#66dd54',
    active: '#58c547',
    contrastText: '#1D4C17',
  },
  secondary: {
    main: '#C7D1DB',
    light: '#DEE4EA',
    dark: '#B6C2CF',
    hover: '#B6C2CF',
    active: '#9FADBC',
    contrastText: '#161A1D',
  },
  text: {
    primary: '#DEE4EA',
    secondary: '#C7D1DB',
    tertiary: '#B6C2CF',
    success: '#D8F0A8',
    warning: '#FACC9E',
    info: '#9EEBFA',
    placeholder: '#B6C2CF',
    inverse: '#49545C',
    error: '#F0A8A8',
    link: '#579DFF',
    hoverPrimary: '#388BFF',
    disabled: '#68737D',
    disabled02: '#87929D',
  },
  action: {
    disabledBackground: '#22272B8F',
    disabled01: '#68737D',
    disabled02: '#87929D',
    focusBorder: '#BDDFFF',
    inverseFocusBorder: '#ffffff',
  },
  error: {
    main: '#F87462',
    light: '#FF9C8F',
    dark: '#EF5C48',
    hover: '#E36760',
    active: '#CA554F',
    contrastText: '#391813',
  },
  info: {
    main: '#60C6D2',
    light: '#8BDBE5',
    dark: '#267180',
    contrastText: '#153337',
  },
  warning: {
    main: '#FAA53D',
    light: '#FEC57B',
    dark: '#37B4C3',
    hover: '#E39434',
    active: '#C98128',
    contrastText: '#43290F',
  },
  success: {
    main: '#61CE4B',
    light: '#8FE27E',
    dark: '#42BB2A',
    contrastText: '#193513',
  },
  background: {
    default: '#161A1D',
    primary: '#173513',
    secondary: '#101214',
    tertiary: '#082145',
    error: '#391813',
    success: '#133527',
    warning: '#43290F',
    info: '#153337',
    purple: '#F4E3FD',
    pink: '#FDE3F4',
    rose: '#FDE3EB',
    blue: '#E3EBFD',
    yellow: '#FDFDE3',
    green: '#E3FDE3',
    peach: '#FDEBE3',
    azure: '#E3F4FD',
    violet: '#EBE3FD',
    lime: '#F4FDE3',
    orange: '#FDF4E3',
    ice: '#E3FDFD',
    fuchsia: '#FDE3FD',
    emerald: '#EBFDE3',
    teal: '#e3fdf4',
    mint: '#E3FDEB',
    royal: '#E3E3FD',
    blaze: '#FCEDE3',
    coral: '#FCE4E3',
    desert: '#FCFAE3',
    jungle: '#E3FCF0',
    kingdom: '#FCE3FC',
    skin: '#FCE3EC',
    amber: '#FCEAE3',
  },
  icon: {
    primary: '#DEE4EA',
    secondary: '#C7D1DB',
    inverse: '#49545C',
  },
  ui: {
    primary: '#22272B',
    secondary: '#161A1D',
    tertiary: '#68737D',
    inverse01: '#D8DCDE',
    inverse02: '#F8F9F9',
    field01: '#22272B',
    field02: '#161A1D',
    overlay: '#68737DA3',
    hover: '#FFFFFF14',
    selected: '#ffffff28',
    active: '#ffffff28',
    hoverSelected: '#87929D33',
    hoverRow: '#161A1D',
    hoverCell: '#68737d',
    highlight: '#B3D2FA',
  },
  tertiary: {
    main: '#579DFF',
    light: '#85B8FF',
    dark: '#388BFF',
    active: '#1D7AFC',
    hover: '#388BFF',
    contrastText: '#082145',
  },
  greyscale: {
    black: '#000000',
    grey80: '#161A1D',
    grey70: '#22272B',
    grey60: '#68737d',
    grey50: '#87929d',
    grey40: '#c2c8cc',
    grey30: '#d8dcde',
    grey20: '#e9ebed',
    grey10: '#f8f9f9',
    white: '#ffffff',
  },
  //Misc Tokens
  transparent: {
    main: '#ffffff00',
    contrastText: '#161616',
  },
  silver: {
    main: '#e5e5e5',
    contrastText: '#161616',
  },
  purple: {
    main: '#CC7AF5',
    contrastText: '#8A0FC7',
    dark: '#8A0FC7',
  },
  pink: {
    main: '#F57ACC',
    contrastText: '#C70F8A',
    dark: '#C70F8A',
  },
  yellow: {
    main: '#F5F57A',
    contrastText: '#C7C70F',
    dark: '#C7C70F',
  },
  dark: {
    main: '#393939',
    contrastText: '#ffffff',
  },
  teal: {
    main: '#7AF5CC',
    contrastText: '#0FC78A',
    dark: '#0FC78A',
  },
  azure: {
    main: '#7ACCF5',
    contrastText: '#0F8AC7',
    dark: '#0F8AC7',
  },
  rose: {
    main: '#F57AA3',
    contrastText: '#C70F4D',
    dark: '#C70F4D',
  },
  peach: {
    main: '#F5A37A',
    contrastText: '#C74D0F',
    dark: '#C74D0F',
  },
  violet: {
    main: '#A37AF5',
    contrastText: '#4C0FC7',
    dark: '#4C0FC7',
  },
  green: {
    main: '#7AF57A',
    contrastText: '#0FC70F',
    dark: '#0FC70F',
  },
  ice: {
    main: '#7AF5F5',
    contrastText: '#0FC7C7',
    dark: '#0FC7C7',
  },
  orange: {
    main: '#F5CC7A',
    contrastText: '#C78A0F',
    dark: '#C78A0F',
  },
  emerald: {
    main: '#A3F57A',
    contrastText: '#4CC70F',
    dark: '#4CC70F',
  },
  lime: {
    main: '#CCF57A',
    contrastText: '#8AC70F',
    dark: '#8AC70F',
  },
  mint: {
    main: '#7AF5A3',
    contrastText: '#0FC74D',
    dark: '#0FC74D',
  },
  blue: {
    main: '#7AA3F5',
    contrastText: '#0F4DC7',
    dark: '#0F4DC7',
  },
  fuchsia: {
    main: '#F57AF5',
    contrastText: '#C70FC7',
    dark: '#C70FC7',
  },
  royal: {
    main: '#7A7AF5',
    contrastText: '#1010C7',
    dark: '#1010C7',
  },
  desert: {
    main: '#EBDD75',
    contrastText: '#C7B210',
    dark: '#C7B210',
  },
  blaze: {
    main: '#FFB585',
    contrastText: '#C75910',
    dark: '#C75910',
  },
  kingdom: {
    main: '#D961DB',
    contrastText: '#C310C7',
    dark: '#C310C7',
  },
  coral: {
    main: '#E57873',
    contrastText: '#C71910',
    dark: '#C71910',
  },
  skin: {
    main: '#FF80AB',
    contrastText: '#C7104F',
    dark: '#C7104F',
  },
  jungle: {
    main: '#75EBB0',
    contrastText: '#10C76B',
    dark: '#10C76B',
  },
  amber: {
    main: '#F5997A',
    contrastText: '#C73E10',
    dark: '#C73E10',
  },

  dataVisualization: {
    blue200: '#BFDBFE',
    blue400: '#60A5FA',
    blue600: '#2563EB',
    blue800: '#1E40AF',
    fuchsia200: '#F5D0FE',
    fuchsia400: '#E879F9',
    fuchsia600: '#C026D3',
    fuchsia800: '#86198F',
    orange200: '#FED7AA',
    orange400: '#FB923C',
    orange600: '#EA580C',
    orange800: '#9A3412',
    emerald200: '#A7F3D0',
    emerald400: '#34D399',
    emerald600: '#059669',
    emerald800: '#065F46',
    yellow200: '#FEF08A',
    yellow400: '#FACC15',
    yellow600: '#CA8A04',
    yellow800: '#854D0E',
    violet200: '#DDD6FE',
    violet400: '#A78BFA',
    violet600: '#7C3AED',
    violet800: '#5B21B6',
    red200: '#FECACA',
    red400: '#F87171',
    red600: '#DC2626',
    red800: '#991B1B',
    lime200: '#D9F99D',
    lime400: '#A3E635',
    lime600: '#65A30D',
    lime800: '#3F6212',
  },
  border: {
    primary: '#68737D',
    secondary: '#45484A',
    disabled: '#68737D',
    tertiary: '#45484A',
    focus: '#BDDFFF',
    focusPrimary: '#AEF8A2',
    focusError: '#FF9C8F',
    focusWarning: '#FEC57B',
    selectedPrimary: '#579DFF',
    selectedSecondary: '#85B8FF',
  },
  channel: {
    metasearch: '#75F461',
    metasearchBackground: '#713F12',
    payTV: '#8B5CF6',
    payTVBackground: '#4C1D95',
    mgm: '#CA8A04',
    mgmBackground: '#713F12',
    display: '#2563EB',
    displayBackground: '#1E3A8A',
    adserver: '#059669',
    adserverBackground: '#064E3B',
    affiliation: '#FB923C',
    affiliationBackground: '#7C2D12',
    direct: '#A7F3D0',
    directBackground: '#064E3B',
    organic: '#D9F99D',
    organicBackground: '#365314',
    ooh: '#A78BFA',
    oohBackground: '#4C1D95',
    webAnalytics: '#4C1D95',
    webAnalyticsBackground: '#7F1D1D',
    brandedContent: '#10B981',
    brandedContentBackground: '#064E3B',
    influencerMarketing: '#DC2626',
    influencerMarketingBackground: '#7F1D1D',
    dsp: '#F97316',
    dspBackground: '#7C2D12',
    chatBot: '#F5D0FE',
    chatBotBackground: '#701A75',
    brandSafety: '#A3E635',
    brandSafetyBackground: '#365314',
    programmaticRadio: '#D946EF',
    programmaticRadioBackground: '#701A75',
    network: '#DDD6FE',
    networkBackground: '#4C1D95',
    offlineRadio: '#84CC16',
    offlineRadioBackground: '#365314',
    publisher: '#60A5FA',
    publisherBackground: '#1E3A8A',
    mobile: '#3B82F6',
    mobileBackground: '#1E3A8A',
    dmp: '#FED7AA',
    dmpBackground: '#7C2D12',
    dooh: '#EA580C',
    doohBackground: '#7C2D12',
    dem: '#65A30D',
    demBackground: '#365314',
    offlineTV: '#FACC15',
    offlineTVBackground: '#713F12',
    video: '#BFDBFE',
    videoBackground: '#1E3A8A',
    addressableTV: '#E879F9',
    addressableTVBackground: '#701A75',
    social: '#C026D3',
    socialBackground: '#701A75',
    native: '#7C3AED',
    nativeBackground: '#4C1D95',
    search: '#34D399',
    searchBackground: '#064E3B',
    promocode: '#FECACA',
    promocodeBackground: '#7F1D1D',
  },
};
export default paletteDark;
