/* eslint-disable @typescript-eslint/no-explicit-any */

import type {
  ServiceArgs,
  ServiceEventsArgs,
  ServiceEventsNames,
  ServiceFromInstanceName,
  ServicesConfig,
  ServicesInstancesNames,
  ServicesNames,
} from './types.js';

type ListenersMap<
  Services extends ServicesConfig,
  Names extends ServicesNames<Services> = ServicesNames<Services>,
> = Map<
  ServicesInstancesNames<Services>,
  Map<
    ServiceEventsNames<Services, Names, ServiceArgs<Services, Names>>,
    Set<Function>
  >
>;

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export class ServiceLocatorEventBus<Services extends ServicesConfig = {}> {
  private listeners: ListenersMap<Services> = new Map();
  constructor(private readonly logger: Console | null = null) {}

  on<
    K extends ServicesInstancesNames<Services>,
    Service extends ServicesNames<Services> = ServiceFromInstanceName<
      Services,
      K
    >,
    E extends ServiceEventsNames<
      Services,
      Service,
      ServiceArgs<Services, Service>
    > = ServiceEventsNames<Services, Service, ServiceArgs<Services, Service>>,
    Args extends ServiceEventsArgs<
      Services,
      Service,
      ServiceArgs<Services, Service>,
      E
    > = ServiceEventsArgs<Services, Service, ServiceArgs<Services, Service>, E>,
  >(
    ns: K,
    event: E | `pre:${E}` | `post:${E}`,
    listener: (event: E, ...args: Args) => void,
  ) {
    this.logger?.debug(
      `[ServiceLocatorEventBus]#on(): ns:${ns} event:${event}`,
    );
    if (!this.listeners.has(ns)) {
      this.listeners.set(ns, new Map());
    }

    const nsEvents = this.listeners.get(ns)!;
    // @ts-expect-error TS2345. This is a correct type
    if (!nsEvents.has(event)) {
      // @ts-expect-error TS2345. This is a correct type
      nsEvents.set(event, new Set());
    }

    // @ts-expect-error TS2345. This is a correct type
    nsEvents.get(event)!.add(listener);

    return () => {
      // @ts-expect-error TS2345. This is a correct type
      nsEvents.get(event)!.delete(listener);
      // @ts-expect-error TS2345. This is a correct type
      if (nsEvents.get(event)?.size === 0) {
        // @ts-expect-error TS2345. This is a correct type
        nsEvents.delete(event);
      }
      if (nsEvents.size === 0) {
        this.listeners.delete(ns);
      }
    };
  }

  once<
    K extends ServicesInstancesNames<Services>,
    Service extends ServicesNames<Services> = ServiceFromInstanceName<
      Services,
      K
    >,
    E extends ServiceEventsNames<
      Services,
      Service,
      ServiceArgs<Services, Service>
    > = ServiceEventsNames<Services, Service, ServiceArgs<Services, Service>>,
    Args extends ServiceEventsArgs<
      Services,
      Service,
      ServiceArgs<Services, Service>,
      E
    > = ServiceEventsArgs<Services, Service, ServiceArgs<Services, Service>, E>,
  >(
    key: K,
    event: E | `pre:${E}` | `post:${E}`,
    listener: (event: E, ...args: Args) => void,
  ) {
    this.logger?.debug(
      `[ServiceLocatorEventBus]#once(): ns:${key} event:${event}`,
    );
    const off = this.on<K, Service, E, Args>(key, event, (event, ...args) => {
      off();
      return listener(event, ...args);
    });
    return off;
  }

  waitFor<
    K extends ServicesInstancesNames<Services>,
    Service extends ServicesNames<Services> = ServiceFromInstanceName<
      Services,
      K
    >,
    E extends ServiceEventsNames<
      Services,
      Service,
      ServiceArgs<Services, Service>
    > = ServiceEventsNames<Services, Service, ServiceArgs<Services, Service>>,
    Args extends ServiceEventsArgs<
      Services,
      Service,
      ServiceArgs<Services, Service>,
      E
    > = ServiceEventsArgs<Services, Service, ServiceArgs<Services, Service>, E>,
  >(key: K, event: E, signal?: AbortSignal): Promise<Args> {
    this.logger?.debug(`[ServiceLocatorEventBus]#waitFor(): ${key}:${event}`);
    return new Promise((resolve, reject) => {
      const off = this.once<K, Service, E, Args>(
        key,
        event,
        (event, ...args) => {
          try {
            resolve(args);
          } catch (error) {
            reject(error);
          }
        },
      );

      if (signal) {
        signal.addEventListener('abort', () => {
          off();
          reject(new DOMException('Aborted', 'AbortError'));
        });
      }
    });
  }

  async emit<
    K extends ServicesInstancesNames<Services>,
    Service extends ServicesNames<Services> = ServiceFromInstanceName<
      Services,
      K
    >,
    E extends ServiceEventsNames<
      Services,
      Service,
      ServiceArgs<Services, Service>
    > = ServiceEventsNames<Services, Service, ServiceArgs<Services, Service>>,
    Args extends ServiceEventsArgs<
      Services,
      Service,
      ServiceArgs<Services, Service>,
      E
    > = ServiceEventsArgs<Services, Service, ServiceArgs<Services, Service>, E>,
  >(key: K, event: E, ...args: Args) {
    if (!this.listeners.has(key)) {
      return;
    }

    const events = this.listeners.get(key)!;

    const preEvent = `pre:${event}` as `pre:${E}`;
    const postEvent = `post:${event}` as `post:${E}`;
    this.logger?.debug(`[ServiceLocatorEventBus]#emit(): ${key}:${preEvent}`);
    await Promise.allSettled(
      // @ts-expect-error TS2345. This is a correct type
      [...(events.get(preEvent) ?? [])].map((listener) =>
        listener(preEvent, ...args),
      ),
    ).then((results) => {
      results
        .filter((result) => result.status === 'rejected')
        // @ts-expect-error TS2345. We just filtered the fulfilled results
        .forEach((result: PromiseRejectedResult) => {
          this.logger?.warn(
            `[ServiceLocatorEventBus]#emit(): ${key}:${preEvent} rejected with`,
            result.reason,
          );
        });
    });
    this.logger?.debug(`[ServiceLocatorEventBus]#emit(): ${key}:${event}`);

    const res = await Promise.allSettled(
      // @ts-expect-error TS2345. This is a correct type
      [...(events.get(event) ?? [])!].map((listener) =>
        listener(event, ...args),
      ),
    ).then((results) => {
      const res = results
        .filter((result) => result.status === 'rejected')
        // @ts-expect-error TS2345. We just filtered the fulfilled results
        .map((result: PromiseRejectedResult) => {
          this.logger?.warn(
            `[ServiceLocatorEventBus]#emit(): ${key}:${event} rejected with`,
            result.reason,
          );
          return result;
        });

      if (res.length > 0) {
        return Promise.reject(res);
      }
      return results;
    });
    this.logger?.debug(`[ServiceLocatorEventBus]#emit(): ${key}:${postEvent}`);
    await Promise.allSettled(
      // @ts-expect-error TS2345. This is a correct type
      [...(events.get(postEvent) ?? [])].map((listener) =>
        listener(postEvent, ...args),
      ),
    ).then((results) => {
      results
        .filter((result) => result.status === 'rejected')
        // @ts-expect-error TS2345. We just filtered the fulfilled results
        .forEach((result: PromiseRejectedResult) => {
          this.logger?.warn(
            `[ServiceLocatorEventBus]#emit(): ${key}:${postEvent} rejected with`,
            result.reason,
          );
        });
    });
    return res;
  }
}
