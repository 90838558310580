import { ComponentProps, ComponentType } from 'react';

import * as AllIcons from './index.jsx';

// // @ts-ignore TS2740
// export const Icons: { [key in keyof typeof AllIcons]: () => Promise<string> } =
//   import.meta.glob('./icons/*.svg');

type WrapKey<
  TKey,
  TPrefix extends string,
  TSuffix extends string = '',
> = TKey extends string ? `${TPrefix}${TKey}${TSuffix}` : never;

export type IconsList = keyof typeof AllIcons;

// @ts-ignore TS2740
export const Icons: {
  [key in WrapKey<keyof typeof AllIcons, './svg/', '.svg'>]: () => Promise<{
    default: ComponentType<ComponentProps<'svg'>>;
  }>;
} = import.meta.glob('./svg/*.svg', {
  query: '?react',
});
