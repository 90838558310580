import { createFileRoute, notFound } from '@tanstack/react-router';

import { buildGuards, hasFlagEnabled } from '@mint-lib/routing-guards';

const guard = buildGuards(hasFlagEnabled('flowchart'));

export const Route = createFileRoute(
  '/_secured/campaign/$campaignId/planning/flowchart/',
)({
  async beforeLoad({ context }) {
    const guardsContext = await context.getGuardContext(
      await context.serviceLocator.getInstance('flagsmith', '@myn/secured'),
    );
    if (!guard(guardsContext)) {
      throw notFound();
    }
    return context;
  },
});
