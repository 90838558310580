import { createFileRoute, notFound } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_secured/campaign/$campaignId/hub/ai-impact/',
)({
  async beforeLoad({ context }) {
    if (!context.hasPermission('suggestionsInsightsFeature:view')) {
      throw notFound();
    }
    return context;
  },
});
