import { LocalStorage } from '../constants/LocalStorage.js';
import type { BaseAuthStorage } from '../types.js';

export const baseAuthStorage: BaseAuthStorage = {
  getAccessToken() {
    return localStorage.getItem(LocalStorage.ACCESS);
  },
  setAccessToken(token: string | null) {
    if (!token) {
      localStorage.removeItem(LocalStorage.ACCESS);
      return;
    }
    localStorage.setItem(LocalStorage.ACCESS, token);
  },
  getAccessTokenExpiresIn() {
    return parseInt(
      localStorage.getItem(LocalStorage.ACCESS_EXPIRES_IN) || '0',
      10,
    );
  },
  setAccessTokenExpiresIn(expiresIn: number | null) {
    if (!expiresIn) {
      localStorage.removeItem(LocalStorage.ACCESS_EXPIRES_IN);
      return;
    }
    localStorage.setItem(LocalStorage.ACCESS_EXPIRES_IN, expiresIn.toString());
  },
  getRefreshToken() {
    return localStorage.getItem(LocalStorage.REFRESH);
  },
  setRefreshToken(token: string | null) {
    if (!token) {
      localStorage.removeItem(LocalStorage.REFRESH);
      return;
    }
    localStorage.setItem(LocalStorage.REFRESH, token);
  },
  getRefreshTokenExpiresIn() {
    return parseInt(
      localStorage.getItem(LocalStorage.REFRESH_EXPIRES_IN) || '0',
      10,
    );
  },
  setRefreshTokenExpiresIn(expiresIn: number | null) {
    if (!expiresIn) {
      localStorage.removeItem(LocalStorage.REFRESH_EXPIRES_IN);
      return;
    }
    localStorage.setItem(LocalStorage.REFRESH_EXPIRES_IN, expiresIn.toString());
  },
};
