import { alpha, Palette } from '@mui/material/styles';

import typography from './typography.js';

const iconButtonOverride = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        ...typography.bodyShort02,
        borderRadius: '4px',
        boxShadow: 'none',
        align: 'center',
        transition:
          'background 70ms cubic-bezier(0,0,.38,.9),box-shadow 70ms cubic-bezier(0,0,.38,.9),border-color 70ms cubic-bezier(0,0,.38,.9),outline 70ms cubic-bezier(0,0,.38,.9)',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
        '& .MuiSvgIcon-root': {
          color: 'currentColor',
        },
      },

      //set sizes
      sizeLarge: {
        height: '2.5rem',
        width: '2.5rem',
        padding: '0.75rem',
      },
      sizeMedium: {
        height: '2rem',
        width: '2rem',
        padding: '0.5rem',
      },
      sizeSmall: {
        height: '1.5rem',
        width: '1.5rem',
        padding: '0.25rem',
      },
      colorPrimary: {
        '&:focus': {
          boxShadow: `0 0 0 2px ${palette.primary.light}`,
        },
        '&.contained': {
          backgroundColor: palette.primary.main,
          color: palette.primary.contrastText,
          '&:hover': {
            backgroundColor: palette.primary.hover,
          },
          '&:active': {
            backgroundColor: palette.primary.active,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.action.disabledBackground,
            color: palette.text.disabled,
          },
        },

        '&.outlined': {
          backgroundColor: palette.transparent.main,
          border: `1px solid ${palette.secondary.light}`,
          color: palette.secondary.main,
          '&:hover': {
            backgroundColor: palette.ui.hover,
            border: `1px solid ${palette.secondary.light}`,
          },
          '&:focus': {
            boxShadow: `0 0 0 2px ${palette.action.focusBorder}`,
          },
          '&:active': {
            backgroundColor: palette.ui.active,
          },
          '&.Mui-disabled': {
            color: palette.text.disabled,
            border: `1px solid ${palette.ui.tertiary}`,
          },
        },
        '&.icon': {
          backgroundColor: palette.transparent.main,
          color: palette.tertiary.main,
          '&:hover': {
            backgroundColor: alpha(palette.tertiary.light, 0.08),
          },
          '&:active': {
            backgroundColor: alpha(palette.tertiary.light, 0.16),
          },
          '&:focus': {
            boxShadow: `0 0 0 2px ${palette.action.focusBorder}`,
          },
          '&.Mui-disabled': {
            color: palette.text.disabled,
          },
        },
        '&.plain': {
          height: '1rem !important',
          width: '1rem',
          padding: '0px',
          backgroundColor: palette.transparent.main,
          color: palette.tertiary.main,
          '&:hover': {
            backgroundColor: palette.transparent.main,
            color: palette.tertiary.hover,
          },
          '&:focus': {
            boxShadow: `0 0 0 2px ${palette.action.focusBorder}`,
          },
          '&:active': {
            color: palette.tertiary.dark,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.transparent.main,
            color: palette.text.disabled,
            border: 'none',
          },
        },
      },
      colorSecondary: {
        '&:focus': {
          boxShadow: `0 0 0 2px ${palette.action.focusBorder}`,
        },
        '&.contained': {
          backgroundColor: palette.secondary.main,
          color: palette.secondary.contrastText,
          '&:hover': {
            backgroundColor: palette.secondary.hover,
          },
          '&:active': {
            backgroundColor: palette.secondary.active,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.action.disabledBackground,
            color: palette.text.disabled,
          },
        },
        '&.outlined': {
          backgroundColor: palette.transparent.main,
          border: `1px solid ${palette.secondary.light}`,
          color: palette.secondary.main,
          '&:hover': {
            backgroundColor: palette.ui.hover,
            border: `1px solid ${palette.secondary.light}`,
          },
          '&:focus': {
            boxShadow: `0 0 0 2px ${palette.action.focusBorder}`,
          },
          '&:active': {
            backgroundColor: palette.ui.active,
          },
          '&.Mui-disabled': {
            color: palette.text.disabled,
            border: `1px solid ${palette.ui.tertiary}`,
          },
        },
        '&.icon': {
          backgroundColor: palette.transparent.main,
          color: palette.tertiary.main,
          '&:hover': {
            backgroundColor: alpha(palette.tertiary.light, 0.08),
          },
          '&:active': {
            backgroundColor: alpha(palette.tertiary.light, 0.16),
          },
          '&.Mui-disabled': {
            color: palette.text.disabled,
          },
        },
        '&.plain': {
          height: '1rem !important',
          width: '1rem',
          padding: '0px',
          backgroundColor: palette.transparent.main,
          color: palette.tertiary.main,
          '&:hover': {
            backgroundColor: palette.transparent.main,
            color: palette.tertiary.hover,
          },
          '&:focus': {
            boxShadow: `0 0 0 2px ${palette.action.focusBorder}`,
          },
          '&:active': {
            color: palette.tertiary.dark,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.transparent.main,
            color: palette.text.disabled,
            border: 'none',
          },
        },
      },
      colorError: {
        '&:focus': {
          boxShadow: `0 0 0 2px ${palette.error.light}`,
        },
        '&.contained': {
          backgroundColor: palette.error.main,
          color: palette.error.contrastText,
          '&.MuiSvgIcon': {
            color: palette.error.contrastText,
          },
          '&:hover': {
            backgroundColor: palette.error.hover,
          },
          '&:active': {
            backgroundColor: palette.error.active,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.action.disabledBackground,
            color: palette.text.disabled,
          },
        },
        '&.outlined': {
          backgroundColor: palette.transparent.main,
          border: `1px solid ${palette.secondary.light}`,
          color: palette.secondary.main,
          '&:hover, &:active': {
            color: palette.error.dark,
          },
          '&:hover': {
            backgroundColor: alpha(palette.error.main, 0.08),
            border: `1px solid ${palette.error.main}`,
          },
          '&:active': {
            backgroundColor: alpha(palette.error.main, 0.16),
            border: `1px solid ${palette.error.main}`,
          },
          '&:focus': {
            boxShadow: `0 0 0 2px ${palette.error.light}`,
          },
          '&.Mui-disabled': {
            color: palette.text.disabled,
            border: `1px solid ${palette.ui.tertiary}`,
          },
        },
        '&.icon': {
          backgroundColor: palette.transparent.main,
          color: palette.error.dark,
          '&:hover': {
            backgroundColor: alpha(palette.error.main, 0.08),
          },
          '&:active': {
            backgroundColor: alpha(palette.error.main, 0.16),
          },
          '&.Mui-disabled': {
            color: palette.text.disabled,
          },
        },
        '&.plain': {
          height: '1rem !important',
          width: '1rem',
          padding: '0px',
          backgroundColor: palette.transparent.main,
          color: palette.tertiary.main,
          '&:hover': {
            backgroundColor: palette.transparent.main,
            color: palette.tertiary.hover,
          },
          '&:focus': {
            boxShadow: `0 0 0 2px ${palette.action.focusBorder}`,
          },
          '&:active': {
            color: palette.tertiary.dark,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.transparent.main,
            color: palette.text.disabled,
            border: 'none',
          },
        },
      },
      colorWarning: {
        '&:focus': {
          boxShadow: `0 0 0 2px ${palette.warning.light}`,
        },
        '&.contained': {
          backgroundColor: palette.warning.main,
          color: palette.warning.contrastText,
          '&:active': {
            backgroundColor: palette.warning.active,
          },
          '&:hover': {
            backgroundColor: palette.warning.hover,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.action.disabledBackground,
            color: palette.text.disabled,
          },
        },
        '&.outlined': {
          backgroundColor: palette.transparent.main,
          border: `1px solid ${palette.secondary.light}`,
          color: palette.secondary.main,
          '&:hover, &:active': {
            color: palette.warning.dark,
          },
          '&:hover': {
            backgroundColor: alpha(palette.warning.main, 0.08),
            border: `1px solid ${palette.warning.main}`,
          },
          '&:active': {
            backgroundColor: alpha(palette.warning.main, 0.16),
            border: `1px solid ${palette.warning.main}`,
          },

          '&.Mui-disabled': {
            color: palette.text.disabled,
            border: `1px solid ${palette.ui.tertiary}`,
          },
        },
        '&.icon': {
          backgroundColor: palette.transparent.main,
          color: palette.warning.dark,
          '&:hover': {
            backgroundColor: alpha(palette.warning.main, 0.08),
          },
          '&:active': {
            backgroundColor: alpha(palette.warning.main, 0.16),
          },
          '&.Mui-disabled': {
            color: palette.text.disabled,
          },
        },
        '&.plain': {
          height: '1rem !important',
          width: '1rem',
          padding: '0px',
          backgroundColor: palette.transparent.main,
          color: palette.tertiary.main,
          '&:hover': {
            backgroundColor: palette.transparent.main,
            color: palette.tertiary.hover,
          },
          '&:focus': {
            boxShadow: `0 0 0 2px ${palette.action.focusBorder}`,
          },
          '&:active': {
            color: palette.tertiary.dark,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.transparent.main,
            color: palette.text.disabled,
            border: 'none',
          },
        },
      },
      colorInherit: {
        '&.Mui-disabled': {
          backgroundColor: palette.transparent.main,
          color: palette.action.disabled01,
        },
      },
    },
  };
};

export default iconButtonOverride;
