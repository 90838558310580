import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_secured/company-profile/naming-convention/',
)({
  loader: async ({ context }) => {
    await context.serviceLocator.getInstance(
      'i18n',
      '@myn/naming-convention_v2',
    );
  },
});
