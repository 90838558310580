import styled from '@mint-lib/styled';

import Box from '../Box/Box.jsx';

export interface FileUploaderItemProps {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  /**
   * If true, error styles apply to component
   */
  error?: boolean;
}

const FileUploaderItem = ({ error, ...props }: FileUploaderItemProps) => (
  <StyledFileUploaderItem $error={error} {...props}>
    {props.children}
  </StyledFileUploaderItem>
);

export default FileUploaderItem;

const StyledFileUploaderItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$error' && prop !== '$fullWidth',
})<{
  $error?: boolean;
  $fullWidth?: boolean;
}>(({ $error, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '4px 8px 4px 16px',
  borderRadius: '4px',
  height: '2rem',
  border: $error
    ? `1px solid ${theme.palette.error.main}`
    : `1px solid ${theme.palette.ui.tertiary}`,
}));
