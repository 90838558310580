import { forwardRef } from 'react';

import styled from '@mint-lib/styled';
import TextFieldM, {
  BaseTextFieldProps,
  TextFieldProps as TextFieldPropsM,
} from '@mui/material/TextField';
import { TooltipProps as TooltipPropsM } from '@mui/material/Tooltip';

import Label from '../../common/Input/Label.jsx';
import Box from '../Box/Box.jsx';
import FormHelperText from '../FormHelperText/FormHelperText.jsx';
import Icon from '../Icon/Icon.jsx';
import Truncation from '../Truncation/Truncation.jsx';

export interface TextFieldProps extends BaseTextFieldProps {
  /**
   * The label content
   */
  label?: string;
  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder?: string;
  /**
   * The value of the `Input` element, required for a controlled component.
   */
  value?: TextFieldPropsM['value'];
  /**
   * This prop helps users to fill forms faster, especially on mobile devices.
   * The name can be confusing, as it's more like an autofill.
   * You can learn more about it: https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill.
   */
  /** @uxpinignoreprop */
  autoComplete?: string;
  /**
   * Override or extend the styles applied to the component. See CSS API: https://mui.com/api/text-field/#css.
   */
  /** @uxpinignoreprop */
  classes?: TextFieldPropsM['classes'];
  /** The size of the component */
  size?: TextFieldPropsM['size'];
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   * @default primary
   */
  color?: 'primary' | 'secondary';
  /**
   * If 'dense' or 'normal', will adjust vertical spacing of this and contained components.
   */
  margin?: TextFieldPropsM['margin'];
  /** The default value. Use when the component is not controlled. */
  /** @uxpinignoreprop */
  defaultValue?: string;
  /** If 'true', the component is disabled. */
  disabled?: boolean;
  /**
   * If `true`, the label is displayed as required and the input will be required.
   */
  required?: boolean;
  /** If 'true', the label is displayed in an error state. */
  error?: boolean;
  /**
   * If 'true', the input will take up the full width of its container.
   */
  fullWidth?: boolean;
  /**
   * If 'true', the input element is focused during the first mount.
   */
  autoFocus?: boolean;
  /**
   * The id of the input element. Use this prop to make label and helperText accessible for screen readers.
   */
  /** @uxpinignoreprop */
  id?: string;
  /**
   * Callback fired when the value is changed.
   */
  onChange?: TextFieldPropsM['onChange'];
  /** The system prop that allows defining
   * system overrides as well as additional CSS styles.
   * See the `sx` page for more details. https://mui.com/system/the-sx-prop/ */
  /** @uxpinignoreprop */
  sx?: TextFieldPropsM['sx'];
  InputLabelProps?: TextFieldPropsM['InputLabelProps'];
  InputProps?: TextFieldPropsM['InputProps'];
  readOnly?: boolean;
  warning?: boolean;
  infoText?: string;
  InfoTextTooltipProps?: Partial<TooltipPropsM>;
  HelperTextTooltipProps?: Partial<TooltipPropsM>;
  helperTextWidth?: string;
}
const TextField = forwardRef(
  (
    {
      InputLabelProps,
      readOnly,
      label,
      helperText,
      error,
      warning,
      infoText,
      disabled,
      InfoTextTooltipProps,
      HelperTextTooltipProps,
      fullWidth,
      helperTextWidth = '12.5rem',
      ...props
    }: TextFieldProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <Box>
        {label ? (
          <Label
            disabled={disabled}
            label={label}
            infoText={infoText}
            TooltipProps={InfoTextTooltipProps}
          />
        ) : null}
        <TextFieldM
          {...props}
          sx={{
            width: fullWidth ? '100%' : '200px',
            '.MuiOutlinedInput-root': {
              padding: '8px 12px',
              '& input': {
                padding: '0px',
              },
            },
          }}
          variant="outlined"
          error={error}
          label=""
          helperText=""
          InputProps={{
            className: `${warning ? 'warning-input' : ''}`,
            readOnly: readOnly,
            disabled: disabled,
            ...props.InputProps,
          }}
          inputProps={{ ...props.inputProps }}
          ref={ref}
        />
        {helperText ? (
          <StyledFormHelperText
            sx={{
              width: fullWidth
                ? '100%'
                : helperTextWidth
                  ? helperTextWidth
                  : '200px',
            }}
            $warning={warning}
            error={error}
            disabled={disabled}
          >
            {warning && !error ? <Icon component="WarningAltFilled" /> : null}
            {error ? <Icon component="WarningFilled" /> : null}
            <Truncation
              tooltipTitle={helperText ? helperText : ''}
              tooltipPlacement="bottom"
              variant="helperText01"
              TooltipProps={HelperTextTooltipProps}
            >
              {helperText}
            </Truncation>
          </StyledFormHelperText>
        ) : null}
      </Box>
    );
  },
);
export default TextField;

const StyledFormHelperText = styled(FormHelperText, {
  shouldForwardProp: (prop) => prop !== '$warning',
})<{
  $warning?: boolean;
}>(({ $warning, theme }) => ({
  display: 'flex',
  gap: '5px',
  color: $warning ? theme.palette.warning.main : '',
}));
