import React, { forwardRef } from 'react';

import { AlertProps as AlertPropsM } from '@mui/material/Alert';

import Box from '../Box/Box.jsx';
import IconButton from '../IconButton/IconButton.jsx';
import Truncation from '../Truncation/Truncation.jsx';
import Typography from '../Typography/Typography.jsx';
import { ActionContainer, StyledAlert } from './Alert.styles.jsx';
import { renderIcons } from './utils.jsx';

export interface AlertProps extends Omit<AlertPropsM, 'action' | 'children'> {
  /**
   * Variant of the text layout
   * @default multiline
   */
  textLayout?: 'inline' | 'multiline';
  /**
   * Alert's subtitle
   */
  subTitle?: React.ReactNode;
  /**
   * Property for buttons that can be placed inside alert
   */
  actions?: React.ReactNode;
  /**
   * Title for tooltip that appears when description or/and title truncated
   */
  tooltipTitle?: React.ReactNode;
  /**
   * onClose
   */
  onClose?: (...args: any) => void;
  /**
   * Close button displaying
   */
  removable?: boolean;
  /**
   * The ARIA role attribute of the element.
   * @default 'alert'
   */
  role?: string;
}

const Alert = forwardRef(
  (
    {
      textLayout = 'multiline',
      severity = 'success',
      removable = false,
      role = 'alert',
      title,
      ...props
    }: AlertProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const contentVariantsMap = {
      inline: InlineAlert,
      multiline: MultilineAlert,
    };

    const AlertContentVariants = contentVariantsMap[textLayout];

    return (
      <StyledAlert
        textLayout={textLayout}
        severity={severity}
        removable={removable}
        role={role}
        {...props}
        icon={false}
        action={
          removable ? (
            <IconButton
              size="small"
              color="primary"
              variant="icon"
              icon="Close"
              onClick={props.onClose}
              data-testid="alert-close-button"
            />
          ) : null
        }
        ref={ref}
      >
        {renderIcons(severity)}
        <AlertContentVariants
          textLayout={textLayout}
          title={title}
          {...props}
        />
      </StyledAlert>
    );
  },
);

const InlineAlert = ({
  subTitle,
  actions,
  tooltipTitle,
  title,
}: AlertProps) => {
  return (
    <>
      <Truncation tooltipTitle={tooltipTitle}>
        {title ? (
          <Typography component="span" variant="h6" mr="4px">
            {title}
          </Typography>
        ) : null}
        {subTitle ? (
          <Typography component="span" variant="bodyShort01">
            {subTitle}
          </Typography>
        ) : null}
      </Truncation>
      {actions ? <ActionContainer>{actions}</ActionContainer> : null}
    </>
  );
};

const MultilineAlert = ({ subTitle, actions, title }: AlertProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {title ? (
        <Typography variant="h6" mb="4px">
          {title}
        </Typography>
      ) : null}
      {subTitle ? (
        <Typography variant="bodyShort01">{subTitle}</Typography>
      ) : null}
      {actions ? <ActionContainer mt="8px">{actions}</ActionContainer> : null}
    </Box>
  );
};

export default Alert;
