import { createFileRoute } from '@tanstack/react-router';

import { z } from 'zod';

const searchSchema = z.object({
  tab: z
    .union([
      z.literal('imports'),
      z.literal('templates'),
      z.literal('glossary'),
    ])
    .optional()
    .default('imports')
    .catch('imports'),
  search: z.string().optional(),
  ordering: z.string().optional(),
  pageNumber: z.number().optional(),
  itemsPerPage: z.number().optional(),
  filters: z
    .object({
      importType: z.union([z.literal('auto'), z.literal('manual')]).optional(),
      dateFrom: z.string().optional(),
      dateTo: z.string().optional(),
      status: z
        .union([
          z.literal('draft'),
          z.literal('uncompleted'),
          z.literal('loading'),
          z.literal('uploaded'),
          z.literal('failed'),
          z.literal('deleting'),
          z.literal('archived'),
        ])
        .array()
        .optional(),
      template: z.string().array().optional(),
      platform: z.string().array().optional(),
      sender: z.string().array().optional(),
    })
    .optional(),
});

export const Route = createFileRoute(
  '/_secured/company-profile/import-hub/$ingestionType/',
)({
  validateSearch: searchSchema,
});
