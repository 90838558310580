import { SessionStorage } from '../constants/LocalStorage.js';
import type { WorkspacesAuthStorage } from '../types.js';
import { baseAuthStorage } from './base.js';

export const workspaceAuthStorage: WorkspacesAuthStorage = {
  ...baseAuthStorage,
  getWorkspaceAccessToken() {
    return sessionStorage.getItem(SessionStorage.ACCESS);
  },
  setWorkspaceAccessToken(token: string | null) {
    if (!token) {
      sessionStorage.removeItem(SessionStorage.ACCESS);
      return;
    }
    sessionStorage.setItem(SessionStorage.ACCESS, token);
  },
  getWorkspaceAccessTokenExpiresIn() {
    return parseInt(
      sessionStorage.getItem(SessionStorage.ACCESS_EXPIRES_IN) || '0',
      10,
    );
  },
  setWorkspaceAccessTokenExpiresIn(expiresIn: number | null) {
    if (!expiresIn) {
      sessionStorage.removeItem(SessionStorage.ACCESS_EXPIRES_IN);
      return;
    }
    sessionStorage.setItem(
      SessionStorage.ACCESS_EXPIRES_IN,
      expiresIn.toString(),
    );
  },
  getWorkspaceRefreshToken() {
    return sessionStorage.getItem(SessionStorage.REFRESH);
  },
  setWorkspaceRefreshToken(token: string | null) {
    if (!token) {
      sessionStorage.removeItem(SessionStorage.REFRESH);
      return;
    }
    sessionStorage.setItem(SessionStorage.REFRESH, token);
  },
  getWorkspaceRefreshTokenExpiresIn() {
    return parseInt(
      sessionStorage.getItem(SessionStorage.REFRESH_EXPIRES_IN) || '0',
      10,
    );
  },
  setWorkspaceRefreshTokenExpiresIn(expiresIn: number | null) {
    if (!expiresIn) {
      sessionStorage.removeItem(SessionStorage.REFRESH_EXPIRES_IN);
      return;
    }
    sessionStorage.setItem(
      SessionStorage.REFRESH_EXPIRES_IN,
      expiresIn.toString(),
    );
  },
};
