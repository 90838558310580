import React, { forwardRef } from 'react';

import IconButtonM, {
  IconButtonProps as IconButtonPropsM,
} from '@mui/material/IconButton';
import { IconButtonTypeMap } from '@mui/material/IconButton/IconButton';
import { OverridableComponent } from '@mui/material/OverridableComponent.js';

import type { IconsList as Icons } from '../../assets/asyncIcons.jsx';
import Icon, { IconProps } from '../Icon/Icon.jsx';

export interface IconButtonProps extends IconButtonPropsM {
  /**
   * The icon element.
   */
  icon?: Icons;
  /**
   * Icon props
   */
  IconProps?: IconProps;
  /**
   * Variant of icon button
   */
  variant?: 'contained' | 'outlined' | 'icon' | 'plain';
}
const IconButton = forwardRef(
  (
    {
      variant = 'contained',
      color = 'primary',
      className,
      ...props
    }: IconButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <IconButtonM
        ref={ref}
        color={color}
        className={`${variant} ${className}`}
        {...props}
      >
        {props.icon ? <Icon component={props.icon} /> : null}
      </IconButtonM>
    );
  },
) as OverridableComponent<IconButtonTypeMap<IconButtonProps>>;

export default IconButton;
