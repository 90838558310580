// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { InternalAxiosRequestConfig } from 'axios';

import type { AuthServiceInterface } from '../AuthServiceInterface.js';

let auth: AuthServiceInterface | null = null;

export function setAuthForAccessToken(newAuth: AuthServiceInterface) {
  auth = newAuth;
}

export const applyLocalAccessToken = (request: InternalAxiosRequestConfig) =>
  applyAccessToken(request, true);

export async function applyAccessToken(
  request: InternalAxiosRequestConfig,
  ignoreWorkspaceToken = false,
) {
  if (import.meta.env.VITEST_WORKER_ID || auth === null) {
    return request;
  }

  return {
    ...request,
    headers: {
      ...request.headers,
      Authorization: await auth.provideBearerToken(!ignoreWorkspaceToken),
    },
  } as InternalAxiosRequestConfig;
}
