import { forwardRef } from 'react';

import styled from '@mint-lib/styled';

import Icon from '../Icon/Icon.jsx';
import TableCell, { Props as TableCellProps } from '../TableCell/TableCell.jsx';

export interface TableHeaderCellProps extends TableCellProps {
  sorted?: true | false | null;
  /**
   * Callback fired when the component is draggable.
   */
  onDrag?: (...args: any) => void;
  /**
   * Callback fired when the onSort button and text clicked.
   */
  onSort?: (...args: any) => void;
  /**
   * initState of arrow when sorted null
   */
  initSortDirection?: boolean;
  /**
   * subheader styles
   */
  subheader?: boolean;
  /**
   * default variant takes 100% of the container so "width" prop for table cell is required
   */
  skeletonVariant?: 'default' | 'checkbox' | 'avatar';
}

const TableHeaderCell = forwardRef(
  (
    {
      sorted,
      onSort,
      onDrag,
      initSortDirection,
      subheader,
      ...props
    }: TableHeaderCellProps,
    ref: React.ForwardedRef<HTMLTableCellElement>,
  ) => {
    const sortAndTextProps = onSort
      ? {
          onClick: onSort,
        }
      : {};

    const displayArrow = (): 'ArrowUp' | 'ArrowDown' => {
      if (sorted || (sorted === null && initSortDirection)) {
        return 'ArrowUp';
      }
      return 'ArrowDown';
    };

    return (
      <StyledTableHeaderCell
        variant="head"
        ref={ref}
        $subheader={subheader}
        {...props}
      >
        <Container align={props.align} className="outer-container">
          {onDrag ? (
            <Icon
              aria-label="drag-button"
              className="drag-button"
              color="inherit"
              component="Draggable"
              onClick={onDrag}
            />
          ) : null}
          <SortAndTextContainer
            className="sort-and-text-container"
            align={props.align}
            sorted={sorted}
            {...sortAndTextProps}
          >
            {onSort ? (
              <Icon
                aria-label="sort-button"
                className="sort-button"
                component={displayArrow()}
              />
            ) : null}
            {props.children}
          </SortAndTextContainer>
        </Container>
      </StyledTableHeaderCell>
    );
  },
);

export default TableHeaderCell;

const SortAndTextContainer = styled('div')<{
  align?: TableCellProps['align'];
  sorted?: true | false | null;
}>(({ theme, align, sorted }) => ({
  '&:hover': {
    '.sort-button': {
      color: theme.palette.ui.inverse01,
      visibility: 'visible',
    },
  },
  '.sort-button': {
    visibility: sorted === true || sorted === false ? 'visible' : 'hidden',
  },
  display: 'flex',
  width: 'inherit',
  minWidth: 0,
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexDirection: align === 'left' ? 'row-reverse' : 'row',
  gap: '0.5rem',
}));

const StyledTableHeaderCell = styled(TableCell, {
  shouldForwardProp: (prop) =>
    prop !== '$subheader' && prop !== 'skeletonVariant',
})<{
  $subheader?: boolean;
}>(({ $subheader, theme }) => ({
  ...theme.typography.h6,
  backgroundColor: $subheader
    ? theme.palette.ui.secondary
    : theme.palette.ui.primary,
  height: '2.5rem',
  padding: '0 1rem 0 1rem',
  boxSizing: 'border-box',
  verticalAlign: 'middle',
  borderTop: $subheader
    ? `1px solid ${theme.palette.border.secondary}`
    : 'none',
  '.drag-button': {
    visibility: 'hidden',
  },
  '&:hover': {
    '.drag-button': { visibility: 'visible' },
  },
}));

const Container = styled('div')<{ align?: TableCellProps['align'] }>(
  ({ align }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: align === 'left' ? 'flex-start' : 'space-between',
    gap: '0.5rem',
  }),
);
