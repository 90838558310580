import api from '@mint-lib/api';
import { snakeCase } from 'snake-case';

import { BaseFile } from '@myn/mui';

import {
  CHECK_EMAIL,
  RESEND_USER_INVITATION,
  SWITCH_USER_STATUS,
  USERS,
} from '../endpointConstants.js';
import type { WithPaginationResponse } from '../types.js';
import { paramsToURLSearchParams } from '../utils.js';
import {
  CreateUserType,
  UserType,
  WithPaginationRequest,
  type SwitchUserStatusResp,
  type UserStatus,
} from './users.types.js';

export const fetchUsers = async (
  params: WithPaginationRequest,
): Promise<WithPaginationResponse<UserType> | UserType[]> => {
  const data = await api.get(`${USERS}?${paramsToURLSearchParams(params)}`);

  return data.data;
};

export const createUser = async (params: CreateUserType): Promise<UserType> => {
  const formData = new FormData();

  Object.entries(params).forEach(([key, value]) => {
    if (value != null) {
      if (Array.isArray(value)) {
        value.forEach((item) => formData.append(snakeCase(key), String(item)));
      } else if (key === 'avatar' && !!value) {
        formData.append(snakeCase(key), (value as BaseFile).fileBlob as Blob);
      } else {
        formData.append(snakeCase(key), String(value));
      }
    }
  });

  const data = await api.post(USERS, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return data.data;
};

export const editUser = async (params: CreateUserType): Promise<UserType> => {
  const formData = new FormData();

  Object.entries(params).forEach(([key, value]) => {
    if (value != null || key === 'id') {
      if (Array.isArray(value)) {
        value.forEach((item) => formData.append(snakeCase(key), String(item)));
      } else if (key === 'avatar' && !!value) {
        formData.append(snakeCase(key), (value as BaseFile).fileBlob as Blob);
      } else {
        formData.append(snakeCase(key), String(value));
      }
    }
  });

  const data = await api.patch(`${USERS}${params.id}/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return data.data;
};

export const deleteUser = (id: number | string): Promise<void> =>
  api.delete(`${USERS}${id}/`);

export const toggleUserActivityStatus = (
  id: number | string,
): Promise<SwitchUserStatusResp> =>
  api
    .post(SWITCH_USER_STATUS, {
      id,
    })
    .then((data) => data.data);

export const resendUserInvitation = (id: number | string): Promise<void> =>
  api.post(RESEND_USER_INVITATION, {
    id,
  });

// 404 if no user exists with such email
// 200 if exists + user data
// 400 if email is not valid
export const checkEmail = async (email: string): Promise<Partial<UserType>> => {
  const formData = new FormData();

  formData.append('email', email);

  const data = await api.post(`${CHECK_EMAIL}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return data.data;
};

export type CheckEmailResponseType = {
  status: UserStatus;
  user: Partial<UserType>;
};

export const checkEmailNew = async (
  email: string,
): Promise<CheckEmailResponseType> => {
  const formData = new FormData();

  formData.append('email', email);

  const data = await api.post(`${CHECK_EMAIL}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return data.data;
};
