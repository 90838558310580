import { useContext, useEffect, useRef, useState } from 'react';

import { useEvent } from '@mint-lib/hooks';
import type {
  ServiceArgs,
  ServiceInstance,
  ServicesNames,
} from '@mint-lib/service-locator';

import { MintServiceLocator } from '../providers/MintServiceLocator.js';
import { MintServices } from '../types.js';

export function useInstance<
  Key extends ServicesNames<MintServices>,
  Args extends ServiceArgs<MintServices, Key>,
  Service extends ServiceInstance<MintServices, Key, Args>,
>(name: Key, ...args: Args): [Service | null, number] {
  const serviceLocator = useContext(MintServiceLocator);
  if (!serviceLocator) {
    throw new Error('No RouterContext found');
  }
  const [instance, setInstance] = useState<Service | null>(
    // Let's try to get the instance synchronously first
    () => serviceLocator.getSyncInstance<Key, Args, Service>(name, ...args),
  );
  const [version, setVersion] = useState(0);
  const resolved = useRef(instance);

  const refresh = useEvent((unmounted: { current: boolean }) => {
    serviceLocator
      .getInstance<Key, Args, Service>(name, ...args)
      .then((inst) => {
        if (unmounted.current) {
          return;
        }
        setInstance(inst);
        setVersion((v) => v + 1);
        resolved.current = inst;
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  });

  // Get the instance from the service locator
  useEffect(() => {
    if (instance) {
      return;
    }
    const unmounted = { current: false };
    refresh(unmounted);
    return () => {
      unmounted.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, ...args]);

  // Subscribe to changes if the instance supports it
  useEffect(() => {
    if (
      instance &&
      typeof instance === 'object' &&
      'subscribe' in instance &&
      typeof instance.subscribe === 'function'
    ) {
      return instance?.subscribe(() => {
        setVersion((v) => v + 1);
      });
    }
  }, [instance]);

  // Subscribe to changes in the service locator
  useEffect(() => {
    const unmounted = { current: false };
    const unsub = serviceLocator
      .getEventBus()
      .on(serviceLocator.makeInstanceName(name, args), 'destroy', () => {
        // We need to refresh the instance, but give the event time to propagate
        requestAnimationFrame(() => refresh(unmounted));
      });
    return () => {
      unmounted.current = true;
      unsub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, ...args]);

  return [instance, version];
}
