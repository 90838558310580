import { Palette } from '@mui/material/styles';

const PopoverOverrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        '.MuiPopover-paper': {
          backgroundColor: palette.ui.primary,
          borderRadius: '4px',
          boxShadow:
            '0px 0px 1px 0px rgba(9, 30, 66, 0.24), 0px 2px 4px 0px rgba(9, 30, 66, 0.16)',
          border: `1px solid ${palette.border.tertiary} `,
        },
      },
    },
  };
};

export default PopoverOverrides;
