import { Palette } from '@mui/material/styles';

import typography from './typography.js';

const TabOverrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        ...typography.bodyShort01,
        borderRadius: '4px',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none' as const,
        flexDirection: 'row' as const,
        color: palette.text.secondary,
        minHeight: 0,
        backgroundColor: palette.transparent.main,
        '.MuiTabs-vertical &': {
          justifyContent: 'flex-start',
        },
        '&:hover': {
          backgroundColor: palette.ui.hover,
        },
        '&:active': {
          backgroundColor: palette.transparent.main,
          color: palette.tertiary.main,
        },
        '&:focus-visible': {
          outline: `none`,
        },
        '& .MuiTab-iconWrapper': {
          margin: '0 8px 0 0',
          width: 16,
          height: 16,
        },
        '& .MuiSvgIcon-root': {
          width: 16,
          height: 16,
          color: 'currentColor',
        },
        '&.Mui-selected': {
          color: palette.tertiary.main,
        },
        '&.Mui-disabled': {
          backgroundColor: palette.transparent.main,
          color: palette.action.disabled01,
        },
      },
    },
  };
};

export default TabOverrides;
