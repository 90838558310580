import { forwardRef, MouseEventHandler, Ref } from 'react';

import styled, { isPropValid } from '@mint-lib/styled';
import { TooltipProps as TooltipPropsM } from '@mui/material/Tooltip';

import Box from '../../components/Box/Box.jsx';
import Icon from '../../components/Icon/Icon.jsx';
import TextField from '../../components/TextField/TextField.jsx';

interface Props {
  onClick?: MouseEventHandler<HTMLDivElement>;
  value?: string | null;
  variant: 'single' | 'range';
  classname?: string;
  color?: 'primary' | 'secondary';
  label?: string;
  fullWidth?: boolean;
  helperText?: string;
  onInputClick?: () => void;
  error?: boolean;
  infoText?: string;
  disabledTextfield?: boolean;
  warning?: boolean;
  readOnlyTextfield?: boolean;
  InfoTextTooltipProps?: Partial<TooltipPropsM>;
  endAdornment?: React.ReactNode;
  size?: 'small' | 'medium';
}
const CalendarButton = (
  {
    onClick,
    value,
    variant,
    fullWidth,
    label,
    color,
    helperText,
    classname = '',
    onInputClick,
    error = false,
    infoText,
    disabledTextfield,
    InfoTextTooltipProps,
    warning,
    size,
    readOnlyTextfield,
    endAdornment,
    ...props
  }: Props,
  ref: Ref<HTMLDivElement>,
) => {
  return (
    <CalendarButtonWrapper
      ref={ref}
      className={classname}
      onClick={onInputClick}
      fullWidth={fullWidth}
      $readOnlyTextfield={readOnlyTextfield}
      role="button"
    >
      <TextField
        {...props}
        color={color}
        value={value}
        size={size}
        fullWidth={fullWidth}
        error={error}
        disabled={disabledTextfield}
        warning={warning}
        label={label}
        readOnly={readOnlyTextfield}
        InputProps={{
          endAdornment: (
            <>
              <Box sx={{ marginRight: '8px' }}>
                {endAdornment ? endAdornment : null}
              </Box>
              <Icon component="Calendar" sx={{ color: 'text.secondary' }} />
            </>
          ),
          sx: { cursor: 'pointer', paddingRight: '12px' },
        }}
        InfoTextTooltipProps={InfoTextTooltipProps}
        InputLabelProps={{ shrink: true }}
        placeholder={variant === 'single' ? 'Select date' : 'Select date range'}
        helperTextWidth="280px"
        onClick={onClick}
        helperText={helperText}
        sx={{ cursor: 'pointer' }}
        infoText={infoText}
      />
    </CalendarButtonWrapper>
  );
};
type CalendarButtonProps = {
  fullWidth?: boolean;
  $readOnlyTextfield?: boolean;
};
export default forwardRef<HTMLDivElement, Props>(CalendarButton);
const CalendarButtonWrapper = styled('div', {
  shouldForwardProp: isPropValid,
})<CalendarButtonProps>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'min-content')};

  .MuiTextField-root {
    min-width: 280px;
  }
`;
