import { RouterContext } from '@mint-lib/routing-context';
import z from 'zod';

export const qrCodeRequest = z.object({
  code: z.string(),
  signal: z.instanceof(AbortSignal),
});

export const qrCodeResponse = z.object({
  qrCode: z.string(),
});

export async function mfaActivateLoader(
  ctx: RouterContext,
  req: z.infer<typeof qrCodeRequest>,
): Promise<{
  qrCode: string;
}> {
  const { code, signal } = qrCodeRequest.parse(req);

  const http = await ctx.serviceLocator.getInstance(
    'http',
    'guestApi' as const,
  );
  const response = await http.get(
    `/api/authentication/v1/activate/mfa/app/${code}/`,
    {
      signal,
    },
  );

  return {
    qrCode: qrCodeResponse.parse(response.data).qrCode,
  };
}
