import { FileError } from 'react-dropzone';

import { Theme } from '@mint-lib/styled';
import { nanoid } from 'nanoid';

import {
  BaseFile,
  LocalErrorFile,
  LocalFile,
  LocalInvalidFile,
  LocalValidFile,
  PersistentFile,
  ReadyForUploadFile,
} from './types.js';

export function isDefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}

function isObject(error: unknown): error is {} {
  return typeof error === 'object' && error != null;
}

function isObjectURL(url: string) {
  return url.startsWith('blob:');
}

export function isFileError(error: unknown): error is FileError {
  if (isObject(error) && 'message' in error) {
    return true;
  }
  return false;
}

export function isMimeTypeImage(mimeType: string) {
  return mimeType && mimeType.startsWith('image/');
}

export function isPersistentFile(file: BaseFile): file is PersistentFile {
  return Boolean(file.isUploaded);
}

export function isLocalFile(file: BaseFile): file is LocalFile {
  return !isPersistentFile(file);
}

export function isLocalValidFile(file: BaseFile): file is LocalValidFile {
  return isLocalFile(file) && !file.validationError;
}

export function isLocalInvalidFile(file: BaseFile): file is LocalInvalidFile {
  return isLocalFile(file) && Boolean(file.validationError);
}

export function isReadyForUploadFile(
  file: BaseFile,
): file is ReadyForUploadFile {
  return isLocalValidFile(file) && !file.uploadError;
}

export function isLocalErrorFile(file: BaseFile): file is LocalErrorFile {
  return isLocalValidFile(file) && Boolean(file.uploadError);
}

export function createPreviewForFileBlob(fileBlob: File) {
  if (isMimeTypeImage(fileBlob.type)) {
    return URL.createObjectURL(new Blob([fileBlob], { type: fileBlob.type }));
  }
}

export function revokePreviewUrl(previewUrl?: string) {
  if (previewUrl && isObjectURL(previewUrl)) {
    URL.revokeObjectURL(previewUrl);
  }
}

type AsyncFunction<T> = (input: T) => Promise<T>;

export const asyncCompose = <T>(
  funcs: Array<AsyncFunction<T>>,
): AsyncFunction<T> => {
  return async (initialValue: T): Promise<T> => {
    let result: T = initialValue;

    for (let i = 0; i < funcs.length; i++) {
      result = await funcs[i](result);
    }

    return result;
  };
};

export function createBaseFile(
  filePartial: Pick<BaseFile, 'name'> & Partial<BaseFile>,
): BaseFile {
  return {
    ...filePartial,
    cid: nanoid(),
    isPersistentFile() {
      return isPersistentFile(this);
    },
    isLocalFile() {
      return isLocalFile(this);
    },
    isLocalValidFile() {
      return isLocalValidFile(this);
    },
    isLocalInvalidFile() {
      return isLocalInvalidFile(this);
    },
    isReadyForUploadFile() {
      return isReadyForUploadFile(this);
    },
    isLocalErrorFile() {
      return isLocalErrorFile(this);
    },
  };
}

export function createBaseFileFromFileBlob(fileBlob: File): LocalFile {
  return createBaseFile({
    fileBlob,
    name: fileBlob.name,
    size: fileBlob.size,
    previewUrl: createPreviewForFileBlob(fileBlob),
    isUploaded: false,
  }) as LocalFile;
}

const kilobyte = 1024;
const megabyte = 1024 * kilobyte;

export function formatBytes(bytes: number, precision = 0): [number, string] {
  if (bytes < kilobyte) {
    return [bytes, 'bytes'];
  }

  if (bytes < megabyte) {
    const kilobytes = (bytes / kilobyte).toFixed(precision);
    return [Number(kilobytes), 'kb'];
  }

  const megabytes = (bytes / megabyte).toFixed(precision);
  return [Number(megabytes), 'mb'];
}

export const getIconButtonColor = (baseFile: BaseFile, theme: Theme) => {
  if (baseFile.isLocalInvalidFile()) {
    return theme.palette.warning.dark;
  }
  if (baseFile.isLocalErrorFile()) {
    return theme.palette.error.dark;
  }
  if (baseFile.isPersistentFile()) {
    return theme.palette.tertiary.main;
  }
  return theme.palette.tertiary.main;
};

export const getFileItemColor = ({
  theme,
  warning,
  error,
  isUploaded,
}: {
  theme: Theme;
  warning?: boolean;
  error?: boolean;
  isUploaded?: boolean;
}) => {
  if (warning) {
    return theme.palette.warning.dark;
  }
  if (error) {
    return theme.palette.error.dark;
  }
  if (isUploaded) {
    return theme.palette.text.primary;
  }
  return theme.palette.text.secondary;
};
