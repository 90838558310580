import { menuItemClasses } from '@mui/material/MenuItem';
import { Palette } from '@mui/material/styles';

import typography from './typography.js';

const menuItemOverrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        ...typography.bodyShort01,
        color: palette.text.primary,
        display: 'block',
        padding: '0.6875rem  12px',
        whiteSpace: 'nowrap' as const,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        minHeight: 0,
        '&:focus': {
          backgroundColor: palette.ui.primary,
        },
        '&:hover': {
          backgroundColor: palette.ui.secondary,
        },
        [`&.${menuItemClasses.selected}`]: {
          backgroundColor: `${palette.background.tertiary} !important`,
          '&:hover': {
            backgroundColor: `${palette.ui.highlight} !important`,
          },
          '&:focus': {
            backgroundColor: palette.ui.hover,
          },
        },
      },
    },
  };
};

export default menuItemOverrides;
