import styled, { useTheme } from '@mint-lib/styled';
import Tooltip, { TooltipProps as TooltipPropsM } from '@mui/material/Tooltip';

import Box from '../../components/Box/Box.jsx';
import Icon from '../../components/Icon/Icon.jsx';
import InputLabelM from '../../components/InputLabel/InputLabel.jsx';

export interface LabelProps {
  /**
   * If true, the component is disabled. The prop defaults to the value (false) inherited from the parent FormControl component.
   * @default false
   */
  disabled?: boolean;
  /**
   * The label of the input. It is only used for layout. The actual labelling is handled by InputLabel.
   */
  label?: string;
  /**
   *  Text to render tooltip for information
   */
  infoText?: string;
  /**
   * Tooltip placement
   */
  tooltipPlacement?: 'top' | 'bottom' | 'right';
  /**
   * Tooltip props
   */
  TooltipProps?: Partial<TooltipPropsM>;
}

const Label = ({ disabled, label, infoText, TooltipProps }: LabelProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '16px',
        gap: '5px',
        marginBottom: '0.25rem',
      }}
    >
      <InputLabelM
        disabled={disabled}
        shrink={true}
        sx={{ transform: 'translate(0, 0)' }}
      >
        {label}
      </InputLabelM>
      {infoText ? (
        <Tooltip arrow title={infoText} {...TooltipProps}>
          <InfoIconWrapper>
            <Icon
              component="Information"
              aria-label="information-icon"
              htmlColor={
                disabled
                  ? theme.palette.action.disabled01
                  : theme.palette.icon.secondary
              }
            />
          </InfoIconWrapper>
        </Tooltip>
      ) : null}
    </Box>
  );
};

export default Label;

const InfoIconWrapper = styled('div')({
  display: 'flex',
});
