import { createFileRoute } from '@tanstack/react-router';

import { z } from 'zod';

export const Route = createFileRoute(
  '/_secured/campaign/$campaignId/settings/adwords/search/$tileId/settings/',
)({
  validateSearch: z.object({
    key: z.string().optional(),
  }),
});
