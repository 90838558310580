import React from 'react';

import styled, { isPropValid } from '@mint-lib/styled';

import type { IconsList as Icons } from '../../assets/asyncIcons.jsx';
import Icon, { IconProps } from '../../components/Icon/Icon.jsx';

interface Props {
  children: React.ReactNode;
  fullWidth?: boolean;
  startIcon?: Icons;
  StartIconProps?: Partial<IconProps>;
  width?: string;
}

const SelectWrapper: React.FC<Props> = ({
  children,
  fullWidth,
  startIcon,
  StartIconProps,
  width,
}: Props) => {
  return (
    <Wrapper>
      {startIcon ? <Icon component={startIcon} {...StartIconProps} /> : null}
      <Container $width={width} $fullWidth={!!fullWidth}>
        {children}
      </Container>
    </Wrapper>
  );
};

export default SelectWrapper;

const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding-right: 8px;
  width: 100%;
`;

const Container = styled('span', { shouldForwardProp: isPropValid })<{
  $fullWidth: boolean;
  $width?: string;
}>`
  width: ${({ $fullWidth, $width }) =>
    ($fullWidth && '100%') || $width || '13.5rem'};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
