import { forwardRef } from 'react';

import styled from '@mint-lib/styled';
import SwitchM, { SwitchProps as SwitchPropsM } from '@mui/material/Switch';

import Typography from '../Typography/Typography.jsx';

export interface SwitchProps extends SwitchPropsM {
  /**
   * Show the on/off text
   */
  withText?: boolean;
  /**
   * Text appear if checked true and withText is true
   */
  checkedText?: string;
  /**
   * Text appear if checked false and withText is true
   */
  unCheckedText?: string;
}

const Switch = forwardRef(
  (
    {
      withText = false,
      checkedText = 'on',
      unCheckedText = 'off',
      ...props
    }: SwitchProps,
    ref,
  ) => {
    return (
      <Wrapper>
        <SwitchM {...props} inputRef={ref} />
        {withText && (
          <StyledTypography variant="bodyShort01" disabled={props.disabled}>
            {props.checked ? checkedText : unCheckedText}
          </StyledTypography>
        )}
      </Wrapper>
    );
  },
);
const StyledTypography = styled(Typography)<{ disabled?: boolean }>(
  ({ theme, disabled }) => ({
    color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
    letterSpacing: 0.32,
  }),
);

const Wrapper = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
  width: fit-content;
`;

export default Switch;
