import { useEffect, useState } from 'react';

/**
 * Use this hook for instances where you need to pass value to a child component or a function
 * and it has some serious logic with this value.
 *
 * Useful for: Autocomplete, Search, etc
 */
export function useDebounce<T>(value: T, delay = 100): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
