import api from '@mint-lib/api';
import { z } from 'zod';

const PLATFORM_ICONS = '/api/bff/v1/platforms/';

const IntegrationType = z.enum(['two_way', 'one_way', 'virtual'] as const);

const IntegrationCategory = z.enum([
  'integrated',
  'publisher',
  'dsp',
  'ad_network',
  'ad_server',
  'dmp',
  'brand_safety',
  'web_analytics',
] as const);

const GetPlatformsResponse = z.array(
  z.object({
    id: z.number(),
    platform: z.string(),
    label: z.string(),
    channels: z.array(z.string()),
    platformType: z.string(),
    description: z.string(),
    category: IntegrationCategory,
    logo: z.string().or(z.null()),
    type: IntegrationType,
    isDeletable: z.boolean().or(z.null()),
    isDeleted: z.boolean(),
    isIntegrated: z.boolean().or(z.null()),
    integrationsCount: z.number().or(z.null()),
    ghostCampaignIntegrationId: z.number().or(z.null()),
    isOmni: z.union([z.boolean(), z.undefined()]),
  }),
);

export const fetchPlatforms = (cancelationToken?: AbortController) =>
  api
    .get(PLATFORM_ICONS, {
      signal: cancelationToken?.signal,
    })
    .then((res) => GetPlatformsResponse.parse(res.data));

export type PlatformsResponce = z.infer<typeof GetPlatformsResponse>;
export type Platform = PlatformsResponce[number];
