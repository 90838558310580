import {
  useTranslation as _useTranslation,
  FallbackNs,
  I18nextProvider,
  initReactI18next,
  Trans,
  Translation,
  UseTranslationOptions,
  UseTranslationResponse,
  withTranslation,
} from 'react-i18next';

import i18n, {
  TFunction as _TFunction,
  FlatNamespace,
  i18n as i18nType,
  KeyPrefix,
  Namespace,
  ParseKeys,
  TOptions,
} from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

export interface CustomWindow extends Window {
  i18n: typeof i18n;
}

const isTest =
  typeof import.meta.env.VITEST_WORKER_ID !== 'undefined' ||
  typeof import.meta.env.VITE_IGNORE_I18N !== 'undefined';

const i18nPath =
  import.meta.env.VITE_BRANCH === 'main'
    ? '/api/localize/translations/{{lng}}?ns={{ns}}'
    : `/api/localize/translations/{{lng}}?ns={{ns}}&branch=${
        import.meta.env.VITE_BRANCH
      }`;

export const i18nInitPromise = i18n
  .use(HttpApi)
  // @ts-ignore
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: isTest
      ? {}
      : {
          loadPath: i18nPath,
          addPath: i18nPath,
          allowMultiLoading: false,
          crossDomain: true,
        },
    fallbackLng: 'en-GB',
    debug: !import.meta.env.PROD && !isTest,
    keySeparator: ':::',
    nsSeparator: '@::',
    supportedLngs: ['en', 'it', 'pt', 'pt-BR', 'en-GB', 'it-IT', 'en-US'],
    react: {
      bindI18n: 'i18n:localeChanged',
      useSuspense: !isTest,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export type TFunctionResult = string | undefined;
export interface TFunction<Ns extends Namespace = 'translation'>
  extends _TFunction<Ns> {
  <
    Key extends ParseKeys<Ns, TOpt, undefined> | TemplateStringsArray,
    TOpt extends TOptions,
    Ret extends TFunctionResult = string,
  >(
    ...args: [key: Key | Key[]]
  ): Ret;

  <
    Key extends ParseKeys<Ns, TOpt, undefined> | TemplateStringsArray,
    TOpt extends TOptions,
    Ret extends TFunctionResult = string,
  >(
    ...args: [key: Key | Key[], options?: TOpt]
  ): Ret;

  <
    Key extends ParseKeys<Ns, TOpt, undefined> | TemplateStringsArray,
    TOpt extends TOptions,
    Ret extends TFunctionResult = string,
  >(
    ...args: [key: Key | Key[], defaultValue: string, options?: TOpt]
  ): Ret;
}
export const t: TFunction = i18n.t.bind(i18n);

export const mockT: TFunction = ((key: string) => key) as TFunction;

export function useTranslation<
  Ns extends FlatNamespace,
  KPrefix extends KeyPrefix<FallbackNs<Ns>> = undefined,
>(
  ns?: Ns,
  options?: UseTranslationOptions<KPrefix>,
): {
  trans: UseTranslationResponse<FallbackNs<Ns>, KPrefix>['t'];
  i18n: UseTranslationResponse<FallbackNs<Ns>, KPrefix>['i18n'];
  ready: UseTranslationResponse<FallbackNs<Ns>, KPrefix>['ready'];
  t: UseTranslationResponse<FallbackNs<Ns>, KPrefix>['t'];
} {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { t, ...rest } = _useTranslation(ns, options);
  return {
    ...rest,
    t,
    trans: t,
  };
}

export { I18nextProvider, Trans, Translation, withTranslation };

// @ts-ignore i18n had problems with typings for ESM import
export default i18n as i18nType;

if (import.meta.env.DEV) {
  (window as never as CustomWindow).i18n = i18n;
}
