import { TFunction, Trans as TransLib } from '@mint-lib/i18n';

import { Link, TypographyProps } from '@myn/mui';

const EmailLink = ({
  children,
  email,
  t,
}: TypographyProps & { email?: string; t: TFunction }) => {
  return (
    <Link
      href={`mailto:${email || t(`support@mint.ai`)}`}
      sx={{ display: 'inline-flex' }}
    >
      {children}
    </Link>
  );
};

export const Trans = ({
  i18nKey,
  t,
  components,
  values,
}: {
  i18nKey: string;
  t: TFunction;
  components?: Record<string, React.ReactNode>;
  values: Record<string, string | undefined>;
}) => {
  return (
    <TransLib
      i18nKey={i18nKey}
      t={t}
      components={{
        ...components,
        emailLink: <EmailLink email={values?.email} t={t} />,
      }}
      values={values}
    />
  );
};
