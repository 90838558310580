import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { RumComponentContextProvider } from '@mint-lib/datadog';
import { FlagsmithProvider } from '@mint-lib/routing-context';

import { queryClient } from '../api/queries/main.js';
import ModalProvider from '../context/ModalContext/ModalProvider.jsx';
import App from './App.jsx';

const PermissionsApp = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <FlagsmithProvider name="@myn/permissions">
        <RumComponentContextProvider componentArea="user-permissions">
          <ModalProvider>
            <App />
          </ModalProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </RumComponentContextProvider>
      </FlagsmithProvider>
    </QueryClientProvider>
  );
};

export default PermissionsApp;
