import { createFileRoute, notFound } from '@tanstack/react-router';

import { currenciesZodSchema } from '@mint-lib/currencies-helpers';
import { buildGuards, hasPermission } from '@mint-lib/routing-guards';
import { format, subMonths } from 'date-fns';
import { z } from 'zod';

const DATE_FORMAT_DASH = `yyyy-MM-dd`;

const formatDateToState = (date: Date | null | undefined) =>
  date ? format(date, DATE_FORMAT_DASH) : null;

const guard = buildGuards(hasPermission('currenciesFeature:view'));

export const Route = createFileRoute('/_secured/company-profile/currencies/')({
  async beforeLoad({ context, search }) {
    const guardsContext = await context.getGuardContext();

    if (!guard(guardsContext)) {
      throw notFound();
    }

    return context;
  },
  validateSearch: z.object({
    startDate: z
      .string()
      .optional()
      .default(formatDateToState(subMonths(new Date(), 6)) as string),
    endDate: z
      .string()
      .optional()
      .default(formatDateToState(new Date()) as string),
    mainCurrency: currenciesZodSchema.optional(),
  }),
  loader: async ({ context }) => {
    await context.serviceLocator.getInstance('i18n', '@myn/currencies');
  },
});
