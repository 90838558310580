import styled from '@mint-lib/styled';

export const StyledDatepickerWrapper = styled('div')`
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }
  .react-datepicker__day-names {
    display: flex;
  }

  /* the hack from the OG CSS file */
  .react-datepicker__aria-live {
    position: absolute;
    clip-path: circle(0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
    white-space: nowrap;
  }
`;
