import { WithPaginationRequest } from './users/users.types.js';

export const paramsToURLSearchParams = (params: WithPaginationRequest) => {
  const queryParams: Record<string, string> = {};

  for (const [key, value] of Object.entries(params)) {
    if (value !== undefined) {
      queryParams[key] = String(value);
    }
  }

  return new URLSearchParams(queryParams).toString();
};
