import { atom } from 'jotai';

import { NormalizedMainCurrencyItem } from '../api/getMainCurrencies/getMainCurrencies.types.js';
import { WithCurrenciesApiResponse } from '../types/types.js';

export type MainCurrenciesState = WithCurrenciesApiResponse<
  NormalizedMainCurrencyItem[]
> | null;

export const mainCurrenciesStore = atom<MainCurrenciesState>(null);
