import { checkboxClasses } from '@mui/material/Checkbox';
import { Palette } from '@mui/material/styles';

const overrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        padding: 0,
        borderRadius: 0,
        width: 20,
        height: 20,
        verticalAlign: 'top',
        '&:hover': {
          backgroundColor: `transparent !important`,
        },
        '&.Mui-focusVisible': {
          outline: `${palette.border.focus} solid 2px`,
          borderRadius: '4px',
        },
        [`&.${checkboxClasses.checked}`]: {
          color: `${palette.tertiary.main} !important`,
          '& .MuiSvgIcon-root': {
            color: palette.tertiary.main,
          },
        },
        [`&.${checkboxClasses.indeterminate}`]: {
          '& .MuiSvgIcon-root': {
            color: palette.tertiary.main,
          },
        },
        [`&.${checkboxClasses.disabled}`]: {
          color: palette.action.disabled01,
          '& .MuiSvgIcon-root': {
            color: palette.action.disabled01,
          },
          '&.readonly-checkbox': {
            color: palette.text.disabled02,
            '& .MuiSvgIcon-root': {
              color: palette.text.disabled02,
            },
          },
        },
        '& .MuiSvgIcon-root': {
          fontSize: '20px',
          width: '20px',
          height: '20px',
          color: palette.icon.primary,
        },
      },
    },
  };
};

export default overrides;
