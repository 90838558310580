import { Palette } from '@mui/material/styles';

import typography from './typography.js';

const PaginationOverrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        ...typography.bodyShort01,
        borderRadius: '4px',
        margin: 0,
        '&.Mui-selected': {
          backgroundColor: palette.tertiary.light,
          color: palette.tertiary.dark,
          '&:hover': {
            backgroundColor: palette.tertiary.light,
            color: palette.tertiary.dark,
          },
        },
        '&:hover': {
          backgroundColor: palette.ui.hover,
          color: palette.text.primary,
        },
        '&:active': {
          backgroundColor: palette.ui.active,
          color: palette.text.primary,
        },
        '&.Mui-disabled': {
          color: palette.action.disabled01,
        },
        '& .Mui-enabled': {
          color: palette.text.primary,
        },
      },
      ellipsis: {
        '&:hover': {
          backgroundColor: 'transparent',
          cursor: 'default',
        },
      },
    },
  };
};
export default PaginationOverrides;
