import { TFunction } from '@mint-lib/i18n';
import * as Yup from 'yup';

const StringWithMaxLength = (
  t: TFunction,
  length: number,
): Yup.StringSchema<string | null | undefined> =>
  Yup.string()
    .trim()
    .max(length, t('Max {{count}} characters', { count: length }))
    .nullable();

export default StringWithMaxLength;
