import React, { useMemo } from 'react';

import styled from '@mint-lib/styled';
import { useTheme } from '@mint-lib/theme';
import SvgIconM, { SvgIconProps } from '@mui/material/SvgIcon';

import {
  IconLoadingState,
  useLazyLoadedIcon,
} from '../../hooks/useLazyLoadedIcon.js';

// @ts-ignore
export interface IllustrationProps extends SvgIconProps {
  component:
    | 'IllustrationLocalError'
    | 'IllustrationFileUpload'
    | 'IllustrationNoResults'
    | 'IllustrationNoConnection'
    | 'IllustrationMaintenance'
    | 'IllustrationError500'
    | 'IllustrationError404'
    | 'IllustrationError403'
    | 'IllustrationEmptyState'
    | 'InterfaceSearching';
}

const Illustration = ({ component, ...props }: IllustrationProps) => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  const componentName = useMemo(() => {
    const modeSuffix = mode.charAt(0).toUpperCase() + mode.slice(1);
    return `${component}${modeSuffix}`;
  }, [component, mode]);
  // @ts-ignore
  const { SvgIcon, state } = useLazyLoadedIcon(componentName);

  return (
    <StyledIcon
      role="img"
      alt={state === IconLoadingState.Loaded ? componentName : ''}
      aria-hidden={state !== IconLoadingState.Loaded}
      {...props}
      aria-label={
        state === IconLoadingState.Loaded ? props['aria-label'] : undefined
      }
      inheritViewBox
      // @ts-ignore
      component={SvgIcon}
    />
  );
};

export default Illustration;

export const StyledIcon = styled(SvgIconM)({
  width: 'inherit',
  height: 'inherit',
  fontSize: 'inherit',
});
