import React, { forwardRef } from 'react';

import { AlertProps } from '../Alert/Alert.jsx';
import { ActionContainer, StyledAlert } from '../Alert/Alert.styles.jsx';
import { renderIcons } from '../Alert/utils.jsx';
import Box from '../Box/Box.jsx';
import IconButton from '../IconButton/IconButton.jsx';
import Typography from '../Typography/Typography.jsx';

export interface SnackbarContentProps extends AlertProps {
  actions?: React.ReactNode;
  fullHeight?: boolean;
}

export const SnackbarContent = forwardRef(
  (
    {
      textLayout = 'multiline',
      severity = 'success',
      removable = false,
      fullHeight,
      ...props
    }: SnackbarContentProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const contentVariantsMap = {
      inline: InlineSnackbar,
      multiline: MultilineSnackbar,
    };

    const SnackbarContentVariants = contentVariantsMap[textLayout];
    return (
      <StyledAlert
        textLayout={textLayout}
        severity={severity}
        icon={false}
        fullHeight={fullHeight}
        removable={removable}
        action={
          removable ? (
            <IconButton
              size="small"
              color="primary"
              variant="icon"
              icon="Close"
              onClick={props.onClose}
            />
          ) : null
        }
        ref={ref}
        sx={{
          boxShadow: '0px 0px 8px rgba(34, 37, 43, 0.24)',
          width: '400px',
          ...props.sx,
        }}
        {...props}
      >
        {renderIcons(severity)}
        <SnackbarContentVariants {...props} />
      </StyledAlert>
    );
  },
);

export const InlineSnackbar = ({ subTitle, actions, title }: AlertProps) => {
  return (
    <>
      <span style={{ width: '100%' }}>
        {title ? (
          <Typography component="span" variant="h6" mr="4px">
            {title}
          </Typography>
        ) : null}
        {subTitle ? (
          <Typography component="span" variant="bodyShort01">
            {subTitle}
          </Typography>
        ) : null}
      </span>
      {actions ? <ActionContainer>{actions}</ActionContainer> : null}
    </>
  );
};

export const MultilineSnackbar = ({ subTitle, actions, title }: AlertProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {title ? (
        <Typography variant="h6" mb="4px">
          {title}
        </Typography>
      ) : null}
      {subTitle ? (
        <Typography variant="bodyShort01">{subTitle}</Typography>
      ) : null}
      {actions ? <ActionContainer mt="8px">{actions}</ActionContainer> : null}
    </Box>
  );
};

export default SnackbarContent;
