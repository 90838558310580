export const ERRORS = {
  invalidType: {
    message: 'File type not supported',
    code: 'file_invalid_type',
  },
  minSize: {
    message: 'File size is too small',
    code: 'file_too_small',
  },
  maxSize: {
    message: 'File size is too big',
    code: 'file_too_big',
  },
  totalSize: {
    message: 'Total allowed size exceeded',
    code: 'total_size',
  },
  fileDuplication: {
    message: 'File name already exists',
    code: 'file_duplication',
  },
  upload: {
    message: 'Failed to upload',
    code: 'upload_error',
  },
} as const;

export const getGlobalErrors = ({ maxFiles }: { maxFiles?: number } = {}) => ({
  hasValidationError: {
    message: "Some files aren't supported",
  },
  totalSize: {
    message: 'Some files exceeded the total allowed size',
    code: 'total_size',
  },
  tooManyFiles: {
    message: `Too many files! You can upload a maximum of ${maxFiles} files`,
    code: 'too_many_files',
  },
  hasUploadError: {
    message: 'There was an error uploading files',
  },
});
