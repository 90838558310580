import { TablePaginationType } from './tablePaginationStore.types.js';

export const DEFAULT_LIMIT = 25;

export const DEFAULT_PAGINATION: Pick<TablePaginationType, 'offset' | 'limit'> =
  { limit: DEFAULT_LIMIT, offset: 0 };

export const DEFAULT_PAGINATION_STATE: TablePaginationType = {
  ...DEFAULT_PAGINATION,
  currentPage: 0,
  totalItems: 0,
};
