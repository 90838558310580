import { radioClasses } from '@mui/material/Radio';
import { Palette } from '@mui/material/styles';

const overrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        padding: 0,
        width: 20,
        height: 20,
        verticalAlign: 'top',
        '&:hover': {
          backgroundColor: `transparent !important`,
        },
        [`&.${radioClasses.checked}`]: {
          '& .MuiSvgIcon-root': {
            color: palette.tertiary.main,
          },
        },
        [`&.${radioClasses.disabled}`]: {
          color: palette.action.disabled01,
          '& .MuiSvgIcon-root': {
            color: palette.action.disabled01,
          },
        },
        '&:focus-within': {
          border: `2px solid ${palette.action.focusBorder}`,
          borderRadius: '50%',
        },
        '& .MuiSvgIcon-root': {
          fontSize: '20px',
          width: '20px',
          height: '20px',
          color: palette.icon.primary,
        },
      },
    },
  };
};

export default overrides;
