import { ReactNode } from 'react';

import styled from '@mint-lib/styled';
import { TooltipProps as TooltipPropsM } from '@mui/material/Tooltip';

import Box from '../../components/Box/Box.jsx';
import FormHelperText from '../../components/FormHelperText/FormHelperText.jsx';
import Icon from '../../components/Icon/Icon.jsx';
import Truncation from '../../components/Truncation/Truncation.jsx';

export interface HelperTextProps {
  /**
   * Value of decimals limit
   */
  warning?: boolean;
  /**
   * Helper Text Tooltip props
   */
  HelperTextTooltipProps?: Partial<TooltipPropsM>;
  /**
   * If true, the input will indicate an error. The prop defaults to the value (false) inherited from the parent FormControl component.
   * @default false
   */
  error?: boolean;
  /**
   * Ability to stretch the entire width of the container
   * @default false
   */
  fullWidth?: boolean;
  /**
   * The helperText of the input. It is only used for layout. The actual labelling is handled by HelperText.
   */
  helperText?: ReactNode;
  /**
   * Helper Text width
   */
  helperTextWidth?: string;
}
const HelperText = ({
  warning,
  HelperTextTooltipProps,
  error,
  fullWidth,
  helperText,
  helperTextWidth,
}: HelperTextProps) => {
  return (
    <Box>
      {helperText ? (
        <StyledFormHelperText
          sx={{ width: fullWidth ? '100%' : helperTextWidth }}
          $warning={warning}
        >
          {warning && !error ? <Icon component="WarningAltFilled" /> : null}
          {error ? <Icon component="WarningFilled" /> : null}
          <Truncation
            tooltipTitle={helperText ? helperText : ''}
            tooltipPlacement="bottom"
            variant="helperText01"
            TooltipProps={HelperTextTooltipProps}
          >
            {helperText}
          </Truncation>
        </StyledFormHelperText>
      ) : null}
    </Box>
  );
};

export default HelperText;

const StyledFormHelperText = styled(FormHelperText, {
  shouldForwardProp: (prop) => prop !== '$warning',
})<{
  $warning?: boolean;
}>(({ $warning, theme }) => ({
  display: 'flex',
  gap: '5px',
  color: $warning ? theme.palette.warning.main : theme.palette.text.secondary,
}));
