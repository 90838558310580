import TooltipM, { TooltipProps as TooltipPropsM } from '@mui/material/Tooltip';

export type TooltipProps = TooltipPropsM;
function Tooltip({ placement = 'top', arrow = true, ...props }: TooltipProps) {
  return (
    <TooltipM placement={placement} arrow={arrow} {...props}>
      {props.children}
    </TooltipM>
  );
}

export default Tooltip;
