import { Theme } from '@mint-lib/styled';
import { AlertColor } from '@mui/material/Alert';

import type { IconsList as Icons } from '../../assets/asyncIcons.jsx';
import Icon from '../Icon/Icon.jsx';

export const renderIcons = (severity: AlertColor) => {
  const icons: {
    [severity: string]: { icon: Icons; color: AlertColor };
  } = {
    error: { icon: 'WarningFilled', color: 'error' },
    success: { icon: 'CheckmarkFilled', color: 'success' },
    info: { icon: 'InformationFilled', color: 'info' },
    warning: { icon: 'WarningAltFilled', color: 'warning' },
  };

  return (
    <Icon component={icons[severity].icon} color={icons[severity].color} />
  );
};
export const controlTextColor = (severity: string, theme: Theme) =>
  ({
    error: theme.palette.text.error,
    success: theme.palette.text.success,
    info: theme.palette.text.info,
    warning: theme.palette.text.warning,
  })[severity];
