const sortObjectByKeys = (obj: Record<string, boolean>) => {
  const keyValueArray: [string, boolean][] = Object.entries(obj).map(
    ([key, value]) => [key, !!value],
  );

  keyValueArray.sort((a, b) => a[0].localeCompare(b[0]));

  return Object.fromEntries(keyValueArray);
};

export default sortObjectByKeys;
