import { useState } from 'react';

import styled from '@mint-lib/styled';

import { Toaster as SonnerToaster } from './sonner/index.jsx';

export const Toaster = () => {
  const [isUnfoldedView, setIsUnfoldedView] = useState(false);

  const handleMouseEnter = () => {
    setIsUnfoldedView(true);
  };

  const handleMouseLeave = () => {
    setIsUnfoldedView(false);
  };

  const visibleToasts = isUnfoldedView ? 50 : 3;

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <StyledSonnerToaster visibleToasts={visibleToasts} />
    </div>
  );
};

const StyledSonnerToaster = styled(SonnerToaster)`
  &[data-sonner-toaster][data-y-position='bottom'][data-x-position='right'] {
    bottom: max(92px, env(safe-area-inset-bottom));
  }

  &[data-sonner-toaster][data-y-position='bottom'][data-x-position='right'] {
    right: max(24px, env(safe-area-inset-right));
  }
`;
