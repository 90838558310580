import { format, precisionFixed } from 'd3-format';

import {
  getNumericFormatTypeComparator,
  isDecimal,
} from './formatComparator.js';
import {
  formatNumber,
  formatPrefix,
  formatWithPostfix,
  formatWithPrefix,
} from './formatter.js';
import {
  DEFAULT_LOCALE,
  formattedPrefixes,
  formatTypes,
  localeSeparatorsSettings,
  localeSeparatorsTypes,
  REGEX_NUMBERS,
} from './formatterType.js';

export const formatToCompactShort = formatNumber({
  localeSeparators: localeSeparatorsTypes,
  defaultLocale: DEFAULT_LOCALE,
  typeResolver: formatTypes.COMPACT_SHORT,
});

export const formatToDecimalShort = formatNumber({
  localeSeparators: localeSeparatorsTypes,
  defaultLocale: DEFAULT_LOCALE,
  typeResolver: formatTypes.DECIMAL_SHORT,
});

export const formatToDecimal3 = formatNumber({
  localeSeparators: localeSeparatorsTypes,
  defaultLocale: DEFAULT_LOCALE,
  typeResolver: formatTypes.DECIMAL_3,
});

export const formatToNumeric = formatNumber({
  localeSeparators: localeSeparatorsTypes,
  defaultLocale: DEFAULT_LOCALE,
  typeResolver: getNumericFormatTypeComparator([
    formatTypes.NUMERIC_INT,
    formatTypes.NUMERIC,
  ]),
});

export const formatToDigit = formatNumber({
  localeSeparators: localeSeparatorsTypes,
  defaultLocale: DEFAULT_LOCALE,
  typeResolver: formatTypes.INTEGER,
});

export const formatToIntOrDec = formatNumber({
  localeSeparators: localeSeparatorsTypes,
  defaultLocale: DEFAULT_LOCALE,
  typeResolver: getNumericFormatTypeComparator([',.0f', formatTypes.NUMERIC]),
});

export const formatToPercentPerformanceAxis = formatNumber({
  localeSeparators: localeSeparatorsTypes,
  defaultLocale: DEFAULT_LOCALE,
  typeResolver: formatTypes.COMPACT_SHORT_AXIS,
});

export const formatToPercent = (number: number) => {
  const p = Math.max(0, precisionFixed(0.05 - 2));
  return format(`.${p}%`)(number);
};

export const formatToBigNumber = (number: number) => {
  if (number < 1) {
    return number;
  }

  const formattedNumber = format('.3~s')(number);

  return formatPrefix(formattedNumber, formattedPrefixes);
};

export const formatToNumber = (number: string | number) => {
  const results = String(number).match(REGEX_NUMBERS);
  return results?.[0] || '';
};

export const formatBudget = (number: string | number) => {
  const [, decimalPart] = `${number}`.split('.');

  return {
    decimalPart,
    hasRightDecimalPart: !!(decimalPart && decimalPart.length < 3),
  };
};

export const metricDefaultFormatter = formatNumber({
  localeSeparators: localeSeparatorsTypes,
  defaultLocale: DEFAULT_LOCALE,
  typeResolver: (number) =>
    isDecimal(number) ? formatTypes.DECIMAL_SHORT : formatTypes.INTEGER_SHORT,
});

const trimPrecedingZero = (value: string): string =>
  value.length > 1 && value.charAt(0) === '0' ? value.substr(1) : value;

export const normalizeValue = ({
  value,
  thousandsSeparator,
  decimalsSeparator,
}: {
  value: string;
  thousandsSeparator: string;
  decimalsSeparator: string;
}) => {
  const [int, decimals] = value.split('.');

  const includeDecimals =
    decimalsSeparator && value.includes('.')
      ? `${decimalsSeparator}${decimals}`
      : '';

  return `${addThousandsSeparator(
    trimPrecedingZero(int),
    thousandsSeparator,
  )}${includeDecimals}`;
};

export const addThousandsSeparator = (value: string, separator: string) =>
  separator ? value.replace(/\B(?=(\d{3})+(?!\d))/g, separator) : value;

export const removeThousandsSeparator = (value: string, separator: string) =>
  separator ? value.replace(new RegExp(`\\${separator}`, 'g'), '') : value;

export const cleanValue = ({
  value,
  allowDecimals,
  decimalsLimit,
  thousandsSeparator,
  decimalsSeparator,
  inlineMetricSign,
}: {
  value: string;
  allowDecimals: boolean;
  decimalsLimit: number;
  thousandsSeparator: string;
  decimalsSeparator: string;
  inlineMetricSign: string;
}) => {
  const withoutThousandsSeparator = removeThousandsSeparator(
    value,
    thousandsSeparator,
  );

  const withoutMetricSign = withoutThousandsSeparator.replace(
    inlineMetricSign,
    '',
  );

  if (withoutMetricSign.includes(decimalsSeparator)) {
    const [int, decimals] = withoutMetricSign.split(decimalsSeparator);
    const includeDecimals = allowDecimals
      ? `.${decimalsLimit ? decimals.slice(0, decimalsLimit) : decimals}`
      : '';

    return `${int}${includeDecimals}`;
  }

  return withoutMetricSign;
};

export const formatToDecimalShortWithPrefix = formatWithPrefix(
  // @ts-ignore
  formatToDecimalShort,
);

// @ts-ignore
export const formatToDecimal3WithPostfix = formatWithPostfix(formatToDecimal3);
export const formatToDecimalShortWithPostfix = formatWithPostfix(
  // @ts-ignore
  formatToDecimalShort,
);

export {
  DEFAULT_LOCALE,
  formattedPrefixes,
  formatTypes,
  localeSeparatorsTypes,
  localeSeparatorsSettings,
  REGEX_NUMBERS,
  isDecimal,
};
