import { IFlagsmithFeature } from '@mint-lib/flags';
import camelCase from 'camelcase';

import { FeatureMetaType } from '../../api/roles/index.js';
import { AccessLevelsMap } from './PermissionsTable.jsx';
import type {
  Dictionary,
  TableCellDataType,
  TableDataType,
} from './permissionsTable.type.js';

const NO_ACCESS_LVL_FEATURES = ['approvalFlowFeature'];

const getDefaultAccessLvl = (featureName: string) => {
  if (NO_ACCESS_LVL_FEATURES.includes(featureName)) {
    return AccessLevelsMap.NoAccess;
  }

  return AccessLevelsMap.ReadOnly;
};

export const mapToTableCellState = (
  labelsMap: Dictionary,
  initialValues: Dictionary,
  item: TableCellDataType,
  ff?: {
    approvalFlowFeature: IFlagsmithFeature;
  },
): TableCellDataType => {
  if (item.children && item.children?.length) {
    if (item?.name === 'campaign_feature' || item?.name === 'settings') {
      return {
        name: item.name,
        label: labelsMap[camelCase(item.name)] ?? item.name,
        children: item.children.map((child) =>
          mapToTableCellState(labelsMap, initialValues, child),
        ),
      };
    }

    return {
      name: item.name,
      label: labelsMap[camelCase(item.name)] ?? item.name,
      accessLevel:
        (initialValues &&
          //@ts-ignore
          initialValues[camelCase(item.name)]) ??
        item.accessLevel ??
        AccessLevelsMap.ReadOnly,
      children: item.children.map((child) =>
        mapToTableCellState(labelsMap, initialValues, child),
      ),
    };
  }

  return {
    name: item.name,
    label: labelsMap[camelCase(item.name)] ?? item.name,
    accessLevel:
      (initialValues &&
        //@ts-ignore
        initialValues[camelCase(item.name)]) ??
      item.accessLevel ??
      (ff?.approvalFlowFeature?.enabled
        ? getDefaultAccessLvl(item.name)
        : AccessLevelsMap.ReadOnly),
    children: null,
  };
};

export const mapChildren = (
  level: TableCellDataType[] | FeatureMetaType[] | null | undefined,
  labelsMap: Dictionary,
  initialValues: Dictionary,
  ff?: {
    approvalFlowFeature: IFlagsmithFeature;
  },
) => {
  return level?.map((item) =>
    mapToTableCellState(
      labelsMap,
      initialValues,
      {
        name: item.name,
        children:
          item.subFeatures &&
          Object.values(item.subFeatures).map(
            (el: TableCellDataType | FeatureMetaType) => {
              if (initialValues && initialValues[camelCase(el.name)]) {
                return {
                  name: camelCase(el.name),
                  accessLevel: initialValues[camelCase(el.name)],
                  children:
                    el.subFeatures &&
                    Object.values(el.subFeatures).map((sub) => {
                      if (initialValues && initialValues[camelCase(sub.name)]) {
                        return {
                          name: camelCase(sub.name),
                          accessLevel: initialValues[camelCase(sub.name)],
                        };
                      }
                      return {
                        name: camelCase(sub.name),
                      };
                    }),
                };
              }
              return {
                name: camelCase(el.name),
                children:
                  el.subFeatures &&
                  Object.values(el.subFeatures).map((sub) => {
                    return {
                      name: camelCase(sub.name),
                    };
                  }),
              };
            },
          ),
      },
      ff,
    ),
  );
};

export const mapToTableState = (
  labelsMap: Dictionary,
  initialValues: Dictionary,
  data: Dictionary<FeatureMetaType[]>,
  ff?: {
    approvalFlowFeature: IFlagsmithFeature;
  },
) => {
  const entries = Object.entries(data).map(([key, level]) => [
    key,
    {
      name: null,
      label: null,
      children: mapChildren(level, labelsMap, initialValues, ff),
    },
  ]);
  return Object.fromEntries(entries) as Dictionary<TableCellDataType>;
};

export const mapCellStateToPermissions = (
  permissionsMap: Dictionary,
  data: TableCellDataType,
): Dictionary<string | undefined> => {
  if (data.children) {
    if (
      data.name &&
      data.name !== 'campaign_feature' &&
      data.name !== 'settings'
    ) {
      return data.children.reduce<any>(
        (acc, child) => ({
          ...acc,
          [data.name]: data.accessLevel,
          ...mapCellStateToPermissions(acc, child),
        }),
        permissionsMap,
      );
    }
    return data.children.reduce<any>(
      (acc, child) => ({
        ...acc,
        ...mapCellStateToPermissions(acc, child),
      }),
      permissionsMap,
    );
  }

  return {
    ...permissionsMap,
    [data.name]: data.accessLevel,
  };
};

export const mapToPermissions = (data: TableDataType): Dictionary => {
  const values = Object.values(data);

  return values.reduce(
    (acc, level: TableCellDataType) => mapCellStateToPermissions(acc, level),
    {},
  );
};

export const mapInitialValues: (initialValues: any) => Dictionary = (
  initialValues,
) => {
  return Object.entries(initialValues).reduce((acc, cur: any[]) => {
    if (cur[0] === 'campaignFeature' || cur[0] === 'settings') {
      return {
        ...acc,
        ...mapInitialValues(cur[1]?.subFeatures),
      };
    }
    return {
      ...acc,
      [cur[0]]: cur[1]?.accessLevel,
      ...mapInitialValues(cur[1]?.subFeatures),
    };
  }, {});
};
