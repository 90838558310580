import React, { forwardRef } from 'react';

import styled from '@mint-lib/styled';
import TabM, { TabProps as TabPropsM } from '@mui/material/Tab';

import type { IconsList as Icons } from '../../assets/asyncIcons.jsx';
import Icon from '../Icon/Icon.jsx';
import Tooltip from '../Tooltip/Tooltip.jsx';

export interface TabProps extends TabPropsM {
  /**
   * The icon element.
   */
  icon?: Icons;
  /**
   * The variant of the tab
   * @default line
   */
  variant?: 'contained' | 'line';
  /**
   * The variant of the tab
   * @default large
   */
  size?: 'medium' | 'large';
  /**
   * Tooltip Title
   *
   */
  tooltipTitle?: string;
  /**
   * Tooltip Placement
   *
   */
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

const Tab = forwardRef(
  (
    {
      variant = 'line',
      size = 'large',
      icon,
      tooltipTitle,
      tooltipPlacement,
      className,
      ...props
    }: TabProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <StyledTab
        {...props}
        ref={ref}
        className={`${size} ${className ? className : ''}`}
        size={size}
        icon={
          icon && (
            <Tooltip title={tooltipTitle || ''} placement={tooltipPlacement}>
              <span>
                <Icon component={icon} data-testid="tab-icon" />
              </span>
            </Tooltip>
          )
        }
      />
    );
  },
);

export default Tab;

const StyledTab = styled(TabM)<{
  size?: 'medium' | 'large';
}>(({ theme, size }) => ({
  height: size === 'large' ? '2.5rem' : '2rem',
  padding: '4px 12px',
}));
