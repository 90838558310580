import Icon from '../Icon/Icon.jsx';

export const LIST_TYPES = ['numbered-list', 'bulleted-list'];

export enum TextStyle {
  heading1 = 'style_heading1',
  heading2 = 'style_heading2',
  normal = 'style_normal',
  numbered = 'numbered-list',
  bulleted = 'bulleted-list',
}

const TextStylesLabelMap = {
  [TextStyle.heading1]: 'Heading 1',
  [TextStyle.heading2]: 'Heading 2',
  [TextStyle.normal]: 'Normal',
  [TextStyle.numbered]: 'Numbered List',
  [TextStyle.bulleted]: 'Bulleted List',
};

export const TextStylesList = [
  {
    value: TextStyle.normal,
    label: TextStylesLabelMap[TextStyle.normal],
    startAdornment: (
      <Icon
        component="TextType"
        sx={{ color: (theme) => theme.palette.icon.secondary }}
      />
    ),
  },
  {
    value: TextStyle.heading1,
    label: TextStylesLabelMap[TextStyle.heading1],
    startAdornment: (
      <Icon
        component="TextHeading"
        sx={{ color: (theme) => theme.palette.icon.secondary }}
      />
    ),
  },
  {
    value: TextStyle.heading2,
    label: TextStylesLabelMap[TextStyle.heading2],
    startAdornment: (
      <Icon
        component="TextHeading"
        sx={{ color: (theme) => theme.palette.icon.secondary }}
      />
    ),
  },
  {
    value: TextStyle.numbered,
    label: TextStylesLabelMap[TextStyle.numbered],
    startAdornment: (
      <Icon
        component="ListNumbered"
        sx={{ color: (theme) => theme.palette.icon.secondary }}
      />
    ),
  },
  {
    value: TextStyle.bulleted,
    label: TextStylesLabelMap[TextStyle.bulleted],
    startAdornment: (
      <Icon
        component="ListBulleted"
        sx={{ color: (theme) => theme.palette.icon.secondary }}
      />
    ),
  },
];
