import React, { forwardRef } from 'react';

import { useMergeRefs } from '@mint-lib/hooks';
import TableContainerM, {
  TableContainerProps as TableContainerPropsM,
} from '@mui/material/TableContainer';

export interface TableContainerProps extends TableContainerPropsM {
  /**
   * The content of the TableContainer, normally TableContainerContainer and TableContainerContainer.
   * @uxpinignoreprop
   * */
  children?: React.ReactNode;
  classes?: TableContainerPropsM['classes'];
  component?: React.ElementType;
  sx?: TableContainerPropsM['sx'];
}

const TableContainer = forwardRef(
  (
    props: TableContainerPropsM,
    outerRef: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const mergedRef = useMergeRefs(outerRef, ref);

    const [isScrolled, setIsScrolled] = React.useState(false);

    React.useEffect(() => {
      const handleScroll = () => {
        if (ref.current) {
          setIsScrolled(ref.current.scrollTop > 0);
        }
      };
      ref.current?.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
        ref.current?.removeEventListener('scroll', handleScroll);
      };
    }, [ref]);

    const className = [props.className, isScrolled && 'isScrolled']
      .filter(Boolean)
      .join(' ');

    return (
      <TableContainerM {...props} ref={mergedRef} className={className}>
        {props.children}
      </TableContainerM>
    );
  },
);

export default TableContainer;
