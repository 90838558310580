import { inputBaseClasses } from '@mui/material/InputBase';
import { Palette } from '@mui/material/styles';

import typography from './typography.js';

const inputBaseOverrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        ...typography.bodyShort01,
        borderRadius: '4px !important',
        boxShadow: `inset 0 0 0 1px ${palette.border.primary}`,
        minHeight: '2.5rem',
        backgroundColor: palette.ui.field02,
        color: palette.text.primary,
        '&.MuiInputBase-multiline': {
          padding: '8px 12px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '&.MuiInputBase-adornedStart': {
          paddingLeft: '12px',
        },
        [`&.${inputBaseClasses.readOnly}`]: {
          backgroundColor: palette.ui.field01,
          color: palette.text.secondary,
          [`&.${inputBaseClasses.focused}`]: {
            boxShadow: `inset 0 0 0 1px ${palette.border.primary}`,
            '&:hover': {
              boxShadow: `inset 0 0 0 1px ${palette.border.primary}`,
            },
          },
          [`&.${inputBaseClasses.error}`]: {
            boxShadow: `inset 0 0 0 1px ${palette.border.primary}`,
          },
          '&.warning-input': {
            boxShadow: `inset 0 0 0 1px ${palette.border.primary}`,
          },
          '&:hover': {
            boxShadow: `inset 0 0 0 1px ${palette.border.primary}`,
          },
        },
        [`&.${inputBaseClasses.colorSecondary}`]: {
          backgroundColor: palette.ui.field02,
        },
        [`&.${inputBaseClasses.focused}`]: {
          boxShadow: `inset 0 0 0 2px ${palette.action.focusBorder}`,
          '&:hover': {
            boxShadow: `inset 0 0 0 2px ${palette.action.focusBorder}`,
          },
        },
        '&:hover': {
          boxShadow: `inset 0 0 0 1px ${palette.action.focusBorder}`,
        },
        '&.warning-input': {
          boxShadow: `inset 0 0 0 2px ${palette.warning.main}`,
          [`&.${inputBaseClasses.focused}`]: {
            boxShadow: `inset 0 0 0 2px ${palette.warning.main}`,
          },
        },
        [`&.${inputBaseClasses.error}`]: {
          boxShadow: `inset 0 0 0 2px ${palette.error.main} !important`,
          '&:hover': {
            boxShadow: `inset 0 0 0 2px ${palette.error.main}`,
          },
        },
        [`&.${inputBaseClasses.disabled}`]: {
          boxShadow: `inset 0 0 0 1px ${palette.border.disabled}`,
          backgroundColor: palette.action.disabledBackground,

          svg: {
            color: palette.action.disabled01,
          },
        },
        [`&.${inputBaseClasses.sizeSmall}`]: {
          height: '2rem',
          minHeight: '2rem',
        },
        '& input': {
          padding: '11px 12px',
          boxSizing: 'border-box' as const,
          '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            MozAppearance: 'none',
            appearance: 'none',
            margin: 0,
          },
          '&::placeholder': {
            color: palette.text.placeholder,
            opacity: '1',
          },
        },
        '& svg': {
          right: 15,
        },
        '& .MuiSelect-select': {
          padding: '10px 12px',
          minHeight: '16px !important',
        },
        '& .MuiSelect-icon': {
          color: palette.icon.secondary,
        },
      },
    },
  };
};

export default inputBaseOverrides;
