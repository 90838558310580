import type { MintServiceLocatorAbstractFactoryContext } from '../types.js';

export async function requestFactory(
  ctx: MintServiceLocatorAbstractFactoryContext,
  url: string,
  params: Record<string, string | number> = {},
  type: 'guestApi' | 'defaultApi' | 'accessApi' = 'defaultApi',
) {
  if (import.meta.env.DEV) {
    if (url.startsWith('/')) {
      console.warn(
        'please pass the url without the leading /api/ prefix and without the leading /',
      );
    }
  }
  const http = await ctx.getInstance('http', type);
  let finalUrl = url;
  if (params) {
    const parts = url.split('/');
    finalUrl = parts
      .map((part) => {
        if (part === '') {
          return '';
        }
        if (part[0] === '$') {
          const key = part.slice(1);
          if (!params?.[key]) {
            throw new Error(`Missing param: ${key}`);
          }
          return params[part.slice(1)];
        }
        return part;
      })
      .join('/');
  }
  return http
    .get(`/api/${finalUrl}`)
    .then((res) => res.data)
    .catch(() => ({}));
}
