import React, { forwardRef } from 'react';

import FormGroup, { FormGroupProps } from '@mui/material/FormGroup';

import FormControl from '../FormControl/FormControl.jsx';
import FormLabel from '../FormLabel/FormLabel.jsx';
import { SwitchProps } from '../Switch/Switch.jsx';

export interface SwitchGroupProps extends FormGroupProps {
  /**
   * Display text over the radio group.
   */
  groupLabel?: string;
  /**
   * The position of the label.
   */
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  /**
   * The value of the initially selected radio button.
   * @uxpinbind onChange 1
   * @uxpinpropname  Selected Value
   */
  value?: string;
  /**
   * The content of the component.
   * @uxpinignoreprop
   */
  children?: React.ReactNode;
  /**
   * The size of the component. small is equivalent to the dense checkbox styling.
   */
  size?: SwitchProps['size'];
  color?: SwitchProps['color'];
  /**
   * The name used to reference the value of the control.
   * @uxpinignoreprop
   */
  name?: string;
  /**
   * @uxpinignoreprop
   */
  onKeyDown?: FormGroupProps['onKeyDown'];
  /**
   * display selection controls in a single row.
   */
  row?: boolean;
  /**
   * Change event to use with UXPin interactions.
   */
  onChange?: FormGroupProps['onChange'];
  checked?: boolean;
}

const SwitchGroup = forwardRef((props: SwitchGroupProps, ref) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{props.groupLabel}</FormLabel>
      <FormGroup {...props} ref={ref}>
        {props.children}
      </FormGroup>
    </FormControl>
  );
});

SwitchGroup.defaultProps = {
  onChange: () => undefined,
};
export default SwitchGroup;
