import * as React from 'react';

import PaperM, { PaperProps } from '@mui/material/Paper';

export interface Props extends PaperProps {
  /**
   * The content of the component. Text or nested components.
   */
  children?: React.ReactNode;
  /**
   * The shadow depth of paper surface. Accepts values between 0 and 16.
   */
  elevation?: number;
  /**
   * the variant to use.
   */
  variant?: PaperProps['variant'];
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api) below for more details.
   * @uxpinignoreprop
   */
  classes?: PaperProps['classes'];
  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   * @uxpinignoreprop
   */
  component?: React.ElementType;
  /**
   * If `true`, rounded corners are disabled.
   * @uxpinignoreprop
   */
  square?: boolean;
  /**
   * Custom Style Object
   * @uxpinignoreprop
   */
  sx?: PaperProps['sx'];
}

function Paper(props: Props) {
  return <PaperM {...props}>{props.children}</PaperM>;
}

export default Paper;
