import { createFileRoute } from '@tanstack/react-router';

import { z } from 'zod';

export const Route = createFileRoute(
  '/_secured/company-profile/integrations/$integration/$tabName',
)({
  validateSearch: z.object({
    code: z.string().optional(),
    state: z.string().optional(),
  }),
});
