import styled from '@mint-lib/styled';
import CircularProgressM, {
  CircularProgressProps as CircularProgressPropsM,
} from '@mui/material/CircularProgress';

import Box from '../Box/Box.jsx';
import Typography from '../Typography/Typography.jsx';

export interface CircularProgressProps extends CircularProgressPropsM {
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api) below for more details.
   * @uxpinignoreprop
   */
  classes?: CircularProgressPropsM['classes'];
  /**
   * @uxpinignoreprop
   */
  className?: string;
  /**
   * The variant to use.
   * Use indeterminate when there is no progress value.
   */
  variant?: CircularProgressPropsM['variant'];
  /**
   * The size of the circle.
   */
  size?: number | string;
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color?: CircularProgressPropsM['color'];
  /**
   * @uxpinignoreprop
   */
  style?: CircularProgressPropsM['style'];
  /**
   * The thickness of the circle.
   */
  thickness?: number;
  /**
   * The value of the progress indicator for the determinate and static variants.
   * Value between 0 and 100.
   */
  value?: number;
  /**
   * If `true`, the shrink animation is disabled.
   * This only works if variant is `indeterminate`.
   */
  disableShrink?: boolean;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   * See the `sx` page for more details. https://mui.com/system/the-sx-prop/
   */
  /** @uxpinignoreprop */
  sx?: CircularProgressPropsM['sx'];
  /**
   * If `true`, the CircularProgress will be with label
   */
  withLabel?: boolean;
  /**
   * Position of the label
   */
  labelPosition?: 'center' | 'right';
}
const CircularProgress = ({
  withLabel,
  value,
  labelPosition = 'center',
  ...props
}: CircularProgressProps) => {
  return (
    <Container className="circular-progress-container">
      <StyledCircularProgress
        size="2.5rem"
        thickness={4.2}
        value={value}
        {...props}
      />
      {withLabel && value ? (
        <LabelContainer $labelPosition={labelPosition}>
          <Typography
            variant="caption01"
            component="div"
            color="text.secondary"
          >{`${Math.round(value)}%`}</Typography>
        </LabelContainer>
      ) : null}
    </Container>
  );
};
export default CircularProgress;

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  '.MuiCircularProgress-colorPrimary': {
    color: theme.palette.tertiary.light,
  },
  '.MuiCircularProgress-colorSecondary': {
    color: theme.palette.secondary.main,
  },
  '.MuiCircularProgress-colorWarning': {
    color: theme.palette.warning.main,
  },
  gap: '8px',
}));

const StyledCircularProgress = styled(CircularProgressM)({
  '.MuiCircularProgress-svg': {
    strokeLinecap: 'round',
  },
});

const LabelContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$labelPosition',
})<{
  $labelPosition?: 'center' | 'right';
}>(({ theme, $labelPosition }) => ({
  '.MuiTypography-root': {
    ...theme.typography.caption01,
  },
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: $labelPosition !== 'center' ? 'relative' : 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
