import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_secured/company-profile/users-management/',
)({
  beforeLoad: ({ params }) => {
    throw redirect({
      to: '/company-profile/users-management/user',
      replace: true,
    });
  },
});
