import { Palette } from '@mui/material/styles';

const PaginationOverrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        '.MuiButtonBase-root': {
          padding: 0,
        },
        '.MuiPaginationItem-previousNext': {
          height: '2rem',
          width: '2rem',
          color: palette.tertiary.main,
          '&:hover': { color: palette.tertiary.main },
          '&:focus': {
            boxShadow: `0 0 0 2px ${palette.action.focusBorder}`,
          },
          '&:active': {
            backgroundColor: palette.ui.active,
          },
        },
      },
    },
  };
};

export default PaginationOverrides;
