import { createContext, type Dispatch, type SetStateAction } from 'react';

export type ModalContextProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const ModalContext = createContext<ModalContextProps>({
  open: false,
  setOpen: () => {},
});

export default ModalContext;
