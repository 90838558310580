import * as React from 'react';

import BoxM, { BoxProps } from '@mui/material/Box';

export interface Props extends BoxProps {
  /**
   * The content of the box.
   */
  /** @uxpinignoreprop */
  children?: React.ReactNode;
  /** @uxpinignoreprop */
  clone?: boolean;
  /**
   * Color of text
   */

  color?: BoxProps['color'];
  bgcolor?: BoxProps['color'];
  /**
   * All Padding.
   * In pixels
   */
  padding?: string;
  /**
   * Top Padding.
   * In pixels
   */
  paddingTop?: string;
  /**
   * Right Padding.
   * In pixels
   */
  paddingRight?: string;
  /**
   * Bottom Padding.
   * In pixels
   */
  paddingBottom?: string;
  /**
   * Left Padding.
   * In pixels
   */
  paddingLeft?: string;
  /**
   * All Margin.
   * In pixels
   */
  margin?: string;
  /**
   * Top margin.
   * In pixels
   */
  marginTop?: string;
  /**
   * Right margin.
   * In pixels
   */
  marginRight?: string;
  /**
   * Bottom margin.
   * In pixels
   */
  marginBottom?: string;
  /**
   * Left margin.
   * In pixels
   */
  marginLeft?: string;
  /**
   * Border margin.
   * In pixels
   */
  border?: number;
  /**
   * Border Top.
   * In pixels
   */
  borderTop?: number;
  /**
   * Border margin.
   * In pixels
   */
  borderRight?: number;
  /**
   * Border margin.
   * In pixels
   */
  borderBottom?: number;
  /**
   * Border margin.
   * In pixels
   */
  borderLeft?: number;
  borderColor?: BoxProps['color'];
  borderRadius?: string;
  boxShadow?: number;
  textAlign?: 'left' | 'center' | 'right';
  // textOverflow: PropTypes.oneOf(["clip", "ellipsis"]),
  /**
   * Accepts all system properties, as well as any valid CSS properties.
   */
  sx?: BoxProps['sx'];
}

const Box = React.forwardRef((props: Props, ref: React.Ref<unknown>) => {
  return (
    <BoxM ref={ref} {...props}>
      {props.children}
    </BoxM>
  );
});

Box.defaultProps = {
  sx: {
    padding: '',
    'padding-top': '',
    'padding-right': '',
    'padding-bottom': '',
    'padding-left': '',
    margin: '',
    'margin-top': '',
    'margin-right': '',
    'margin-bottom': '',
    'margin-left': '',
  },
};

export default Box;
