import { ReactNode, useState } from 'react';

import ModalProviderContext from './ModalContext.js';

type DialogProviderProps = {
  children: ReactNode;
};

const ModalProvider = ({ children }: DialogProviderProps) => {
  const [open, setOpen] = useState(false);

  return (
    <ModalProviderContext.Provider value={{ open, setOpen }}>
      {children}
    </ModalProviderContext.Provider>
  );
};

export default ModalProvider;
