import api from '@mint-lib/api';

import { USER_ROLES } from '../endpointConstants.js';
import { WithPaginationResponse } from '../types.js';
import type { WithPaginationRequest } from '../users/users.types.js';
import { paramsToURLSearchParams } from '../utils.js';
import { CreateRoleType, RolesMetaType, RoleType } from './roles.types.js';

export const fetchUserRoles = async (
  params: WithPaginationRequest,
): Promise<WithPaginationResponse<RoleType> | RoleType[]> => {
  const data = await api.get(
    `${USER_ROLES}?${paramsToURLSearchParams(params)}`,
  );
  return data.data;
};

export const fetchRolesMeta = (): Promise<RolesMetaType> =>
  api.get(`${USER_ROLES}meta/`).then((data) => data.data);

export const createRole = (params: CreateRoleType): Promise<RoleType> =>
  api.post(USER_ROLES, params).then((data) => data.data);

export const editRole = (role: Partial<RoleType>): Promise<RoleType> =>
  api
    .patch(
      `${USER_ROLES}${role.id}/`,
      // Omitting specified keys from the role object
      (({ id, createdAt, usersDetails, isDefault, ...rest }) => rest)(role),
    )
    .then((data) => data.data);

export const deleteRole = (id: number): Promise<void> =>
  api.delete(`${USER_ROLES}${id}/`).then((data) => data.data);
