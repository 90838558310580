import * as React from 'react';

import FormHelperTextM, {
  FormHelperTextProps,
} from '@mui/material/FormHelperText';

export interface Props extends FormHelperTextProps {
  /**
   * @uxpinignoreprop
   * The content of the component.
   */
  children?: React.ReactNode;
  /**
   * @uxpinignoreprop
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api) below for more details.
   */
  classes?: FormHelperTextProps['classes'];
  /**
   * @uxpinignoreprop
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   */
  component?: React.ElementType;
  /**
   * If `true`, the helper text should be displayed in a disabled state.
   */
  disabled?: boolean;
  /**
   * If `true`, helper text should be displayed in an error state.
   */
  error?: boolean;
  /**
   * If `true`, the helper text should use filled classes key.
   */
  filled?: boolean;
  /**
   * If `true`, the helper text should use focused classes key.
   */
  focused?: boolean;
  /**
   * If `dense`, will adjust vertical spacing. This is normally obtained via context from
   * FormControl.
   */
  margin?: 'dense';
  /**
   * If `true`, the helper text should use required classes key.
   */
  required?: boolean;
  /**
   * The variant to use.
   */
  variant?: FormHelperTextProps['variant'];
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   * See the `sx` page for more details https://mui.com/system/the-sx-prop/.
   */
  /** @uxpinignoreprop */
  sx?: FormHelperTextProps['sx'];
}

function FormHelperText(props: Props) {
  return <FormHelperTextM {...props}>{props.children}</FormHelperTextM>;
}

export default FormHelperText;
