import { useTranslation } from '@mint-lib/i18n';
import { useNavigate } from '@mint/routing';

import { Box, Button, Icon, Typography } from '@myn/mui';

import IllustrationError500 from '../assets/illustration-error-500.png';

export const InternalServerErrorPage = ({ reset }: { reset: () => void }) => {
  const { t } = useTranslation('@mint-lib/error-boundary');
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      gap={(theme) => theme.spacing(8)}
    >
      <Box
        display="inline-flex"
        flexDirection="column"
        justifyContent="center"
        gap={(theme) => theme.spacing(3)}
      >
        <Box
          display="inline-flex"
          gap={(theme) => theme.spacing(1)}
          alignItems={'center'}
        >
          <Icon component={'WarningFilled'} color={'error'} fontSize="large" />
          <Typography variant="h3" color={'error.main'}>
            {t('500 Internal Server Error')}
          </Typography>
        </Box>
        <Typography variant="h1" color="text.primary">
          {t('Something went wrong')}
        </Typography>
        <Typography variant="bodyShort01" color="text.primary">
          {t(
            'We are informed of your issue, we are working on it to resolve it.',
          )}
        </Typography>
        <Box display="flex" gap={2}>
          <Button
            // refresh the page
            onClick={() =>
              navigate({
                to: '/',
              })
            }
            variant="outlined"
            size="large"
            color="primary"
          >
            {t('Reload the page')}
          </Button>

          <Button
            variant="contained"
            href="https://support.mint.ai"
            size="large"
            color="primary"
          >
            {t('Visit our Help Center')}
          </Button>
        </Box>
      </Box>
      <Box component={'img'} src={IllustrationError500} />
    </Box>
  );
};
