import * as React from 'react';

import styled from '@mint-lib/styled';
import ToggleButtonGroupM, {
  ToggleButtonGroupProps as ToggleButtonGroupPropsM,
} from '@mui/material/ToggleButtonGroup';

export interface ToggleButtonGroupProps extends ToggleButtonGroupPropsM {
  /**
   * The content of the component.
   * @uxpinignoreprop
   * */
  children?: React.ReactNode;
  /**
   * Override or extend the styles applied to the component.
   * @uxpinignoreprop
   */
  classes?: ToggleButtonGroupPropsM['classes'];
  /**
   * @uxpinignoreprop
   */
  onChange?: ToggleButtonGroupPropsM['onChange'];
  /**
   * The component orientation (layout flow direction).
   */
  orientation?: ToggleButtonGroupPropsM['orientation'];
  /**
   * The size of the button.
   */
  size?: ToggleButtonGroupPropsM['size'];
  /**
   * The color of the button whhen it is in an active state.
   */
  color?: ToggleButtonGroupPropsM['color'];
  /**
   * The value to associate with the button when selected in a ToggleButtonGroup.
   */
  value?: string | string[];
  /**
   * If true, only allow one of the child ToggleButton values to be selected.
   */
  exclusive?: boolean;
  /**
   * If true, the component is disabled.
   * @uxpincontroltype
   */
  disabled?: boolean;
  /**
   * If true, the button group will take up the full width of its container.
   */
  fullWidth?: boolean;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   * @uxpinignoreprop
   */
  sx?: ToggleButtonGroupPropsM['sx'];
}

export default function ToggleButtonGroup(props: ToggleButtonGroupProps) {
  return (
    <StyledToggleButtonGroup
      {...props}
      value={props.value}
      onChange={props.onChange}
      aria-label="toggle button"
    >
      {props.children}
    </StyledToggleButtonGroup>
  );
}
const StyledToggleButtonGroup = styled(ToggleButtonGroupM)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    '&.Mui-disabled': {
      border: 0,
    },
    '&.MuiToggleButtonGroup-groupedHorizontal': {
      '&:not(:first-of-type):not(.Mui-selected):not(:hover)': {
        marginLeft: 0,
        borderLeft: 'none',
      },
      '&.Mui-selected': {
        border: `1px solid ${theme.palette.tertiary.light}`,
        zIndex: 2,
      },
      '&.Mui-selected:hover': {
        border: `1px solid ${theme.palette.tertiary.light}`,
        backgroundColor: `${theme.palette.ui.highlight}`,
        marginLeft: '-1px !important',
        '&:first-of-type': {
          marginLeft: '0px !important',
        },
      },
    },
    '&.MuiToggleButtonGroup-groupedVertical': {
      height: 'unset',
      '&:not(:first-of-type):not(.Mui-selected):not(:hover)': {
        marginTop: 0,
        borderTop: 'none',
      },
      '&.Mui-selected': {
        border: `1px solid ${theme.palette.tertiary.light}`,
        zIndex: 2,
      },
      '&.Mui-selected:hover': {
        border: `1px solid ${theme.palette.tertiary.light}`,
        backgroundColor: `${theme.palette.ui.highlight}`,
        color: `${theme.palette.tertiary.main}`,
        marginTop: '-1px !important',
        '&:first-of-type': {
          marginTop: '0px !important',
        },
      },
    },
  },
}));
