import { Palette } from '@mui/material/styles';

const AlertOverrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        '& .MuiAlert-icon': {
          padding: 0,
          marginRight: 8,
        },
        '& .MuiAlert-message': {
          padding: 0,
        },
        '& .MuiAlert-action': {
          marginRight: 0,
          padding: 0,
          height: 'fit-content',
        },
      },
      standardSuccess: {
        backgroundColor: palette.background.success,
        '& .MuiAlert-icon': {
          color: palette.success.main,
        },
      },
      standardError: {
        backgroundColor: palette.background.error,
        '& .MuiAlert-icon': {
          color: palette.error.main,
        },
      },
      standardWarning: {
        backgroundColor: palette.background.warning,
        '& .MuiAlert-icon': {
          color: palette.warning.main,
        },
      },
      standardInfo: {
        backgroundColor: palette.background.info,
        '& .MuiAlert-icon': {
          color: palette.info.main,
        },
      },
    },
  };
};

export default AlertOverrides;
