import { createElement, useEffect, useState } from 'react';
import DatePickerM, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';

import { useProfile } from '@mint-lib/profile-helpers';
import { TooltipProps as TooltipPropsM } from '@mui/material/Tooltip';
import { isSameDay } from 'date-fns';
import { enGB, enUS, it, ptBR } from 'date-fns/locale';

import CalendarButton from '../../common/Calendar/CalendarButton.jsx';
import CalendarContainer from '../../common/Calendar/CalendarContainer.jsx';
import CalendarHeader from '../../common/Calendar/CalendarHeader.jsx';
import CalendarPopper from '../../common/Calendar/CalendarPopper.jsx';
import { StyledDatepickerWrapper } from '../../common/Calendar/DatePickerWrapper.jsx';
import EventMark from '../../common/Calendar/EventMark.jsx';

// TODO: come up with a dynamic import both for locales and DatePicker component
// FIXME: when team C do their part we need to support both en-US and en-GB
registerLocale('en', enGB);
registerLocale('en-GB', enGB);
registerLocale('en-US', enUS);
registerLocale('it-IT', it);
registerLocale('pt-BR', ptBR);
registerLocale('FAKE', enGB);

export interface DatepickerProps extends ReactDatePickerProps {
  /**
   * If true calendar is open
   * @uxpinbind onCalendarOpen 1
   */
  open?: ReactDatePickerProps['open'];
  /**
   * Callback for calendar opening
   */
  onCalendarOpen?: ReactDatePickerProps['onCalendarOpen'];
  /**
   * Callback for calendar closing
   */
  onCalendarClose?: ReactDatePickerProps['onCalendarClose'];
  /**
   * The first day of the week
   * From 1 to 7 as from monday to sunday
   */
  calendarStartDay?: ReactDatePickerProps['calendarStartDay'];
  /**
   * if true calendar should be close on select the date
   */
  shouldCloseOnSelect?: ReactDatePickerProps['shouldCloseOnSelect'];
  /**
   * Format of displaying date
   * dd/MM/yyyy by default
   * @deprecated not using in favour of standart locale formating
   */
  dateFormat?: ReactDatePickerProps['dateFormat'];
  /**
   * The selected date
   * @uxpinbind onChange 2
   */
  selected?: ReactDatePickerProps['selected'];
  /**
   * Callback when state is changing
   */
  onChange: ReactDatePickerProps['onChange'];
  /**
   * @uxpinignoreprop
   */
  className?: string;

  /**
   * Array of dates to mark events
   */
  eventDates?: Date[];
  /**
   * Color of the input
   */
  color?: 'primary' | 'secondary';
  /**
   * Label of the input
   */
  label?: string;
  /**
   * Props fot input element to make it fullWidth
   */
  fullWidth?: boolean;
  /**
   * The helperText of the input. It is only used for layout. The actual labelling is handled by HelperText.
   */
  helperText?: string;
  /**
   * Handler that fires when the datepicker closes
   */
  closeCb?: () => void;
  /**
   * If true, the datepicker will be in error status
   */
  error?: boolean;
  /**
   * The size can be small or medium
   * @default medium
   */
  size?: 'small' | 'medium';
  infoText?: string;
  disabled?: boolean;
  warning?: boolean;
  readOnly?: boolean;
  InfoTextTooltipProps?: Partial<TooltipPropsM>;
  endAdornment?: React.ReactNode;
}

const Datepicker = ({
  open,
  selected,
  onChange,
  className,
  eventDates,
  color,
  label,
  fullWidth,
  helperText,
  closeCb,
  error = false,
  infoText,
  disabled,
  InfoTextTooltipProps,
  warning,
  readOnly,
  endAdornment,
  size,
  ...props
}: DatepickerProps) => {
  const { getProfileField } = useProfile();

  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);

  const handleCalendarClose = () => {
    setCalendarOpen(false);
    closeCb?.();
  };

  const renderDayContents = (
    dayOfMonth: number,
    calendarDate?: Date | undefined,
  ) => {
    if (
      calendarDate &&
      eventDates?.some((eventDate) => isSameDay(calendarDate, eventDate))
    ) {
      return (
        <div>
          {dayOfMonth} <EventMark />
        </div>
      );
    } else {
      return dayOfMonth;
    }
  };

  useEffect(() => {
    setCalendarOpen(open ?? false);
  }, [open]);

  // If flag enabled use new fields from profile to get locale settings
  // else use old implementation
  const locale =
    getProfileField('formattingConfig')?.datesFormat ||
    getProfileField('locale');

  return (
    <StyledDatepickerWrapper>
      <DatePickerM
        {...props}
        dateFormat="P"
        locale={locale}
        className={className}
        open={calendarOpen}
        selected={selected}
        onCalendarClose={handleCalendarClose}
        onChange={onChange}
        customInput={createElement(CalendarButton, {
          variant: 'single',
          infoText,
          disabledTextfield: disabled,
          InfoTextTooltipProps: InfoTextTooltipProps,
          warning,
          color,
          label,
          fullWidth,
          error,
          size,
          endAdornment,
          helperText: !calendarOpen ? helperText : undefined,
          readOnlyTextfield: readOnly,
          onInputClick:
            readOnly || disabled
              ? () => {}
              : () => setCalendarOpen((prev) => !prev),
        })}
        renderDayContents={renderDayContents}
        formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}
        // eslint-disable-next-line @typescript-eslint/no-shadow
        calendarContainer={(props: any) => (
          <CalendarContainer variant="single" {...props} />
        )}
        renderCustomHeader={({
          changeYear,
          date: customHeaderDate,
          increaseMonth,
          decreaseMonth,
          customHeaderCount,
          monthDate,
        }) => (
          <CalendarHeader
            date={customHeaderDate}
            changeYear={changeYear}
            increaseMonth={increaseMonth}
            decreaseMonth={decreaseMonth}
            customHeaderCount={customHeaderCount}
            variant="single"
            monthDate={monthDate}
          />
        )}
        popperContainer={CalendarPopper}
      />
    </StyledDatepickerWrapper>
  );
};

export default Datepicker;
