import { createFileRoute, redirect } from '@tanstack/react-router';

import { z } from 'zod';

const fallback = '/';

export const Route = createFileRoute('/_auth/login/')({
  validateSearch: z.object({
    next: z.string().optional().catch(''),
  }),
  beforeLoad: async ({ context, search }) => {
    console.log('beforeLoad');
    if (await context.isAuthenticated()) {
      throw redirect({ to: search.next || fallback });
    }
    return context;
  },
});
