import styled from '@mint-lib/styled';
import AvatarGroupM, {
  AvatarGroupProps as AvatarGroupPropsM,
} from '@mui/material/AvatarGroup';

import { AvatarSize } from '../Avatar/Avatar.constants.js';
import { getTypography } from '../Avatar/Avatar.utils.js';

export interface AvatarGroupProps extends AvatarGroupPropsM {
  /**
   * className
   */
  className?: string;
  /**
   * size of Avatar Group
   */
  size?: AvatarSize;
  /**
   * background Color
   */
  bgColor?: 'secondary' | 'primary';
}

const AvatarGroup = ({
  size = AvatarSize.large,
  bgColor = 'primary',
  variant = 'circular',
  className,
  ...props
}: AvatarGroupProps) => {
  return (
    <StyledAvatarGroup
      size={size}
      bgColor={bgColor}
      className={className}
      {...props}
    />
  );
};

export default AvatarGroup;

const StyledAvatarGroup = styled(AvatarGroupM, {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'bgColor',
})<{
  size: AvatarSize;
  bgColor?: AvatarGroupProps['bgColor'];
}>(({ theme, size, bgColor }) => ({
  '& .MuiAvatar-root': {
    width: `${size}rem`,
    height: `${size}rem`,
    backgroundColor:
      bgColor === 'primary'
        ? theme.palette.ui.primary
        : theme.palette.ui.secondary,
    ...getTypography(size, theme),
  },
}));
