/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import EventSource from 'reconnecting-eventsource';

import { Config, defaultAPI, Flagsmith } from './origins/flagsmith-core.js';
import {
  GetValueOptions,
  IDatadogRum,
  IFlags,
  IInitConfig,
  IRetrieveInfo,
  IState,
  LoadingState,
} from './origins/types.js';

type FlagsmithConfig = Config & {
  environmentID: string;
  name: string;
};

enum FlagSource {
  'NONE' = 'NONE',
  'DEFAULT_FLAGS' = 'DEFAULT_FLAGS',
  'CACHE' = 'CACHE',
  'SERVER' = 'SERVER',
}

export class FlagsmithClient<
  FlagsNames extends string = string,
> extends Flagsmith {
  private name: string;
  private parent: FlagsmithClient | null;
  private storageKey: string;
  private storageEventKey: string;
  private dataDogFlagValueKey: string;
  private dataDogFlagEnabledKey: string;
  private dataDogTraitKey: string;
  private datadogRum: IDatadogRum | null = null;
  listeners: Set<
    (
      previousFlags: IFlags<FlagsNames> | null,
      params: IRetrieveInfo,
      loadingState: LoadingState,
    ) => void
  > = new Set();

  additionalListeners: Set<(action: 'SUBSCRIBE' | 'UNSUBSCRIBE') => void> =
    new Set();

  constructor(props: FlagsmithConfig, parent: FlagsmithClient | null = null) {
    super(props);
    this.name = props.name;
    this.environmentID = props.environmentID;
    if (!this.environmentID) {
      throw new Error('Please specify a environment id for' + this.name);
    }
    this.parent = parent;
    this.storageKey = `${this.name}_keys`;
    this.storageEventKey = `${this.name}_events`;
    this.dataDogFlagEnabledKey = `${this.name.replace('/', '_')}✅`;
    this.dataDogFlagValueKey = `${this.name.replace('/', '_')}📨`;
    this.dataDogTraitKey = `${this.name.replace('/', '_')}📮`;
  }

  setParent(parent: FlagsmithClient) {
    if (this.parent && this.parent !== parent) {
      console.warn(
        `Setting parent of ${this.name} to ${parent.name}, but it already has a parent ${this.parent.name}`,
      );
    }
    this.parent = parent;
  }

  override init({
    api = defaultAPI,
    headers,
    onChange,
    cacheFlags,
    datadogRum,
    onError,
    defaultFlags,
    preventFetch,
    enableLogs,
    enableDynatrace,
    enableAnalytics = false,
    realtime,
    eventSourceUrl = 'https://realtime.flagsmith.com/',
    identity,
    traits,
    state,
    cacheOptions,
    angularHttpClient,
    _trigger,
    _triggerLoadingStateChange,
  }: Omit<IInitConfig<string, string>, 'environmentID'>) {
    return new Promise((resolve, reject) => {
      this.api = api;
      this.headers = headers;
      this.getFlagInterval = null;
      this.analyticsInterval = null;
      const WRONG_FLAGSMITH_CONFIG =
        'Wrong Flagsmith Configuration: preventFetch is true and no defaulFlags provided';

      // Emulate onChange event
      this.subscribe((previousFlags, params, loadingState) => {
        this.setLoadingState(loadingState);
        if (onChange) {
          onChange(previousFlags, params, this.loadingState);
        }
        if (this._trigger) {
          this.log('trigger called');
          this._trigger();
        }
      });

      this._trigger = _trigger || this._trigger;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.onError = (message: any) => {
        this.setLoadingState({
          ...this.loadingState,
          isFetching: false,
          isLoading: false,
          error: message,
        });
        if (onError) {
          if (message instanceof Error) {
            onError(message);
          } else {
            onError(new Error(message));
          }
        }
      };

      this.identity = identity;
      this.withTraits = traits;
      this.enableLogs = enableLogs || false;
      this.cacheOptions = cacheOptions
        ? { skipAPI: !!cacheOptions.skipAPI, ttl: cacheOptions.ttl || 0 }
        : this.cacheOptions;
      if (!this.cacheOptions.ttl && this.cacheOptions.skipAPI) {
        console.warn(
          'Flagsmith: you have set a cache ttl of 0 and are skipping API calls, this means the API will not be hit unless you clear local storage.',
        );
      }
      this.enableAnalytics = enableAnalytics ? enableAnalytics : false;
      this.flags = Object.assign({}, defaultFlags) || {};
      this.initialised = true;
      this.ticks = 10000;
      if (Object.keys(this.flags).length) {
        //Flags have been passed as part of SSR / default flags, update state silently for initial render
        this.loadingState = {
          ...this.loadingState,
          isLoading: false,
          source: FlagSource.DEFAULT_FLAGS,
        };
      }
      if (realtime && typeof window !== 'undefined') {
        const connectionUrl =
          eventSourceUrl + 'sse/environments/' + this.environmentID + '/stream';
        if (!this.eventSource) {
          this.log('Creating event source with url ' + connectionUrl);
          // @ts-ignore ESM types are wrong
          this.eventSource = new (EventSource as typeof EventSource.default)(
            connectionUrl,
          );
          this.eventSource?.addEventListener('environment_updated', (e) => {
            let updated_at;
            try {
              const data = JSON.parse(e.data);
              updated_at = data.updated_at;
            } catch (error) {
              this.log('Could not parse sse event', error);
            }
            if (!updated_at) {
              this.log('No updated_at received, fetching flags', e);
            } else if (!this.timestamp || updated_at > this.timestamp) {
              if (this.isLoading) {
                this.log(
                  'updated_at is new, but flags are loading',
                  e.data,
                  this.timestamp,
                );
              } else {
                this.log(
                  'updated_at is new, fetching flags',
                  e.data,
                  this.timestamp,
                );
                this.getFlags();
              }
            } else {
              this.log(
                'updated_at is outdated, skipping get flags',
                e.data,
                this.timestamp,
              );
            }
          });
        }
      }

      this.log(
        'Initialising with properties',
        {
          environmentID: this.environmentID,
          api,
          headers,
          onChange,
          cacheFlags,
          onError,
          defaultFlags,
          preventFetch,
          enableLogs,
          enableAnalytics,
          identity,
          traits,
          _trigger,
          state,
          angularHttpClient,
        },
        this,
      );

      this.timer = this.enableLogs ? new Date().valueOf() : null;
      this.cacheFlags = !!cacheFlags;
      this.setState(state as IState);
      if (!this.environmentID) {
        reject('Please specify a environment id');
        throw new Error('Please specify a environment id');
      }

      if (datadogRum) {
        this.datadogRum = datadogRum;
      }

      if (enableDynatrace) {
        // @ts-expect-error Dynatrace's dtrum is exposed to global scope
        if (typeof dtrum === 'undefined') {
          console.error(
            'You have attempted to enable dynatrace but dtrum is undefined, please check you have the Dynatrace RUM JavaScript API installed.',
          );
        } else {
          // @ts-expect-error Dynatrace's dtrum is exposed to global scope
          this.dtrum = dtrum;
        }
      }

      const storageEvents = localStorage.getItem(this.storageEventKey);

      if (storageEvents) {
        try {
          this.evaluationEvent = JSON.parse(storageEvents);
        } catch (e_1) {
          this.evaluationEvent = {};
        }
      } else {
        this.evaluationEvent = {};
      }

      if (this.enableAnalytics) {
        if (this.analyticsInterval) {
          clearInterval(this.analyticsInterval);
        }
        this.analyticsInterval = setInterval(this.analyticsFlags, this.ticks!);

        const res_1 = localStorage.getItem(this.storageEventKey);

        if (res_1) {
          const events = JSON.parse(res_1);
          if (events[this.environmentID]) {
            state = this.getState();
            this.log('Retrieved events from cache', res_1);
            this.setState({
              ...state,
              evaluationEvent: events[this.environmentID],
            });
          }
        }
      }

      //If the user specified default flags emit a changed event immediately
      if (cacheFlags) {
        const storedFlags = localStorage.getItem(this.storageKey);
        if (storedFlags) {
          try {
            const json = JSON.parse(storedFlags);
            let cachePopulated = false;
            if (
              json &&
              json.api === this.api &&
              json.environmentID === this.environmentID
            ) {
              let setState = true;
              if (this.identity && json.identity !== this.identity) {
                this.log(
                  'Ignoring cache,  identity has changed from ' +
                    json.identity +
                    ' to ' +
                    this.identity,
                );
                setState = false;
              }
              if (this.cacheOptions.ttl) {
                if (
                  !json.ts ||
                  new Date().valueOf() - json.ts > this.cacheOptions.ttl
                ) {
                  if (json.ts) {
                    this.log(
                      'Ignoring cache, timestamp is too old ts:' +
                        json.ts +
                        ' ttl: ' +
                        this.cacheOptions.ttl +
                        ' time elapsed since cache: ' +
                        (new Date().valueOf() - json.ts) +
                        'ms',
                    );
                    setState = false;
                  }
                }
              }
              if (setState) {
                cachePopulated = true;
                this.setState(json);
                this.log('Retrieved flags from cache', json);
              }
            }

            if (this.flags) {
              // retrieved flags from local storage
              const shouldFetchFlags =
                !preventFetch &&
                (!this.cacheOptions.skipAPI || !cachePopulated);
              this.onChange?.(
                null,
                {
                  isFromServer: false,
                  flagsChanged: true,
                  traitsChanged: !!this.traits,
                },
                this._loadedState(null, FlagSource.CACHE, shouldFetchFlags),
              );
              this.oldFlags = this.flags;
              resolve(true);
              if (this.cacheOptions.skipAPI && cachePopulated) {
                this.log('Skipping API, using cache');
              }
              if (shouldFetchFlags) {
                this.getFlags();
              }
            } else {
              if (!preventFetch) {
                this.getFlags(resolve, reject);
              } else {
                resolve(true);
              }
            }
          } catch (e) {
            this.log('Exception fetching cached logs', e);
          }
        } else {
          if (!preventFetch) {
            this.getFlags(resolve, reject);
          } else {
            if (defaultFlags) {
              this.onChange?.(
                null,
                {
                  isFromServer: false,
                  flagsChanged: true,
                  traitsChanged: !!this.traits,
                },
                this._loadedState(null, FlagSource.DEFAULT_FLAGS),
              );
            } else if (this.flags) {
              // flags exist due to set state being called e.g. from nextJS serverState
              this.onChange?.(
                null,
                {
                  isFromServer: false,
                  flagsChanged: true,
                  traitsChanged: !!this.traits,
                },
                this._loadedState(null, FlagSource.DEFAULT_FLAGS),
              );
            } else {
              // @ts-ignore This method is always set
              onError(new Error(WRONG_FLAGSMITH_CONFIG));
            }
            resolve(true);
          }
        }
      } else if (!preventFetch) {
        this.getFlags(resolve, reject);
      } else {
        if (defaultFlags) {
          this.onChange?.(
            null,
            {
              isFromServer: false,
              flagsChanged: true,
              traitsChanged: !!this.traits,
            },
            this._loadedState(null, FlagSource.CACHE),
          );
        } else if (this.flags) {
          let error = null;
          if (Object.keys(this.flags).length === 0) {
            error = WRONG_FLAGSMITH_CONFIG;
          }
          this.onChange?.(
            null,
            {
              isFromServer: false,
              flagsChanged: true,
              traitsChanged: !!this.traits,
            },
            this._loadedState(error, FlagSource.DEFAULT_FLAGS),
          );
        }
        resolve(true);
      }
    }).catch((error) => {
      this.log('Error during initialisation ', error);
      onError && onError(error);
    });
  }

  subscribe = (
    listener: (
      previousFlags: IFlags<FlagsNames> | null,
      params: IRetrieveInfo,
      loadingState: LoadingState,
    ) => void,
  ) => {
    this.listeners.add(listener);
    this.notifyAdditionalListeners('SUBSCRIBE');
    return () => {
      this.listeners.delete(listener);
      this.notifyAdditionalListeners('UNSUBSCRIBE');
    };
  };

  private plannedNotifyTimeouts = new Map<
    'SUBSCRIBE' | 'UNSUBSCRIBE',
    NodeJS.Timeout
  >();
  private notifyAdditionalListeners = (action: 'SUBSCRIBE' | 'UNSUBSCRIBE') => {
    if (this.plannedNotifyTimeouts.has(action)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      clearTimeout(this.plannedNotifyTimeouts.get(action)!);
    }
    this.plannedNotifyTimeouts.set(
      action,
      setTimeout(() => {
        this.additionalListeners.forEach((listener) => listener(action));
        this.plannedNotifyTimeouts.delete(action);
      }, 100),
    );
  };

  notify = (
    previousFlags: IFlags<FlagsNames> | null,
    params: IRetrieveInfo,
    loadingState: LoadingState,
  ) => {
    this.listeners.forEach((listener) =>
      listener(previousFlags, params, loadingState),
    );
  };

  onChange = (
    previousFlags: IFlags<string> | null,
    params: IRetrieveInfo,
    loadingState: LoadingState,
  ) => {
    this.notify(previousFlags, params, loadingState);
  };

  override updateStorage(): void {
    if (this.cacheFlags) {
      this.ts = new Date().valueOf();
      const state = JSON.stringify(this.getState());
      this.log('Setting storage', state);
      localStorage!.setItem(this.storageKey, state);
    }
  }

  override updateEventStorage() {
    if (this.enableAnalytics) {
      const events = JSON.stringify(this.getState().evaluationEvent);
      this.log('Setting event storage', events);
      localStorage!.setItem(this.storageEventKey, events);
    }
  }

  // @ts-ignore TS2416 I'm overriding the method signature
  override getValue(
    env: string,
    key: string,
    options?: GetValueOptions,
    skipAnalytics?: boolean,
  ) {
    if (typeof key !== 'string') {
      if (typeof key === 'object' && key !== null) {
        // @ts-ignore TS2345 Needed for RUM
        return super.getValue(env, key, options);
      }
      throw new Error(
        'Cannot pass options as second argument when passing options as third argument',
      );
    }
    if (env !== this.name && env !== 'default') {
      if (!this.parent) {
        throw new Error(
          `Requested feature value ${key} for environment ${env} but this environment is not available. Please check that you wrapped your app in a FlagsmithProvider and that the environment ${env} exists.`,
        );
      }
      return this.parent.getValue(env, key, options, skipAnalytics);
    }

    // @ts-ignore TS2345. We know that everything is right
    return super.getValue(key, options, skipAnalytics);
  }

  // @ts-ignore TS2416 I'm overriding the method signature
  override getTrait(env: string, key: string) {
    if (typeof env === 'string' && !key) {
      return super.getTrait(env);
    }
    if (env !== this.name && env !== 'default') {
      if (!this.parent) {
        throw new Error(
          `Requested trait ${key} for environment ${env} but this environment is not available. Please check that you wrapped your app in a FlagsmithProvider and that the environment ${env} exists.`,
        );
      }
      return this.parent.getTrait(env, key);
    }
    return super.getTrait(key);
  }

  // @ts-ignore TS2416 I'm overriding the method signature
  override hasFeature(
    env: string,
    key: string,
    skipAnalytics?: boolean | undefined,
  ): boolean {
    if (typeof key === 'boolean') {
      if (typeof env === 'string') {
        // @ts-ignore TS2345 Needed for RUM
        return super.hasFeature(env, key, skipAnalytics);
      }
      throw new Error(
        'Wrong hasFeature arguments, please check the documentation',
      );
    }
    if (env !== this.name && env !== 'default') {
      if (!this.parent) {
        throw new Error(
          `Requested feature ${key} for environment ${env} but this environment is not available. Please check that you wrapped your app in a FlagsmithProvider and that the environment ${env} exists.`,
        );
      }
      return this.parent.hasFeature(env, key, skipAnalytics);
    }
    // @ts-ignore TS2345
    return super.hasFeature(key, skipAnalytics);
  }

  override evaluateFlag(key: string, method: 'VALUE' | 'ENABLED') {
    // @ts-ignore It's exists
    const { datadogRum } = this;
    if (datadogRum) {
      if (!datadogRum!.client!.addFeatureFlagEvaluation) {
        console.error(
          'Flagsmith: Your datadog RUM client does not support the function addFeatureFlagEvaluation, please update it.',
        );
      } else {
        this.log('Sending feature flag evaluation to Datadog', key, method);
        if (method === 'VALUE') {
          datadogRum!.client!.addFeatureFlagEvaluation(
            this.dataDogFlagValueKey + key,
            this.getValue(this.name, key, {}, true),
          );
        } else {
          datadogRum!.client!.addFeatureFlagEvaluation(
            this.dataDogFlagEnabledKey + key,
            this.hasFeature(this.name, key, true),
          );
        }
      }
    }

    if (this.enableAnalytics) {
      if (!this.evaluationEvent) {
        return;
      }
      if (!this.evaluationEvent[this.environmentID]) {
        this.evaluationEvent[this.environmentID] = {};
      }
      if (this.evaluationEvent[this.environmentID][key] === undefined) {
        this.evaluationEvent[this.environmentID][key] = 0;
      }
      this.evaluationEvent[this.environmentID][key] += 1;
    }
    this.updateEventStorage();
  }
}
