import { useMemo } from 'react';

import { useTranslation } from '@mint-lib/i18n';
import { useTheme } from '@mint-lib/styled';

import { Box, Chip, ChipProps, Icon, Tooltip, Truncation } from '@myn/mui';

import { getFullName } from './UserCell.helpers.js';
import { UserCellTypeNew } from './UserCell.types.js';

const UserCell = (value: UserCellTypeNew) => {
  const { t } = useTranslation('@myn/permissions');

  const theme = useTheme();
  const fullName = useMemo(() => getFullName(value), [value]);

  return (
    <>
      {value.isMintUser && value.ffId != null ? (
        <Tooltip title={value.ffId} placement="left">
          <span>
            <Icon component="Information" />
          </span>
        </Tooltip>
      ) : null}

      <>
        {Boolean(fullName) && (
          <Box>
            <Truncation
              ml={theme.spacing(1)}
              tooltipTitle={fullName}
              maxWidth={250}
            >
              {fullName}
            </Truncation>
          </Box>
        )}
        {value.agencyUser ? (
          // Assertion fixes yarn check on color for Chip
          <Chip
            color={'tertiary' as ChipProps['color']}
            label={t(`Agency`)}
            variant="outlined"
          />
        ) : null}
      </>
    </>
  );
};

export default UserCell;
