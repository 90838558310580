import api from '@mint-lib/api';
import { isRedirect, redirect } from '@mint/routing';

type ID = string;

type IngestionTypes = 'single_platform' | 'cross_platforms';

type StepsNames =
  | 'upload'
  | 'headers_mapping'
  | 'validation'
  | 'channel_assignment';

type TableDataFilePreview = {
  id: string;
  name: string | null;
  currentStep: StepsNames;
  ingestionType: IngestionTypes;
  context: Context;
  isFileUploaded: boolean;
  isEdited: boolean;
  fromTemplate: {
    id: string;
    name: string | null;
  } | null;
  templateHeadersMismatched: boolean | null;
};

type Context = {
  journeyId?: ID | null;
  journeyName?: string | null;
  mediaPlanId?: ID | null;
  platformName?: ID | null;
};

type CreateDataTableFileResponse = {
  data: {
    additional: { id: ID };
  };
};

type CreateImportLoaderOptions = {
  params: { ingestionType: 'single-platform' | 'cross-platform' };
  deps: { template?: string };
  context: { apiIngestionType: IngestionTypes };
};

export const createNewImportLoader = async (
  opts: CreateImportLoaderOptions,
) => {
  const { template } = opts.deps;
  try {
    const { data } = await createTableDataFile({
      fromTemplateId: template ? template : null,
      ingestionType: opts.context.apiIngestionType,
    });
    throw redirect({
      to: '/company-profile/import-hub/$ingestionType/imports/$dataFileId',
      params: {
        ingestionType: opts.params.ingestionType,
        dataFileId: data.additional.id,
      },
    });
  } catch (error: unknown) {
    if (isRedirect(error)) {
      throw error;
    }

    throw redirect({
      to: '/company-profile/import-hub/$ingestionType/',
      params: {
        ingestionType: opts.params.ingestionType,
      },
      search: {
        tab: 'imports',
      },
    });
  }
};

const createTableDataFile = (payload: {
  fromTemplateId: string | null;
  ingestionType: IngestionTypes;
}) => {
  return api
    .post<CreateDataTableFileResponse>(
      '/api/data-ingestion/tabledatafile/',
      payload,
    )
    .then((res) => res.data);
};

type GetImportFileLoaderOptions = {
  params: {
    dataFileId: ID;
  };
};

export const getImportFileLoader = async (opts: GetImportFileLoaderOptions) => {
  const dataFilePreview = await getDataFilePreview(opts.params.dataFileId);

  return dataFilePreview;
};

const getDataFilePreview = (id: ID): Promise<TableDataFilePreview> => {
  return api
    .get(`/api/data-ingestion/tabledatafile/${id}/preview/`)
    .then((res) => {
      return res.data.data.items[0];
    });
};
