import { formLabelClasses } from '@mui/material/FormLabel';
import { Palette } from '@mui/material/styles';

const overrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        color: palette.text.secondary,
        [`&.${formLabelClasses.focused}`]: {
          color: palette.text.secondary,
        },
      },
    },
  };
};

export default overrides;
