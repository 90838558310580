import { useEffect } from 'react';

import { useNavigate } from '@mint/routing';
import { useAtom } from 'jotai';

import { DEFAULT_SORTING_STATE } from '../../store/atoms/TableSorting/constatnts.js';
import { tableSortingStore } from '../../store/atoms/TableSorting/tableSortingStore.js';
import { OrderingType } from '../../store/atoms/TableSorting/tableSortingStore.types.js';
import type { TableSortingType } from '../../store/atoms/TableSorting/tableSortingStore.types.js';
import useConditionalSearch from '../useConditionalSearch/useConditionalSearch.js';

const useTableSorting = (initialSorting?: TableSortingType) => {
  const navigate = useNavigate();

  const { ordering: routeOrdering } = useConditionalSearch();

  const [sorting, setSorting] = useAtom(tableSortingStore);

  const setOrdering = (ordering?: OrderingType) => {
    setSorting((state) => {
      state.ordering = ordering;
    });
  };

  const setOrderingBE = (ordering?: string) => {
    // route search params
    navigate({
      search: (prev) => ({ ...prev, ordering: ordering }),
    });
  };

  const setField = (field?: string) => {
    setSorting((state) => {
      state.field = field;
    });
  };

  const resetSorting = (defaultSorting?: TableSortingType) => {
    setSorting(defaultSorting || DEFAULT_SORTING_STATE);
  };

  useEffect(() => {
    if (routeOrdering) {
      const getInitialOrdering = () => {
        if (!routeOrdering) {
          return;
        }

        const field = routeOrdering.replace(/^-/, '');
        const ordering = routeOrdering.startsWith('-')
          ? 'desc'
          : ('asc' as OrderingType);
        const orderingBE = routeOrdering;

        return { field, ordering, orderingBE };
      };

      resetSorting({ ...getInitialOrdering() });

      return;
    }

    resetSorting({ ...(initialSorting ?? DEFAULT_SORTING_STATE) });
  }, [JSON.stringify(initialSorting)]);

  return {
    ordering: sorting.ordering,
    field: sorting.field,
    orderingBE: routeOrdering,
    setOrdering,
    setField,
    setOrderingBE,
  };
};

export default useTableSorting;
