function megabytesToBytes(megabytes: number): number {
  const bytesInMegabyte = 1024 * 1024;
  return megabytes * bytesInMegabyte;
}
const maxSizeMB = 2;

export const maxSizeBytes = megabytesToBytes(maxSizeMB);

// compare two number arrays
export const isEqual = (base: number[], compare?: number[]): boolean =>
  base.length === compare?.length &&
  base.every((baseValue) =>
    compare.some((compareValue) => compareValue === baseValue),
  );
