import React, { forwardRef } from 'react';

import RadioM, { RadioProps } from '@mui/material/Radio';

import Icon from '../Icon/Icon.jsx';

const Radio = forwardRef(
  (props: RadioProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    return (
      <RadioM
        checkedIcon={<Icon component="RadioButtonChecked" />}
        icon={<Icon component="RadioButton" />}
        {...props}
        ref={ref}
      />
    );
  },
);

Radio.defaultProps = {
  // NOTE: Checked must be controlled state from the outset, otherwise changing state in the app will trigger an error
  // see: https://fb.me/react-controlled-components
};

export default Radio;
