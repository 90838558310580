import { every } from './every.js';
import { some } from './some.js';
import type { ContextGuards, Guard } from './types.js';

export function buildGuards(...allGuards: Guard[]) {
  const checker = every(...allGuards);

  function check(ctx: ContextGuards) {
    return checker(ctx);
  }

  check.and = (...newGuards: Guard[]) => {
    return buildGuards(every(check, some(...newGuards)));
  };
  check.or = (...newGuards: Guard[]) => {
    return buildGuards(some(check, some(...newGuards)));
  };

  return check;
}
