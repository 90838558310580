import { Palette } from '@mui/material/styles';

const getBorderColor = (
  { selected, warning, error, readOnly }: { [key: string]: boolean },
  palette: Palette,
) => {
  if (error) {
    return palette.error.main;
  }
  if (warning) {
    return palette.warning.main;
  }
  if (selected) {
    return palette.tertiary.main;
  }
};

const CardOverrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: (ownerState: { [key: string]: boolean }) => ({
        pointerEvents:
          ownerState.disabled || ownerState.readOnly ? 'none' : 'auto',
        boxShadow: ownerState.disabled
          ? 'none'
          : '0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 0px 5px 0px rgba(23, 24, 24, 0.05)',
        '&:hover': {
          ...(ownerState.readOnly
            ? {
                boxShadow:
                  '0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 0px 5px 0px rgba(23, 24, 24, 0.05)',
              }
            : {
                boxShadow: ownerState.disabled
                  ? 'none'
                  : '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1)',
              }),
        },
        '&.MuiPaper-elevation': {
          border: `1px solid ${palette.border.tertiary}`,
        },
        '&.MuiPaper-outlined': {
          boxShadow: 'none',
          border: `1px solid ${palette.border.secondary}`,
          '&:hover': { border: `1px solid ${palette.border.secondary}` },
          'Mui-disabled': { border: `1px solid ${palette.border.disabled}` },
        },
        '&.MuiPaper-elevation, &.MuiPaper-outlined': {
          border: `1px solid ${getBorderColor(
            {
              ...ownerState,
            },
            palette,
          )}`,
        },
        '&.MuiPaper-root': {
          borderRadius: '8px',
        },
        backgroundColor: ownerState.selected
          ? palette.background.tertiary
          : palette.ui.primary,
      }),
    },
  };
};

export default CardOverrides;
