export enum LocalStorage {
  ACCESS = 'access',
  REFRESH = 'refresh',
  ACCESS_EXPIRES_IN = 'accessExpiresIn',
  REFRESH_EXPIRES_IN = 'refreshExpiresIn',
}

export enum SessionStorage {
  ACCESS = 'access',
  REFRESH = 'refresh',
  ACCESS_EXPIRES_IN = 'accessExpiresIn',
  REFRESH_EXPIRES_IN = 'refreshExpiresIn',
}
