import { Dispatch, SetStateAction, useState } from 'react';

import { useTranslation } from '@mint-lib/i18n';
import { enqueueSnackbar } from '@mint-lib/notifications';
import { useIsAgencyCompany } from '@mint-lib/profile-helpers';
import { SlotPortal } from '@mint-lib/slots';

import { useAgencyClients } from '../../api/queries/agencyClients.js';
import { useUsers } from '../../api/queries/usersData.js';
import type { WithPaginationResponse } from '../../api/types.js';
import { UserType } from '../../api/users/index.js';
import { useModal } from '../../context/ModalContext/useModal.js';
import Filters from '../../features/Filters/Filters.jsx';
import UserTable from '../../features/UserTable/index.js';
import useIsReadOnlyPermission from '../../hooks/useIsReadOnlyPermission/useIsReadOnlyPermission.js';
import useTablePagination from '../../hooks/useTablePagination/useTablePagination.jsx';
import UserModal from '../../organisms/UserModal/index.jsx';
import { HandleSubmitPramsType } from '../../organisms/UserModal/userModal.types.js';
import { DEFAULT_PAGINATION_STATE } from '../../store/atoms/TablePagination/constants.js';

const UserPage = () => {
  const { t } = useTranslation('@myn/permissions');

  const isReadonlyPermission = useIsReadOnlyPermission();

  const { open: isNewModalOpened, setOpen: setIsNewModalOpened } = useModal();

  const { users, usersData, createUser, refetchUsers } = useUsers();

  // init pagination
  useTablePagination({
    ...DEFAULT_PAGINATION_STATE,
    totalItems:
      (users?.data as WithPaginationResponse<UserType>)?.count ||
      (users?.data as UserType[])?.length ||
      0,
  });

  const isAgencyCompany = useIsAgencyCompany();

  const { assignClients } = useAgencyClients();

  const [isUserExistInWorkspace, setIsUserExistInWorkspace] =
    useState<boolean>(false);

  const handleCreate = async (params: HandleSubmitPramsType) => {
    try {
      const data = await createUser.mutateAsync(params.userData);

      enqueueSnackbar({
        removable: true,
        variant: 'success',
        title: t('Success'),
        subTitle: t('The user has been added successfully'),
      });

      // if passed clients and user created successfully
      // pass id from data and call assign
      if (
        isAgencyCompany &&
        params?.clients &&
        params?.clients?.length !== 0 &&
        data.id
      ) {
        try {
          await assignClients.mutateAsync({
            clients: params.clients,
            user: data.id,
          });
        } catch (error) {
          enqueueSnackbar({
            removable: true,
            variant: 'error',
            title: t('Error'),
            subTitle: t('Failed to assign clients to the user.'),
          });

          return null;
        }
      }

      setIsNewModalOpened(false);

      // refetch users to update table
      refetchUsers();
    } catch (error) {
      // specific error handle for modal field
      if (
        error?.response?.status === 400 &&
        error?.response?.data?.email[0] === 'This field must be unique.'
      ) {
        setIsUserExistInWorkspace(true);

        return null;
      }

      // error on  createUser.mutateAsync
      enqueueSnackbar({
        removable: true,
        variant: 'error',
        title: t('Error'),
        subTitle: t(
          'En error occurred while adding the user. Please try again',
        ),
      });
    }
  };

  const handleClose = () => {
    setIsNewModalOpened(false);
    setIsUserExistInWorkspace(false);
  };

  const noResultsStatus = !users.isLoading && usersData?.length === 0;

  const notNullUserData = (usersData || []) as UserType[];

  return (
    <>
      <UserTable
        data={notNullUserData}
        noResultsStatus={noResultsStatus}
        dataIsLoading={users.isPlaceholderData || users.isLoading}
        isReadonlyPermission={isReadonlyPermission}
      />
      <SlotPortal name="filters">
        <Filters key={'user'} />
      </SlotPortal>

      {isNewModalOpened ? (
        <UserModal
          onClose={handleClose}
          onSubmit={handleCreate}
          isActive={isNewModalOpened}
          isReadonlyPermission={isReadonlyPermission}
          isUserExistInWorkspace={isUserExistInWorkspace}
          setIsUserExistInWorkspace={setIsUserExistInWorkspace}
        />
      ) : null}
    </>
  );
};

export default UserPage;
