import { createRootRoute, Outlet } from '@tanstack/react-router';
import { Suspense } from 'react';

import { InternalServerErrorPage } from '@mint-lib/error-boundary';

import {
  FlagsmithDevTools,
  TanStackRouterDevtools,
} from '../components/DevTools.js';
import { NotFound } from '../components/NotFound.jsx';

export const Route = createRootRoute({
  component: Root,
  errorComponent: InternalServerErrorPage,
  notFoundComponent: NotFound,
});

function Root() {
  return (
    <>
      <Outlet />
      <Suspense fallback={null}>
        <TanStackRouterDevtools />
        <FlagsmithDevTools />
      </Suspense>
    </>
  );
}
