import { createContext, useRef, type PropsWithChildren } from 'react';

import { SlotsManager } from '../SlotsManager.js';

export const Slots = createContext<SlotsManager>(new SlotsManager());

export function SlotsProvider({ children }: PropsWithChildren) {
  const manager = useRef(new SlotsManager());

  return <Slots.Provider value={manager.current}>{children}</Slots.Provider>;
}
