import React, { forwardRef, useId } from 'react';

import { RadioProps } from '@mui/material/Radio';

import FormControlLabelM, {
  Props as FormControlLabelProps,
} from '../FormControlLabel/FormControlLabel.jsx';
import Radio from '../Radio/Radio.jsx';

export interface RadioWithLabelProps {
  /**
   * If `true`, the checkbox appears selected.
   * @uxpinbind onChange 1
   */
  checked?: boolean;
  /**
   * If `true`, the checkbox will be disabled.
   */
  disabled?: boolean;
  /**
   * The label text.
   */
  label?: React.ReactNode;
  /**
   * The value of the component.
   */
  value?: string;
  /**
   * The size of the component. small is equivalent to the dense checkbox styling.
   */
  size?: RadioProps['size'];
  color?: RadioProps['color'];
  /**
   * The position of the label.
   */
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  /**
   * Change event to use with UXPin interactions.
   */
  onChange?: FormControlLabelProps['onChange'];
}

const RadioWithLabel = forwardRef(
  (props: RadioWithLabelProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const id = useId();

    return (
      <FormControlLabelM
        {...props}
        htmlFor={id}
        control={
          <Radio
            ref={ref}
            id={id}
            inputProps={{ role: 'radio', 'aria-checked': props.checked }}
            color={props.color}
            size={props.size}
          />
        }
        label={props.label}
        sx={{
          width: '100%',
          gap: '0.25rem',
        }}
      />
    );
  },
);

RadioWithLabel.defaultProps = {
  // NOTE: Checked must be controlled state from the outset, otherwise changing state in the app will trigger an error
  // see: https://fb.me/react-controlled-components
  checked: false,
  onChange: () => undefined,
};

export { RadioWithLabel as default };
