import { createFileRoute, notFound } from '@tanstack/react-router';

import {
  buildGuards,
  hasFlagEnabled,
  hasPermission,
} from '@mint-lib/routing-guards';

const guard = buildGuards(
  hasPermission('masterDataManagerFeature:view'),
  hasFlagEnabled('mdmInCompanyProfile'),
);

export const Route = createFileRoute(
  '/_secured/company-profile/values-manager',
)({
  async beforeLoad({ context }) {
    const guardsContext = await context.getGuardContext(
      await context.serviceLocator.getInstance('flagsmith', '@mint/arm'),
    );

    if (!guard(guardsContext)) {
      throw notFound();
    }
    return context;
  },
  loader: async ({ context }) => {
    await context.serviceLocator.getInstance('i18n', '@myn/mdm-hub');
  },
});
