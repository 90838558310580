const palette = {
  primary: {
    main: '#75F461',
    light: '#aef8a2',
    dark: '#3d9730',
    hover: '#66dd54',
    active: '#58c547',
    contrastText: '#1D4C17',
  },
  secondary: {
    main: '#49545c',
    light: '#c2c8cc',
    dark: '#2f3941',
    hover: '#68737d',
    active: '#87929d',
    contrastText: '#ffffff',
  },
  text: {
    primary: '#2f3941',
    secondary: '#68737d',
    tertiary: '#87929d',
    success: '#5E8217',
    warning: '#914D08',
    info: '#087A91',
    placeholder: '#87929d',
    inverse: '#ffffff',
    error: '#C20A0A',
    link: '#4073B1',
    hoverPrimary: '#4F87CA',
    disabled: '#c2c8cc',
    disabled02: '#87929d',
  },
  action: {
    disabledBackground: '#E9EBED8F',
    disabled01: '#c2c8cc',
    disabled02: '#87929d',
    focusBorder: '#72b1fc',
    inverseFocusBorder: '#ffffff',
  },
  error: {
    main: '#FC7972',
    light: '#fcafab',
    dark: '#B14640',
    hover: '#E36760',
    active: '#CA554F',
    contrastText: '#260D0B',
  },
  info: {
    main: '#6EDEF5',
    light: '#ABEEFC',
    dark: '#267180',
    contrastText: '#194A54',
  },
  warning: {
    main: '#FCAB72',
    light: '#FCCDAB',
    dark: '#985C32',
    hover: '#E59C67',
    active: '#D98F5B',
    contrastText: '#452814',
  },
  success: {
    main: '#b7e360',
    light: '#D7FC8E',
    dark: '#628026',
    contrastText: '#354514',
  },
  background: {
    default: '#f8f9f9',
    primary: '#e7fce3',
    secondary: '#f8f9f9',
    tertiary: '#f6fafe',
    error: '#fce4e3',
    success: '#EFFCD4',
    warning: '#fcede3',
    info: '#E3F8FC',
    purple: '#F4E3FD',
    pink: '#FDE3F4',
    rose: '#FDE3EB',
    blue: '#E3EBFD',
    yellow: '#FDFDE3',
    green: '#E3FDE3',
    peach: '#FDEBE3',
    azure: '#E3F4FD',
    violet: '#EBE3FD',
    lime: '#F4FDE3',
    orange: '#FDF4E3',
    ice: '#E3FDFD',
    fuchsia: '#FDE3FD',
    emerald: '#EBFDE3',
    teal: '#e3fdf4',
    mint: '#E3FDEB',
    royal: '#E3E3FD',
    blaze: '#FCEDE3',
    coral: '#FCE4E3',
    desert: '#FCFAE3',
    jungle: '#E3FCF0',
    kingdom: '#FCE3FC',
    skin: '#FCE3EC',
    amber: '#FCEAE3',
  },
  icon: {
    primary: '#2F3941',
    secondary: '#68737d',
    inverse: '#ffffff',
  },
  ui: {
    primary: '#ffffff',
    secondary: '#f8f9f9',
    tertiary: '#D8DCDE',
    inverse01: '#68737D',
    inverse02: '#2F3941',
    field01: '#f8f9f9',
    field02: '#ffffff',
    overlay: '#2F394180',
    hover: '#87929D14',
    selected: '#87929D29',
    active: '#87929D29',
    hoverSelected: '#87929D33',
    hoverRow: '#f8F9F9',
    hoverCell: '#e9ebed',
    highlight: '#E2EEFD',
  },
  tertiary: {
    main: '#4073B1',
    light: '#abd0fc',
    dark: '#264e80',
    active: '#609CE3',
    hover: '#4F87CA',
    contrastText: '#ffffff',
  },
  greyscale: {
    black: '#000000',
    grey80: '#2f3941',
    grey70: '#49545c',
    grey60: '#68737d',
    grey50: '#87929d',
    grey40: '#c2c8cc',
    grey30: '#d8dcde',
    grey20: '#e9ebed',
    grey10: '#f8f9f9',
    white: '#ffffff',
  },
  //Misc Tokens
  transparent: {
    main: '#ffffff00',
    contrastText: '#161616',
  },
  silver: {
    main: '#e5e5e5',
    contrastText: '#161616',
  },
  purple: {
    main: '#CC7AF5',
    contrastText: '#8A0FC7',
    dark: '#8A0FC7',
  },
  pink: {
    main: '#F57ACC',
    contrastText: '#C70F8A',
    dark: '#C70F8A',
  },
  yellow: {
    main: '#F5F57A',
    contrastText: '#C7C70F',
    dark: '#C7C70F',
  },
  dark: {
    main: '#393939',
    contrastText: '#ffffff',
  },
  teal: {
    main: '#7AF5CC',
    contrastText: '#0FC78A',
    dark: '#0FC78A',
  },
  azure: {
    main: '#7ACCF5',
    contrastText: '#0F8AC7',
    dark: '#0F8AC7',
  },
  rose: {
    main: '#F57AA3',
    contrastText: '#C70F4D',
    dark: '#C70F4D',
  },
  peach: {
    main: '#F5A37A',
    contrastText: '#C74D0F',
    dark: '#C74D0F',
  },
  violet: {
    main: '#A37AF5',
    contrastText: '#4C0FC7',
    dark: '#4C0FC7',
  },
  green: {
    main: '#7AF57A',
    contrastText: '#0FC70F',
    dark: '#0FC70F',
  },
  ice: {
    main: '#7AF5F5',
    contrastText: '#0FC7C7',
    dark: '#0FC7C7',
  },
  orange: {
    main: '#F5CC7A',
    contrastText: '#C78A0F',
    dark: '#C78A0F',
  },
  emerald: {
    main: '#A3F57A',
    contrastText: '#4CC70F',
    dark: '#4CC70F',
  },
  lime: {
    main: '#CCF57A',
    contrastText: '#8AC70F',
    dark: '#8AC70F',
  },
  mint: {
    main: '#7AF5A3',
    contrastText: '#0FC74D',
    dark: '#0FC74D',
  },
  blue: {
    main: '#7AA3F5',
    contrastText: '#0F4DC7',
    dark: '#0F4DC7',
  },
  fuchsia: {
    main: '#F57AF5',
    contrastText: '#C70FC7',
    dark: '#C70FC7',
  },
  royal: {
    main: '#7A7AF5',
    contrastText: '#1010C7',
    dark: '#1010C7',
  },
  desert: {
    main: '#EBDD75',
    contrastText: '#C7B210',
    dark: '#C7B210',
  },
  blaze: {
    main: '#FFB585',
    contrastText: '#C75910',
    dark: '#C75910',
  },
  kingdom: {
    main: '#D961DB',
    contrastText: '#C310C7',
    dark: '#C310C7',
  },
  coral: {
    main: '#E57873',
    contrastText: '#C71910',
    dark: '#C71910',
  },
  skin: {
    main: '#FF80AB',
    contrastText: '#C7104F',
    dark: '#C7104F',
  },
  jungle: {
    main: '#75EBB0',
    contrastText: '#10C76B',
    dark: '#10C76B',
  },
  amber: {
    main: '#F5997A',
    contrastText: '#C73E10',
    dark: '#C73E10',
  },
  dataVisualization: {
    blue200: '#BFDBFE',
    blue400: '#60A5FA',
    blue600: '#2563EB',
    blue800: '#1E40AF',
    fuchsia200: '#F5D0FE',
    fuchsia400: '#E879F9',
    fuchsia600: '#C026D3',
    fuchsia800: '#86198F',
    orange200: '#FED7AA',
    orange400: '#FB923C',
    orange600: '#EA580C',
    orange800: '#9A3412',
    emerald200: '#A7F3D0',
    emerald400: '#34D399',
    emerald600: '#059669',
    emerald800: '#065F46',
    yellow200: '#FEF08A',
    yellow400: '#FACC15',
    yellow600: '#CA8A04',
    yellow800: '#854D0E',
    violet200: '#DDD6FE',
    violet400: '#A78BFA',
    violet600: '#7C3AED',
    violet800: '#5B21B6',
    red200: '#FECACA',
    red400: '#F87171',
    red600: '#DC2626',
    red800: '#991B1B',
    lime200: '#D9F99D',
    lime400: '#A3E635',
    lime600: '#65A30D',
    lime800: '#3F6212',
  },

  border: {
    primary: '#D8DCDE',
    secondary: '#E9EBED',
    disabled: '#D8DCDE',
    tertiary: '#ffffff00',
    focus: '#72B1FC',
    focusPrimary: '#AEF8A2',
    focusError: '#FCAFAB',
    focusWarning: '#DBEAFE',
    selectedPrimary: '#4073B1',
    selectedSecondary: '#ABD0FC',
  },
  channel: {
    metasearch: '#EAB308',
    metasearchBackground: '#FEF9C3',
    payTV: '#8B5CF6',
    payTVBackground: '#EDE9FE',
    mgm: '#FACC15',
    mgmBackground: '#FEF9C3',
    display: '#60A5FA',
    displayBackground: '#DBEAFE',
    adserver: '#34D399',
    adserverBackground: '#D1FAE5',
    affiliation: '#EA580C',
    affiliationBackground: '#FFEDD5',
    direct: '#065F46',
    directBackground: '#D1FAE5',
    organic: '#3F6212',
    organicBackground: '#ECFCCB',
    ooh: '#7C3AED',
    oohBackground: '#EDE9FE',
    webAnalytics: '#EF4444',
    webAnalyticsBackground: '#FEE2E2',
    brandedContent: '#10B981',
    brandedContentBackground: '#D1FAE5',
    influencerMarketing: '#F87171',
    influencerMarketingBackground: '#FEE2E2',
    dsp: '#F97316',
    dspBackground: '#FFEDD5',
    chatBot: '#86198F',
    chatBotBackground: '#FAE8FF',
    brandSafety: '#65A30D',
    brandSafetyBackground: '#ECFCCB',
    programmaticRadio: '#D946EF',
    programmaticRadioBackground: '#FAE8FF',
    network: '#5B21B6',
    networkBackground: '#EDE9FE',
    offlineRadio: '#84CC16',
    offlineRadioBackground: '#ECFCCB',
    publisher: '#2563EB',
    publisherBackground: '#DBEAFE',
    mobile: '#3B82F6',
    mobileBackground: '#DBEAFE',
    dmp: '#9A3412',
    dmpBackground: '#FFEDD5',
    dooh: '#FB923C',
    doohBackground: '#FFEDD5',
    dem: '#A3E635',
    demBackground: '#ECFCCB',
    offlineTV: '#CA8A04',
    offlineTVBackground: '#FEF9C3',
    video: '#1E40AF',
    videoBackground: '#DBEAFE',
    addressableTV: '#C026D3',
    addressableTVBackground: '#FAE8FF',
    social: '#E879F9',
    socialBackground: '#FAE8FF',
    native: '#A78BFA',
    nativeBackground: '#EDE9FE',
    search: '#059669',
    searchBackground: '#D1FAE5',
    promocode: '#991B1B',
    promocodeBackground: '#FEE2E2',
  },
};
export default palette;
