import * as React from 'react';

import styled from '@mint-lib/styled';
import DividerM, { DividerProps as DividerPropsM } from '@mui/material/Divider';

export interface DividerProps extends DividerPropsM {
  /**
   * Absolutely position the element.
   * @uxpinignoreprop
   */
  absolute?: boolean;
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  /**
   * @uxpinignoreprop
   * Override or extend the styles applied to the component.
   */
  classes?: DividerPropsM['classes'];
  /**
   * The component used for the root node. Either a string to use a HTML element or a component.
   */
  component?: React.ElementType;
  /**
   * If true, a vertical divider will have the correct height when used in flex container.
   * (By default, a vertical divider will have a calculated height of 0px if it is the child of a flex container.)
   * @uxpinignoreprop
   */
  flexItem?: boolean;
  /**
   * If true, the divider will have a lighter color.
   * @uxpinignoreprop
   */
  light?: boolean;
  /**
   * The component orientation.
   * @uxpinignoreprop
   */
  orientation?: DividerPropsM['orientation'];
  /**
   * The text alignment.
   */
  textAlign?: DividerPropsM['textAlign'];
  /**
   * The variant to use.
   */
  variant?: DividerPropsM['variant'];
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   * @uxpinignoreprop
   */
  sx?: DividerPropsM['sx'];
}

function Divider(props: DividerProps) {
  return <StyledDivider {...props} />;
}

export default Divider;

const StyledDivider = styled(DividerM)(({ theme }) => ({
  borderColor: theme.palette.border.secondary,
  '&::before, &::after': {
    borderColor: theme.palette.border.secondary,
  },
  '.MuiDivider-wrapper': {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
  '.MuiDivider-wrapperVertical': {
    padding: '0.5rem',
  },
}));
