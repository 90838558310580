import type { ContextGuards } from './types.js';

export function hasFlagEnabled(flag: string) {
  return (ctx: ContextGuards) => {
    if (!ctx.flagsService) {
      // eslint-disable-next-line no-console
      console.warn('Flags service not available');
      return false;
    }
    return ctx.flagsService.hasFeature(ctx.flagsService['name'], flag);
  };
}
