import * as React from 'react';

import PaginationM, {
  PaginationProps as PaginationPropsM,
} from '@mui/material/Pagination';
import PaginationItemM from '@mui/material/PaginationItem';

import Icon from '../Icon/Icon.jsx';

export interface PaginationProps extends PaginationPropsM {
  /**
   * The size of the component.
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * The total number of rows.
   *
   */
  count: number;
  /**
  Number of always visible pages at the beginning and end.
   *
   */
  boundaryCount?: number;
  /**
  Callback fired when the page is changed.
  event: The event source of the callback.
  page: The page selected.
   *
   */
  onChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  /**
  If true, hide the previous-page button.
   *
   */
  hidePrevButton?: boolean | undefined;
  /**
  If true, hide the next-page button.
   *
   */
  hideNextButton?: boolean | undefined;
}

const Pagination: React.FC<PaginationProps> = ({
  count = 10,
  size = 'medium',
  ...props
}) => {
  const ChevronLeft = React.useCallback(
    () => <Icon component="ChevronLeft" />,
    [],
  );
  const ChevronRight = React.useCallback(
    () => <Icon component="ChevronRight" />,
    [],
  );

  return (
    <PaginationM
      count={count}
      size={size}
      renderItem={(item) => (
        <PaginationItemM
          components={{ previous: ChevronLeft, next: ChevronRight }}
          {...item}
        />
      )}
      {...props}
    />
  );
};

export default Pagination;
