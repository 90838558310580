import { FC, ReactNode } from 'react';

import { TablePaginationProps } from './TablePagination.jsx';
import TablePaginationActions, {
  TablePaginationActionsProps,
} from './TablePaginationActions.jsx';
import TablePaginationActionsFull from './TablePaginationActionsFull.jsx';

const getActionButtonsComponent =
  (Component: FC<TablePaginationActionsProps>, disabledButtonProp?: boolean) =>
  (p: TablePaginationProps) => {
    return <Component buttonsDisabled={disabledButtonProp} {...p} />;
  };
interface TablePaginationInterface {
  labelRowsPerPage?: ReactNode;
  rowsPerPageOptions: Array<number | { value: number; label: string }>;
  buttonsDisabled?: boolean;
}

export interface TablePaginationVariantsProps extends TablePaginationInterface {
  type?: string;
}

export interface TablePaginationAttributes extends TablePaginationInterface {
  ActionsComponent: (p: TablePaginationProps) => ReactNode;
}

const getTablePaginationVariants = ({
  rowsPerPageOptions,
  labelRowsPerPage,
  buttonsDisabled,
  type = 'default',
}: TablePaginationVariantsProps) => {
  const typesSchema: { [type: string]: TablePaginationAttributes } = {
    default: {
      rowsPerPageOptions: [],
      labelRowsPerPage: '',
      ActionsComponent: getActionButtonsComponent(
        TablePaginationActions,
        buttonsDisabled,
      ),
    },
    withSelect: {
      rowsPerPageOptions,
      labelRowsPerPage,
      ActionsComponent: getActionButtonsComponent(
        TablePaginationActions,
        buttonsDisabled,
      ),
    },
    withButtons: {
      rowsPerPageOptions: [],
      labelRowsPerPage: '',
      ActionsComponent: getActionButtonsComponent(
        TablePaginationActionsFull,
        buttonsDisabled,
      ),
    },
    withSelectAndButtons: {
      rowsPerPageOptions,
      labelRowsPerPage,
      ActionsComponent: getActionButtonsComponent(
        TablePaginationActionsFull,
        buttonsDisabled,
      ),
    },
  };

  return { ...typesSchema[type] };
};

export default getTablePaginationVariants;
