import styled from '@mint-lib/styled';

type CalendarContainerProps = {
  variant: 'single' | 'range';
  disableStartDate?: boolean;
};

const CalendarContainer = styled('div')<CalendarContainerProps>`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  box-sizing: border-box;
  ${({ variant }) => {
    if (variant === 'single') {
      return `width: 15.25rem`;
    }
    return 'width: 31rem';
  }};

  border-radius: 4px;
  border: none;
  box-shadow:
    0px 2px 4px rgba(9, 30, 66, 0.16),
    0px 0px 1px rgba(9, 30, 66, 0.24);

  /* the hack from the OG CSS file */
  .react-datepicker__aria-live {
    position: absolute;
    clip-path: circle(0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
    white-space: nowrap;
  }

  &.react-datepicker {
    display: flex;
    justify-content: space-evenly;
    ${({ variant }) => {
      if (variant === 'single') {
        return `
            flex-direction: column;
          `;
      }
    }};
    padding: 0;
  }
  & .react-datepicker__month {
    margin: 0px;
  }
  & .react-datepicker__month-container {
    background-color: ${({ theme }) => theme.palette.ui.primary};
    padding: 4px;
    border-radius: 4px;
  }
  & .react-datepicker__header {
    background-color: ${({ theme }) => theme.palette.ui.primary};
    border-bottom: none;
    padding-top: 0;
  }

  & .react-datepicker__current-month {
    width: 5.25rem;
  }

  //  Week day and day number customization

  & .react-datepicker__week {
    display: flex;
  }

  & .react-datepicker__day-name,
  & .react-datepicker__day {
    background-color: ${({ theme }) => theme.palette.ui.primary};
    color: ${({ theme }) => theme.palette.text.primary};
    box-sizing: border-box;
    margin: 1px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
  }
  & .react-datepicker__day-names {
    margin-top: 4px;
    display: flex;
  }
  & .react-datepicker__day {
    position: relative;
    background-color: ${({ theme }) => theme.palette.ui.secondary};
    border-radius: 4px;
    font-size: 14px;
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 0.875rem;
    font-weight: 400;
  }

  & .react-datepicker__day.react-datepicker__day--disabled {
    color: ${({ theme }) => theme.palette.action.disabled01};
  }

  &
    .react-datepicker__day.react-datepicker__day--selected.react-datepicker__day--disabled {
    color: ${({ theme }) => theme.palette.action.disabled02};
    background-color: ${({ theme }) => theme.palette.action.disabled01};
  }

  & .react-datepicker__day--today {
    position: relative;
    ::after {
      content: '';
      height: 4px;
      background-color: ${({ theme }) => theme.palette.tertiary.light};
      position: absolute;

      bottom: 3px;
      width: 16px;
      border-radius: 2px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  & .react-datepicker__day--outside-month {
    pointer-events: none;
    visibility: hidden;
  }
  & .react-datepicker__day:hover:not(.react-datepicker__day--disabled) {
    background-color: ${({ theme }) => theme.palette.tertiary.main};
    color: ${({ theme }) => theme.palette.tertiary.contrastText};
  }
  & .react-datepicker__day:focus:not(.react-datepicker__day--disabled) {
    background-color: ${({ theme }) => theme.palette.ui.secondary};
    color: ${({ theme }) => theme.palette.text.primary};
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.palette.tertiary.main};
    color: ${({ theme }) => theme.palette.tertiary.contrastText};
    border: 1px solid transparent;
  }

  // Range styles

  & .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day.react-datepicker__day--in-range:hover {
    background-color: ${({ theme }) => theme.palette.ui.highlight};
    color: ${({ theme }) => theme.palette.tertiary.dark};
  }
  & .react-datepicker__day--range-end,
  & .react-datepicker__day--selecting-range-end,
  & .react-datepicker__day.react-datepicker__day--range-end:hover,
  & .react-datepicker__day.react-datepicker__day--selecting-range-end:hover,
  & .react-datepicker__day:focus:not(.react-datepicker__day--disabled) {
    background-color: ${({ theme }) => theme.palette.tertiary.main};
    color: ${({ theme }) => theme.palette.tertiary.contrastText};
  }
  & .react-datepicker__day--range-start,
  & .react-datepicker__day--selecting-range-start,
  & .react-datepicker__day.react-datepicker__day--range-start:hover,
  & .react-datepicker__day.react-datepicker__day--selecting-range-start:hover {
    background-color: ${({ theme }) => theme.palette.tertiary.main};
    color: ${({ theme }) => theme.palette.tertiary.contrastText};
  }
  .react-datepicker__day--range-start.react-datepicker__day--keyboard-selected,
  &
    .react-datepicker__day--range-start.react-datepicker__day--keyboard-selected:hover {
    ${({ disableStartDate, theme }) => {
      if (disableStartDate) {
        return `
        color: ${theme.palette.action.disabled02};
        background-color: ${theme.palette.action.disabled01};
        `;
      }
    }};
  }
`;

export default CalendarContainer;
