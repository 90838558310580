import { Theme } from '@mint-lib/theme';

export const controlColorDescription = (status: string, theme: Theme) =>
  ({
    default: theme.palette.ui.primary,
    success: theme.palette.background.success,
    warning: theme.palette.background.warning,
    error: theme.palette.background.error,
    editable: theme.palette.ui.hoverCell,
    'hover-editable': theme.palette.ui.primary,
  })[status] as string;

export const getSkeletonStyle = (variant: string) =>
  ({
    default: { width: '100%', height: 16 },
    avatar: { width: 16, height: 16 },
    checkbox: { width: 20, height: 20 },
  })[variant] || { width: '100%', height: 16 };
