import * as React from 'react';

import FormControlM, { FormControlProps } from '@mui/material/FormControl';

export interface Props extends FormControlProps {
  /**
   * The content of the component.
   * */
  /** @uxpinignoreprop */
  children?: React.ReactNode;
  /**
   * Override or extend the styles applied to the component.
   * See CSS API https://mui.com/api/form-label/#css for more details.
   */
  classes?: FormControlProps['classes'];
  /**
   * The color of the component.
   * It supports those theme colors that make sense for this component.
   */
  color?: FormControlProps['color'];
  /**
   * The component used for the root node. Either a string to use a HTML element or a component.
   */
  /** @uxpinignoreprop */
  component?: React.ElementType;
  /**
   * If 'true', the label should be displayed in a disabled state.
   */
  disabled?: boolean;
  /**
   * If 'true', the label is displayed in an error state.
   */
  error?: boolean;
  /**
   * If 'true', the label should use filled classes key.
   */
  /** @uxpinignoreprop */
  filled?: boolean;
  /**
   * If 'true', the input of this label is focused (used by FormGroup components).
   */
  focused?: boolean;
  /**
   * If 'true', the label will indicate that the input is required.
   */
  required?: boolean;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   * See the `sx` page for more details https://mui.com/system/the-sx-prop/.
   */
  /** @uxpinignoreprop */
  sx?: FormControlProps['sx'];
}

function FormControl(props: Props) {
  return <FormControlM {...props}>{props.children}</FormControlM>;
}

export default FormControl;
