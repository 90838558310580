import styled, { isPropValid } from '@mint-lib/styled';

import Button from '../../components/Button/Button.jsx';
import { GroupType, OptionType } from '../../types/SelectTypes.js';

type Props = {
  text?: string;
  value: string | (string | number)[];
  allOptions: OptionType[] | GroupType[];
  onClick: (...args: any) => void;
  isGroup: boolean;
};

const SelectAllCheckbox = ({ text, onClick, isGroup }: Props) => {
  return (
    <SelectAllButton onClick={onClick} isGroup={isGroup}>
      <StyledButton variant="plain">{text}</StyledButton>
    </SelectAllButton>
  );
};

export default SelectAllCheckbox;

const SelectAllButton = styled('div', { shouldForwardProp: isPropValid })<{
  isGroup: boolean;
}>`
  cursor: pointer;
  padding: 4px 0px 0px 12px;
  display: flex;
  align-items: center;
  z-index: 2;
  top: 0;
  height: 28px;
`;

const StyledButton = styled(Button)({
  '&:focus': {
    boxShadow: 'none',
  },
});
