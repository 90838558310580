import { createFileRoute, notFound } from '@tanstack/react-router';

import { buildGuards, hasPermission } from '@mint-lib/routing-guards';

const guard = buildGuards(
  hasPermission('campaignSettingsFeature:view'),
  hasPermission('financialDataFeature:full_control'),
);

export const Route = createFileRoute(
  '/_secured/campaign/$campaignId/settings/microsoft/display/$tileId',
)({
  async beforeLoad({ context }) {
    const guardsContext = await context.getGuardContext(
      await context.serviceLocator.getInstance('flagsmith', '@myn/secured'),
    );
    if (!guard(guardsContext)) {
      throw notFound();
    }
    return context;
  },
  loader: async ({ context }) => {
    await context.serviceLocator.getInstance('i18n', '@myn/display-microsoft');
  },
});
