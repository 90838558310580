import { useProfile } from '@mint-lib/profile-helpers';
import { useSearch } from '@mint/routing';

const useConditionalSearch = () => {
  const { profileService } = useProfile();

  const isAgencyCompany = profileService.isAgencyCompany();

  return useSearch({
    from: isAgencyCompany
      ? '/_secured/agency-hub/users-management'
      : '/_secured/company-profile/users-management',
  });
};
export default useConditionalSearch;
