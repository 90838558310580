import React, {
  ChangeEventHandler,
  FocusEventHandler,
  ForwardedRef,
  forwardRef,
  KeyboardEvent,
} from 'react';

import styled, { useTheme } from '@mint-lib/styled';
import InputBase, { InputBaseComponentProps } from '@mui/material/InputBase';
import { PaperProps } from '@mui/material/Paper';

import Divider from '../Divider/Divider.jsx';
import Icon from '../Icon/Icon.jsx';
import IconButton from '../IconButton/IconButton.jsx';
import Paper from '../Paper/Paper.jsx';

export interface SearchBarProps {
  /**
   * Callback is fired when value has been changing
   */
  onChange: ChangeEventHandler;

  onBlur?: FocusEventHandler;
  /**
   * If true component gets disabled state
   */
  disabled?: boolean;
  /**
   * Size variant, large by default
   */
  size?: 'small' | 'medium';
  /**
   * The color of the searchbar.
   */
  color?: 'field01' | 'field02';
  /**
   * Value of the input
   * @uxpinbind onChange 0.target.value
   */
  value?: string;
  defaultValue?: string;
  /**
   * Callback is fired after search button is clicked
   * @uxpinignoreprop
   */
  onSearch?: () => void;
  /**
   * @uxpinignoreprop
   */
  classname?: string;
  /**
   * Placeholder for input element
   */
  placeholder?: string;
  /**
   * If 'true', searchbar will take up the full width of its container.
   */
  fullWidth?: boolean;
  /**
   * Callback is fired when clear button is clicked
   */
  onClear?: () => void;
  /**
   * If 'true' - search button appears
   */
  searchButton?: boolean;

  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;

  inputProps?: InputBaseComponentProps;

  sx?: PaperProps['sx'];
  autoFocus?: boolean;
  innerRef?: React.Ref<unknown>;
}

const SearchBar = forwardRef(
  (
    {
      disabled = false,
      size = 'medium',
      color = 'field01',
      value,
      defaultValue,
      onChange,
      onBlur,
      onClear,
      onSearch,
      fullWidth = false,
      classname = '',
      placeholder = '',
      searchButton = false,
      onKeyDown,
      inputProps,
      autoFocus,
      innerRef,
      ...rest
    }: SearchBarProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const theme = useTheme();

    const handleKeyboardEvent = (event: KeyboardEvent<HTMLInputElement>) => {
      event.stopPropagation();
      if (event.key === 'Enter' && onSearch) {
        onSearch();
      }

      if (onKeyDown) {
        onKeyDown(event);
      }
    };

    return (
      <StyledPaper
        size={size}
        color={color}
        disabled={disabled}
        aria-label="search"
        className={classname}
        fullWidth={fullWidth}
        {...rest}
      >
        {!searchButton ? (
          <Icon
            aria-label="search-icon"
            htmlColor={
              disabled
                ? theme.palette.action.disabled01
                : theme.palette.text.secondary
            }
            component="Search"
            fontSize="small"
            sx={{ margin: '0 8px 0 0px' }}
          />
        ) : null}
        <StyledInput
          inputRef={innerRef}
          ref={ref}
          fullWidth={fullWidth}
          value={value}
          defaultValue={defaultValue}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          inputProps={{ 'aria-label': 'search-input', ...inputProps }}
          onKeyDown={handleKeyboardEvent}
          type="search"
          size={size}
          autoFocus={autoFocus}
          inputColor={color}
        />
        {!!value || !!defaultValue ? (
          <IconButton
            aria-label="search-clear"
            icon="Close"
            size="small"
            color="secondary"
            variant="icon"
            disabled={disabled}
            onClick={onClear}
          />
        ) : null}
        {searchButton ? (
          <>
            {value || !!defaultValue ? (
              <Divider
                orientation="vertical"
                sx={{ height: '24px', margin: '0px 4px' }}
              />
            ) : null}
            <IconButton
              icon="Search"
              size="small"
              color="secondary"
              variant="icon"
              disabled={disabled}
              onClick={onSearch}
            />
          </>
        ) : null}
      </StyledPaper>
    );
  },
);

export default SearchBar;

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})<{
  disabled?: boolean;
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  color?: 'field01' | 'field02';
}>(({ disabled, size, fullWidth, theme }) => ({
  position: 'relative',
  boxSizing: 'border-box',
  maxWidth: fullWidth ? '100%' : '20rem',
  display: 'flex',
  transition: 'none',
  alignItems: 'center',
  backgroundImage: 'none',
  height: size === 'medium' ? '2.5rem' : '2rem',
  borderRadius: '4px',
  boxShadow: `0 0 0 1px ${theme.palette.ui.tertiary}`,
  backgroundColor: disabled
    ? theme.palette.ui.field01
    : theme.palette.ui.field02,
  color: disabled
    ? theme.palette.action.disabled01
    : theme.palette.icon.primary,
  '&:hover': {
    boxShadow: disabled
      ? `0 0 0 1px ${theme.palette.ui.tertiary}`
      : `0 0 0 1px ${theme.palette.action.focusBorder}`,
  },
  '&:focus-within, &:focus-visible': {
    boxShadow: `0 0 0 2px ${theme.palette.action.focusBorder}`,
    outline: 'none',
  },
  cursor: disabled ? 'not-allowed' : undefined,
  '& input': {
    cursor: disabled && 'not-allowed',
  },
  '&:disabled': {
    backgroundColor: theme.palette.ui.field01,
  },
  '& .MuiIconButton-root.icon': {
    '&:focus': {
      boxShadow: 'none',
    },
  },
  padding: '0px 8px 0px 12px',
}));
const StyledInput = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== 'inputColor',
})<{
  size: 'small' | 'medium';
  inputColor?: 'field01' | 'field02';
}>(({ theme }) => ({
  ...theme.typography.bodyShort01,
  flex: 1,
  color: theme.palette.text.primary,
  padding: 0,
  backgroundColor: theme.palette.ui.field02,
  boxShadow: 'none',
  height: '39px',
  '&.MuiInputBase-sizeSmall': {
    height: '31px',
  },
  '&:hover': {
    backgroundColor: theme.palette.ui.field02,
    boxShadow: 'none',
  },
  '&.Mui-focused': {
    boxShadow: 'none',
    outline: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.ui.field01,
    boxShadow: 'none',
  },
  '& > input': {
    padding: `0 4px 0 0`,
  },
  '& > input::placeholder': {
    ...theme.typography.bodyShort01,
    color: theme.palette.text.placeholder,
    opacity: '1',
  },
  '& > input::-webkit-search-cancel-button': {
    display: 'none',
  },
}));
