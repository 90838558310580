import * as React from 'react';

import DialogContentM, {
  DialogContentProps,
} from '@mui/material/DialogContent';

export interface Props extends DialogContentProps {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  /**
   * Display the top and bottom dividers.
   */
  dividers?: boolean;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   * See the `sx` page for more details https://mui.com/system/the-sx-prop/.
   */
  sx?: DialogContentProps['sx'];
  /**
   * Override or extend the styles applied to the component. See CSS API below for more details..
   */
  classes?: DialogContentProps['classes'];
}

function DialogContent(props: Props) {
  return <DialogContentM {...props}>{props.children}</DialogContentM>;
}

export default DialogContent;
