import { Palette } from '@mui/material/styles';

const AvatarOverrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: (ownerState: { [key: string]: boolean }) => ({
        display: 'flex',

        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: ownerState.disabled ? 'none' : 'inherit',
        backgroundColor: ownerState.disabled ? palette.ui.primary : 'inherit',
        '&.MuiAvatar-square': {
          borderRadius: '4px',
        },
        '& > img': ownerState.disabled && {
          opacity: 0.4,
        },
      }),
    },
  };
};

export default AvatarOverrides;
