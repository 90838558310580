import { createFileRoute } from '@tanstack/react-router';

import { z } from 'zod';

export const Route = createFileRoute('/_auth/login-mfa/')({
  validateSearch: z.object({
    next: z.string().optional(),
    search: z.string().optional(),
    authCode: z.string().optional(),
  }),
});
