import { useContext } from 'react';

import { UserProfile } from '../index.js';
import {
  ProfileContext,
  ProfileContextValue,
} from '../ProfileContext/index.jsx';

type Profile = UserProfile['profile'];
type Company = UserProfile['profile']['company'];

export const useProfile = <IsAmbiguous extends boolean = false>() => {
  const { setProfileData, profileService } =
    useContext<ProfileContextValue<IsAmbiguous>>(ProfileContext);

  const profile = profileService.getProfileData();

  function getProfileField<T extends keyof Profile>(key: T) {
    return profile?.profile[key] as IsAmbiguous extends true
      ? Profile[T] | undefined
      : Profile[T];
  }

  function getCompanyField<T extends keyof Company>(key: T) {
    return profile?.profile.company[key] as IsAmbiguous extends true
      ? Company[T] | undefined
      : Company[T];
  }

  return {
    profile,
    getProfileField,
    getCompanyField,
    setProfileData,
    profileService,
  };
};
