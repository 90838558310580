import AlertOverrides from '../overrides/AlertOverrides.js';
import AlertTitleOverrides from '../overrides/AlertTitleOverrides.js';
import AutocompleteOverrides from '../overrides/AutocompleteOverrides.js';
import AvatarGroupOverrides from '../overrides/AvatarGroupOverrides.js';
import AvatarOverrides from '../overrides/AvatarOverrides.js';
import ButtonBaseOverrides from '../overrides/ButtonBaseOverrides.js';
import ButtonOverrides from '../overrides/ButtonOverrides.js';
import CardOverrides from '../overrides/CardOverrides.js';
import CheckboxOverrides from '../overrides/CheckboxOverrides.js';
import ChipOverrides from '../overrides/ChipOverrides.js';
import DialogContentOverrides from '../overrides/DialogContentOverrides.js';
import FormControlLabelOverrides from '../overrides/FormControlLabelOverrides.js';
import FormControlOverrides from '../overrides/FormControlOverrides.js';
import FormGroupOverrides from '../overrides/FormGroupOverrides.js';
import FormHelperTextOverrides from '../overrides/FormHelperTextOverrides.js';
import FormLabelOverrides from '../overrides/FormLabelOverrides.js';
import IconButtonOverrides from '../overrides/IconButtonOverrides.js';
import InputBaseOverrides from '../overrides/InputBaseOverrides.js';
import InputLabelOverrides from '../overrides/InputLabelOverrides.js';
import LinkOverrides from '../overrides/LinkOverrides.js';
import ListOverrides from '../overrides/ListOverrides.js';
import ListSubHeaderOverrides from '../overrides/ListSubHeaderOverrides.js';
import LoadingButtonOverrides from '../overrides/LoadingButtonOverrides.js';
import MenuItemOverrides from '../overrides/MenuItemOverrides.js';
import MuiCssBaselineOverrides from '../overrides/MuiCssBaselineOverrides.js';
import PaginationItemOverrides from '../overrides/PaginationItemOverrides.js';
import PaginationOverrides from '../overrides/PaginationOverrides.js';
import PaperOverrides from '../overrides/PaperOverrides.js';
import PopoverOverrides from '../overrides/PopoverOverrides.js';
import RadioOverrides from '../overrides/RadioOverrides.js';
import SliderOverrides from '../overrides/SliderOverrides.js';
import SwitchOverrides from '../overrides/SwitchOverrides.js';
import TableCellOverrides from '../overrides/TableCell.js';
import TableContainerOverrides from '../overrides/TableContainerOverrides.js';
import TableOverrides from '../overrides/TableOverrides.js';
import TablePaginationOverrides from '../overrides/TablePaginationOverrides.js';
import TableRowOverrides from '../overrides/TableRowOverrides.js';
import TabOverrides from '../overrides/TabOverrides.js';
import TabsOverrides from '../overrides/TabsOverrides.js';
import ToggleButtonOverrides from '../overrides/ToggleButtonOverrides.js';
import TooltipOverrides from '../overrides/TooltipOverrides.js';
import typography from '../overrides/typography.js';
import { ThemeModeType } from '../theme.js';
import paletteDark from './palette-dark.js';
import palette from './palette.js';

const componentsOverrides = {
  MuiInputBase: InputBaseOverrides,
  MuiCssBaseline: MuiCssBaselineOverrides,
  MuiButton: ButtonOverrides,
  MuiIconButton: IconButtonOverrides,
  MuiTab: TabOverrides,
  MuiTabs: TabsOverrides,
  MuiLink: LinkOverrides,
  MuiTooltip: TooltipOverrides,
  MuiCard: CardOverrides,
  MuiButtonBase: ButtonBaseOverrides,
  MuiCheckbox: CheckboxOverrides,
  MuiRadio: RadioOverrides,
  MuiAvatarGroup: AvatarGroupOverrides,
  MuiAvatar: AvatarOverrides,
  MuiFormControlLabel: FormControlLabelOverrides,
  MuiFormLabel: FormLabelOverrides,
  MuiFormGroup: FormGroupOverrides,
  MuiFormControl: FormControlOverrides,
  MuiMenuItem: MenuItemOverrides,
  MuiList: ListOverrides,
  MuiListSubheader: ListSubHeaderOverrides,
  MuiPaper: PaperOverrides,
  MuiSwitch: SwitchOverrides,
  MuiFormHelperText: FormHelperTextOverrides,
  MuiInputLabel: InputLabelOverrides,
  MuiTableCell: TableCellOverrides,
  MuiTableContainer: TableContainerOverrides,
  MuiAlert: AlertOverrides,
  MuiAlertTitle: AlertTitleOverrides,
  MuiChip: ChipOverrides,
  MuiDialogContent: DialogContentOverrides,
  MuiPagination: PaginationOverrides,
  MuiPaginationItem: PaginationItemOverrides,
  MuiTablePagination: TablePaginationOverrides,
  MuiAutocomplete: AutocompleteOverrides,
  MuiSlider: SliderOverrides,
  MuiLoadingButton: LoadingButtonOverrides,
  MuiToggleButton: ToggleButtonOverrides,
  MuiTableRow: TableRowOverrides,
  MuiTable: TableOverrides,
  MuiPopover: PopoverOverrides,
};

const renderOverridesObj = (overrides = componentsOverrides, args: any) => {
  const result = Object.entries(overrides).map(([key, value]) => {
    if (value instanceof Function) {
      return { [key]: value(args) };
    }
    return { [key]: value };
  });

  const overridesWithArgs = Object.assign({}, ...result);
  return overridesWithArgs;
};

const boilerplateTheme = (mode: ThemeModeType) => {
  const getPalette = mode === 'light' ? palette : paletteDark;
  return {
    // To further customize this theme,
    // please see the full theme object here: https://mui.com/customization/default-theme/
    palette: {
      mode,
      ...getPalette,
    },
    components: renderOverridesObj(componentsOverrides, getPalette),
    typography: typography,
  };
};

export default boilerplateTheme;
