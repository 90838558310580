import { useEffect } from 'react';

import { useTranslation } from '@mint-lib/i18n';
import { Slot, SlotPortal } from '@mint-lib/slots';
import { Outlet } from '@mint/routing';

import { Box } from '@myn/mui';

import { queryClient } from '../api/queries/main.js';

const App = () => {
  const { t } = useTranslation('@myn/permissions');

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.style.overflow = 'hidden';
    return () => {
      body.style.overflow = 'initial';
    };
  }, []);

  // clear data when user switched ws to prevent previous data
  useEffect(() => {
    return () => {
      queryClient.clear();
    };
  }, []);

  return (
    <>
      <SlotPortal name="topBarTitle">{t('Users Management')}</SlotPortal>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: (theme) => theme.spacing(2),
        }}
      >
        <Slot name="filters" />

        <Outlet />
      </Box>
    </>
  );
};

export default App;
