import { RouterContext } from '@mint-lib/routing-context';
import z from 'zod';

export const mixChannelRequest = z.object({
  signal: z.instanceof(AbortSignal),
  campaignId: z.string(),
});

export const metricSchema = z.object({
  id: z.number(),
  label: z.string(),
  value: z.number(),
  valueType: z.string(),
});

export const campaignSchema = z.object({
  dateTo: z.string(),
  dateFrom: z.string(),
  metrics: z.array(metricSchema),
  id: z.number(),
  name: z.string(),
  mediaplanId: z.number().optional().nullable(),
  acceptedSuggestionsCount: z.number().optional(),
});

export const campaignAndChannelMixesSchema = z.object({
  campaign: campaignSchema,
  channelMixes: z.array(campaignSchema),
});

export type CampaignAndChannelMixes = z.infer<
  typeof campaignAndChannelMixesSchema
>;

export type CampaignType = z.infer<typeof campaignSchema>;

export type MetricType = z.infer<typeof metricSchema>;

export async function mixChannelLoader(
  ctx: RouterContext,
  req: z.infer<typeof mixChannelRequest>,
): Promise<CampaignAndChannelMixes> {
  const { signal, campaignId } = mixChannelRequest.parse(req);

  const api = await ctx.serviceLocator.getInstance(
    'http',
    'defaultApi' as const,
  );

  const res = await api
    .get(`/api/insights_hub/goals_progress/meta?campaign_id=${campaignId}`, {
      signal,
    })
    .then(({ data }) => data.data.items[0]);

  return campaignAndChannelMixesSchema.parse(res);
}
