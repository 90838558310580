import React, { forwardRef } from 'react';

import TableBodyM, {
  TableBodyProps as TableBodyPropsM,
} from '@mui/material/TableBody';

import { useTableContext } from '../Table/Table.jsx';
import TableBodyCell from '../TableBodyCell/TableBodyCell.jsx';
import TableRow from '../TableRow/TableRow.jsx';

export interface TableBodyProps extends TableBodyPropsM {
  /**
   * The content of the component, normally TableRow.
   * @uxpinignoreprop
   * */
  children?: React.ReactNode;
  /**
   * Override or extend the styles applied to the component.
   * See CSS API below for more details.
   * @uxpinignoreprop
   */
  classes?: TableBodyPropsM['classes'];
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component?: React.ElementType;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   * See the `sx` page for more details.
   */
  sx?: TableBodyPropsM['sx'];
}

const TableBody = forwardRef<HTMLTableSectionElement, TableBodyProps>(
  (props, ref) => {
    const { variants, isLoading, placeholderRowsCount } = useTableContext();
    const tableRows = isLoading
      ? new Array(placeholderRowsCount).fill(true).map((_, index) => (
          <TableRow key={index}>
            {variants.map((variant, i) => (
              <TableBodyCell key={i} skeletonVariant={variant} isLoading />
            ))}
          </TableRow>
        ))
      : props.children;

    return (
      <TableBodyM ref={ref} {...props}>
        {tableRows}
      </TableBodyM>
    );
  },
);
export default TableBody;
