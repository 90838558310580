import { Palette } from '@mui/material/styles';

const TabsOverrides = (palette: Palette) => {
  return {
    styleOverrides: {
      root: {
        border: 0,
        minWidth: 48,
        minHeight: 32,
        '&.MuiTabs-vertical': {
          width: 'fit-content',
        },
      },
      indicator: {
        backgroundColor: palette.tertiary.main,
        height: 2,
        width: 2,
      },
    },
  };
};

export default TabsOverrides;
