import { createFileRoute, notFound } from '@tanstack/react-router';

import { z } from 'zod';

import { createNewImportLoader } from '@myn/import-hub/loaders';

const searchSchema = z.object({
  template: z.string().optional(),
});

export const Route = createFileRoute(
  '/_secured/company-profile/import-hub/$ingestionType/imports/new',
)({
  validateSearch: searchSchema,
  async beforeLoad({ context }) {
    const hasModifyPermission = await context.hasPermission(
      'dataManagementHubFeature:modify',
    );
    if (!hasModifyPermission) {
      throw notFound();
    }
  },
  loaderDeps: ({ search: { template } }) => ({ template }),
  loader: createNewImportLoader,
});
