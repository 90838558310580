const JourneyProviders = {
  APPNEXUS: 'appnexus',
  DBM: 'dbm',
  DV360: 'dv360',
  DV360_INTEGRATED_CH: 'dv360_integrated',
  DV360_VIRTUAL: 'dv360_virtual',
  DV360_INTEGRATED: 'dv360Integrated',
  FB: 'facebook',
  INSTA: 'instagram',
  MAILCHIMP: 'mailchimp',
  ELOQUA: 'eloqua',
  GOOGLE: 'google',
  BING: 'bing',
  THEOUTPLAY: 'theoutplay',

  ADFORM: 'adform',
  THE_TRADE_DESK: 'thetradedesk',
  OATH: 'oath',
  AMAZON_ADVERTISING: 'amazonadvertising',
  HYBRID_AI: 'hybridai',
  INSTAL: 'instal',
  YOUTUBE: 'youtube',
  TWITTER: 'twitter',
  LINKEDIN: 'linkedin',
  VK: 'vk',
  XING: 'xing',
  PINTEREST: 'pinterest',
  WHATSAPP: 'whatsappBusiness',
  MAKEMEREACH: 'makemereach',
  WECHAT: 'wechat',
  WEIBO: 'weibo',
  YANDEX: 'yandex',
  YAHOO: 'yahoo',
  BAIDU: 'baidu',
  CRITEO: 'criteo',

  CAMPAIGN_MANAGER: 'campaignManager',
  ADWORDS: 'adwords',
  GOOGLEADS: 'googleads',
  VIDEO_ADWORDS: 'youtube_google_ads_integrated',
  GDN: 'GDN',

  ADOBE: 'adobe', // TODO: refactor
  ADOBE_AUDIENCE_MANAGER: 'adobeAudienceManager',
  GOOGLE_DISPLAY_NETWORK: 'googleDisplayNetwork',
  MY_VIDEO: 'myVideo',
  // yeah. this is o not zero
  DV360_VIDEO: 'dv36O',
  CONVERSION_PIXEL: 'conversionPixel',

  // Ghost
  TRIBOO: 'triboo',
  FOUR_W_MARKET_PLACE: 'four_w_market_place',
  BEINTOO: 'beintoo',
  AL_FEMMINILE: 'al_femminile',
  ALIBABA: 'alibaba',
  AUTOSCOUT: 'autoscout',
  CIAOPEOPLE_ADV_FANPAGE: 'ciaopeople_adv_fanpage',
  CITYNEWS: 'citynews',
  CLASS_PUBBLICITA: 'class_pubblicita',
  CONDENAST: 'condenast',
  DAZN: 'dazn',
  DISCOVERY_MEDIA: 'discovery_media',
  EDREAMS_ODIGEO: 'edreams_odigeo',
  EURONEWS: 'euronews',
  INNOVATIVE_PUBLISHING: 'innovative_publishing',
  ITALIA_ON_LINE: 'italia_on_line',
  LASTMINUTE: 'lastminute',
  LUNIFIN_SRL: 'lunifin_srl',
  MANZONI: 'manzoni',
  MEDIAMOND: 'mediamond',
  MOBILE_DE: 'mobile_de',
  NET_ADDICTION: 'net_addiction',
  NEWSONLINE_IOL: 'newsonline_iol',
  PIEMME_ONLINE: 'piemme_online',
  SKY: 'sky',
  SPEED: 'speed',
  SPORT_NETWORK: 'sport_network',
  SUBITO: 'subito',
  VISIBILIA: 'visibilia',
  WEB_SYSTEM_24: 'web_system_24',
  AOL: 'aol',
  MAIL_RU: 'mail_ru',
  SOGOU: 'sogou',
  RAI_PUBBLICITA: 'rai_pubblicita',
  RAKUTEN_ADV: 'rakuten_adv',
  RCS_PUBBLICITA: 'rcs_pubblicita',
  SPOTIFY: 'spotify',
};

export default JourneyProviders;
