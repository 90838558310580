import { createFileRoute, notFound } from '@tanstack/react-router';

import {
  buildGuards,
  hasPermission,
  isAgencyCompany,
  isControllerCompany,
  isRetailLicensee,
  not,
} from '@mint-lib/routing-guards';

const guard = buildGuards(
  hasPermission('notificationsFeature:view'),
  not(isAgencyCompany),
  not(isControllerCompany),
  not(isRetailLicensee),
);

export const Route = createFileRoute('/_secured/notifications/')({
  async beforeLoad({ context }) {
    const guardsContext = await context.getGuardContext();
    if (!guard(guardsContext)) {
      throw notFound();
    }
    return context;
  },
  async loader({ context }) {
    await context.serviceLocator.getInstance('i18n', '@myn/notifications-hub');

    return {
      isReadOnly: !(await context.hasPermission('notificationsFeature:modify')),
    };
  },
});
