import type { ContextGuards } from './types.js';

type Permissions = ContextGuards['profileService']['hasPermission'] extends (
  permission: infer P,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => any
  ? P
  : never;

export function hasPermission(permission: Permissions) {
  return (ctx: ContextGuards) => {
    return ctx.profileService.hasPermission(permission);
  };
}
