import { createFileRoute, notFound } from '@tanstack/react-router';

import { z } from 'zod';

const paramsSchema = z.object({
  ingestionType: z.union([
    z.literal('single-platform'),
    z.literal('cross-platform'),
  ]),
});

type UrlIngestionType = z.infer<typeof paramsSchema>['ingestionType'];
const apiIngestionTypesByUrlParam = {
  'single-platform': 'single_platform',
  'cross-platform': 'cross_platforms',
} as const satisfies Record<UrlIngestionType, string>;

export const Route = createFileRoute(
  '/_secured/company-profile/import-hub/$ingestionType',
)({
  parseParams(rawParams) {
    return paramsSchema.parse(rawParams);
  },
  beforeLoad(opts) {
    try {
      const params = paramsSchema.parse(opts.params);
      return {
        apiIngestionType: apiIngestionTypesByUrlParam[params.ingestionType],
      };
    } catch {
      throw notFound();
    }
  },
});
