const MuiCssBaselineOverrides = {
  styleOverrides: `
        @font-face {
          font-family: 'Montserrat';
          src: url('../resources/src/fonts/Montserrat/Montserrat-Regular.eot');
          src: local('☺'), url('../resources/src/fonts/Montserrat/Montserrat-Regular.woff') format('woff'), url('../resources/src/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype'), url('../resources/src/fonts/Montserrat/Montserrat-Regular.svg') format('svg');
          font-weight: 400;
          font-style: normal;
        };
        @font-face {
          font-family: 'Montserrat';
          src: url('../resources/src/fonts/Montserrat/Montserrat-Medium.eot');
          src: local('☺'), url('../resources/src/fonts/Montserrat/Montserrat-Medium.woff') format('woff'), url('../resources/src/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype'), url('../resources/src/fonts/Montserrat/Montserrat-Medium.svg') format('svg');
          font-weight: 500;
          font-style: normal;
        };
        @font-face {
          font-family: 'Montserrat';
          src: url('../resources/src/fonts/Montserrat/Montserrat-SemiBold.eot');
          src: local('☺'), url('../resources/src/fonts/Montserrat/Montserrat-SemiBold.woff') format('woff'), url('../resources/src/fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype'), url('../resources/src/fonts/Montserrat/Montserrat-SemiBold.svg') format('svg');
          font-weight: 600;
          font-style: normal;
        };
      `,
};

export default MuiCssBaselineOverrides;
