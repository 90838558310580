// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { AxiosError } from 'axios';

import { AuthServiceInterface } from '../AuthServiceInterface.js';
import { HttpStatus } from '../HttpStatus.js';
import { responseErrorDataToCamelCase } from './responseErrorDataToCamelCase.js';

let auth: AuthServiceInterface | null = null;

export function setAuthForErrorInterceptor(newAuth: AuthServiceInterface) {
  auth = newAuth;
}

export function responseErrorInterceptor(err: AxiosError) {
  // eslint-disable-next-line no-console

  if (err.response?.status === HttpStatus.Unauthorized) {
    auth?.logout(err);
  }
  return responseErrorDataToCamelCase(err);
}
