/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  type AxiosInstance,
  type BaseAuthServiceInterface,
  type Tokens,
} from '@mint-lib/api';
import { EventEmitter } from '@mint-lib/service-locator';

import { TOKEN_REFRESH } from './constants/Endpoints.js';
import type { BaseAuthStorage } from './types.js';

export type BaseAuthEvents = {
  auth: [];
  logout: [];
};

export class BaseAuthService<Storage extends BaseAuthStorage = BaseAuthStorage>
  implements BaseAuthServiceInterface
{
  protected readonly eventEmitter: EventEmitter<BaseAuthEvents> =
    new EventEmitter();
  listeners = new Set<() => any>();
  private ensureTokensValidPromise: Promise<any> | null = null;

  constructor(
    protected guestApi: AxiosInstance,
    protected storage: Storage,
  ) {
    this.readTokens();
  }

  private readTokens() {
    if (
      !this.isLoggedIn() &&
      this.storage.getAccessToken() &&
      this.storage.getRefreshToken()
    ) {
      this.logout();
    }
  }

  getEventEmitter() {
    return this.eventEmitter;
  }

  getAccessToken() {
    return this.storage.getAccessToken();
  }

  refreshTokenExpired() {
    return !this.isTokenAlive(this.storage.getRefreshTokenExpiresIn());
  }

  accessTokenExpired() {
    return !this.isTokenAlive(this.storage.getAccessTokenExpiresIn() - 60);
  }

  async ensureTokensValid(): Promise<any> {
    if (this.ensureTokensValidPromise) {
      return this.ensureTokensValidPromise;
    }
    this.ensureTokensValidPromise = this.executeEnsureTokensValid().then(() => {
      this.ensureTokensValidPromise = null;
    });
    return this.ensureTokensValidPromise;
  }

  async provideBearerToken() {
    await this.ensureTokensValid();
    return `jwt ${this.storage.getAccessToken()}`;
  }

  protected async executeEnsureTokensValid() {
    if (this.refreshTokenExpired()) {
      this.logout();
      throw new Error('Refresh token expired');
    }
    if (this.accessTokenExpired()) {
      try {
        await this.updateTokens();
      } catch (error) {
        this.logout();
        throw new Error('Failed update tokens');
      }
    }
  }

  // eslint-disable-next-line
  protected isTokenAlive(expiresIn: number) {
    return expiresIn && Math.ceil(Date.now() / 1000) < expiresIn;
  }

  isLoggedIn() {
    return Boolean(
      this.storage.getAccessToken() &&
        this.storage.getRefreshToken() &&
        this.storage.getAccessTokenExpiresIn() &&
        !this.refreshTokenExpired(),
    );
  }

  saveTokens({ access, refresh, accessExpiresIn, refreshExpiresIn }: Tokens) {
    this.storage.setAccessToken(access);
    this.storage.setRefreshToken(refresh);
    this.storage.setAccessTokenExpiresIn(accessExpiresIn);
    this.storage.setRefreshTokenExpiresIn(refreshExpiresIn);
    this.notify();
    return this.eventEmitter.emit('auth');
  }

  async updateTokens() {
    const tokensPromise = this.guestApi.post<Tokens>(TOKEN_REFRESH, {
      refresh: this.storage.getRefreshToken(),
    });

    const tokens = await tokensPromise;

    this.saveTokens(tokens.data);

    return tokens.data;
  }

  logout(event?: any) {
    this.storage.setAccessToken(null);
    this.storage.setRefreshToken(null);
    this.storage.setAccessTokenExpiresIn(null);
    this.storage.setRefreshTokenExpiresIn(null);
    this.notify(event);
    return this.eventEmitter.emit('logout');
  }

  notify(event?: any) {
    setTimeout(
      () =>
        this.listeners.forEach((handler: (event?: any) => any) =>
          handler(event),
        ),
      10,
    );
  }

  subscribe(handler: (event?: any) => any) {
    console.warn(
      '[AuthService]#subscribe() DEPRECATED API. Use eventEmitter instead',
    );
    this.listeners.add(handler);
    return () => {
      this.listeners.delete(handler);
    };
  }
}
// TODO: remove temporary login service when proper login for admin will be ready
export class BaseAuthAdminService<
  Storage extends BaseAuthStorage = BaseAuthStorage,
> implements BaseAuthServiceInterface
{
  protected readonly eventEmitter: EventEmitter<BaseAuthEvents> =
    new EventEmitter();
  listeners = new Set<() => any>();
  private ensureTokensValidPromise: Promise<any> | null = null;

  constructor(
    protected guestApi: AxiosInstance,
    protected storage: Storage,
  ) {
    this.readTokens();
  }

  private readTokens() {
    if (!this.isLoggedIn() && this.storage.getAccessToken()) {
      this.logout();
    }
  }

  getAccessToken() {
    return this.storage.getAccessToken();
  }

  refreshTokenExpired() {}

  accessTokenExpired() {
    return !this.isTokenAlive(this.storage.getAccessTokenExpiresIn() - 60);
  }

  async ensureTokensValid(): Promise<any> {
    if (this.ensureTokensValidPromise) {
      return this.ensureTokensValidPromise;
    }
    this.ensureTokensValidPromise = this.executeEnsureTokensValid().then(() => {
      this.ensureTokensValidPromise = null;
    });
    return this.ensureTokensValidPromise;
  }

  async provideBearerToken() {
    await this.ensureTokensValid();
    return `${this.storage.getAccessToken()}`;
  }

  protected async executeEnsureTokensValid() {
    if (this.accessTokenExpired()) {
      this.logout();
    }
  }

  // eslint-disable-next-line
  protected isTokenAlive(expiresIn: number) {
    return expiresIn && Math.ceil(Date.now() / 1000) < expiresIn;
  }

  isLoggedIn() {
    return Boolean(
      this.storage.getAccessToken() && this.storage.getAccessTokenExpiresIn(),
    );
  }

  saveTokens({ access, accessExpiresIn }: Tokens) {
    this.storage.setAccessToken(access);
    this.storage.setAccessTokenExpiresIn(accessExpiresIn);
    this.notify();
    return this.eventEmitter.emit('auth');
  }

  async updateTokens() {
    const tokensPromise = this.guestApi.post<Tokens>(TOKEN_REFRESH, {
      refresh: this.storage.getRefreshToken(),
    });

    const tokens = await tokensPromise;

    this.saveTokens(tokens.data);
    this.notify();

    return tokens.data;
  }

  logout(event?: any) {
    this.storage.setAccessToken(null);
    this.storage.setAccessTokenExpiresIn(null);
    this.notify(event);
    return this.eventEmitter.emit('logout');
  }

  getEventEmitter() {
    return this.eventEmitter;
  }
  notify(event?: any) {
    setTimeout(
      () =>
        this.listeners.forEach((handler: (event?: any) => any) =>
          handler(event),
        ),
      10,
    );
  }

  subscribe(handler: (event?: any) => any) {
    this.listeners.add(handler);
    return () => {
      this.listeners.delete(handler);
    };
  }
}
