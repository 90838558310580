import React, { useMemo } from 'react';

import Box from '@mui/material/Box';

import IconButton from '../IconButton/IconButton.jsx';
import { IconButtonInterface } from './TablePagination.jsx';

export interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
  buttonsDisabled?: boolean;
}

const TablePaginationActions: React.FC<TablePaginationActionsProps> = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
  buttonsDisabled,
}) => {
  const iconButtonsArray = useMemo(() => {
    return [
      {
        disabled: page === 0 || buttonsDisabled,
        icon: 'ChevronLeft',
        ariaLabel: 'previous page',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) =>
          onPageChange(event, page - 1),
      },
      {
        disabled: page >= Math.ceil(count / rowsPerPage) - 1 || buttonsDisabled,
        icon: 'ChevronRight',
        ariaLabel: 'next page',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) =>
          onPageChange(event, page + 1),
      },
    ] as IconButtonInterface[];
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      {iconButtonsArray.map(({ disabled, icon, ariaLabel, onClick }) => {
        return (
          <IconButton
            key={icon}
            icon={icon}
            color="secondary"
            variant="icon"
            size="medium"
            onClick={onClick}
            aria-label={ariaLabel}
            disabled={disabled}
          />
        );
      })}
    </Box>
  );
};

export default TablePaginationActions;
