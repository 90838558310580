import { createFileRoute } from '@tanstack/react-router';

import { z } from 'zod';

import { mfaActivateLoader } from '@myn/guest/loaders';

export const Route = createFileRoute('/_auth/activate-mfa/')({
  validateSearch: z.object({
    next: z.string().optional(),
    search: z.string().optional(),
  }),
  loaderDeps: ({ search }) => ({
    code: search?.search ?? '',
  }),
  async loader({ context, deps: { code }, abortController }) {
    return mfaActivateLoader(context, {
      code,
      signal: abortController.signal,
    });
  },
});
