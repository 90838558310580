import * as React from 'react';

import FormControlLabelM, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';

export interface Props extends FormControlLabelProps {
  /**
   * If `true`, the component appears selected.
   */
  checked?: boolean;
  /**
   * @uxpinignoreprop
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api) below for more details.
   */
  classes?: FormControlLabelProps['classes'];
  /**
   * A control element. For instance, it can be be a `Radio`, a `Switch` or a `Checkbox`.
   */
  control: React.ReactElement;
  /**
   * If `true`, the control will be disabled.
   */
  disabled?: boolean;
  /**
   * If 'true', the label is rendered as it is passed without an additional typography node.
   */
  disableTypography?: boolean;
  /**
   * @uxpinignoreprop
   * Use that property to pass a ref callback to the native input component.
   */
  inputRef?: FormControlLabelProps['inputRef'];
  /**
   * The text to be used in an enclosing label element.
   */
  label: FormControlLabelProps['label'];
  /**
   * The position of the label.
   */
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  /**
   * @uxpinignoreprop
   * @ignore
   */
  muiFormControl?: Record<string, unknown>;
  /*
   * @ignore
   */
  name?: string;
  /**
   * Callback fired when the state is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.checked`.
   * @param {boolean} checked The `checked` value of the switch
   */
  onChange?: FormControlLabelProps['onChange'];
  /**
   * @uxpinignoreprop
   * The value of the component.
   */
  value?: string;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles. See the `sx` page for more details.
   * https://mui.com/system/the-sx-prop/
   */
  /** @uxpinignoreprop */
  sx?: FormControlLabelProps['sx'];
  children?: React.ReactNode;
}

function FormControlLabel(props: Props) {
  return <FormControlLabelM {...props}>{props.children}</FormControlLabelM>;
}

export default FormControlLabel;
