import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  InitialTableState,
  useReactTable,
} from '@tanstack/react-table';

import { useFlags } from '@mint-lib/flags';
import { useTranslation } from '@mint-lib/i18n';

import {
  Box,
  SxProps,
  Table,
  TableBody,
  TableBodyCell,
  TableCell,
  TableContainer,
  TableHead,
  TableHeaderCell,
  TablePagination,
  TableRow,
  Theme,
} from '@myn/mui';

import { RoleType } from '../../api/roles/index.js';
import { UserType } from '../../api/users/index.js';
import NoData from '../../atoms/NoData/index.js';
import useTablePagination from '../../hooks/useTablePagination/useTablePagination.jsx';
import useTableSorting from '../../hooks/useTableSorting/useTableSorting.jsx';
import { DEFAULT_LIMIT } from '../../store/atoms/TablePagination/constants.js';
import type { OrderingType } from '../../store/atoms/TableSorting/tableSortingStore.types.js';

export type GenericTableWrapperType = UserType | RoleType;

type TableWrapperProps<T extends GenericTableWrapperType> = {
  data: T[];
  columns: ColumnDef<T>[];
  tableWrapperSx?: SxProps<Theme>;
  initialState?: InitialTableState;
  dataIsLoading?: boolean;
  noResultsStatus?: boolean;
};

const TableWrapper = <T extends GenericTableWrapperType>({
  data,
  columns,
  tableWrapperSx,
  initialState,
  dataIsLoading,
  noResultsStatus,
}: TableWrapperProps<T>) => {
  const { t } = useTranslation('@myn/permissions');

  const ff = useFlags(['hidePagination']);

  // init pagination
  const { limit, setLimit, totalItems, currentPage, setCurrentPage } =
    useTablePagination();

  // init sorting
  const { ordering, setOrdering, setOrderingBE, field, setField } =
    useTableSorting();

  const table = useReactTable({
    data,
    columns,
    state: {},
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    initialState: { ...initialState },
  });

  return (
    <Box
      sx={{
        height: `calc(100vh - 211px)`,
        display: 'flex',
        flexDirection: 'column',
        gap: (theme) => theme.spacing(2),
        justifyContent: 'space-between',
      }}
    >
      <TableContainer sx={{ ...tableWrapperSx }}>
        <Table
          stickyHeader
          isLoading={dataIsLoading}
          placeholderRowsCount={limit}
        >
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  if (header.column.id === 'expander') {
                    return flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    );
                  }

                  const fieldMeta = header?.column?.columnDef?.meta?.field;

                  const getIsSorted = () => {
                    if (field !== fieldMeta) {
                      return null;
                    }

                    if (ordering === 'asc') {
                      return true;
                    }
                    if (ordering === 'desc') {
                      return false;
                    }

                    return null;
                  };

                  const getSortOrder = (): {
                    orderingBE?: string;
                    ordering?: OrderingType;
                    field?: string;
                  } => {
                    if (fieldMeta !== field) {
                      return {
                        orderingBE: fieldMeta,
                        ordering: 'asc',
                        field: fieldMeta,
                      };
                    }

                    if (!fieldMeta) {
                      return { orderingBE: '', field: fieldMeta };
                    }

                    if (ordering === 'asc') {
                      return {
                        orderingBE: `-${fieldMeta}`,
                        ordering: 'desc',
                        field,
                      };
                    }

                    if (ordering === 'desc') {
                      return { orderingBE: '', field: fieldMeta };
                    }

                    return {
                      orderingBE: fieldMeta,
                      ordering: 'asc',
                      field: fieldMeta,
                    };
                  };

                  return (
                    <TableHeaderCell
                      key={header.id}
                      onSort={
                        header?.column?.columnDef.enableSorting !== false
                          ? () => {
                              // prevent sorting when data is loading
                              if (dataIsLoading) {
                                return;
                              }

                              const {
                                ordering: normalizedOrdering,
                                orderingBE: normalizedOrderingBE,
                                field: normalizedField,
                              } = getSortOrder();

                              setField(normalizedField);
                              setOrdering(normalizedOrdering);
                              setOrderingBE(normalizedOrderingBE);
                            }
                          : undefined
                      }
                      sorted={getIsSorted()}
                      align="left"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHeaderCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>

          <TableBody>
            {noResultsStatus ? (
              <TableRow>
                <TableCell colSpan={100}>
                  <NoData header={t('No results')} message="" />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {table.getRowModel().rows.map((row) => (
                  <TableRow
                    sx={{
                      '&:hover': {
                        '& .hoverClass': {
                          opacity: '100',
                        },
                      },
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      if (cell.column.id === 'actions') {
                        return (
                          <TableBodyCell key={cell.id} align="right">
                            <Box className="hoverClass" sx={{ opacity: '0' }}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </Box>
                          </TableBodyCell>
                        );
                      }

                      return (
                        <TableBodyCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableBodyCell>
                      );
                    })}
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!ff.hidePagination.enabled && totalItems <= limit ? null : (
        <Box sx={{ mr: '100px' }}>
          <TablePagination
            rowsPerPage={limit}
            count={totalItems}
            page={currentPage}
            onPageChange={(_, page) => {
              setCurrentPage(page);
            }}
            type="withSelectAndButtons"
            size="medium"
            rowsPerPageOptions={[15, DEFAULT_LIMIT, 50, 70]}
            onRowsPerPageChange={(e) => {
              setLimit(Number(e.target.value));
            }}
            labelRowsPerPage={t('Items per page:')}
          />
        </Box>
      )}
    </Box>
  );
};

export default TableWrapper;
