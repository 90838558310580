import typography from './typography.js';

const AutocompleteOverrides = (palette: any) => {
  return {
    styleOverrides: {
      root: {
        minWidth: '280px',
        '& .MuiFormControl-root .MuiInputBase-adornedStart': {
          '&.Mui-disabled .MuiInputAdornment-root path': {
            fill: palette.action.disabled01,
          },
        },
        '& .MuiAutocomplete-popupIndicator, .MuiAutocomplete-clearIndicator': {
          height: '24px',
          width: '24px',
          color: palette.tertiary.main,
          '&:focus': {
            boxShadow: `0 0 0 2px ${palette.action.focusBorder}`,
          },
          '&:active': {
            backgroundColor: palette.ui.active,
          },
        },
        '&.read-only-autocomplete': {
          '.MuiOutlinedInput-root': {
            backgroundColor: palette.ui.field01,
            '&.Mui-focused': {
              boxShadow: `inset 0 0 0 1px ${palette.border.primary}`,
            },
            '&:focus': {
              boxShadow: `inset 0 0 0 1px ${palette.border.primary}`,
              '&:hover': {
                boxShadow: `inset 0 0 0 1px ${palette.border.primary}`,
              },
            },
            '&:hover': {
              boxShadow: `inset 0 0 0 1px ${palette.border.primary}`,
            },
          },
        },
      },
      noOptions: {
        ...typography.bodyShort01,
        display: 'flex',
        justifyContent: 'center',
        padding: '1rem 0rem',
      },
      paper: {
        boxShadow:
          '0px 2px 4px rgba(9, 30, 66, 0.16), 0px 0px 1px rgba(9, 30, 66, 0.24)',
        border: `1px solid ${palette.border.tertiary} `,
      },
      listbox: {
        padding: 0,
        '& .MuiAutocomplete-groupLabel': {
          ...typography.overline01,
          color: palette.text.secondary,
          padding: '12px 12px 4px 12px',
          backgroundColor: palette.ui.primary,
        },
        '& .MuiAutocomplete-groupUl .MuiAutocomplete-option': {
          paddingLeft: '12px !important',
        },

        '& .MuiAutocomplete-option': {
          ...typography.bodyShort01,
          color: palette.text.primary,
          display: 'block',
          padding: '0.6875rem 12px',
          whiteSpace: 'nowrap' as const,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          minHeight: 0,
          '&:active': {
            backgroundColor: palette.ui.primary,
          },
          '&:hover': {
            backgroundColor: palette.ui.hover,
          },
          '&[aria-selected="true"]': {
            backgroundColor: `${palette.background.tertiary} !important`,
            '&.Mui-focused': {
              backgroundColor: palette.ui.hover,
            },
            '&:hover': {
              backgroundColor: `${palette.ui.highlight} !important`,
            },
            '&:active': {
              backgroundColor: palette.ui.hover,
            },
          },
        },
      },
      input: {
        padding: '0px !important',
      },
      inputRoot: {
        '& .MuiAutocomplete-endAdornment': {
          right: '12px',
          top: 'initial',
        },
      },
      clearIndicator: {
        marginRight: '4px',
      },
    },
  };
};
export default AutocompleteOverrides;
