import {
  ComponentProps,
  ComponentType,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Icons, IconsList } from '../assets/asyncIcons.jsx';

const iconReplacements = new Map<IconsList, IconsList>([
  ['Database' as 'DataBase', 'DataBase'],
]);

const cache = new Map<IconsList, ComponentType<ComponentProps<'svg'>>>();

export enum IconLoadingState {
  Initial,
  Loading,
  Loaded,
  Error,
}

export function useLazyLoadedIcon(name: IconsList) {
  // We have some deprecated icons, so we need to replace them
  let iconName: IconsList = name;
  if (iconReplacements.has(name)) {
    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console
      console.warn(
        `Icon ${name} is deprecated, use ${iconReplacements.get(name)} instead`,
      );
    }
    iconName = iconReplacements.get(name)!;
  }
  // This ref is used to avoid re-rendering the component when the icon is loaded
  const SvgIconRef = useRef<ComponentType<ComponentProps<'svg'>> | undefined>(
    cache.get(iconName),
  );
  const [SvgIcon, setIcon] = useState<
    ComponentType<ComponentProps<'svg'>> | undefined
  >(() => cache.get(iconName));
  // This state is used to track the loading state of the icon
  const [state, setState] = useState(
    cache.has(iconName) ? IconLoadingState.Loaded : IconLoadingState.Initial,
  );
  // This ref is used to track the icon name, so we can reload the icon if it changes
  const LoadedIcon = useRef<string>(iconName);
  useEffect(() => {
    let destroyed = false;
    if (!iconName) {
      setState(IconLoadingState.Error);
      return;
    }
    // If the icon is already loaded, we don't need to do anything
    if (SvgIconRef.current && LoadedIcon.current === iconName) {
      setState(IconLoadingState.Loaded);
      return;
      // If the icon is already in cache, we don't need to ask for it again
    } else if (cache.has(iconName)) {
      SvgIconRef.current = cache.get(iconName)!;
      LoadedIcon.current = iconName;
      setState(IconLoadingState.Loaded);
      setIcon(() => SvgIconRef.current);
      return;
    }
    const path = `./svg/${iconName}.svg` as const;
    if (!Icons[path]) {
      // eslint-disable-next-line no-console
      console.error(`Icon ${iconName} not found`);
      setState(IconLoadingState.Error);
      return;
    }
    setState(IconLoadingState.Loading);
    Icons[path]()
      .then((icon) => {
        cache.set(iconName, icon.default);
        SvgIconRef.current = icon.default;
        LoadedIcon.current = iconName;
        setIcon(() => SvgIconRef.current);

        // If the component was destroyed before the icon was loaded, we don't need to set the state
        if (destroyed) {
          return;
        }
        setState(IconLoadingState.Loaded);
      })
      .catch(() => {
        if (destroyed) {
          return;
        }
        setState(IconLoadingState.Error);
      });
    return () => {
      destroyed = true;
    };
  }, [iconName, LoadedIcon.current]);
  return { state, SvgIcon: SvgIcon };
}
