import { useTranslation } from '@mint-lib/i18n';
import { SlotPortal } from '@mint-lib/slots';
import { useNavigate } from '@mint/routing';

import { Box, Button, Icon, Typography } from '@myn/mui';

import IllustrationError404 from '../assets/illustration-error-404.png';

export const NotFoundErrorPage = () => {
  const { t } = useTranslation('@mint-lib/error-boundary');
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      // magic number 100px is approx height of the top bar and the layout padding
      height="calc(100vh - 100px)"
      gap={(theme) => theme.spacing(8)}
    >
      <SlotPortal name="topBarTitle">{404}</SlotPortal>
      <Box
        display="inline-flex"
        flexDirection="column"
        justifyContent="center"
        gap={(theme) => theme.spacing(3)}
      >
        <Box
          display="inline-flex"
          gap={(theme) => theme.spacing(1)}
          alignItems={'center'}
        >
          <Icon component={'WarningFilled'} color={'error'} fontSize="large" />
          <Typography variant="h3" color={'error.main'}>
            {t('404 Error')}
          </Typography>
        </Box>
        <Typography variant="h1" color="text.primary" mb={2}>
          {t('Page not found')}
        </Typography>
        <Typography variant="bodyShort01" color="text.primary" mb={2}>
          {t(
            "The page you are looking for doesn't exist or you don't have permission",
          )}
        </Typography>
        <Box display="flex" gap={2}>
          <Button
            onClick={() => navigate({ to: '/' })}
            variant="contained"
            size="large"
            color="primary"
          >
            {t('Back to Start Page')}
          </Button>
        </Box>
      </Box>
      <Box component={'img'} src={IllustrationError404} />
    </Box>
  );
};
