import { useFlags } from '@mint-lib/flags';
import { useTranslation } from '@mint-lib/i18n';
import type { ClientType } from '@mint-lib/profile-helpers';

import {
  Avatar,
  AvatarSize,
  Box,
  Dialog,
  Pagination,
  SearchBar,
  SelectionListItem,
  Truncation,
} from '@myn/mui';

import { useAgencyClients } from '../../api/queries/agencyClients.js';
import { UserType } from '../../api/users/index.js';
import useClientsPaginationAndSearch from '../../hooks/useClientsPaginationAndSearch/useClientsPaginationAndSearch.jsx';

type Props = {
  className?: string;
  onClose: () => void;
  userProfile: Partial<UserType>;
  isActive: boolean;
};

const ClientsModal: React.FC<Props> = ({ onClose, isActive, userProfile }) => {
  const { t } = useTranslation('@myn/permissions');
  const ff = useFlags(['agencyRedesign']);
  const { agencyClients } = useAgencyClients();

  const clients =
    userProfile.assignedClients?.map((assignedClient) => {
      return agencyClients?.find((client) => client.id === assignedClient);
    }) || [];

  const { data, search, pagination } = useClientsPaginationAndSearch(
    clients as ClientType[],
    ff.agencyRedesign,
  );

  return (
    <Dialog
      open={isActive}
      onClose={onClose}
      title={
        ff.agencyRedesign.enabled
          ? t('Clients assigned to this user')
          : t('Clients assigned to') +
            ` ${userProfile.firstName} ${userProfile.lastName}`
      }
      size="m"
      submitButtonProps={{
        label: t('Close'),
        onClick: onClose,
        color: 'primary',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: (theme) => theme.spacing(2),
          justifyContent: 'space-between',
          minHeight: ff.agencyRedesign.enabled ? '640px' : undefined,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: (theme) => theme.spacing(2),
          }}
        >
          {ff.agencyRedesign.enabled ? (
            <Truncation
              tooltipTitle={`${userProfile.firstName} ${userProfile.lastName}`}
              maxWidth={'648px'}
              variant="bodyShort01"
            >
              {t('These are all the clients assigned to {{fullName}}.', {
                fullName: `${userProfile.firstName} ${userProfile.lastName}`,
              })}
            </Truncation>
          ) : null}
          {ff.agencyRedesign.enabled && clients.length > 1 ? (
            <SearchBar
              {...search}
              size="small"
              placeholder={`${t('Search client')}`}
              sx={{
                maxWidth: '100%',
              }}
            />
          ) : null}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: (theme) => theme.spacing(ff.agencyRedesign.enabled ? 1 : 2),
            }}
          >
            {data.map((client) => {
              return (
                <SelectionListItem
                  readOnly
                  text={client?.name || ''}
                  startAdornment={
                    <Avatar
                      firstName={client?.name}
                      key={client?.id}
                      src={client?.logo}
                      avatarSize={AvatarSize.small}
                    />
                  }
                />
              );
            })}
          </Box>
        </Box>
        {ff.agencyRedesign.enabled && pagination.count > 1 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Pagination size="medium" {...pagination} />
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default ClientsModal;
