/* eslint-disable no-underscore-dangle */

/** @deprecated this is a part of source code of react-datepicker and when the inner logic change this functions stops work. Need to find more elegant solution after MVP */
export function getLocaleObject(localeSpec = 'en-GB') {
  if (typeof localeSpec === 'string') {
    // Treat it as a locale name registered by registerLocale
    const scope = typeof window !== 'undefined' ? window : globalThis;
    // @ts-ignore
    return scope.__localeData__ ? scope.__localeData__[localeSpec] : null;
  } else {
    // Treat it as a raw date-fns locale object
    return localeSpec;
  }
}
