import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_secured/campaign/$campaignId/settings/microsoft/search/',
)({
  beforeLoad: () => {
    throw redirect({
      to: '/campaigns',
    });
  },
});
