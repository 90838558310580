import { useFlags } from '@mint-lib/routing-context';

import { Avatar, AvatarGroup, AvatarSize, Box } from '@myn/mui';

import { useAgencyClients } from '../../../api/queries/agencyClients.js';
import { UserType } from '../../../api/users/index.js';

export type Props = UserType & {
  onClick: (user: UserType) => void;
};

const ClientsCell = ({ onClick, ...value }: Props) => {
  const ffArm = useFlags('@mint/arm', ['userManagemetFixes']);
  const { agencyClients } = useAgencyClients();

  const clients =
    value?.assignedClients?.map((assignedClient) => {
      return agencyClients?.find((client) => client.id === assignedClient);
    }) || [];

  return (
    <Box
      sx={{
        display: 'flex',
        whiteSpace: 'nowrap',
        gap: (theme) => theme.spacing(1),
        zIndex: ffArm.userManagemetFixes.enabled ? 1 : undefined,
      }}
    >
      <AvatarGroup
        max={4}
        size={AvatarSize.small}
        bgColor="primary"
        onClick={() => onClick(value)}
      >
        {clients.map((client) => (
          <Avatar
            firstName={client?.name}
            key={client?.id}
            src={client?.logo}
          />
        ))}
      </AvatarGroup>
    </Box>
  );
};

export default ClientsCell;
