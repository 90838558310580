export const FeaturePermissionsConstants = {
  FULL_CONTROL: 'full_control',
  MODIFY: 'modify',
  VIEW: 'view',
  VIEW_DUMMY_NN: 'view_dummy_nn',
  VIEW_DUMMY: 'view_dummy',
};

export const PERMISSIONS_HIERARCHY = ['view', 'modify', 'full_control'];

export type FeaturePermission = 'view' | 'modify' | 'full_control';
