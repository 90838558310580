import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
} from 'react';

import { AuthContext } from '@mint-lib/auth';
import { useFlagsmith } from '@mint-lib/flags';
import { useService } from '@mint-lib/hooks';

import { UserProfile } from '../getUserProfile/index.js';
import { ProfileService } from '../services/ProfileService.js';

export type AmbiguousUserProfile = null | UserProfile;

export type ProfileContextValue<IsAmbiguous extends boolean> = {
  profileData: IsAmbiguous extends true ? AmbiguousUserProfile : UserProfile;
  setProfileData: Dispatch<SetStateAction<AmbiguousUserProfile>>;
  profileService: ProfileService;
};

// @ts-ignore
export const ProfileContext = createContext<ProfileContextValue>();

/**
 * @deprecated
 */
export const ProfileProvider = ({
  children,
  value = null,
}: {
  children: ReactNode;
  value?: AmbiguousUserProfile;
}) => {
  const auth = useContext(AuthContext);
  const [profileService] = useService(ProfileService, auth);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.warn(
      'ProfileProvider from @mint-lib/profile-helpers has been deprecated, please use the new ProfileProvider from @mint-lib/routing-context instead.',
    );
  }, []);

  const flagSmith = useFlagsmith();
  useEffect(() => {
    const profileData = value ?? profileService.getProfileData();
    if (profileData) {
      flagSmith.identify(profileData.ffId);
    }
  }, [profileService.getProfileData()]);

  return (
    <ProfileContext.Provider
      value={{
        profileData: profileService.getProfileData(),
        setProfileData: profileService.setProfileData.bind(profileService),
        profileService,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
