import type { ReactNode } from 'react';

import { Box, Illustration, Typography } from '@myn/mui';

type Props = {
  header: ReactNode;
  message: ReactNode;
};

const NoData = ({ header, message }: Props) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: `calc(100vh - 350px)`,
    }}
  >
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Illustration
        component={'InterfaceSearching'}
        width="160px"
        height="160px"
      />

      <Box>
        <Typography variant="h4">{header}</Typography>
      </Box>
      <Box sx={{ overflow: 'hidden', overflowWrap: 'break-word' }}>
        <Typography
          variant="bodyShort01"
          color={(theme) => theme.palette.text.secondary}
        >
          {message}
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default NoData;
